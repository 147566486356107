/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import {
  createGroup,
  resetGroupForm,
  getGroupList,
} from "../redux/actions/groups";
/* mui icons-material components */

import AddGroup from "./AddGroup";
import GroupsTable from "./GroupsTable";



class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    /* Function bindings here */
  }

  /**
   * When the component is rendered
   */
  componentDidMount() {
    this.props.getGroupList();
  }

  /**
   * When the component is updated
   */
  componentDidUpdate() {}

  /**
   *
   * @returns the component
   */
  render() {
    return (
      <div id="groups-page-container">
        <AddGroup />
        <GroupsTable />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups.groups,
  };
};

export default connect(mapStateToProps, {
  createGroup,
  resetGroupForm,
  getGroupList,
})(Groups);
