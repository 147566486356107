/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { createUser, resetUserForm, getUserList } from '../redux/actions/users';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';

/* mui icons-material components */
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import i18n from '../i18n'


class AddUser extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            email: '',
            userRole: 'none',
            loggedIn: false,
            loginFailed: false,
            userGroup: -1
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
        this.handleUserGroup = this.handleUserGroup.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        if(window.location.pathname=='/adminpanel'){
            window.addEventListener('keypress', (e) =>{
                if(e.key === 'Enter'){
                    this.handleSubmit()
                }
            })
        }
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isSuccess !== this.props.isSuccess && this.props.isSuccess){
            this.props.getUserList()
        }
    }

    /**
     * 
     */
    handleSubmit(){
        this.props.createUser({
            user:{
                username: this.state.username,
                password: this.state.password,
                email: this.state.email,
                is_superuser: this.state.userRole === 'admin',
                is_staff: this.state.userRole === 'admin',
                group: this.state.userGroup==-1?null:this.state.userGroup
        }, 
            token: `token ${window.localStorage.getItem('token')}`
        })
        this.setState({
            username: '',
            password: '',
            email: '',
            userRole: 'none',
            userGroup: -1
        })
        setTimeout(() => {
            this.props.resetUserForm()
        }, 3000)
    }


    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {
        this.setState({
            userRole: e.target.value
        })
    }

    /**
     * 
     */
     handleUserGroup(e) {
        this.setState({
            userGroup: e.target.value
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div 
                    id='add-user-page-container' 
                >
                    <div id='admin-page-alert-container'>
                      {
                        this.props.isSuccess ?
                        <Alert onClose={this.props.resetUserForm} severity='success'>
                            {/* {`Created a new user: ${this.props.newUser.username}`} */}
                            {`${i18n.t("AddUser.CreatedNewUser")}: ${this.props.newUser.username}`}
                        </Alert> 
                        :
                        null
                      }
                      {
                        this.props.isFailed ?
                        <Alert onClose={this.props.resetUserForm} severity='error'>
                            {/* {`Not able to create user: ${this.props.error}`} */}
                            {`${i18n.t("AddUser.NotAbleToCreateUser")}: ${this.props.error}`}
                        </Alert> 
                        :
                        null
                      }
                    </div>
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        border: 'solid 1px lightgray',
                        background: 'white'
                        }}
                        component="form" 
                        type="form"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* Create User */}
                            {`${i18n.t("AddUser.CreateUser")}`}
                        </Typography>
                        <div id='login-page-username-container'>
                            <Tooltip 
                                // title='Username'
                                title={i18n.t("AddUser.Username")}
                            >
                                <PersonIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                // placeholder='Username'
                                placeholder={i18n.t("AddUser.Username")}
                                autoFocus
                                required
                                value={this.state.username}
                                onChange={this.handleChange('username')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='Password'>
                                <LockIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                // placeholder='Password'
                                placeholder={i18n.t("AddUser.Password")}
                                required
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                type='password'
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='E-mail'>
                                <EmailIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder='E-mail'
                                required
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                            />
                        </div>
                        <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                                }}>
                                <Select
                                    value={this.state.userRole} 
                                    defaultValue='none' 
                                    sx={{height: '42px', color: this.state.userRole=='none'?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleUserRole}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                        {/* Select user role */}
                                        {`${i18n.t("AddUser.SelectUserRole")}`}
                                    </MenuItem>
                                    <MenuItem value='viewer' sx={{fontFamily: 'Poppins'}}>
                                        {/* Viewer */}
                                        {`${i18n.t("AddUser.Viewer")}`}
                                    </MenuItem>
                                    <MenuItem value='admin' sx={{fontFamily: 'Poppins'}}>
                                        {/* Admin */}
                                        {`${i18n.t("AddUser.Admin")}`}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <br/>
                        <div id='admin-panel-formcontrol-container'>
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Select
                                    value={this.state.userGroup} 
                                    defaultValue={-1} 
                                    sx={{height: '42px', color: this.state.userGroup==-1?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleUserGroup}
                                >
                                    <MenuItem value={-1} disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                        {/* Select user group */}
                                        {`${i18n.t("AddUser.SelectUserGroup")}`}
                                    </MenuItem>
                                    {
                                        this.props.groups.map((group)=>
                                            <MenuItem value={group.id} sx={{fontFamily: 'Poppins'}}>{group.name}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <br/>
                        <Button
                            sx={{
                                width: '91%',
                                height: '37px',
                                mb:'20px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                            type='submit'
                        >
                            {/* Submit */}
                            {`${i18n.t("AddUser.Submit")}`}
                        </Button>
                    </Box>
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        isSuccess: state.users.isSuccess,
        isFailed: state.users.isFailed,
        newUser: state.users.newUser,
        error: state.users.error,
        users: state.users.users,
        groups: state.groups.groups,
        language: state.language.language,
    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList })(AddUser)