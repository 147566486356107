import { combineReducers } from 'redux';
import auth from './auth';
import map from './map';
import users from './users';
import areas from './areas';
import maps from './maps';
import annotation from './annotation';
import whatsnew from './whatsnew';
import change from './change'
import groups from './groups';
import detection from './detection';
import object from './object';
import language from './language';
import bucket from './bucket';
import whereelse from './whereelse';
import hosts from './hosts';

export default combineReducers({
  auth,
  map,
  users,
  areas,
  maps,
  annotation,
  whatsnew,
  change,
  groups,
  detection,
  object,
  language,
  bucket,
  whereelse,
  hosts
});