/* eslint-disable eqeqeq */
import {
    SET_ATLASML
} from './types'

export const setAtlasML = (ip) => (dispatch) => {
    dispatch({
        type: SET_ATLASML,
        payload: ip
    })
}