import {
    CREATED_USER,
    FAILED_NEW_USER,
    RESET_USER_FORM,
    USERS_RETRIEVED,
    FAILED_USERS,
    FAILED_TO_DELETE,
    USER_DELETED,
    USER_FETCHED,
    FAILED_USER_FETCH,
    USER_PUT,
    FAILED_USER_PUT,
  } from '../actions/types';

const initialState = {
  error: '',
  newUser: {username: null},
  isSuccess: false,
  isFailed: false,
  users: [],
  isDeleted: false,
  isFailedToDelete: false,
  editedUser: {username: null},
  isUserFetched: false,
  isUserFailedToFetch: false,
  isUserPut: false,
  isFailedToPutUser: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case CREATED_USER:
        return {
          ...state,
          isSuccess: true,
          isFailed: false,
          newUser: action.payload
        };
      case FAILED_NEW_USER:
        return {
          ...state,
          isSuccess: false,
          isFailed: true,
          error: action.payload
        };
      case RESET_USER_FORM:
        return {
          ...state,
          isSuccess: false,
          isFailed: false,
          newUser: {username: null},
          isDeleted: false,
          editedUser: {username: null},
          isUserFetched: false,
          isUserFailedToFetch: false,
          isFailedToDelete: false,
          isUserPut: false,
          isFailedToPutUser: false
        }
      case USERS_RETRIEVED:
        return {
          ...state,
          users: [...action.payload]
        }
      case FAILED_USERS:
        return {
          ...state,
          users: [],
          error: action.payload
      }
      case USER_DELETED:
        return {
          ...state,
          isDeleted: true,
          isFailedToDelete: false,
        }
      case FAILED_TO_DELETE:
        return {
          ...state,
          isDeleted: false,
          isFailedToDelete: true,
        }
      case USER_FETCHED:
        return {
          ...state,
          editedUser: action.payload,
          isUserFetched: true,
          isUserFailedToFetch: false
        }
      case FAILED_USER_FETCH:
        return {
          ...state,
          isUserFetched: false,
          isUserFailedToFetch: false
        }
      case USER_PUT:
        return {
          ...state,
          isUserPut: true,
          isFailedToPutUser: false,
        }
      case FAILED_USER_PUT:
        return {
          ...state,
          editedUser: {username: null},
          isUserPut: false,
          isFailedToPutUser: true,
        }
      default:
        return state;
    }
}
  




