/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { generateWhereelses } from '../redux/actions/whereelse';

/* helpers */

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';

/* mui icons-material components */
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LocationSearchBar from './LocationSearchBar';


class WhereelseSearchForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            whereelseCenter: "",
            radius: 0.05
        }

        /* Function bindings here */
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.setState({
            whereelseCenter: `${this.props.center[0].toFixed(4)}, ${this.props.center[1].toFixed(4)}`
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        try {
            if(prevProps.center!=this.props.center){
                this.setState({
                    whereelseCenter: `${this.props.center[0].toFixed(4)}, ${this.props.center[1].toFixed(4)}`
                })
            }   
        } catch (error) {
            this.setState({
                whereelseCenter: `${prevProps.center[0].toFixed(4)}, ${prevProps.center[1].toFixed(4)}`
            })
        }
    }
    /* */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /* */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };
    /* */
    handleSubmit() {
        this.props.handleFlyTo(this.state.whereelseCenter)
        if(isNaN(this.state.radius)){
            this.props.setAlert(
                i18n.t("WhereelseSearchForm.InputShouldBeANumber"),
                this.props.unsetAlert, 
                null, 
                "", 
                "", 
                false, 
                "error",
                <ErrorOutlineIcon/>,
                false
            )
            return null
        }
        let center = this.state.whereelseCenter.split(',')
        if(center.length!=2){
            this.props.setAlert(
                i18n.t("WhereelseSearchForm.CenterInputShouldBeInDecimal"),
                this.props.unsetAlert, 
                null, 
                "", 
                "", 
                false, 
                "error",
                <ErrorOutlineIcon/>,
                false
            )
            return null
        }
        if(isNaN(center[0])&&isNaN(center[1])){
            this.props.setAlert(
                i18n.t("WhereelseSearchForm.CenterInputShouldBeInDecimal"),
                this.props.unsetAlert, 
                null, 
                "", 
                "", 
                false, 
                "error",
                <ErrorOutlineIcon/>,
                false
            )
            return null
        }
        let l = this.props.displayedLayer.MFStitch.split('/').length
        let geojsonServer = this.props.atlasML.split(":")[0]
        let whereelseBody = {
            center: [Number(center[0]), Number(center[1])],
            radius: Number(this.state.radius),
            polygon: this.props.data.Geojson.geometry.coordinates[0],
            tile_source: this.props.displayedLayer.MFStitch.split('/').slice(0,l-3).join('/'),
            server: `http://${geojsonServer}`,
            object_class: this.props.data.Name,
            map_id: this.props.displayedLayer.id
        }
        this.props.generateWhereelses(whereelseBody, this.props.map, this.props.atlasML)
    }
    /* */
    render () {
            return (
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        marginTop: 'auto', 
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                        <Box sx={{
                            height: 'auto', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center',
                            alignItems: 'center', 
                            width: '500px',
                            borderRadius: '10px',
                            background: 'white'
                            }}
                            component="form" 
                            type="submit"
                            onSubmit={this.props.handleSubmit}>
                            <Typography
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '30px',
                                    fontFamily: 'Poppins',
                                    color: '#121f33'
                                }}
                            >
                                {i18n.t("WhereelseSearchForm.WhereelseSettings")}
                            </Typography>
                            <Alert 
                                severity={"info"}
                                sx={{width:"84%", fontFamily: "Poppins"}}
                            >
                                {i18n.t("WhereelseSearchForm.DoYouWantToRunWhereelse")}
                            </Alert>
                            <br/>
                            <div id='whereelse-location-searchbar-container'>
                                <LocationSearchBar
                                    handleChange={() => this.handleChange('whereelseCenter')}
                                    text={i18n.t("WhereelseSearchForm.Center")}
                                    iconTitle={i18n.t("TimelapsePanel.FlyToTheCoordinate")}
                                    value={this.state.whereelseCenter}
                                    width='465px'
                                    handleSearch={() => this.props.handleFlyTo(this.state.whereelseCenter)}
                                    hasInfo={true}
                                    infoText={i18n.t("TimelapsePanel.DecimalDegreesWithCommaInBetweenLongitudeAndLatitudeOrMGRS")}
                                />
                            </div>
                            <br/>
                            <div id='login-page-username-container'>
                                <Tooltip title={i18n.t("WhereelseSearchForm.Radius")}>
                                    <TrackChangesIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    placeholder={i18n.t("WhereelseSearchForm.QueryCircleRadius")}
                                    value={this.state.radius}
                                    onChange={this.handleChange('radius')}
                                />
                            </div>
                        </Box>
                        <div id='edit-form-button-container'>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.handleSubmit}
                            >
                                {/* Submit */}
                                {i18n.t("ChangeDetectionForm.Start")}
                            </Button>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#DC143C',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#b22222',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleClose}
                            >
                                {/* Close */}
                                {i18n.t("ChangeDetectionForm.Cancel")}
                            </Button>                 
                        </div>
                    </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML,
        /* generateWhereelses */
        isWhereelsesRequested: false,
        isWhereelsesGenerated: false,
        whereelseResults: [],
        isFailedToGenerateWhereelses: false,
        /* getWhereelseList */
        iswhereelseListLoading: false,
        isWhereelseListFetched: false,
        whereelseList: [],
        isFailedToFetchWhereelseList: false
    }
}

export default connect(mapStateToProps, { generateWhereelses })( WhereelseSearchForm )