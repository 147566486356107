/* eslint-disable eqeqeq */
import React from 'react'
import i18n from '../i18n';

/* custom components */
import Header from '../components/Header'
import DatasetCard from '../components/DatasetCard'
import GallerySearchBar from '../components/GallerySearchBar';

/* mui material components */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

/* mui icons-material components */


/* assets */ 


/* Redux */
import { connect } from 'react-redux'
import { loadUser } from '../redux/actions/auth';
import { getAreaList } from '../redux/actions/areas';
import { setLanguage } from '../redux/actions/language';


// const SERVER = process.env.REACT_APP_DJANGO_SERVER

class Gallery extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            areas: [],
            query: ''
        }

        /* Function bindings here */
        this.handleChange=this.handleChange.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount() {
        window.localStorage.removeItem('backward')
        this.props.getAreaList()
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps, prevState) {

    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     * @returns the component 
     */
    render() {
        const result = this.props.areas.filter((area)=>area.RegionName.toLowerCase().includes(this.state.query.toLowerCase()))

        return (
            <div id='main-gallery-container'>
                <Header marginTop='25px'/>
                <Box sx={{flexGrow: 1, mt: '60px', ml: '10px', mr: '10px'}}>
                <GallerySearchBar
                    width='50px'
                    handleChange={this.handleChange}
                    text={i18n.t("Gallery.SearchArea")}
                />
                    <Grid container rowSpacing={2} spacing={{xs: 2, md: 4}} columns={{xs: 4, sm: 8, md: 24}}>
                    {
                        result.map((area,index) =>
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <DatasetCard
                            regionName={area.RegionName}
                            imageUrl={area.Thumbnail}
                            areaId={area.id}
                            center={area.center}
                            />
                        </Grid>
                        )
                    }
                    </Grid>
                </Box>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        areas: state.areas.areaList,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { loadUser, getAreaList, setLanguage })(Gallery)