/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'
/* Redux */
import { connect } from 'react-redux'
import { getDetectionList, getOneDetection, deleteOneDetection } from '../redux/actions/detection';
import { createObject, editOneObject } from '../redux/actions/object';
/* helper functions */

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton';
/* mui icons-material components */
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

class ConfirmedObjectForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            initialName: '',
            name: '',
            description: '',
            geojson: {},
            label: '',
            areaId: null,
            objectId: null
        }
        /* Function bindings here */
        this.handleInitialName=this.handleInitialName.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    /* */
    componentDidMount(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        let areaId=params.areaId
        this.setState({
            initialName: this.props.clickedObject.Name,
            name: this.props.clickedObject.Name,
            geojson: {...this.props.clickedObject.Geojson},
            description: this.props.clickedObject.Description,
            label: this.props.clickedObject.Label,
            areaId: areaId,
            objectId: this.props.clickedObject.id
        })
    }
    /* */
    componentDidUpdate(){
    }
    /* */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /* */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }
    /* */
    handleSubmit() {
        this.props.editOneObject({
            id: this.state.objectId,
            Name: this.state.name,
            Description: this.state.description,
            Geojson: this.state.geojson,
            Storage: this.props.displayedLayer.id,
            Area: Number(this.state.areaId),
            Displayed: true,
            Zoom: 21,
            Label: this.state.label,
            User: this.props.user.id,
        })
        this.props.handleClose()
    }
    /* */
    handleInitialName() {
        this.setState({
            name: this.state.initialName
        })
    }
    /* */
    render () {
        return (
            <ClickAwayListener onClickAway={this.props.handleClose}>
                <div style={{
                    position: 'absolute', 
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    marginBottom: 'auto', 
                    marginTop: 'auto', 
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 200, 
                    width: 'fit-content', 
                    height: 'fit-content',
                    paddingBottom: 10,
                    gap: '10px', 
                    fontFamily: 'Poppins', 
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '7px'
                }}>
                        <Box sx={{
                            height: 'auto', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'flex-start',
                            alignItems: 'center', 
                            width: '500px',
                            borderRadius: '10px',
                            background: 'white'
                            }}
                            component="form" 
                            type="submit"
                            onSubmit={() => this.handleSubmit()}>
                            <Typography
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '30px',
                                    fontFamily: 'Poppins',
                                    color: '#121f33'
                                }}
                            >
                                {/* {`Edit ${this.state.label}`} */}
                                {`${i18n.t("ConfirmedObjectForm.Edit")} ${this.state.label}`} 
                            </Typography>
                            <div id='login-page-username-container'>
                                <Tooltip title='Name'>
                                    <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    // placeholder={'Name'}
                                    placeholder={i18n.t("ConfirmedObjectForm.Name")}
                                    required
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    autoFocus={true}
                                />
                                <Tooltip 
                                    // title='Reset'
                                    title={i18n.t("ConfirmedObjectForm.Reset")} 
                                    placement='right'
                                >
                                    <IconButton 
                                        onClick={() => this.handleInitialName()}
                                    >
                                        <RestartAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <br/>
                            <div id='login-page-username-container'>
                                <Tooltip 
                                    // title='Description'
                                    title={i18n.t("ConfirmedObjectForm.Description")}
                                >
                                    <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    // placeholder={'Description'}
                                    placeholder={i18n.t("ConfirmedObjectForm.Description")}
                                    value={this.state.description}
                                    onChange={this.handleChange('description')}
                                />
                            </div>
                            <br/>
                                <div
                                    id='login-page-username-container'
                                >
                                    <Tooltip title='Geojson'>
                                        <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={JSON.stringify(this.state.geojson)}
                                        id={`${this.state.name}-geojson`}
                                    >
                                    </InputBase>
                                    <Tooltip 
                                        // title='Copy'
                                        title={i18n.t("ConfirmedObjectForm.Copy")} 
                                        placement='right'
                                    >
                                        <IconButton 
                                                onClick={() => this.copyToClipboard(`${this.state.name}-geojson`)}
                                            >
                                            <ContentCopyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                        </Box>
                        <div id='edit-form-button-container'>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={() => this.handleSubmit()}
                            >
                                {/* Submit */}
                                {i18n.t("ConfirmedObjectForm.Submit")}
                            </Button>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#DC143C',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#b22222',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleClose}
                            >
                            {/* Close */}
                            {i18n.t("ConfirmedObjectForm.Close")}
                        </Button>                 
                    </div>
                </div>
            </ClickAwayListener>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        areaId: state.map.areaId,
        user: state.auth.user,
        detectionList: state.detection.detectionList,
        detectionsLoading: state.detection.isDetectionListLoading,
        detectionsFetched: state.detection.isDetectionListFetched,
        detection: state.detection.detection,
        fetchedDetection: state.detection.fetchedDetection,
        detectionFetched: state.detection.isDetectionFetched
    }
}

export default connect(mapStateToProps, { getDetectionList, getOneDetection, createObject, deleteOneDetection, editOneObject })(ConfirmedObjectForm)