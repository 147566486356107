/* eslint-disable eqeqeq */
import axios from 'axios';
import { polygon, centerOfMass,} from "@turf/turf"
import {
    DETECTIONS_LOADING,
    DETECTIONS_FETCHED,
    FAILED_DETECTIONS,
    DETECTION_FETCHED,
    DETECTION_DELETED
  } from './types';
import { addTmpDetectedPolygons } from '../../helpers/helpers';
/* 
Important Note:
Detection components will be used for unconfirmed detections, the output of object detection scripts 
We have another set of redux components for dealing with confirmed object detections actions/object.js, reducers/object.js
*/
// const SERVER = process.env.REACT_APP_DJANGO_SERVER
const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}
/**
 * Get initial detected object list
 * @param {*} body 
 * @returns 
 */
export const getDetectionList = (detection) => (dispatch) => {
    if(!detection){
        dispatch({
            type: DETECTIONS_LOADING,
            payload: detection
        })
    }
    else {
        dispatch({
            type: DETECTIONS_LOADING,
            payload: detection
        })
        axios.get(`/flightsapi/detection?url=${detection.url}&rkey=${detection.id}`, config).then((res) => {
            dispatch({
                type: DETECTIONS_FETCHED,
                payload: res.data
            })
        }).catch((error) => {
            dispatch({
                type: FAILED_DETECTIONS,
                payload: 'Failed to fetch changes'
            })
        })
    }
}
/*Fetch a detection from redis*/
export const getOneDetection = (detection, map) => (dispatch) => {
    dispatch({
        type: DETECTIONS_LOADING,
        payload: detection
    })
    axios.get(`/flightsapi/detection/${detection.id}?url=${detection.url}&rkey=${detection.Name}`, config).then((res) => {
        dispatch({
            type: DETECTION_FETCHED,
            payload: res.data
        })
        let coord = polygon(res.data.geometry.coordinates)
        let center = centerOfMass(coord).geometry.coordinates
        // map.flyTo({
        //     center,
        //     essential: true,
        //     zoom: 21,
        //     speed: 1.5
        // })
        map.setZoom(20)
        map.setCenter(center)
        addTmpDetectedPolygons(map, res.data)
    }).catch((error) => {
        dispatch({
            type: FAILED_DETECTIONS,
            payload: 'Failed to fetch changes'
        })
    })
}
/*Delete a detection from redis*/
export const deleteOneDetection = (detection, map) => (dispatch) => {
    dispatch({
        type: DETECTIONS_LOADING,
        payload: detection
    })
    let id=detection.id
    let rkey=detection.Name
    let path=id?`/${id}?rkey=${rkey}`:`?rkey=${rkey}`
    axios.delete(`/flightsapi/detection${path}`, config).then((res) => {
        dispatch({
            type: DETECTION_DELETED,
            payload: res.data
        })
    }).catch((error) => {
        dispatch({
            type: FAILED_DETECTIONS,
            payload: 'Failed to delete detection'
        })
    })
}