/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'
/* Redux */
import { connect } from 'react-redux'
import { createArea, getAreaList, resetAreaForm, putArea } from '../redux/actions/areas';

/* custom components */
import { BlackCheckbox }from './CustomCheckbox'

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ClickAwayListener from '@mui/material/ClickAwayListener'

/* mui icons-material components */
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import TerrainIcon from '@mui/icons-material/Terrain';
import LinkIcon from '@mui/icons-material/Link';

class AreaForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            regionName: '',
            terrain: '',
            thumbnailLink: '',
            area: {
                RegionName: '',
                Terrain: '',
                Thumbnail: ''
            },
            userGroup: [],
            listOpen: false,
            groups: []
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        /* add checked field to the user groups */
        if(!this.props.isEditMode){
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
            this.setState({
                groups: [...checkedGroups]
            })
        }
    }

    /* When the component is updated */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
        }
        if(prevProps.isEditMode !== this.props.isEditMode && this.props.isEditMode){
            let editedArea=this.props.areaList.find((area)=>area.id==this.props.editedAreaId)
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: editedArea.UserGroup.includes(group.id)}))
            this.setState({
                area: {...editedArea},
                groups: checkedGroups
            })
        }
    }

    /* This is used for both editing and creating new area */
    handleSubmit(){
        if(this.props.isEditMode){
            this.props.putArea({
                UserGroup: this.state.userGroup,
                RegionName: this.state.regionName ? this.state.regionName : this.state.area.RegionName,
                Terrain: this.state.terrain ? this.state.terrain : this.state.area.Terrain,
                Thumbnail: this.state.thumbnailLink ? this.state.thumbnailLink : this.state.area.Thumbnail,
                Polygon: this.props.areaPolygon,
                center: this.props.centerOfArea,
                PolygonData: this.props.areaPolygon,
                id: this.props.id
            })
            this.setState({
                userGroup: [],
                regionName: '',
                terrain: '',
                thumbnailLink: ''
            })
            /* reset groups */
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
            this.setState({
                groups: [...checkedGroups]
            })
            this.props.handleClose()
            setTimeout(() => {
                this.props.resetAreaForm()
            }, this.props.isFailedToPutArea ? 4000 : 2000)
        } else {
            this.props.createArea({
                UserGroup: this.state.userGroup,
                RegionName: this.state.regionName,
                Terrain: this.state.terrain,
                Thumbnail: this.state.thumbnailLink,
                Polygon: this.props.areaPolygon,
                center: this.props.centerOfArea,
                PolygonData: this.props.areaPolygon
            })
            this.setState({
                userGroup: [],
                regionName: '',
                terrain: '',
                thumbnailLink: ''
            })
            /* reset groups */
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
            this.setState({
                groups: [...checkedGroups]
            })
            this.props.handleClose()
            setTimeout(() => {
                this.props.resetAreaForm()
            }, this.props.isFailedToCreateArea? 4000 : 2000)
        }
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /* */
    handleOpen(e) {
        this.setState({
            listOpen: !this.state.listOpen
        })
    }

    /* */
    handleClose(e) {
        this.setState({
            listOpen: false
        })
    }

    /* */
    handleCheckbox(id) {
        this.setState({
            listOpen: true
        })
        let editedGroups=[...this.state.groups]
        let checkedGroup=editedGroups.find(group=>group.id==id)
        let index=this.state.groups.findIndex(group=>group.id==id)
        let checked=checkedGroup.checked
        checkedGroup.checked=!checked
        editedGroups.splice(index, 1, checkedGroup)
        let userGroups=[]
        editedGroups.forEach(group=>group.checked?userGroups.push(group.id):'')
        this.setState({
            groups: editedGroups,
            userGroup: userGroups
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* {this.props.isEditMode ? `Edit Area` : `Create Area`} */}
                            { this.props.isEditMode ? i18n.t("AreaForm.EditArea") : i18n.t("AreaForm.CreateArea")}
                        </Typography>
                        
                        <div id='admin-panel-formcontrol-container'>
                                <FormControl 
                                    placeholder='E-mail'
                                    fullWidth sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <Select
                                            value={-1} 
                                            defaultValue={-1} 
                                            sx={{height: '42px', color: this.state.userGroup.length==0?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                            open={this.state.listOpen}
                                            onClick={this.handleOpen}
                                        >
                                            <MenuItem disabled value={-1} sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                                {/* Select User Groups */}
                                                {i18n.t("AreaForm.SelectUserGroups")}
                                            </MenuItem>
                                            {
                                                this.state.groups.map((group)=>
                                                <MenuItem key={group.id} value={group.id} sx={{fontFamily: 'Poppins'}}>
                                                    <BlackCheckbox checked={group.checked} onChange={() => this.handleCheckbox(group.id)}/>
                                                    <Typography sx={{fontFamily: 'Poppins'}}>{group.name}</Typography>
                                                </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </ClickAwayListener>
                                </FormControl>
                            
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("AreaForm.RegionName")}>
                                {
                                    this.props.isEditMode
                                    ?
                                    <EditLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    :
                                    <AddLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                }
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                // placeholder={this.props.isEditMode ? `${this.state.area.RegionName}` : 'Region Name'}
                                placeholder={this.props.isEditMode ? `${this.state.area.RegionName}` : i18n.t("AreaForm.RegionName")}
                                required
                                value={this.state.regionName}
                                onChange={this.handleChange('regionName')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='Terrain'>
                                <TerrainIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                // placeholder={this.props.isEditMode ? `${this.state.area.Terrain}` : 'Terrain'}
                                placeholder={this.props.isEditMode ? `${this.state.area.Terrain}` :  i18n.t("AreaForm.Terrain")}
                                value={this.state.terrain}
                                onChange={this.handleChange('terrain')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip 
                                // title='Thumnbnail Link'
                                title={i18n.t("AreaForm.ThumbnailLink")}
                            >
                                <LinkIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                // placeholder={this.props.isEditMode ? `${this.state.area.Thumbnail}` : 'Thumbnail'}
                                placeholder={this.props.isEditMode ? `${this.state.area.Thumbnail}` : i18n.t("AreaForm.Thumbnail")}
                                value={this.state.thumbnailLink}
                                onChange={this.handleChange('thumbnailLink')}
                            />
                        </div>
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("AreaForm.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("AreaForm.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        isAreaCreated: state.areas.isAreaCreated,
        isFailedToCreateArea: state.areas.isFailedToCreateArea,
        areaList: state.areas.areaList,
        message: state.areas.message,
        isAreaPut: state.areas.isAreaPut,
        isFailedToPutArea: state.areas.isFailedToPutArea,
        isEditMode: state.areas.isEditMode,
        editedAreaId: state.areas.editedAreaId,
        groups: state.groups.groups
    }
}

export default connect(mapStateToProps, { createArea, getAreaList, resetAreaForm, putArea })(AreaForm)