import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ATLAS_ML } from '../configs/hosts';

/** Reducers **/
import rootReducer from './reducers/index';

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.log(e)
    }
}


function loadFromLocalStorage() {
    /* If the localStorage is empty or can not be read then return the default values */
    const initState = {
        atlasML: ATLAS_ML
    };

    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) return initState
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return initState;
    }
}

const middleware = [thunk];

const persistedState = loadFromLocalStorage()

const store = createStore(
    rootReducer, 
    persistedState,
    composeWithDevTools(applyMiddleware(...middleware)),
    )

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store