import {
    OBJECTS_LOADING,
    OBJECTS_FETCHED,
    CREATED_OBJECT,
    OBJECT_DELETED,
    RESET_OBJECT_FORM,
    FAILED_TO_DELETE_OBJECT,
    OBJECTS_FETCHED_IND,
    OBJECT_PUT,
    FAILED_OBJECT_PUT
  } from '../actions/types';

const initialState = {
    object: {},
    objectList: [],
    isObjectsFetched: false,
    isObjectListLoading: false,
    isObjectCreated: false,
    isFailedToCreateObject: false,
    isObjectDeleted: false,
    isFailedToDeleteObject: false
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case OBJECTS_LOADING:
        return {
            ...state,
            isObjectsFetched: false,
            isObjectListLoading: true
        }
      case OBJECTS_FETCHED:
        return {
            ...state,
            objectList: action.payload,
            isObjectListLoading: false,
            isObjectsCreated: false,
            isObjectsFetched: true
        }
      case OBJECTS_FETCHED_IND:
        return {
            ...state,
            objectList: action.payload,
            isObjectsFetched: true,
            isObjectListLoading: false
        }
      case CREATED_OBJECT:
        return {
            ...state,
            isObjectsFetched: false,
            isObjectCreated: true,
            isFailedToCreateObject: false,
            message: action.payload,
            isObjectListLoading: false
        }
      case RESET_OBJECT_FORM:
        return {
          ...state,
          isObjectsFetched: false,
          isObjectCreated: false,
          isFailedToCreateObject: false,
          isObjectDeleted: false,
          isFailedToDeleteObject: false, 
          message: '',
          isObjectPut: false,
          isFailedToPutObject: false,
          isEditMode: false,
        }
      case OBJECT_DELETED:
        return {
          ...state,
          isObjectsFetched: false,
          isObjectDeleted: true,
          isFailedToDeleteObject: false,
          message: action.payload
        }
      case FAILED_TO_DELETE_OBJECT:
        return {
          ...state,
          isObjectsFetched: false,
          isFailedToDeleteObject: true,
          isObjectDeleted: false,
          message: ''
        }
      case OBJECT_PUT:
        return {
          ...state,
          isObjectPut: true,
        }
      case FAILED_OBJECT_PUT:
        return {
          ...state,
          isObjectPut: false,
        }
      default:
        return state;
    }
}