/* eslint-disable eqeqeq */
import {
    OPEN_LANGUAGE,
    CLOSE_LANGUAGE,
    SET_LANGUAGE
} from './types'

export const openLanguage = () => (dispatch) => {
    dispatch({
        type: OPEN_LANGUAGE,
    })
}

export const closeLanguage = () => (dispatch) => {
    dispatch({
        type: CLOSE_LANGUAGE,
    })
}

export const setLanguage = (language) => (dispatch) => {
    window.localStorage.setItem("language",language)
    dispatch({
        type: SET_LANGUAGE,
        payload: language
    })
}