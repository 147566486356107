import {
    DETECTIONS_LOADING,
    DETECTIONS_FETCHED,
    DETECTION_FETCHED,
    DETECTION_DELETED
  } from '../actions/types';

const initialState = {
  //this is the detection group
    detection: {},
    detectionList: [],
    isDetectionListLoading: false,
    isDetectionListFetched: false,
    isDetectionFetched: false,
  //this is the individual detection
    fetchedDetection: {},
    isDetectionAdded: false,
    isFailedToAddDetection: false,
    isDetectionDeleted: false,
    isFailedToDeleteDetection: false
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case DETECTIONS_LOADING:
        return {
            ...state,
            isDetectionListLoading: true,
            isDetectionListFetched: false,
            isDetectionFetched: false,
            detection: {...action.payload},
            isDetectionDeleted: false
        }
      case DETECTIONS_FETCHED:
        return {
            ...state,
            detectionList: action.payload.features,
            isDetectionListFetched: true,
            isDetectionListLoading: false,
            isDetectionDeleted: false
        }
      case DETECTION_FETCHED:
        return {
            ...state,
            fetchedDetection: {...action.payload},
            isDetectionFetched: true,
            isDetectionListLoading: false,
            isDetectionDeleted: false,
            isDetectionListFetched: false
        }
      case DETECTION_DELETED:
        return {
          ...state,
          isDetectionDeleted: true,
          isDetectionListFetched: false
        }
      default:
        return state;
    }
}
  




