/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next';

/* custom components */
import ChangeLanguage from '../ChangeLanguage';

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components */
import LanguageIcon from '@mui/icons-material/Language';

/** redux */
import { submitLoginForm, resetLogin } from '../redux/actions/auth';
import { openLanguage, closeLanguage } from '../redux/actions/language';

class Login extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            loggedIn: false,
            loginFailed: false,
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        if(window.location.pathname=='/'){
            window.addEventListener('keypress', (e) =>{
                if(e.key === 'Enter'){
                    this.handleSubmit()
                }
            })
        }
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /**
     * 
     */
    handleSubmit(){
        this.props.submitLoginForm({
            username: this.state.username,
            password: this.state.password
        })
        window.localStorage.setItem("language",this.props.language)
        setTimeout(() => {
            this.setState({
                password: ''
            })
            
        }, 200)
    }
    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            !this.props.loading ?
            <div id='login-page-container'>
                <div
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        borderRadius: '2px',
                        display: this.props.isLanguageOpen ? 'none': ''  
                    }}
                >
                    <Tooltip title={i18n.t("Login.Language")}>
                        <IconButton onClick={this.props.openLanguage}>
                            <LanguageIcon sx={{color: 'white'}} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        right: '14px',
                        top: '10px',
                        background: 'white',
                        border: "1px solid white",
                        borderRadius: '2px',
                        display: this.props.isLanguageOpen ? 'flex': 'none',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <ChangeLanguage width={150}/>
                </div>
                <div id='login-page-alert-container'>
                    {
                    this.props.loginFailed ?
                    <Alert onClose={this.props.resetLogin} severity='error'>{i18n.t("Login.InvalidUsernameOrPassword")}</Alert> :
                    null
                    }
                </div>
                <Box sx={{
                    height: '240px', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start',
                    alignItems: 'center', 
                    width: '360px',
                    mb: '250px',
                    borderRadius: '10px',
                    border: 'solid 2px #121f33',
                    background: 'white'
                    }}
                    component="form" 
                    type="submit"
                    onSubmit={this.handleSubmit}
                    >
                    <Typography
                        sx={{
                            height: '70px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '30px',
                            fontFamily: 'Poppins',
                            color: '#121f33'
                        }}
                    >
                        {i18n.t("Login.BaykarMapping")}
                    </Typography>
                    <div id='login-page-username-container'>
                    <InputBase
                        sx={{
                            borderRadius: '3px',
                            background: 'white',
                            fontFamily: 'Poppins',
                            width: '93%',
                        }}
                        placeholder={i18n.t("Login.Username")}
                        autoFocus
                        required
                        value={this.state.username}
                        onChange={this.handleChange('username')}
                    />
                    </div>
                    <br></br>
                    <div id='login-page-username-container'>
                    <InputBase
                        sx={{
                            borderRadius: '3px',
                            background: 'white',
                            fontFamily: 'Poppins',
                            width: '93%',
                        }}
                        placeholder={i18n.t("Login.Password")}
                        required
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        type='password'
                    />
                    {/* <ChangeLanguage/> */}
                    </div>
                    <br/>
                    <Button
                        sx={{
                            width: '91%',
                            height: '37px',
                            mb:'20px',
                            background: '#1aaa55',
                            color: 'white',
                            fontSize: '13px',
                            fontFamily: 'Poppins',
                            '&:hover':{
                                background: '#168f48',
                            },
                            borderRadius: '4px',
                        }}
                        onClick={this.handleSubmit}
                    >
                        {i18n.t("Login.SignIn")}
                    </Button>
                    <Typography>
                        {this.props.username}
                    </Typography>
                </Box>
            </div>
            :
            <div id='login-page-container-loading'>
                <CircularProgress sx={{color: "#121f33"}}/>
            </div>
            )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        loginFailed: state.auth.loginFailed,
        language: state.language.language,
        isLanguageOpen: state.language.isLanguageOpen
    }
}

export default connect(mapStateToProps, { submitLoginForm, resetLogin, openLanguage, closeLanguage })(Login)