import {
    ANNOTATIONS_LOADING,
    ANNOTATIONS_FETCHED,
    CREATED_ANNOTATION,
    ANNOTATION_DELETED,
    RESET_ANNOTATION_FORM,
    FAILED_TO_DELETE_ANNOTATION,
    ANNOTATION_EDIT_MODE_ON,
    ANNOTATION_EDIT_MODE_OFF,
    ANNOTATION_PUT,
    FAILED_ANNOTATION_PUT
  } from '../actions/types';

const initialState = {
    annotation: {},
    annotationList: [],
    isAnnotationListLoading: false,
    isAnnotationCreated: false,
    isFailedToCreateAnnotation: false,
    isAnnotationDeleted: false,
    isFailedToDeleteAnnotation: false,
    isEditMode: false,
    isAnnotationPut: false,
    isFailedToPutAnnotation: false
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case ANNOTATIONS_LOADING:
        return {
            ...state,
            isAnnotationListLoading: true
        }
      case ANNOTATIONS_FETCHED:
        return {
            ...state,
            annotationList: action.payload,
            isAnnotationListLoading: false
        }
      case CREATED_ANNOTATION:
        return {
            ...state,
            isAnnotationCreated: true,
            isFailedToCreateAnnotation: false,
            message: action.payload
        }
      case RESET_ANNOTATION_FORM:
        return {
          ...state,
          isAnnotationCreated: false,
          isFailedToCreateAnnotation: false,
          isAnnotationDeleted: false,
          isFailedToDeleteAnnotation: false, 
          message: '',
          isAnnotationPut: false,
          isFailedToPutAnnotation: false,
          isEditMode: false,
        }
      case ANNOTATION_DELETED:
        return {
          ...state,
          isAnnotationDeleted: true,
          isFailedToDeleteAnnotation: false,
          message: ''
        }
      case FAILED_TO_DELETE_ANNOTATION:
        return {
          ...state,
          isFailedToDeleteAnnotation: true,
          isAnnotationDeleted: false,
          message: ''
        }
      case ANNOTATION_PUT:
        return {
          ...state,
          isAnnotationPut: true,
          isFailedToPutAnnotation: false
        }
      case FAILED_ANNOTATION_PUT:
        return {
          ...state,
          isAnnotationPut: false,
          isFailedToPutAnnotation: true
        }
      case ANNOTATION_EDIT_MODE_ON:
        return {
          ...state,
          isEditMode: true,
          editedAnnotationId: action.payload
        }
      case ANNOTATION_EDIT_MODE_OFF:
        return {
          ...state,
          isEditMode: false,
        }
      default:
        return state;
    }
}
  




