/* eslint-disable eqeqeq */
import React from 'react'

/* Redux */
import { connect } from 'react-redux'

/* custom components */

/* mui material components */
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip'

/* mui icons-material components */
import SearchIcon from '@mui/icons-material/Search';

class AdminSearchBar extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){

    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){

    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Box sx={{
                    height: 'auto', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start',
                    alignItems: 'center', 
                    width: this.props.width,
                    borderRadius: '10px',
                    background: 'white'
                    }}>
                        <div id='areas-searchbar-container'>
                            <Tooltip title={`${this.props.iconTitle}`}>
                                <SearchIcon sx={{color: 'gray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '95%',
                                }}
                                placeholder={this.props.text}
                                required
                                value={this.props.value}
                                onChange={this.props.handleChange('query')}
                            />
                        </div>
                </Box>
            )}
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(AdminSearchBar)