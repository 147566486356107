/* eslint-disable eqeqeq */
import axios from "axios";
import {
  S3_BUCKETS_LOADING,
  S3_BUCKETS_RETRIEVED,
  S3_BUCKET_DELETING,
  S3_BUCKET_DELETED,
  S3_BUCKET_CREATING,
  CREATED_S3_BUCKET,
  FAILED_TO_CREATE_S3_BUCKET,
  RESET_S3_BUCKET_FORM,
  S3_BUCKET_LOADING,
  S3_BUCKET_POLICY_FETCHED,
  S3_BUCKET_CORS_FETCHED,
  S3_BUCKET_EDITING,
  S3_BUCKET_PUT,
  FAILED_TO_DELETE_S3_BUCKET,
  FAILED_S3_BUCKETS,
  FAILED_GROUP_PUT,
  FAILED_TO_FETCH_S3_BUCKET_POLICY,
  FAILED_TO_FETCH_S3_BUCKET_CORS
} from "./types";

// const SERVER = process.env.REACT_APP_AWS_MANAGER_SERVER;

// const config = {
//   headers: {
//     Authorization: `token ${window.localStorage.getItem("token")}`,
//   },
// };

/* */
export const getBucketList = (credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKETS_LOADING
  })
  axios
    .get(`/s3/listbuckets`, {
      params: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
      }
    })
    .then((res) => {
      dispatch({
        type: S3_BUCKETS_RETRIEVED,
        payload: res.data.Buckets,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_S3_BUCKETS,
        payload: "Failed to fetch buckets.",
      });
    });
};
/* */
export const createBucket = (bucket) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_CREATING
  })
  axios
    .post(`/s3/createbucket`, bucket)
    .then((res) => {
      dispatch({
        type: CREATED_S3_BUCKET,
        payload: "Created S3 bucket",
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_TO_CREATE_S3_BUCKET,
        payload: "Failed to create S3 bucket",
      });
    });
};
/* */
export const resetBucketForm = () => (dispatch) => {
  dispatch({
    type: RESET_S3_BUCKET_FORM,
  });
};
/* */
export const deleteBucket = (bucketName, credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_DELETING
  })
  axios
    .delete(`/s3/deletebucket`, {
      params: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        bucketName: bucketName
      }
    })
    .then((res) => {
      dispatch({
        type: S3_BUCKET_DELETED,
        payload: "Deleted S3 Bucket",
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_TO_DELETE_S3_BUCKET,
        payload: "Failed to delete S3",
      });
    });
};
/* */
export const getBucketPolicy = (bucketName, credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_LOADING
  })
  axios
    .get(`/s3/getbucketpolicy`, {
      params: {
        bucketName: bucketName,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
      }
    })
    .then((res) => {
        dispatch({
          type: S3_BUCKET_POLICY_FETCHED,
          payload: res.data.Policy
        });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_TO_FETCH_S3_BUCKET_POLICY,
        payload: 'No Policy',
      });
    });
};
/* */
export const getBucketCors = (bucketName, credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_LOADING
  })
  axios
    .get(`/s3/getbucketcors`, {
      params: {
        bucketName: bucketName,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
      }
    })
    .then((res) => {
        dispatch({
          type: S3_BUCKET_CORS_FETCHED,
          payload: JSON.stringify(res.data.CORSRules)
        });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_TO_FETCH_S3_BUCKET_CORS,
        payload: 'No Cors',
      });
    });
};
/* */
export const putBucketPolicy = (bucketName, policy, credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_EDITING
  })
  axios
    .post(`/s3/putbucketpolicy`, {
      bucketName: bucketName, 
      policy: policy, 
      credentials: credentials
    })
    .then((res) => {
      dispatch({
        type: S3_BUCKET_PUT,
        payload: "Successfully edited the bucket policy.",
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_GROUP_PUT,
        payload: "Failed to edit the bucket policy.",
      });
    });
};
/* */
export const putCORSConfigurations = (bucketName, CORSRules, credentials) => (dispatch) => {
  dispatch({
    type: S3_BUCKET_EDITING
  })
  axios
    .post(`/s3/putbucketcors`,{
      bucketName: bucketName, 
      CORSRules: JSON.parse(CORSRules), 
      credentials: credentials
    })
    .then((res) => {
      dispatch({
        type: S3_BUCKET_PUT,
        payload: "Successfully edited the CORS configurations.",
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_GROUP_PUT,
        payload: "Failed to edit the CORS configuration.",
      });
    });
};