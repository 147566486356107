/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    WHEREELSES_REQUESTED,
    WHEREELSES_GENERATED,
    FAILED_TO_GENERATE_WHEREELSES
  } from './types';

/* */
// const SERVER = process.env.REACT_APP_ATLASML_SERVER
// const config = {
//   headers: {
//     Authorization: `token ${window.localStorage.getItem('token')}`
//   }
// }
/**
 * Get initial detected object list
 * @param {*} body 
 * @returns 
 */
export const generateWhereelses = (whereelse, map, SERVER) => (dispatch) => {
    dispatch({
        type: WHEREELSES_REQUESTED,
        payload: "Searching for similar objects"
    })
    axios.post(`http://${SERVER}/flightsapi/templateMatch`, whereelse).then((res)=>{
        dispatch({
            type: WHEREELSES_GENERATED,
            payload: res.data
        })
        // addWherelseResults(map, res.data)
    }).catch((error)=>{
        dispatch({
            type: FAILED_TO_GENERATE_WHEREELSES,
            payload: 'Failed to generate whereelses'
        })
    })
}