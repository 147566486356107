/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

/* mui icons-material components*/
import PublicIcon from '@mui/icons-material/Public'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import TerrainIcon from '@mui/icons-material/Terrain';
import {getMGRS} from "../helpers/mgrs"

class PanelFooter extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
        this.calculate_gsd = this.calculate_gsd.bind(this)
        this.calculateRatio = this.calculateRatio.bind(this)

    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){

    }

    calculate_gsd(zoom){
        return 156412/(Math.pow(2,zoom))
    }

    calculateRatio( zoomlevel, ppi = 80){
        const gsd = this.calculate_gsd(zoomlevel)
        const ratio = ppi*gsd*100*0.39
        return Math.round(ratio/100)*100
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div id='panel-footer-container'>
                    <Tooltip 
                        // title='Coordinate system' 
                        title={i18n.t("PanelFooter.CoordinateSystem")}
                        placement='top-end'
                    >
                        <PublicIcon sx={{color: 'white'}} fontSize='small'/>
                    </Tooltip>
                    <Typography sx={{color: 'white' , fontSize:'11px', ml: '5px'}}>
                        WGS84 - EPSG:4326
                    </Typography>
                    <Tooltip 
                        // title='Cursor location'
                        title={i18n.t("PanelFooter.CursorLocation")}
                        placement='top-start'
                    >
                        <LocationOnIcon sx={{color: 'white', position: 'absolute', left: '188px'}} fontSize='small'/>
                    </Tooltip>
                    <Typography sx={{color: 'white' , fontSize:'11px', position: 'absolute', left: '208px', fontFamily: 'Poppins'}}>
                        {`${Math.abs(this.props.clickedLocation[0].toFixed(4))}° ${this.props.mapCenter[0]>0?'E ':'W '}`}
                    </Typography>
                    <Typography sx={{color: 'white' , fontSize:'11px', position: 'absolute', left: '270px', fontFamily: 'Poppins'}}>
                        {` | ${Math.abs(this.props.clickedLocation[1].toFixed(4))}° ${this.props.mapCenter[1]>0?'N':'S'}`}
                    </Typography>
                    <Tooltip 
                        // title='Cursor location'
                        title={i18n.t("PanelFooter.MGRS")}
                        placement='top-start'
                    >
                        <Typography sx={{color: 'white' , fontSize:'11px', ml: '5px', position: 'absolute', left: '378px'}}>
                                MGRS:
                        </Typography>
                    </Tooltip>
                    <Typography sx={{color: 'white' , fontSize:'11px',position: 'absolute', left: '427px'}}>
                    {getMGRS(this.props.clickedLocation[0].toFixed(4),this.props.clickedLocation[1].toFixed(4))}
                    </Typography>
                    <Tooltip 
                        // title='Zoom level' 
                        title={i18n.t("PanelFooter.ZoomLevel")}
                        placement='top-start'
                    >
                        <ZoomInIcon sx={{color: 'white', position: 'absolute', left: '568px'}} fontSize='small'/>
                    </Tooltip>
                    <Typography sx={{color: 'white' , fontSize:'12px', position: 'absolute', left: '587px'}}>
                        {`${this.props.zoom.toFixed(2)}`}
                    </Typography>
                    <Tooltip 
                        // title='Elevation'
                        title={i18n.t("PanelFooter.Elevation")}
                        placement='top-start'
                    >
                        <TerrainIcon sx={{color: 'white', position: 'absolute', left: '653px'}} fontSize='small'/>
                    </Tooltip>
                    <Typography sx={{color: 'white' , fontSize:'12px', position: 'absolute', left: '673px'}}>
                        {`${this.props.elevation.toFixed(2)} ft`}
                    </Typography>  
                    <Typography sx={{color: 'white' , fontSize:'12px', position: 'absolute', left: '1950px'}}>
                        {`1:${this.calculateRatio(this.props.zoom)} scale`}
                    </Typography>
            </div>
        )
    }
}

export default (PanelFooter)