/* eslint-disable eqeqeq */
import axios from "axios";
import {
  GROUPS_RETRIEVED,
  FAILED_GROUPS,
  CREATED_GROUP,
  RESET_GROUP_FORM,
  GROUP_DELETED,
  FAILED_TO_DELETE_GROUP,
  GROUP_FETCHED,
  FAILED_GROUP_FETCH,
  FAILED_GROUP_PUT,
  GROUP_PUT,
  FAILED_NEW_GROUP,
} from "./types";

// const SERVER = process.env.REACT_APP_DJANGO_SERVER;

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem("token")}`,
  },
};

/* */
export const getGroupList = () => (dispatch) => {
    axios.get(`/usersapi/auth/group`, config).then((res)=>{
      dispatch({
        type: GROUPS_RETRIEVED,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_GROUPS,
        payload: "Failed to fetch groups.",
      });
    });
};

export const createGroup = (group) => (dispatch) => {
  axios
    .post(`/usersapi/auth/group`, group, config)
    .then((res) => {
      dispatch({
        type: CREATED_GROUP,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_NEW_GROUP,
        payload: "Failed to add",
      });
    });
};

export const resetGroupForm = () => (dispatch) => {
  dispatch({
    type: RESET_GROUP_FORM,
  });
};

export const deleteGroup = (id) => (dispatch) => {
  axios
    .delete(`/usersapi/auth/group/${id}`, config)
    .then((res) => {
      dispatch({
        type: GROUP_DELETED,
        payload: res.status,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_TO_DELETE_GROUP,
        payload: e.response.statusText,
      });
    });
};
export const getEditedGroup = (id) => (dispatch) => {
  axios
    .get(`/usersapi/auth/group/${id}`, config)
    .then((res) => {
      dispatch({
        type: GROUP_FETCHED,
        payload: res.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_GROUP_FETCH,
        payload: e.response.statusText,
      });
    });
};

export const putGroup = (body) => (dispatch) => {
  axios
    .put(`/usersapi/auth/group/${body.group.id}`, body.group, config)
    .then((res) => {
      dispatch({
        type: GROUP_PUT,
        payload: res.status,
      });
    })
    .catch((e) => {
      dispatch({
        type: FAILED_GROUP_PUT,
        payload: e.response.statusText,
      });
    });
};
