/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    CHANGES_LOADING,
    CHANGES_FETCHED,
    FAILED_CHANGES,
    CREATED_CHANGE,
    FAILED_NEW_CHANGE,
    RESET_CHANGE_FORM,
    CHANGE_LOADING,
    CHANGE_FETCHED,
    FAILED_CHANGE,
    CHANGE_EDITING,
    CHANGE_PUT,
    FAILED_TO_PUT_CHANGE,
    CHANGE_DELETING,
    CHANGE_DELETED,
    FAILED_TO_DELETE_CHANGE,
    CHANGE_CREATING,
    CHANGE_DETECTION_STARTED,
    CHANGES_GENERATED,
    FAILED_TO_GENERATE_CHANGES
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}
/* */
export const getChangeList = (url, rkey, map) => (dispatch) => {
    dispatch({
        type: CHANGES_LOADING
    })
    axios.get(`/flightsapi/change?url=${url}&rkey=${rkey}`, config).then((res) => {
      let changesFeatureCollection={...res.data}
      dispatch({
          type: CHANGES_FETCHED,
          payload: changesFeatureCollection
      })
    }).catch((error) => {
        dispatch({
            type: FAILED_CHANGES,
            payload: 'Failed to fetch changes'
        })
    })
}
/* */
export const getChange = (polygonId, url, rkey) => (dispatch) => {
  dispatch({
    type: CHANGE_LOADING
  })
  axios.get(`/flightsapi/change/${polygonId}?url=${url}&rkey=${rkey}`, config).then(res=>{
    dispatch({
      type: CHANGE_FETCHED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_CHANGE,
      payload: 'Failed to fetch change'
    })
  })
}
/* */
export const createChange = (map, draw, rkey, newChange) => (dispatch) => {
  dispatch({
    type: CHANGE_CREATING
  })
  axios.post(`/flightsapi/change?rkey=${rkey}`, newChange, config).then(res=>{
    map.removeLayer(`${rkey}-fill`)
    map.removeLayer(`${rkey}-line`)
    map.removeLayer(`${rkey}-circle`)
    map.removeSource(rkey)
    draw.deleteAll()
    dispatch({
      type: CREATED_CHANGE,
      payload: 'Successfully created change.'
    })
  }).catch((error)=>{
    dispatch({
      type: FAILED_NEW_CHANGE,
      payload: 'Failed to create change'
    })
  })
}
/* */
export const putChange = (map, draw, id, rkey, editedChange) => (dispatch) => {
  dispatch({
    type: CHANGE_EDITING
  })
  axios.put(`/flightsapi/change/${id}?rkey=${rkey}`, editedChange, config).then(res=>{
    map.removeLayer(`${rkey}-fill`)
    map.removeLayer(`${rkey}-line`)
    map.removeLayer(`${rkey}-circle`)
    map.removeSource(rkey)
    draw.deleteAll()
    dispatch({
      type: CHANGE_PUT
    })
  }).catch((error)=>{
      dispatch({
        type: FAILED_TO_PUT_CHANGE
      })
  })   
}
/* */
export const deleteChange = (map, draw, id, rkey) => (dispatch) => {
  dispatch({
    type: CHANGE_DELETING
  })
  let path=id?`/${id}?rkey=${rkey}`:`?rkey=${rkey}`
  axios.delete(`/flightsapi/change${path}`, config).then(res=>{
    map.removeLayer(`${rkey}-fill`)
    map.removeLayer(`${rkey}-line`)
    map.removeLayer(`${rkey}-circle`)
    map.removeSource(rkey)
    draw.deleteAll()
    dispatch({
      type: CHANGE_DELETED,
      payload: 'Successfully deleted the change.'
    })
  }).catch((error)=>{
      dispatch({
        type: FAILED_TO_DELETE_CHANGE,
        payload: 'Failed to delete the change.'
      })
  })  
}
/* */
export const resetChangeForm = () => (dispatch) => {
  dispatch({
    type: RESET_CHANGE_FORM
  })
}
/* */
export const startChangeDetection = (newerId, olderId, queryData, SERVER) => (dispatch) => {
  dispatch({
    type: CHANGE_DETECTION_STARTED,
    payload: "Detecting changes"
  })
  axios.post(`http://${SERVER}/flightsapi/detectchange/${newerId}/${olderId}/3`, queryData).then((res) => {
    dispatch({
        type: CHANGES_GENERATED,
        payload: res.data
    })
  }).catch((error) => {
      dispatch({
          type: FAILED_TO_GENERATE_CHANGES,
          payload: 'Failed to generate changes'
      })
  })
}
/* */
export const resetChangeDetection = () => (dispatch) => {
  dispatch({
    type: FAILED_TO_GENERATE_CHANGES,
    payload: 'Failed to generate changes'
  })
}