/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* redux */
import { connect } from 'react-redux'
import { createGroup, resetGroupForm, getGroupList, getEditedGroup, putGroup } from '../redux/actions/groups';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

/* mui icons-material components */
import GroupIcon from '@mui/icons-material/Group';



class EditGroup extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            name:'',
            id:'',
            
            loggedIn: false,
            loginFailed: false
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
     
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isGroupPut !== this.props.isGroupPut && this.props.isGroupPut){
            this.props.getGroupList()
        }
    }

    /**
     * 
     */
    handleSubmit(){
        this.props.putGroup({
            group:{
                id : this.props.editedGroup.id,
                name: this.state.name,
            }
        })
        setTimeout(() => {
            this.props.resetGroupForm()
        }, 2000)
        this.props.handleClose()
        this.setState({
            name: ''
        })
        setTimeout(() => {
            this.props.resetGroupForm()
        }, 2000)
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /**
     * 
     * @returns the component 
     */
    render () {
            return (    
                <Dialog
                    open={this.props.isDialogOpen}
                >  
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* {`Edit Group`} */}
                            {i18n.t("EditGroup.EditGroup")}
                        </Typography>
                    
                        <div id='login-page-username-container'>
                            <Tooltip title='E-mail'>
                                <GroupIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedGroup.name}`}
                                required
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                            />
                        </div>
                        <br/>
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                // mb:'20px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("EditGroup.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("EditGroup.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        isSuccess: state.groups.isSuccess,
        isFailed: state.groups.isFailed,
        newGroup: state.groups.newGroup,
        error: state.groups.error,
        groups: state.groups.groups,
        editedGroup:state.groups.editedGroup,
        isGroupPut: state.groups.isGroupPut,
        isFailedToPutGroup: state.groups.isFailedToPutGroup,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { createGroup, resetGroupForm, getGroupList, getEditedGroup, putGroup })(EditGroup)