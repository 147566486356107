/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    WHATSNEWS_LOADING,
    WHATSNEWS_FETCHED,
    FAILED_WHATSNEWS,
    CREATED_WHATSNEW,
    FAILED_NEW_WHATSNEW,
    RESET_WHATSNEW_FORM,
    WHATSNEW_FETCHED,
    FAILED_WHATSNEW,
    WHATSNEW_DELETED,
    FAILED_TO_DELETE_WHATSNEW
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}

/**
 * Filters all WHATSNEWs by storage and user id
 * user id is loaded automatically via config
 * @param {*} body 
 * @returns 
 */
export const getWhatsnewList = (area) => (dispatch) => {
    dispatch({
        type: WHATSNEWS_LOADING
    })
    axios.get(`/flightsapi/whatsnew?Area=${area}`, config).then((res) => {
        dispatch({
            type: WHATSNEWS_FETCHED,
            payload: res.data
        })
    }).catch((error) => {
        dispatch({
            type: FAILED_WHATSNEWS,
            payload: 'Failed to fetch changes'
        })
    })
}

/**
 * 
 * @param {*} area 
 * @returns 
 */
export const createWhatsnew = (change) => (dispatch) => {
    axios.post(`/flightsapi/whatsnew`, change, config).then((res) => {
        if(res.data=='Added successfully.'){
          dispatch({
            type: CREATED_WHATSNEW,
            payload: res.data
          }) 
        } else {
          dispatch({
            type: FAILED_NEW_WHATSNEW,
            payload: 'Failed to add the change.'
          })
        }
    }).catch((e) => {
      dispatch({
        type: FAILED_NEW_WHATSNEW,
        payload: `Faild to add the change.`
      })
    })
}

/**
 * reset WHATSNEWs
 */
 export const resetWhatsnewForm = () => (dispatch) => {
  dispatch({
    type: RESET_WHATSNEW_FORM
  })
}

/**
 * 
 */
export const getWhatsnew = (link) => (dispatch) => {
  axios.get(link, config).then((res) => {
    dispatch({
      type: WHATSNEW_FETCHED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_WHATSNEW,
      payload: 'Failed to fetch change'
    })
  })
}

/**
 * 
 */
export const deleteWhatsnew = (id) => (dispatch) => {
  axios.delete(`/flightsapi/whatsnew/${id}`, config).then((res)=>{
    dispatch({
      type: WHATSNEW_DELETED,
      payload: res.status
    })
  }).catch((e)=>{
    dispatch({
      type: FAILED_TO_DELETE_WHATSNEW,
      payload: 'Failed to delete'
    })
  })
}
