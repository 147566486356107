/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'

/*redux*/
import {
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
} from "../redux/actions/bucket";

/* custom components */
import CreateBucket from './CreateBucket'
import BucketTable from './BucketTable'
import Loading from './Loading';

/* mui material components */
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components */
// const config = {
//     headers: {
//       Authorization: `token ${window.localStorage.getItem('token')}`
//     }
// }

class Buckets extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            signedIn: false,
            alert: null
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps,prevState){
        if (prevProps.isBucketDeleting !== this.props.isBucketDeleting && this.props.isBucketDeleting) {
            this.setState({
              alert: <Loading open={true}/>
            })
        }
        if (prevProps.isBucketDeleted !== this.props.isBucketDeleted && this.props.isBucketDeleted) {
            this.setState({
                alert: null
            })
        }
        if (prevProps.isBucketFailedToDelete !== this.props.isBucketFailedToDelete && this.props.isBucketFailedToDelete) {
            this.setState({
                alert: null
            })
        }
        if (prevProps.isBucketEditing !== this.props.isBucketEditing && this.props.isBucketEditing) {
            this.setState({
              alert: <Loading open={true}/>
            })
        }
        if (prevProps.isBucketPut !== this.props.isBucketPut && this.props.isBucketPut) {
            this.setState({
                alert: null
            })
        }
        if (prevProps.isBucketFailedToPut !== this.props.isBucketFailedToPut && this.props.isBucketFailedToPut) {
            this.setState({
                alert: null
            })
        }
    }
    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div 
                    id='maps-page-container'
                >
                    {this.state.alert}
                    {
                        this.props.isBucketCreating
                        ?
                        <div id='area-table-page-container'>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '600px'
                            }}
                            >
                                {/* <Typography sx={{fontFamily: 'Poppins'}}>Creating Bucket</Typography> */}
                                <Typography sx={{fontFamily: 'Poppins'}}>{i18n.t("Buckets.CreatingBucket")}</Typography>
                                <br></br>
                                <CircularProgress sx={{color: "#121f33"}}/>
                            </div>
                        </div>
                        :
                        <CreateBucket />
                    }
                    {
                        this.props.isBucketListLoading
                        ?
                        <div id='area-table-page-container'>
                            <div style={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '600px'
                            }}
                            >
                                {/* <Typography sx={{fontFamily: 'Poppins'}}>Loading Buckets</Typography> */}
                                <Typography sx={{fontFamily: 'Poppins'}}>{i18n.t("Buckets.LoadingBuckets")}</Typography>
                                <br></br>
                                <CircularProgress sx={{color: "#121f33"}}/>
                            </div>
                        </div>
                        :
                        <BucketTable />
                    }
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        alert: state.bucket.alert,
        isBucketListLoading: state.bucket.isBucketListLoading,
        bucketList: state.bucket.bucketList,
        isBucketListFetched: state.bucket.isBucketListFetched,
        isBucketListFailedToFetch: state.bucket.isBucketListFailedToFetch,
        isBucketCreating: state.bucket.isBucketCreating,
        isBucketCreated: state.bucket.isBucketCreated,
        isBucketFailedToCreate: state.bucket.isBucketFailedToCreate,
        bucketPolicy: state.bucket.bucketPolicy,
        bucketCors: state.bucket.bucketCors,
        newBucket: state.bucket.newBucket,
        isBucketLoading: state.bucket.isBucketLoading,
        isBucketFetched: state.bucket.isBucketFetched,
        isBucketFailedToFetch: state.bucket.isBucketFailedToFetch,
        isBucketPolicyFetched: state.bucket.isBucketPolicyFetched,
        isBucketPolicyFailedToFetch: state.bucket.isBucketPolicyFailedToFetch,
        isBucketCorsFetched: state.bucket.isBucketCorsFetched,
        isBucketCorsFailedToFetch: state.bucket.isBucketCorsFailedToFetch,
        isBucketDeleting: state.bucket.isBucketDeleting,
        isBucketDeleted: state.bucket.isBucketDeleted,
        isBucketFailedToDelete: state.bucket.isBucketFailedToDelete,
        isBucketEditing: state.bucket.isBucketEditing,
        isBucketPut: state.bucket.isBucketPut,
        isBucketFailedToPut: state.bucket.isBucketFailedToPut,
    }
}

export default connect(mapStateToProps, { 
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
})(Buckets)