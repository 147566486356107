/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    MAPS_RETRIEVED,
    FAILED_MAPS,
    STORAGE_FLIGHTS_RETRIEVED,
    FAILED_STORAGE_FLIGHTS,
    // STITCHED_DIRS_RETRIEVED,
    // FAILED_STITCHED_DIRS,
    FLIGHT_INFO_FETCHED,
    FAILED_FLIGHT_INFO,
    PROCESSED_DIRS_RETRIEVED,
    FAILED_PROCESSED_DIRS,
    MAP_DELETED,
    FAILED_TO_DELETE_MAP,
    RESET_MAP_FORM,
    IS_XYZ,
    FAILED_IS_XYZ,
    MAP_ADDED,
    MAP_FETCHED,
    FAILED_TO_ADD_MAP,
    AREA_PUT,
    FAILED_MAP_FETCH,
    MAP_PUT,
    FAILED_MAP_PUT
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}

/**
 * Fetches all the layers data from the metastore
 * @returns 
 */
export const getMapList = () => (dispatch) => {
    axios.get(`/flightsapi/storage`, config).then((res) => {
        dispatch({
            type: MAPS_RETRIEVED,
            payload: res.data
        })
    }).catch((e) => {
        dispatch({
            type: FAILED_MAPS,
            payload: e.response.statusText
        })
    })
}

/**
 * Fetches the flight list in your storage device
 * @param {*} uri
 * @returns 
 */
export const getFlightList = (uri) => (dispatch) => {
  let flightList = []
  let ipAddress=uri.split('/')[0]
  let flightsDirArr=uri.split('/').slice(1)
  let flightsDir=''
  flightsDirArr.forEach((dir)=>flightsDir+=`/${dir}`)
  axios.get(`http://${uri}`).then((res) => {
    let indexArray = res.data.split('\n').filter((text) => text.startsWith("<a"))
    indexArray.forEach((index) => {
      let nameWithSlash = index.split('"')[1]
      let l = nameWithSlash.length
      let name = nameWithSlash.substring(0,l-1)
      flightList.push(name)
    })
    dispatch({
      type: STORAGE_FLIGHTS_RETRIEVED,
      payload: {
        flightList,
        ipAddress,
        flightsDir
      }
    })
  }).catch((e)=>{
    dispatch({
      type: FAILED_STORAGE_FLIGHTS,
      payload: 'Bad request'
    })
  })
}

/**
 * 
 * @param {*} flightPath 
 * @returns 
 */
 export const getMapGeojson = (flightPath, flightDirName) => (dispatch) => {
  axios.get(`http://${flightPath}/records/map.geojson`).then(res=>{
    let info = res.data.features ? res.data.features[0] : res.data
    dispatch({
      type: FLIGHT_INFO_FETCHED,
      payload: {
        flightInfo: { PolygonData: info },
        flightName: flightDirName
      }
    })
  }).catch((e)=>{
    dispatch({
      type: FAILED_FLIGHT_INFO,
      payload: {
        message: 'Flight info does not exist',
        flightName: flightDirName
      }
    })
  })
}


/**
 * If flight info json does not exist look for map.geojson
 * @param {*} flightPath 
 * @returns 
 */
export const getFlightInfo = (flightPath, flightDirName) => (dispatch) => {
  axios.get(`http://${flightPath}/records/flight_info.json`).then(res=>{
    dispatch({
      type: FLIGHT_INFO_FETCHED,
      payload: {
        flightInfo: res.data,
        flightName: flightDirName
      }
    })
  }).catch((e)=>{
    axios.get(`http://${flightPath}/records/map.geojson`).then(res=>{
      let info = res.data.features ? res.data.features[0] : res.data
      dispatch({
        type: FLIGHT_INFO_FETCHED,
        payload: {
          flightInfo: { PolygonData: info },
          flightName: flightDirName
        }
      })
    }).catch((e)=>{
      dispatch({
        type: FAILED_FLIGHT_INFO,
        payload: {
          message: 'Flight info does not exist',
          flightName: flightDirName
        }
      })
    })
  })
}



/**
 * 
 * @param {*} map 
 * @returns 
 */
export const addFlightFromStorage = (map) => (dispatch) => {
  axios.post(`/flightsapi/storage`, map, config).then((res) => {
    if(res.data.message=='Added successfully'){   
      if(res.data.areaId){
        axios.get(`/flightsapi/storage?Area=${res.data.areaId}`, config).then((res0)=>{
          axios.get(`/flightsapi/area/${res.data.areaId}`, config).then((res1) => {
            let area = res1.data
            let flightsCount=res0.data.length
            area.FlightsCount = flightsCount
            axios.put(`/flightsapi/areas/${area.id}`, area, config).then((res2)=>{
              dispatch({
                type: MAP_ADDED,
                payload: {
                  message: res.data.message,
                  area: res.data.area
                }
              })
              dispatch({
                type: AREA_PUT,
                payload: res2.status
              })
            })
          })
        })
      } else {
        dispatch({
          type: MAP_ADDED,
          payload: {
            message: res.data.message,
            area: res.data.area
          }
        }) 
      }
    } else {
      dispatch({
        type: FAILED_TO_ADD_MAP,
        payload: res.data.FlightName[0]
      })
    }
  }).catch((e) => {
    dispatch({
      type: FAILED_TO_ADD_MAP,
      payload: e.response.statusText,
    })
  })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const deleteMap = (id) => (dispatch) => {
  axios.delete(`/flightsapi/storage/${id}`, config).then((res) => {
    if(res.data.areaId){
      axios.get(`/flightsapi/storage?Area=${res.data.areaId}`).then((res0)=>{
        axios.get(`/flightsapi/area/${res.data.areaId}`).then((res1) => {
          let area = res1.data
          let flightsCount=res0.data.length
          area.FlightsCount = flightsCount
          axios.put(`/flightsapi/areas/${area.id}`, area, config).then((res2)=>{
            dispatch({
              type: MAP_DELETED,
              payload: {
                message: res.data.message,
                area: res.data.areaId,
                status: res.data.status
              }
            })
            dispatch({
              type: AREA_PUT,
              payload: res2.status
            })
          })
        })
      })
    } else {
      dispatch({
        type: MAP_DELETED,
        payload: {
          message: res.data.message,
          area: res.data.areaId,
          status: res.data.status
        }
      })
    }
  }).catch((error) => {
    dispatch({
      type: FAILED_TO_DELETE_MAP,
      payload: error.response.statusText
    })
  })
}

/**
 * Fetches the flight list in your storage device
 * @param {*} uri
 * @returns 
 */
 export const getProcessedDirs= (uri) => (dispatch) => {
  let processedList = []
  axios.get(`http://${uri}`).then((res) => {
    let indexArray = res.data.split('\n').filter((text) => text.startsWith("<a"))
    indexArray.forEach((index) => {
      let nameWithSlash = index.split('"')[1]
      let l = nameWithSlash.length
      let name = nameWithSlash.substring(0,l-1)
      processedList.push(name)
    })
    dispatch({
      type: PROCESSED_DIRS_RETRIEVED,
      payload: processedList
    })
  }).catch((e)=>{
    dispatch({
      type: FAILED_PROCESSED_DIRS,
      payload: 'Bad request'
    })
  })
}

/**
 * 
 * @returns 
 */
export const resetMapForm = () => (dispatch) => {
  dispatch({
    type: RESET_MAP_FORM
  })
}

/**
 * Not in use but let's keep it here just in case
 */
export const isXyz = (stitchedDir) => (dispatch) => {
  axios.get(`http://${stitchedDir}`).then((res) => {
    let indexArray = res.data.split('\n').filter((text) => text.startsWith("<a"))
    dispatch({
      type: IS_XYZ,
      payload: '{Checking...}'
    })
    setTimeout(() => {
      dispatch({
        type: IS_XYZ,
        payload: indexArray.length<30 && indexArray.length>3 ? '/{z}/{x}/{y}.jpg' : '/a{quadkey}.jpg'
      })
    },2000)
  }).catch((e) => {
    dispatch({
      type: FAILED_IS_XYZ,
      payload: 'Bad request'
    })
  })
}
export const getEditedMap = (id) => (dispatch) => {
  axios.get(`/flightsapi/storage/${id}`, config).then((res)=>{
    dispatch({
      type: MAP_FETCHED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_MAP_FETCH,
      payload: e.response
    })
  })
}
/**
 * 
 * @param {*} id 
 * @returns 
 */
 export const putMap = (body) => (dispatch) => {
  axios.put(`/flightsapi/storage/${body.map.id}`, body.map, config).then((res)=>{
    dispatch({
      type: MAP_PUT,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_MAP_PUT,
      payload: e.response
    })
  })
}