/* eslint-disable eqeqeq */
import React from 'react'

/* Redux */
import { connect } from 'react-redux'
import { createArea, getAreaList, resetAreaForm, putArea } from '../redux/actions/areas';

/* custom components */

/* mui material components */
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip'

/* mui icons-material components */
import SearchIcon from '@mui/icons-material/Search';

class GallerySearchBar extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){

    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){

    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div style={{
                    height: "47px",  
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start',
                    alignItems: 'center', 
                    background: '#121f33',
                    zIndex: 10000,
                    position: 'absolute',
                    right: '95px',
                    top: '0px',
                    width: '300px',
                }}>
                    <div id='gallery-searchbar-container'>
                        <Tooltip title={`${this.props.iconTitle}`}>
                            <SearchIcon sx={{color: 'gray', ml: '5px', mr: '4px'}}/>
                        </Tooltip>
                        <InputBase
                            sx={{
                                borderRadius: '3px',
                                background: 'white',
                                fontFamily: 'Poppins',
                                width: '95%',
                            }}
                            placeholder={this.props.text}
                            autoFocus
                            value={this.props.value}
                            onChange={this.props.handleChange('query')}
                        />
                    </div>
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        isAreaCreated: state.areas.isAreaCreated,
        isFailedToCreateArea: state.areas.isFailedToCreateArea,
        areaList: state.areas.areaList,
        message: state.areas.message,
        isAreaPut: state.areas.isAreaPut,
        isFailedToPutArea: state.areas.isFailedToPutArea,
        isEditMode: state.areas.isEditMode,
        editedAreaId: state.areas.editedAreaId
    }
}

export default connect(mapStateToProps, { createArea, getAreaList, resetAreaForm, putArea })(GallerySearchBar)