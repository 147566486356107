import React, { useEffect, useState } from 'react';
import i18n from './i18n';
import ReactCountryFlag from "react-country-flag"

/* mui */
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

/* redux */
import { connect } from 'react-redux'
import { setLanguage, closeLanguage } from './redux/actions/language'

const ChangeLanguage = (props) => {
   const [locale, setLocale] = useState(props.language) 
   const locales = ["GB","TR"]

   useEffect(() => {
    if(window.localStorage.getItem("language")){
      setLocale(window.localStorage.getItem("language"))
    } else {
      setLocale("TR")
    }
   },[])

  const handleChange = (event) => {
    props.closeLanguage()
    setLanguage(event.target.value)
    i18n.changeLanguage(event.target.value)
    setLocale(event.target.value)
    props.setLanguage(event.target.value)
  }

  return (
    <div 
      style={{
        display: "flex", 
        flexDirection: "row", 
        alignItems:"center", 
        justifyContent: "center",
        gap: "5px"
      }}
    >
        <Select
          value={locale}
          onChange={handleChange}
          onClose={()=>props.closeLanguage()}
        >
          {
            locales.map((lng, i)=>
              <MenuItem key={i} value={lng}>
                <ReactCountryFlag countryCode={lng}/>
              </MenuItem>
            )
          }
        </Select>
        <Snackbar />
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
      language: state.language.language
  }
}

export default connect(mapStateToProps, { setLanguage, closeLanguage })(ChangeLanguage)