/* eslint-disable eqeqeq */
import React from 'react'

/* mui material components */
import MenuItem from '@mui/material/MenuItem'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

class DateDropdown extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isDropdownOpen: false
        }

        /* Function bindings here */
        this.handleDropdown = this.handleDropdown.bind(this);
        this.handleCloseDropdown = this.handleCloseDropdown.bind(this);
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){

    }

    /* */
    handleDropdown(e) {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        })
    }

    /* */
    handleCloseDropdown() {
        this.setState({
            isDropdownOpen: false
        })
    }

    /* */
    handleClickOnDate(flightDate) {
        let displayedLayer=this.props.rasterLayers.find((layer)=>layer.FlightDate===flightDate)
        this.props.handleClickOnLayer(displayedLayer.id, true)
    }

    /* */
    render () {

        return (
            <ClickAwayListener onClickAway={() => this.handleCloseDropdown()}>
                <Box sx={{
                    position: 'relative'
                }}>
                    <Button 
                        variant='outlined' 
                        sx={{
                            color: `${this.props.textColor}`, 
                            borderColor: 'lightgray', 
                            fontFamily: 'Poppins',
                            justifyContent: 'space-between',
                            width: '150px',
                            gap: '5px',
                            "&:hover": {
                                borderColor: `${this.props.textColor}`,
                            }
                        }} 
                        onClick={this.handleDropdown}
                    >
                        {this.props.displayedLayer.FlightDate}
                        {
                            this.state.isDropdownOpen?
                            <ArrowDropUpIcon sx={{color: `${this.props.textColor}`}}/>:
                            <ArrowDropDownIcon sx={{color: `${this.props.textColor}`}}/>
                        }
                    </Button>
                    {
                        this.state.isDropdownOpen?
                        <Box sx={{
                            position: 'fixed', 
                            right: 0, 
                            left: `${this.props.left}`, 
                            zIndex: 1500, 
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            width: `${this.props.width}`,
                            fontFamily: 'Poppins',
                            border: '1px solid lightgray'
                        }}>
                            {
                                this.props.rasterLayers.map((layer, i) => 
                                <MenuItem 
                                    sx={{fontFamily: 'Poppins', color: 'black', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} 
                                    value={layer.FlightDate} 
                                    onClick={() => this.handleClickOnDate(layer.FlightDate)}
                                >
                                    {layer.FlightDate}
                                </MenuItem>
                                )
                            }
                        </Box>
                        :
                        null
                    }
                </Box>
            </ClickAwayListener>
        )
    }
}

export default (DateDropdown)