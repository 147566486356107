/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";

import {
  createGroup,
  resetGroupForm,
  getGroupList,
} from "../redux/actions/groups";

/* custom components */

/* mui material components */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";

/* mui icons-material components */
import GroupIcon from '@mui/icons-material/Group';
import i18n from "../i18n";

class AddGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loggedIn: false,
      loginFailed: false,
      userGroup: "",
    };

    /* Function bindings here */
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * When the component is rendered
   */
  componentDidMount() {
    if (window.location.pathname == "/adminpanel") {
      window.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
          this.handleSubmit();
        }
      });
    }
  }

  /**
   * When the component is updated
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isSuccess !== this.props.isSuccess && this.props.isSuccess) {
      this.props.getGroupList();
    }
  }

  /**
   *
   */
  handleSubmit() {
    this.props.createGroup({
      name: this.state.userGroup,
    });
    this.setState({
      userGroup: "",
    });
    setTimeout(() => {
      this.props.resetGroupForm();
    }, 2000);
  }
  
  /**
   *
   */
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  /**
   *
   * @returns the component
   */
  render() {
    return (
      <div id="add-user-page-container">
        <div id="admin-page-alert-container">
          {this.props.isSuccess ? (
            <Alert
              onClose={this.props.resetGroupForm}
              severity="success"
            >{`${this.props.newGroup.status}`}</Alert>
          ) : null}
          {this.props.isFailed ? (
            <Alert
              onClose={this.props.resetGroupForm}
              severity="error"
            > 
              {/* {`Failed Operation`} */}
              {`${i18n.t("AddGroup.FailedOperations")}`}
            </Alert>
          ) : null}
        </div>
        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "500px",
            borderRadius: "10px",
            border: "solid 1px lightgray",
            background: "white",
          }}
          component="form"
          type="form"
          onSubmit={this.handleSubmit}
        >
          <Typography
            sx={{
              height: "70px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px",
              fontFamily: "Poppins",
              color: "#121f33",
            }}
          >
            {/* Create User Group */}
            {`${i18n.t("AddGroup.CreateUserGroup")}`}
          </Typography>
          <div id="login-page-username-container">
            <Tooltip 
              // title="User Group Name"
              title={i18n.t("AddGroup.UserGroupName")}
            >
              <GroupIcon sx={{ color: "lightgray", ml: "5px", mr: "4px" }} />
            </Tooltip>
            <InputBase
              sx={{
                borderRadius: "3px",
                background: "white",
                fontFamily: "Poppins",
                width: "93%",
              }}
              // placeholder="User Group Name"
              placeholder={i18n.t("AddGroup.UserGroupName")}
              autoFocus
              required
              value={this.state.userGroup}
              onChange={this.handleChange("userGroup")}
            />
          </div>
          <br />
          <Button
            sx={{
              width: "91%",
              height: "37px",
              mb: "20px",
              background: "#1aaa55",
              color: "white",
              fontSize: "13px",
              fontFamily: "Poppins",
              "&:hover": {
                background: "#168f48",
              },
              borderRadius: "4px",
            }}
            onClick={this.handleSubmit}
            type="submit"
          >
            {/* Submit */}
            {`${i18n.t("AddGroup.Submit")}`}
          </Button>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSuccess: state.groups.isSuccess,
    isFailed: state.groups.isFailed,
    newGroup: state.groups.newGroup,
    error: state.users.error,
    groups: state.groups.groups,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, {
  createGroup,
  resetGroupForm,
  getGroupList,
})(AddGroup);
