/* eslint-disable eqeqeq */
import axios from 'axios';
// import mapboxgl from 'mapbox-gl'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';

import {
    LOAD_MAP,
    RESET_MAP_STATE
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER
mapboxgl.accessToken = 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg'

const config = {
    headers: {
      Authorization: `token ${window.localStorage.getItem('token')}`
    }
}
  
/* */
export const loadMap = (hashId, mapRef, mapContainerRef, draw, urlSearchParams) => (dispatch) => {
    const params=Object.fromEntries(urlSearchParams.entries())
    axios.get(`/flightsapi/hashedmap/${hashId}`, { 
    })
    .then((res) => {
        let savedState = res.data[0].SavedState
        let fcLayers = res.data[0].Layers    
        let areaId=params.areaId
        let regionName=params.regionName

        axios.get(`/flightsapi/storage?Area=${areaId}`, config).then((res)=>{
            let layers = []
            let filteredRes = res.data.filter((layer) => layer.MFStitch)
            //set compare layers directly if there are only two layers
            if (filteredRes.length === 2) {
                let firstLayer_str = JSON.stringify(filteredRes[0])
                let secondLayer_str = JSON.stringify(filteredRes[1])
                let compareLayers = [firstLayer_str]
                compareLayers.push(secondLayer_str)
                window.localStorage.setItem('firstLayer', compareLayers[0])
                window.localStorage.setItem('secondLayer', compareLayers[1])
            }
            res.data.filter((layer) => layer.MFStitch).forEach((layer) => {
                layer.displayed = true
                layers.push(layer)
            })
            let lng = Number(params.lng)
            let lat = Number(params.lat)
            let center = [lng,lat]
            dispatch({
                type: LOAD_MAP,
                payload: {layers,  fcLayers, savedState, mapRef, center, regionName}
            })
        })
    /**
     * Remove layers stored for the compare page
     */
    try {
        window.localStorage.removeItem('firstLayer')
        window.localStorage.removeItem('secondLayer')
    } catch (error) {
    }
    })
    .catch((error) => {
    })
}

export const resetMapState = () => (dispatch) => {
    dispatch({
        type: RESET_MAP_STATE
    })
}