/* eslint-disable eqeqeq */
import React from 'react'
// import mapboxgl from 'mapbox-gl'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import RadiusMode from '../helpers/RadiusMode'
import { polygon, area, length, centerOfMass, lineString, point } from "@turf/turf"
import axios from 'axios'
import { connect } from 'react-redux'
import { loadMap, resetMapState } from '../redux/actions/map';
import { loadUser } from '../redux/actions/auth';
import { getAnnotationList, createAnnotation, getAnnotation, deleteAnnotation, resetAnnotationForm, editAnnotationModeOn, editAnnotationModeOff } from '../redux/actions/annotation';
import { getWhatsnewList, getWhatsnew, deleteWhatsnew, resetWhatsnewForm } from '../redux/actions/whatsnew';
import { getDetectionList, getOneDetection, deleteOneDetection } from '../redux/actions/detection';
import { getObjectList, resetObjects, getOneObject, deleteOneObject } from '../redux/actions/object';
import {convertKML} from '../helpers/togeojson';
import logoBaykar from '../assets/baykar_haritalama.png'
import logoBaykarDark from '../assets/baykar_haritalama_dark.png'
import i18n from 'i18next'
/*helper functions */
import {
    addGrid,
    addRasterLayer,
    getMapStyle,
    allDisplayed,
    addPopupToEachLayerOnClick,
    setCompareLayers,
    createMap,
    setZoomAndCoordinates,
    moveDrawLayersToTop,
    handleAnnotationVisibility,
    setDimensionTerrain,
    addSatelliteLayers,
    hideStreetLayers,
    showDisplayedLayer,
    addObjectDetectionLayers,
    paintAnnotation,
    drawStyle,
    addAnnotationsToMap,
    addWhatsnewsToMap,
    addObjectsToMap,
    groupBy,
    removeTmpDetectedPolygons,
    removeDetectedPolygons,
    extractLabel,
    removeTmpConfirmedPolygons,
    handleDetectionVisiblity,
    handleConfirmedObjectVisibility,
    handleWhatsNewVisibility,
    paintWhatsnew,
    updateDrawingAnno,
    addWhereelseCircle,
    removeWhereelseCircle,
    isWhereelseCircle,
    removeWhereelseResults,
    addTmpWhereelsePolygons,
    removeTmpWhereelsePolygons,
    isMGRS
} from '../helpers/helpers'
import { toPoint } from '../helpers/mgrs'
/* custom components */
import TimelapsePanel from '../components/TimelapsePanel'
import Header from '../components/Header'
import CopySuccessNotif from '../components/CopySuccessNotif'
import AnnotationForm from '../components/AnnotationForm'
import AnnotationInfo from '../components/AnnotationInfo'
import UploadAnnotation from '../components/UploadAnnotation'
import ChangeInfo from '../components/ChangeInfo'
import DetectedObjects from '../components/DetectedObjects'
import DetectionForm from '../components/DetectionForm'
import ConfirmedObjects from '../components/ConfirmedObjects'
import ConfirmedObjectForm from '../components/ConfirmedObjectForm'
import AlertAction from '../components/AlertAction';
import BasicAlert from '../components/BasicAlert'
import WhereelseForm from '../components/WhereelseForm'
import WhereelseObjects from '../components/WhereelseObjects';
import WhereelseSearchForm from '../components/WhereelseSearchForm'
/* mui material components */
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
/* mui icons-material components */
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import GalleryIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';
import GridViewIcon from '@mui/icons-material/GridView';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import InfoIcon from '@mui/icons-material/Info';
/* assets */
import streets from '../assets/streets.png'
import google from '../assets/google.png'

/**
 *
 */
mapboxgl.accessToken = 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg'
mapboxgl.workerClass = MapboxWorker

/**
 *
 */
const config = {
    headers: {
      Authorization: `token ${window.localStorage.getItem('token')}`
    }
}
/**
 * Set in docker-compose.yml
 */
// const SERVER = process.env.REACT_APP_DJANGO_SERVER
let BASE_URL = process.env.REACT_APP_BASE_URL

class Timelapse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            areaId: null,
            userId: null,
            regionName: null,
            areaCenter: [0, 0],
            lng: 0,
            lat: 0,
            zoom: null,
            mapCenter: [0, 0],
            clickedLocation: [0, 0],
            rasterLayers: [

            ],
            baseLayer: { date: '', url: '' },
            baseLayerIndex: 0,
            displayedLayer: { date: '', url: '' },
            displayedLayerIndex: 0,
            displayedLayerId: null,
            displayAll: true,
            selectedRasterLayers: [

            ],
            timelapseLayers: [

            ],
            timelapseLayerIndex: 0,
            selectedAll: true,
            displayBaseLayer: true,
            displayTimelapseControl: false,
            timelapseTimeout: null,
            played: false,
            drawerOpen: true,
            fullscreen: false,
            removeTimeout: null,
            timeoutDuration: 1000,
            anchorEl: null,
            showPlaybackOptions: false,
            playbackRate: "1x",
            backward: false,
            featureCollectionLayers: [

            ],
            detectedObjects: [

            ],
            selectedAllDetectedObjs: false,
            selectedWhatIsNew :false,
            selectedFcLayerId: null,
            notAnyDetectedObjSelected: true,
            notAnyWhatIsNew:true,
            notAnyFcSelected:true,
            showSuccessAlarm: false,
            successMessage: '',
            hashLink: '',
            alarmAction: '',
            hashedMapLoading: true,
            satelliteLoading: true,
            threeDimension: false,
            mapStyle: 'google',
            styleOptions: ['google', 'streets', 'satelliteStreets', 'dark', 'light'],
            countLoadTime: 0,
            elevation: 0,
            showAnnotationForm: false,
            showUploadForm: false,
            showAnnotationInfo: false,
            annoImported: false,
            newGeojson: '',
            newAnnoLink: '',
            newKML: '',
            selectedAnno: {},
            lastEditedAnno: {},
            annotationGeojson: {},
            annotationList: [],
            showMeasurement: false,
            areaMeasured: 0,
            lengthMeasured: 0,
            areaSaved: 0,
            lengthSaved: 0,
            annoName: '',
            annoDescription: '',
            hideAllAnnos: false,
            hideAllDetectedObjects: false,
            hideAllWhatIsNew:false,
            hideAllConfirmedObjects: false,
            displayAllDetectedObjects: true,
            displayAllWhatIsNew:true,
            displayAllAnnos: true,
            displayAllConfirmedObjects: true,
            whatsnewList: [],
            selectedWhatsnew: {},
            selectedWhatsnewId: null,
            showWhatsnewInfo: false,
            showTileBoundaries: false,
            showDetectionList: false,
            clickedObjectGroup: '',
            showDetectionForm: false,
            confirmedObjectGroups: [

            ],
            confirmedObjects: [

            ],
            showObjectList: false,
            clickedDetectionGroup: {},
            alert: null,
            clickedDetection: {},
            clickedObjectLabel: '',
            clickedObject: {},
            showObjectForm: false,
            detectedObjectsPage: 0,
            clickedPropId: null,
            gridToggeled: false,
            showWhereelseForm: false,
            whereelseCenter: [0,0],
            whereelseSearchRadius: 0, //km
            whereelseSearchRadiusId: '', //created in RadiusMode.js
            showWhereelseList: false,
            confirmWhereelse: false,
            clickedWhereelseObject: {},
            flyToLocation: '',
            showWhereelseSearchForm: false,
        }

        /* Function bindings here */
        this.handleChangeBaseLayer = this.handleChangeBaseLayer.bind(this)
        this.handleNextDisplayedLayer = this.handleNextDisplayedLayer.bind(this)
        this.handlePrevDisplayedLayer = this.handlePrevDisplayedLayer.bind(this)
        this.handleSelectAll = this.handleSelectAll.bind(this)
        this.handleDeselectAll = this.handleDeselectAll.bind(this)
        this.handleSelectOne = this.handleSelectOne.bind(this)
        this.handleDeselectOne = this.handleDeselectOne.bind(this)
        this.handleCheckOne = this.handleCheckOne.bind(this)
        this.handleClickOnLayer = this.handleClickOnLayer.bind(this)
        this.handleHideShowBaseLayer = this.handleHideShowBaseLayer.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.handleShowHideTimelapse = this.handleShowHideTimelapse.bind(this)
        this.handlePlay = this.handlePlay.bind(this)
        this.handlePause = this.handlePause.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleDrawerOpener = this.handleDrawerOpener.bind(this)
        this.handleNextTimelapseLayer = this.handleNextTimelapseLayer.bind(this)
        this.handlePrevTimelapseLayer = this.handlePrevTimelapseLayer.bind(this)
        this.handleMapIcon = this.handleMapIcon.bind(this)
        this.toggleFullScreen = this.toggleFullScreen.bind(this)
        this.handlePlaybackSpeed = this.handlePlaybackSpeed.bind(this)
        this.setPlaybackSpeed = this.setPlaybackSpeed.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleCloseSuccessAlarm = this.handleCloseSuccessAlarm.bind(this)
        this.handleClickOnFcLayer = this.handleClickOnFcLayer.bind(this)
        this.handleEditAnnotation = this.handleEditAnnotation.bind(this)
        this.handleConfirmDeleteAnno = this.handleConfirmDeleteAnno.bind(this)
        this.handleCancelDeleteAnno = this.handleCancelDeleteAnno.bind(this)
        this.handleDeleteAnno = this.handleDeleteAnno.bind(this)
        this.goTo = this.goTo.bind(this)
        this.handleSignOut = this.handleSignOut.bind(this)
        this.handleExtrusion = this.handleExtrusion.bind(this)
        this.handleStyleChange = this.handleStyleChange.bind(this)
        this.handleCheckOneMapFeature = this.handleCheckOneMapFeature.bind(this)
        this.handleCheckAllFeatureList = this.handleCheckAllFeatureList.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleImport = this.handleImport.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUploadToDb = this.handleUploadToDb.bind(this)
        this.handleClickOnWhatsnewLayer = this.handleClickOnWhatsnewLayer.bind(this)
        this.handleSaveAnnotation = this.handleSaveAnnotation.bind(this)
        this.handleClickOnDetectionGroup = this.handleClickOnDetectionGroup.bind(this)
        this.handleClickOnObjectGroup = this.handleClickOnObjectGroup.bind(this)
        this.handleFlyToDetectedObject = this.handleFlyToDetectedObject.bind(this)
        this.handleConfirmObject = this.handleConfirmObject.bind(this)
        this.handleDeleteDetectedObject = this.handleDeleteDetectedObject.bind(this)
        this.setAlert = this.setAlert.bind(this)
        this.unsetAlert = this.unsetAlert.bind(this)
        this.handleFlyToConfirmedObject = this.handleFlyToConfirmedObject.bind(this)
        this.handleEditConfirmedObject = this.handleEditConfirmedObject.bind(this)
        this.handleDeleteConfirmedObject = this.handleDeleteConfirmedObject.bind(this)
        this.handleCheckOneConfirmedObject = this.handleCheckOneConfirmedObject.bind(this)
        this.handleCheckAllConfirmedObjects = this.handleCheckAllConfirmedObjects.bind(this)
        this.handleHideShowTileBoundaries = this.handleHideShowTileBoundaries.bind(this)
        this.handleDeleteWhatsnew = this.handleDeleteWhatsnew.bind(this)
        this.handleDetectedObjectsPageChange = this.handleDetectedObjectsPageChange.bind(this)
        this.handleGridToggle = this.handleGridToggle.bind(this)
        this.handleCancelEdit = this.handleCancelEdit.bind(this)
        this.handleConfirmEdit = this.handleConfirmEdit.bind(this)
        this.handleWhereelse = this.handleWhereelse.bind(this)
        this.handleCircleDraw = this.handleCircleDraw.bind(this)
        this.handleCircleRedraw = this.handleCircleRedraw.bind(this)
        this.handleFlyToWhereelseObject = this.handleFlyToWhereelseObject.bind(this)
        this.handleConfirmWhereelseObject = this.handleConfirmWhereelseObject.bind(this)
        this.handleFlyToEnteredLocation = this.handleFlyToEnteredLocation.bind(this)
        this.handleDeleteDetectedObjects = this.handleDeleteDetectedObjects.bind(this)

        /* Refs */
        this.map = React.createRef()
        this.mapContainer = React.createRef()
        this.draw = React.createRef()
        this.flyToMarker = React.createRef()
    }
    /**
     * When the component is rendered
     */
    componentDidMount() {
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        document.title=params.regionName
        let regionName=params.regionName
        let areaId=params.areaId
        let areaLng=Number(params.areaLng)
        let areaLat=Number(params.areaLat)
        let lng=Number(params.lng)
        let lat=Number(params.lat)
        let zoom=Number(params.zoom)
        /* get feature collections */
        this.props.getAnnotationList(areaId)
        this.props.getWhatsnewList(areaId)
        this.props.getObjectList({Area: areaId})
        this.props.getDetectionList() //this is used only for resetting related redux states because detected objects fetched from the storage  
        /**
         * Render the map only once
         */
        if (this.map.current) return
        /**
         * Create a draw control
         */
        this.draw.current = new MapboxDraw({
            displayControlsDefault: true,
            styles: drawStyle("orange"),
            modes: Object.assign({
              draw_radius: RadiusMode,
            }, MapboxDraw.modes),
        });
        const draw = this.draw.current
        this.setState({
            areaId: areaId,
            regionName: regionName
        })
        this.flyToMarker.current = new mapboxgl.Marker({
            color: '#F84C4C' 
        });

        /**
         * Check the requested url if it is hashed fetch the state from db via redux
         */
        if (this.props.match) {
            let hashId = this.props.match.params.hashId
            this.props.loadMap(hashId, this.map.current, this.mapContainer.current, draw, urlSearchParams)
            this.map.current = this.props.mapRef
        } else {
            /* Remove annotations from the local storage when it is not a shared map */
            window.localStorage.removeItem("annotationList")
            axios.get(`/flightsapi/storage?Area=${areaId}`, config).then((res) => {
                let layers = []
                let filteredRes = res.data.filter((layer) => layer.MFStitch)
                /** Set compare layers */
                setCompareLayers(filteredRes)
                /** Assign new key called displayed to layers */
                res.data.filter((layer) => layer.MFStitch).forEach((layer) => {
                    layer.displayed = true
                    layers.push(layer)
                })
                let center = [lng, lat]
                try {
                    /* Assign map states */
                    this.setState({
                        lng: lng,
                        lat: lat,
                        mapCenter: [lng, lat],
                        areaCenter: [areaLng, areaLat],
                        clickedLocation: [lng, lat],
                        zoom: zoom
                    })
                    /** Create mapbox map */
                    this.map.current = createMap(this.mapContainer.current, center, zoom)
                    setTimeout(() => {
                        this.setState({ hashedMapLoading: false })
                    },4000)
                    this.map.current.addControl(new mapboxgl.ScaleControl(), 'top-right');


                    if (filteredRes.length > 0) {
                        /** Assign states and add tiles to map on load */
                        this.map.current.on('load', () => {
                            let layerId = layers[0].id
                            this.setState({
                                rasterLayers: layers,
                                selectedRasterLayers: layers,
                                timelapseLayers: layers,
                                baseLayer: layers[this.state.baseLayerIndex],
                                displayedLayer: layers[this.state.baseLayerIndex],
                                displayedLayerId: `displayed-layer-${layerId}`,
                            })
                        
                            /** Add google layers */
                            addSatelliteLayers(this.map.current)
                            if(this.state.gridToggeled){
                            addGrid(this.map.current, 2, 2)
                            }
                            /** Add all the layers at once */
                            layers.forEach((layer) => {
                                addRasterLayer(this.map.current, `displayed-layer-${layer.id}`, layer, true, this.state.timeoutDuration)
                            })
                            /** Move first layer to top */
                            this.map.current.moveLayer(`WFdisplayed-layer-${layerId}-png`)
                            this.map.current.moveLayer(`WFdisplayed-layer-${layerId}`)
                            this.map.current.moveLayer(`displayed-layer-${layerId}-png`)
                            this.map.current.moveLayer(`displayed-layer-${layerId}`)
                            /** Hide the other ones */
                            setTimeout(() => {
                                this.setState({ 
                                    satelliteLoading: false, 
                                    showObjectList: false
                                })
                            }, 400);
                            /** hide street layers */
                            hideStreetLayers(this.map.current)
                            /** First make sure that all the requested layers are displayed then hide the ones underneath */
                            /** this was needed for smooth transition when changing map layers */
                            /*counLoadTime increases by one each time a layer gets loaded*/
                            this.map.current.on('idle', () => {
                                let count = this.state.countLoadTime
                                this.setState({
                                    countLoadTime: count+1
                                })
                                if(count+1 >= 1 && count+1 <= filteredRes.length){
                                    showDisplayedLayer(this.map.current, layerId)
                                    this.setState({ 
                                        hashedMapLoading: false
                                    })
                                }
                            });
                            /**build and add annotation lists*/
                            this.setState({
                                annotationList: [...this.props.annotationList],
                                whatsnewList: [...this.props.whatsnewList],
                                objectList: [...this.props.objectList]
                            })
                            addAnnotationsToMap(this.map.current, this.props.annotationList, this.state.displayedLayerId)
                            addWhatsnewsToMap(this.map.current, this.props.whatsnewList, this.state.displayedLayerId)
                            /**build and add detectedObjects*/
                            let detectedObjects=[]
                            layers.forEach((layer)=>{
                                if(layer.DetectedObjects){
                                    let detectedObjectsJson=layer.DetectedObjects
                                    Object.keys(detectedObjectsJson).forEach((key)=>{
                                        detectedObjects.push({
                                            "id": key,
                                            "Name": key,
                                            "url": detectedObjectsJson[key],
                                            "Displayed": true,
                                            "Storage": layer.id,
                                        })
                                    })
                                    this.setState({
                                        detectedObjects: [...detectedObjects]
                                    })
                                    addObjectDetectionLayers(this.map.current, detectedObjects, this.state.displayedLayerId)
                                }
                            })
                            addObjectsToMap(this.map.current, this.props.objectList, this.state.displayedLayerId)
                            /**build confirmed objects*/
                            /*Group by label*/
                            let confirmedObjectByStorage=groupBy(this.props.objectList,'Storage')
                            let confirmedObjectGroups=[]
                            Object.keys(confirmedObjectByStorage).forEach((storage)=>{
                                let byLabel=groupBy(confirmedObjectByStorage[storage],'Label')
                                Object.keys(byLabel).forEach((label)=>{
                                    confirmedObjectGroups.push({
                                        "id": `${label}-${storage}`,
                                        "Name": label,
                                        "Displayed": true,
                                        "Storage": storage
                                    })
                                })
                            })
                            this.setState({
                                confirmedObjectGroups: [...confirmedObjectGroups]
                            })
                            // this.props.objectList
                            /**open a popup window on click*/
                            addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
                        })
                    }
                    /** Set zoom and coordinates when the move ends */
                    this.map.current.on('moveend', () => {
                        let center = this.map.current.getCenter()
                        let zoom = this.map.current.getZoom()
                        this.setState({
                            mapCenter: [center.lng, center.lat],
                            zoom: zoom
                        })
                        setZoomAndCoordinates(this.map.current, urlSearchParams)
                    })
                    this.map.current.on('zoomend', () => {
                        if(this.state.gridToggeled){
                        if(this.map.current.getZoom() > 8 && this.state.zoom <8){
                            this.map.current.removeLayer('graticule')
                            this.map.current.removeLayer('graticule2')
                            this.map.current.removeSource('graticule')
                            addGrid(this.map.current,0.1 , 0.1)}
                        else if(this.map.current.getZoom() < 8 && this.state.zoom >8){
                            this.map.current.removeLayer('graticule')
                            this.map.current.removeLayer('graticule2')
                            this.map.current.removeSource('graticule')
                            addGrid(this.map.current,2 , 2)
                        }
                    }
                        let zoom = this.map.current.getZoom()
                        this.setState({
                            mapCenter: [center.lng, center.lat],
                            zoom: zoom
                        })
                        setZoomAndCoordinates(this.map.current, urlSearchParams)
                    })
                    /** Set clicked location and move the draw layers to top on click */
                    this.map.current.on('click', (e) => {
                        this.flyToMarker.current.remove()
                        moveDrawLayersToTop(this.map.current)
                        if(!this.props.isEditAnnotationModeOn){
                            paintAnnotation(this.map.current, 'none', this.state.annotationList)
                            this.setState({
                                selectedFcLayerId: null,
                                showAnnotationInfo: false,
                            })
                        }
                        paintWhatsnew(this.map.current, 'none', this.state.whatsnewList)
                        this.setState({
                            selectedWhatsnewId: null,
                            showWhatsnewInfo: false,
                            showDetectionList: false,
                            showObjectList: false,
                            showWhereelseList: false,
                            clickedPropId: null
                        })
                        removeTmpDetectedPolygons(this.map.current)
                        removeTmpConfirmedPolygons(this.map.current)
                        removeTmpWhereelsePolygons(this.map.current)
                        removeWhereelseResults(this.map.current)
                        /* needs function: get the clicked polygon on the map instance */
                        const features = this.map.current.queryRenderedFeatures(e.point)
                        if(features[0]!=undefined){
                            let label=features[0].properties.label
                            let propId=features[0].properties.id
                            if(features[0].source.split('-')[0]=='detected'){
                                this.handleClickOnDetectionGroup(`${label}-${this.state.displayedLayer.id}`)
                                this.handleFlyToDetectedObject(`${label}-${propId}`)
                                // this.setAlert(`${label}-${propId} save or delete`, () => this.handleConfirmObject(`${label}-${propId}`), () => this.handleDeleteDetectedObject(`${label}-${propId}`), 'Save', 'Delete', true, 'info', true)
                                this.setAlert(`${label}-${propId} ${i18n.t("Timelapse.SaveOrDelete")}`, () => this.handleConfirmObject(`${label}-${propId}`), () => this.handleDeleteDetectedObject(`${label}-${propId}`), i18n.t("Timelapse.Save"), i18n.t("Timelapse.Delete"), true, 'info', true, true)
                                this.setState({
                                    clickedPropId: propId
                                })
                            }
                            if(features[0].source.split('-')[0]=='object'){
                                let foundObject = this.props.objectList.find(obj=>obj.id==Number(features[0].source.split('-')[1]))
                                this.handleClickOnObjectGroup(`${foundObject.Label}-${foundObject.Storage}`)
                                this.handleFlyToConfirmedObject(foundObject.Name)
                                let content=
                                    <html>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                                            <Tooltip title={foundObject.Description}>
                                                <InfoIcon></InfoIcon>
                                            </Tooltip>
                                            {/* <span>{`${foundObject.Name} edit or delete`}</span> */}
                                            <span>{`${foundObject.Name} ${i18n.t("Timelapse.EditOrDelete")}`}</span>
                                        </div>
                                    </html>
                                this.setAlert(
                                    content, 
                                    () => this.handleEditConfirmedObject(foundObject.Name), 
                                    () => this.handleDeleteConfirmedObject(foundObject.Name), 
                                    // 'Edit',
                                    i18n.t("Timelapse.Edit"), 
                                    // 'Delete',
                                    i18n.t("Timelapse.Delete"), 
                                    true, 
                                    'info',
                                    false,
                                    true
                                    )
                            }
                        }
                        /* */
                    })
                    /** Mouse move events */
                    this.map.current.on('mousemove', (e) => {
                        let point = (e.lngLat)
                        let elevationNow = this.map.current.queryTerrainElevation(point, {exaggerated: false})*3.28084
                        this.setState({
                            clickedLocation: [point.lng, point.lat],
                            elevation: elevationNow < 0 || elevationNow==null ? 0 : elevationNow
                        })
                    })
                    /** add draw control to map */
                    this.map.current.addControl(draw, 'top-right')
                    this.map.current.addControl(new mapboxgl.NavigationControl())
                    this.map.current.on('draw.create', (e) => {
                        this.setState({
                            areaSaved: this.state.areaMeasured,
                            lengthSaved: this.state.lengthMeasured
                        })
                        let drawData=draw.getAll()
                        let feature=drawData.features[0]
                        let {id, ...annotationGeojson} = feature
                        if(isWhereelseCircle(drawData)){
                            addWhereelseCircle(feature.geometry.coordinates[0], this.state.lengthMeasured, feature.id, 64, this.map.current)
                            this.setState({
                                whereelseSearchRadiusId: feature.id
                            })
                        }
                        if(!this.state.showAnnotationInfo&&!isWhereelseCircle(drawData)){
                            this.setState({
                                showMeasurement: false,
                                areaMeasured: 0,
                                lengthMeasured: 0,
                                showAnnotationForm: true,
                                annotationGeojson: {...annotationGeojson},
                            })
                            moveDrawLayersToTop(this.map.current)
                            addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
                        } else {
                            this.setState({
                                whereelseCenter: feature.geometry.coordinates[0],
                                whereelseSearchRadius: this.state.lengthMeasured,
                                showWhereelseForm: true,
                                confirmWhereelse: false,
                            })
                        } 
                    })
                    /** used for showing measurements while drawing */
                    this.map.current.on('draw.render', (e) => {
                        let drawData = draw.getAll()
                        let type=''
                        // let coordinates=[]
                        let geoData={}
                        if(drawData.features.length>0 && !this.state.showAnnotationForm){
                            geoData=drawData.features[0]
                            type=geoData.geometry.type
                            // coordinates=[...geoData.geometry.coordinates]
                            if(this.state.areaMeasured>0||this.state.lengthMeasured>0){
                                this.setState({
                                    showMeasurement: true
                                })
                            }
                        } else {
                            this.setState({
                                showMeasurement: false,
                                areaMeasured: 0,
                                lengthMeasured: 0
                            })
                        }
                        switch (type) {
                            case 'Polygon':
                                this.setState({
                                    areaMeasured: (area(geoData)/1000000).toFixed(4),
                                    lengthMeasured: length(geoData).toFixed(4)
                                })
                                break;
                            case 'LineString':
                                this.setState({
                                    lengthMeasured: (length(geoData)).toFixed(4)
                                })
                                break;
                            case 'Point':
                                break;
                            default:
                                break;
                        }
                    })
                    /** Delete all draw layers as the draw is getting updated */
                    // this.map.current.on('draw.update', (e) => {
                    //     draw.deleteAll()
                    // })
                } catch (error) {
                    console.log('ERROR: ', error)
                    this.map.current = createMap(this.mapContainer.current, [28.9784, 41.0082], 12)
                }
            })
            /** Remove layers stored for the compare page */
            try {
                window.localStorage.removeItem('firstLayer')
                window.localStorage.removeItem('secondLayer')
            } catch (error) {
                console.log('ERROR: ', error)
            }
            /** Handle keyboard events */
            window.addEventListener('keydown', (e) => {
                switch (e.key) {
                    case "ArrowLeft":
                        if (e.ctrlKey && this.state.timelapseLayerIndex > 0) {
                            if (this.state.displayTimelapseControl) {
                                this.handleTimeChange(e, this.state.timelapseLayerIndex - 1)
                            }
                        }
                        break;
                    case "ArrowRight":
                        if (e.ctrlKey && this.state.timelapseLayerIndex <= this.state.timelapseLayers.length - 2) {
                            if (this.state.displayTimelapseControl) {
                                this.handleTimeChange(e, this.state.timelapseLayerIndex + 1)
                            }
                        }
                        break;
                    case " ":
                        if (this.state.played) {
                            if (this.state.displayTimelapseControl) {
                                this.handlePause()
                            }
                        } else {
                            if (this.state.displayTimelapseControl) {
                                this.handlePlay()
                            }
                        }
                        break;
                    default:
                        return 0;
                }
            })
            document.addEventListener('fullscreenchange', () => {
                if (!document.fullscreenElement) {
                    this.setState({ fullscreen: false })
                }
            });
            window.addEventListener("beforeunload", (e) => {
                e.preventDefault()
            });
        }
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps,prevState) {
        if (this.state.played) {
            this.handlePlay()
        }
        /*update annotation list on created*/
        if(prevProps.isAnnotationCreated !== this.props.isAnnotationCreated && this.props.isAnnotationCreated){
            this.props.getAnnotationList(this.state.areaId)
            this.setState({
                annoImported: false,
                newAnnoLink: '',
                newGeojson: '',
                annoName: '',
                annoDescription: ''
            })
        }
        /*update annotation list on edit*/
        if(prevProps.isAnnotationPut !== this.props.isAnnotationPut && this.props.isAnnotationPut){
            this.setAlert("Successfully Edited", null, null, '', '', false, 'success', true, true)
            this.props.getAnnotationList(this.state.areaId)
            this.props.editAnnotationModeOff()
            let storageId=this.state.displayedLayerId.split('-').reverse()[0]
            updateDrawingAnno(this.map.current, this.state.lastEditedAnno.id, storageId)
            setTimeout(() => {
                this.setState({
                    newAnnoLink: '',
                    newGeojson: '',
                    annoName: '',
                    annoDescription: ''
                })
                this.unsetAlert()
            }, 1000)
        }
        /**update annotation list redux state on deleted*/
        if(prevProps.isAnnotationDeleted !== this.props.isAnnotationDeleted && this.props.isAnnotationDeleted){
            this.props.getAnnotationList(this.state.areaId)
            this.setAlert(i18n.t("Timelapse.SuccessfullyDeleted"), null, null, '', '', false, 'success', true, true)
            setTimeout(()=>{
                this.unsetAlert()
                this.handleClose()
            },500)
        }
        /**/
        if(prevProps.isAnnotationListLoading !== this.props.isAnnotationListLoading && !this.props.isAnnotationListLoading){
            if(this.map.current){
                addAnnotationsToMap(this.map.current, this.props.annotationList, this.state.displayedLayerId)
                addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
                this.setState({
                    lastEditedAnno: {},
                    annotationList: this.props.annotationList
                })
            }
        }
        /**update annotation list local state on any change*/
        if(prevProps.annotationList.length !== this.props.annotationList.length){
            let hashedAnnotations=JSON.parse(window.localStorage.getItem("annotationList"))
            let updatedAnnotationList=this.props.annotationList
            if(hashedAnnotations){
                updatedAnnotationList=this.props.annotationList.concat(hashedAnnotations)
            }
            this.setState({
                annotationList: [...updatedAnnotationList]
            })
        }
        /**add to the map*/
        if(prevProps.annotationList.length !== this.props.annotationList.length && this.props.annotationList.length!==0){
            let hashedAnnotations=JSON.parse(window.localStorage.getItem("annotationList"))
            let updatedAnnotationList=this.props.annotationList
            if(hashedAnnotations){
                updatedAnnotationList=this.props.annotationList.concat(hashedAnnotations)
            }
            this.setState({
                annotationList: [...updatedAnnotationList]
            })
            if(this.map.current){
                addAnnotationsToMap(this.map.current, updatedAnnotationList, this.state.displayedLayerId)
                addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
            }
        }
        /**fetch whatsnew list redux state on deleted*/
        if(prevProps.whatsnewDeleted !== this.props.whatsnewDeleted && this.props.whatsnewDeleted){
            this.props.getWhatsnewList(this.state.areaId)
        }
        /* */
        if(prevProps.whatsnewsFetched !== this.props.whatsnewsFetched && this.props.whatsnewsFetched){
            this.setState({
                whatsnewList: [...this.props.whatsnewList]
            })
        }
        /**update object list when it is created*/
        if(prevProps.isObjectCreated !== this.props.isObjectCreated && this.props.isObjectCreated){
            this.props.getObjectList({Area: this.state.areaId})
            this.setAlert("Successfully Added", null, null, '', '', false, 'success', true, true)
            setTimeout(()=>{
                this.unsetAlert()
            },1000)
        }
        /**update object list when it is created*/
        if(prevProps.objectsFetched !== this.props.objectsFetched && this.props.objectsFetched){
            /**build confirmed objects*/
            /*Group by label*/
            let confirmedObjectByStorage=groupBy(this.props.objectList,'Storage')
            let confirmedObjectGroups=[]
            Object.keys(confirmedObjectByStorage).forEach((storage)=>{
                let byLabel=groupBy(confirmedObjectByStorage[storage],'Label')
                Object.keys(byLabel).forEach((label)=>{
                    confirmedObjectGroups.push({
                        "id": `${label}-${storage}`,
                        "Name": label,
                        "Displayed": true,
                        "Storage": storage
                    })
                })
            })
            /* */
            this.setState({
                confirmedObjects: this.props.objectList,
                showDetectionList: false,
                showObjectList: true,
                confirmedObjectGroups: [...confirmedObjectGroups],
                clickedPropId: null,
                showAnnotationInfo: false,
                showWhereelseForm: false,
                confirmWhereelse: false
            })
            addObjectsToMap(this.map.current, this.props.objectList, this.state.displayedLayerId)
        }
        /* */
        if(prevProps.detectionDeleted!==this.props.detectionDeleted && this.props.detectionDeleted){
            this.props.getDetectionList(this.state.clickedDetectionGroup)
            addObjectDetectionLayers(this.map.current,[this.state.clickedDetectionGroup],this.state.displayedLayerId)
            this.props.resetObjects()
            removeTmpDetectedPolygons(this.map.current)
            this.setAlert(i18n.t("Timelapse.SuccessfullyDeleted"), null, null, '', '', false, 'success', true, true)
            setTimeout(()=>{
                this.unsetAlert()
            },500)
        }
        /* */
        if(prevProps.detectionsFetched!==this.props.detectionsFetched && this.props.detectionsFetched){
            this.setState({
                showDetectionList: true,
                showAnnotationInfo: false
            })
            // addObjectDetectionLayers(this.map.current,[this.state.clickedDetectionGroup],this.state.displayedLayerId)
            if(this.state.clickedPropId){
                let index=this.props.detectionList.findIndex((detection)=>detection.properties.id==this.state.clickedPropId)
                let pageNumber=Math.floor(index/10)
                this.setState({
                    detectedObjectsPage: pageNumber
                })
            }
        }
        /* */
        if(prevProps.objectPut !==this.props.objectPut && this.props.objectPut){
            this.setAlert(i18n.t("Timelapse.SuccessfullyDeleted"), null, null, '', '', false, 'success', true, true)
            this.props.getObjectList({Area: this.state.areaId})
            removeTmpConfirmedPolygons(this.map.current)
            setTimeout(() => {
                this.props.resetObjects()
                this.unsetAlert()
            }, 2000)
        }
        /* */
        if(prevProps.objectDeleted !==this.props.objectDeleted && this.props.objectDeleted){
            this.setAlert(i18n.t("Timelapse.SuccessfullyDeleted"), null, null, '', '', false, 'success', true, true)
            this.props.getObjectList({Area: this.state.areaId})
            setTimeout(() => {
                this.props.resetObjects()
                this.unsetAlert()
            }, 2000)
        }
        /* */
        if(prevProps.isWhereelsesRequested !== this.props.isWhereelsesRequested && this.props.isWhereelsesRequested){
            this.setState({
                showWhereelseForm: false,
                confirmWhereelse: false,
                showWhereelseList: true,
                showWhereelseSearchForm: false,
                showAnnotationInfo: false
            })
        }
        /* */
        if(prevProps.isWhereelsesGenerated !==this.props.isWhereelsesGenerated && this.props.isWhereelsesGenerated){
            this.draw.current.deleteAll()
            this.setState({
                showWhereelseList: true
            })
            this.flyToMarker.current.remove()
            /* */
            /* remove detection list if they exist */

            /* remove drawings if they exist */
            this.handleDeleteDetectedObjects(this.props.whereelseResults.rkey)
        }
        /* This is used for loading shared hashed maps */
        /* Ensure that all map related states are loaded from the store and then load the map */
        if(prevProps.mapStateLoaded!=this.props.mapStateLoaded&&this.props.mapStateLoaded){
            try {
                let id = this.props.displayedLayer.id
                const urlSearchParams=new URLSearchParams(window.location.search)
                const params=Object.fromEntries(urlSearchParams.entries())
                let areaLng=params.areaLng
                let areaLat=params.areaLat
                let regionName=params.regionName

                this.setState({
                    baseLayer: { ...this.props.baseLayer },
                    displayedLayer: { ...this.props.displayedLayer },
                    displayedLayerId: this.props.displayedLayerId,
                    lat: this.props.lat,
                    lng: this.props.lng,
                    rasterLayers: this.props.rasterLayers,
                    selectedRasterLayers: this.props.selectedRasterLayers,
                    timelapseLayers: this.props.timelapseLayers,
                    zoom: this.props.zoom,
                    clickedLocation: this.props.clickedLocation,
                    mapCenter: this.props.mapCenter,
                    featureCollectionLayers: this.props.featureCollectionLayers,
                    areaCenter: [areaLng, areaLat],
                    regionName: regionName
                })

                this.map.current=createMap(this.mapContainer.current, this.props.mapCenter, this.props.zoom)
                this.map.current.on('load', () => {
                    /**
                     * Add the first layer
                     */
                    addSatelliteLayers(this.map.current)
                    let zoom  = this.props.zoom
                    if(this.state.gridToggeled){
                    addGrid(this.map.current, 1/(0.5*zoom), 1/(0.5*zoom))
                    }
                    /**
                    * Add all the layers at once
                    */
                    this.props.rasterLayers.forEach((layer) => {
                        addRasterLayer(this.map.current, `displayed-layer-${layer.id}`, layer, true, this.state.timeoutDuration)
                    })

                    /**
                    * Move first layer to top
                    */
                    this.map.current.moveLayer(`WFdisplayed-layer-${id}-png`)
                    this.map.current.moveLayer(`WFdisplayed-layer-${id}`)
                    this.map.current.moveLayer(`displayed-layer-${id}-png`)
                    this.map.current.moveLayer(`displayed-layer-${id}`)

                    /*Add 3D*/
                    setDimensionTerrain(this.map.current, this.state.threeDimension)
                    /*hide street layers*/
                    hideStreetLayers(this.map.current)

                    /**
                     * Set the timelapse control time to 0
                     */
                    let dummyEvent
                    this.handleTimeChange(dummyEvent, 0)
                    /** First make sure that all the requested layers are displayed then hide the ones underneath */
                    /** this was needed for smooth transition when changing map layers */
                    this.map.current.on('idle', () => {
                        if(this.props.mapStateLoaded){
                            showDisplayedLayer(this.map.current, id)
                            this.handleClickOnLayer(id, true)
                            setTimeout(() => {
                                this.setState({
                                    hashedMapLoading: false
                                })
                                this.props.resetMapState()
                                this.setState({
                                    showDetectionList: false,
                                    showObjectList: false
                                })
                            },300)
                        }
                    });
                    /* */
                    addAnnotationsToMap(this.map.current, this.props.fcLayers.Annotations, this.state.displayedLayerId)
                    window.localStorage.setItem("annotationList", JSON.stringify(this.props.fcLayers.Annotations))
                    this.setState({
                        annotationList: [...this.props.fcLayers.Annotations]
                    })
                    addWhatsnewsToMap(this.map.current, this.props.fcLayers.Changes, this.state.displayedLayerId)
                    addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
                })

                /** Set zoom and coordinates when the move ends */
                this.map.current.on('moveend', () => {
                    let center = this.map.current.getCenter()
                    let zoom = this.map.current.getZoom()
                    this.setState({
                        mapCenter: [center.lng, center.lat],
                        zoom: zoom
                    })
                    setZoomAndCoordinates(this.map.current, urlSearchParams)
                })
                /** Set clicked location and move the draw layers to top on click */
                this.map.current.on('click', (e) => {
                    this.flyToMarker.current.remove()
                    moveDrawLayersToTop(this.map.current)
                    paintAnnotation(this.map.current, 'none', this.state.annotationList)
                    paintWhatsnew(this.map.current, 'none', this.state.whatsnewList)
                    this.setState({
                        selectedFcLayerId: null,
                        selectedWhatsnewId: null,
                        showAnnotationInfo: false,
                        showWhatsnewInfo: false,
                        showDetectionList: false,
                        showObjectList: false,
                        showWhereelseForm: false,
                        confirmWhereelse: false
                    })
                })
                /** Mouse move events */
                this.map.current.on('mousemove', (e) => {
                    let point = (e.lngLat)
                    let elevationNow = this.map.current.queryTerrainElevation(point, {exaggerated: false})*3.28084
                    this.setState({
                        clickedLocation: [point.lng, point.lat],
                        elevation: elevationNow < 0 || elevationNow==null ? 0 : elevationNow
                    })
                })
                let draw=this.draw.current
                /** add draw control to map */
                this.map.current.addControl(draw, 'top-right')
                this.map.current.addControl(new mapboxgl.NavigationControl())
                this.map.current.on('draw.create', (e) => {
                    this.setState({
                        areaSaved: this.state.areaMeasured,
                        lengthSaved: this.state.lengthMeasured
                    })
                    let drawData=draw.getAll()
                    let feature=drawData.features[0]
                    let {id, ...annotationGeojson} = feature
                    this.setState({
                        showMeasurement: false,
                        areaMeasured: 0,
                        lengthMeasured: 0,
                        showAnnotationForm: true,
                        annotationGeojson: {...annotationGeojson},
                    })
                    moveDrawLayersToTop(this.map.current)
                    addPopupToEachLayerOnClick(this.map.current, this.handleClickOnFcLayer)
                })
                /** used for showing measurements while drawing */
                this.map.current.on('draw.render', (e) => {
                    let drawData = draw.getAll()
                    let type=''
                    let geoData={}
                    if(drawData.features.length>0 && !this.state.showAnnotationForm){
                        geoData=drawData.features[0]
                        type=geoData.geometry.type
                        if(this.state.areaMeasured>0||this.state.lengthMeasured>0){
                            this.setState({
                                showMeasurement: true
                            })
                        }
                    } else {
                        this.setState({
                            showMeasurement: false,
                            areaMeasured: 0,
                            lengthMeasured: 0
                        })
                    }
                    switch (type) {
                        case 'Polygon':
                            this.setState({
                                areaMeasured: (area(geoData)/1000000).toFixed(4),
                                lengthMeasured: length(geoData).toFixed(4)
                            })
                            break;
                        case 'LineString':
                            this.setState({
                                lengthMeasured: (length(geoData)).toFixed(4)
                            })
                            break;
                        case 'Point':
                            break;
                        default:
                            break;
                    }
                })
                /** Delete all draw layers as the draw is getting updated */
                this.map.current.on('draw.update', (e) => {
                    draw.deleteAll()
                })

                // this.handleClickOnLayer(this.props.displayedLayer.id, true)
            } catch (error) {
                this.map.current = new mapboxgl.Map({
                    container: this.mapContainer.current,
                    style: getMapStyle('google'),
                    center: [28.9784, 41.0082],
                    zoom: 12
                })
            }
        }
    }
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
     */
    toggleFullScreen() {
        if (!document.fullscreenElement) {
            try {
                document.documentElement.requestFullscreen()
                this.setState({
                    drawerOpen: false,
                    fullscreen: !this.state.fullscreen
                })
            } catch (error) {
                console.log('ERROR: ', error)
            }
        } else if (document.exitFullscreen) {
            try {
                document.exitFullscreen()
                this.setState({
                    drawerOpen: true,
                    fullscreen: !this.state.fullscreen
                })
            } catch (error) {
                console.log('ERROR: ', error)
            }
        }
    }
    /**
     *
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.displayTimelapseControl && !this.state.played) {
            return true
        } else if (this.state.displayTimelapseControl) {
            return nextState.timelapseLayerIndex !== this.state.timelapseLayerIndex || this.state.played !== nextState.played || this.state.drawerOpen !== nextState.drawerOpen || this.state.displayBaseLayer !== nextState.displayBaseLayer || this.state.fullscreen !== nextState.fullscreen || this.state.showPlaybackOptions !== nextState.showPlaybackOptions
        } else {
            return true
        }
    }
    /**
     *
     */
    handleChangeBaseLayer() {
        let newBaseLayerIndex = this.state.baseLayerIndex + 1
        this.setState({ baseLayerIndex: newBaseLayerIndex })
        this.map.current.removeLayer(`base-layer-${this.state.baseLayerIndex}`)
        this.map.current.removeSource(`base-layer-${this.state.baseLayerIndex}`)
        addRasterLayer(this.map.current, `base-layer-${newBaseLayerIndex}`, this.state.rasterLayers[newBaseLayerIndex], this.state.timeoutDuration)
    }
    /**
     *
     */
    handleHideShowBaseLayer() {
        try {
            let id = this.state.rasterLayers[this.state.baseLayerIndex].id
            let baseLayerId = `base-layer-${id}`
            this.setState({ displayBaseLayer: !this.state.displayBaseLayer })
            this.map.current.setLayoutProperty(baseLayerId, 'visibility', !this.state.displayBaseLayer ? 'visible' : 'none')
            this.map.current.setLayoutProperty(`${baseLayerId}-png`, 'visibility', !this.state.displayBaseLayer ? 'visible' : 'none')
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }
    /**
     *
     */
    handleNextDisplayedLayer() {
        let i = this.state.displayedLayerIndex
        try {
            if (i + 1 < this.state.selectedRasterLayers.length) {
                let dummyEvent
                this.handleTimeChange(dummyEvent, i+1)
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }
    /**
     *
     */
    handlePrevDisplayedLayer() {
        let i = this.state.displayedLayerIndex
        try {
            if (i - 1 >= 0) {
                let dummyEvent
                this.handleTimeChange(dummyEvent, i-1)
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleNextTimelapseLayer() {
        let i = this.state.timelapseLayerIndex
        try {
            if (i + 1 < this.state.timelapseLayers.length) {
                let dummyEvent
                this.handleTimeChange(dummyEvent, i + 1)
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handlePrevTimelapseLayer() {
        let i = this.state.timelapseLayerIndex
        try {
            if (i - 1 >= 0) {
                let dummyEvent
                this.handleTimeChange(dummyEvent, i - 1)
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleTimeChange(e, value) {
        let renderedRasterLayers = this.map.current.getStyle().layers.filter((layer) => layer.id.startsWith('displayed'))
        try {
            clearTimeout(this.state.removeTimeout)
            let nextTimelapseLayer
            if (value < this.state.timelapseLayers.length) {
                nextTimelapseLayer = this.state.timelapseLayers[value]
            } else {
                nextTimelapseLayer = this.state.timelapseLayers[0]
            }
            let i = this.state.rasterLayers.findIndex((layer) => layer.id === nextTimelapseLayer.id)
            this.setState({ displayedLayerIndex: i })
            this.setState({ displayedLayer: nextTimelapseLayer })
            let layerId = `displayed-layer-${nextTimelapseLayer.id}`
            let removeTimeout = setTimeout(() => {
                renderedRasterLayers.forEach((thisLayer) => {
                    if (thisLayer.id.split('-')[2]==nextTimelapseLayer.id) {
                        this.map.current.setLayoutProperty('WF'+thisLayer.id, 'visibility', 'visible')
                        this.map.current.setPaintProperty('WF'+thisLayer.id, 'raster-opacity', 1)
                        this.map.current.setLayoutProperty(thisLayer.id, 'visibility', 'visible')
                        this.map.current.setPaintProperty(thisLayer.id, 'raster-opacity', 1)
                    } else {
                        this.map.current.setLayoutProperty('WF'+thisLayer.id, 'visibility', 'visible')
                        this.map.current.setPaintProperty('WF'+thisLayer.id, 'raster-opacity', 0)
                        this.map.current.setLayoutProperty(thisLayer.id, 'visibility', 'visible')
                        this.map.current.setPaintProperty(thisLayer.id, 'raster-opacity', 0)
                    }
                })
            }, 100);
            this.setState({
                removeTimeout: removeTimeout,
                timelapseLayerIndex: value,
                displayedLayerId: layerId,
            })
            /**handle layer visibility (fill, outline, line)*/
            handleAnnotationVisibility(this.map.current, this.state.annotationList, layerId)
            handleWhatsNewVisibility(this.map.current, this.state.whatsnewList, layerId)
            handleDetectionVisiblity(this.map.current, this.state.detectedObjects, layerId)
            handleConfirmedObjectVisibility(this.map.current, this.state.confirmedObjects, layerId)
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handlePlay() {
        try {
            if (!this.state.played) {
                this.setState({ played: true })
            }
            let value = this.state.timelapseLayerIndex
            let dummyEvent
            if (value < this.state.timelapseLayers.length) {
                clearTimeout(this.state.timelapseTimeout)
                let to = window.setTimeout(() => {
                    this.handleTimeChange(dummyEvent, value + 1)
                }, this.state.timeoutDuration)
                this.setState({ timelapseTimeout: to })
            } else {
                clearTimeout(this.state.timelapseTimeout)
                this.setState({ displayedLayer: this.state.timelapseLayers[0] })
                this.setState({ timelapseLayerIndex: 0 })
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handlePause() {
        clearTimeout(this.state.timelapseTimeout)
        this.setState({ played: false })
    }

    /**
     *
     */
    handleReset() {
        this.handlePause()
        let dummyEvent
        this.handleTimeChange(dummyEvent, 0)
    }

    /**
     *
     */
    handlePlaybackSpeed(event) {
        event.preventDefault()
        this.setState({ anchorEl: event.currentTarget })
        this.setState({ showPlaybackOptions: true })
    }

    /**
     *
     */
    setPlaybackSpeed(event) {
        event.preventDefault()
        let text = event.target.innerText
        let rate = Number(text.split("x")[0])
        this.setState({
            showPlaybackOptions: false,
            timeoutDuration: 1000 / rate,
            playbackRate: text
        })

    }

    /**
     *
     */
    handleShowHideTimelapse() {
        try {
            this.setState({ displayTimelapseControl: !this.state.displayTimelapseControl })
            this.handlePause()
            let dummyEvent
            this.handleTimeChange(dummyEvent, 0)
            // https://gitlab.atlasimaging.co/mapteam/timelapse/-/issues/32
            // annotations get stuck under png or jpg layers when the timelapse functionality enabled
            // this.map.current.moveLayer(this.state.displayedLayerId)
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleClickOnLayer(id) {
        try {
            /**Manage Raster Layers*/
            let dummyEvent
            let timeChangeValue = this.state.timelapseLayers.findIndex((layer) => layer.id === id)
            this.handleTimeChange(dummyEvent, timeChangeValue)
        } catch (error) {
            
        }
    }


    /**
     * Raster Layers (Maps)
     */
    handleSelectAll() {
        try {
            window.localStorage.removeItem('firstLayer')
            window.localStorage.removeItem('secondLayer')
            let copyArr = this.state.selectedRasterLayers
            copyArr.map((layer, i) => {
                layer.displayed = true
                return layer
            })
            this.setState({ selectedRasterLayers: copyArr })
            this.setState({ selectedAll: true })
            this.setState({ timelapseLayers: copyArr.filter((layer) => layer.displayed) })
            showDisplayedLayer(this.map.current, this.state.displayedLayer.id)
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }



    /**
     * Raster Layers (Maps)
     */
    handleDeselectAll() {
        try {
            window.localStorage.removeItem('firstLayer')
            window.localStorage.removeItem('secondLayer')
            let copyArr = this.state.selectedRasterLayers
            copyArr.map((layer, i) => {
                layer.displayed = false
                return layer
            })
            this.setState({ selectedRasterLayers: copyArr })
            this.setState({ timelapseLayers: copyArr.filter((layer) => layer.displayed) })
            this.setState({ selectedAll: false })
            this.map.current.getStyle().layers.forEach((layer) => {
                if (layer.id.includes('displayed')) {
                    this.map.current.setLayoutProperty(layer.id, 'visibility', 'none')
                }
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *  Raster layer (Maps)
     */
    handleCheckOne(i, checked) {
        if (!checked) {
            this.handleSelectOne(i)
            let layerId = this.state.rasterLayers[i].id
            if (this.state.displayedLayerId === `displayed-layer-${layerId}`) {
                this.map.current.setLayoutProperty(`displayed-layer-${layerId}`, 'visibility', 'visible')
                this.map.current.setLayoutProperty(`displayed-layer-${layerId}-png`, 'visibility', 'visible')
            }
            if (this.state.timelapseLayers.length == 1) {
                let firstLayer_str = JSON.stringify(this.state.timelapseLayers[0])
                let secondLayer_str = JSON.stringify(this.state.rasterLayers[i])
                let compareLayers = [firstLayer_str]
                compareLayers.push(secondLayer_str)
                window.localStorage.setItem('firstLayer', compareLayers[0])
                window.localStorage.setItem('secondLayer', compareLayers[1])
            } else {
                window.localStorage.removeItem('firstLayer')
                window.localStorage.removeItem('secondLayer')
            }
        } else {
            this.handleDeselectOne(i)
            let layerId = this.state.rasterLayers[i].id
            if (this.state.displayedLayerId === `displayed-layer-${layerId}`) {
                this.map.current.setLayoutProperty(`displayed-layer-${layerId}`, 'visibility', 'none')
                this.map.current.setLayoutProperty(`displayed-layer-${layerId}-png`, 'visibility', 'none')
            }
            if (this.state.timelapseLayers.length == 3) {
                let compareLayers = [...this.state.timelapseLayers]
                let index = compareLayers.findIndex((layer) => layer.id === this.state.rasterLayers[i].id)
                compareLayers.splice(index, 1)
                window.localStorage.setItem('firstLayer', JSON.stringify(compareLayers[0]))
                window.localStorage.setItem('secondLayer', JSON.stringify(compareLayers[1]))
            } else {
                window.localStorage.removeItem('firstLayer')
                window.localStorage.removeItem('secondLayer')
            }
        }
    }

    /**
     * Raster layer (Maps)
     */
    handleSelectOne(i) {
        try {
            let copyArr = this.state.selectedRasterLayers
            let selectedLayer = copyArr[i]
            selectedLayer.displayed = true
            copyArr.splice(i, 1, selectedLayer)
            this.setState({ selectedRasterLayers: copyArr })
            this.setState({ timelapseLayers: copyArr.filter((layer) => layer.displayed) })
            let length = this.state.selectedRasterLayers.length
            for (let i = 0; i < length; i++) {
                if (!copyArr[i].displayed) {
                    this.setState({ selectedAll: false })
                    break
                } else if (i === length - 1) {
                    this.setState({ selectedAll: true })
                }
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     * Raster layer (Maps)
     */
    handleDeselectOne(i) {
        try {
            let copyArr = this.state.selectedRasterLayers
            let selectedLayer = copyArr[i]
            selectedLayer.displayed = false
            copyArr.splice(i, 1, selectedLayer)
            this.setState({ selectedRasterLayers: copyArr })
            this.setState({ timelapseLayers: copyArr.filter((layer) => layer.displayed) })
            this.setState({ selectedAll: false })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleDrawerOpener() {
        try {
            this.setState({ drawerOpen: !this.state.drawerOpen })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleMapIcon() {
        try {
            this.map.current.setZoom(12)
            this.map.current.flyTo({
                center: this.state.areaCenter,
                essential: true
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleReverse() {
        try {
            window.localStorage.setItem("backward", true)
            window.location.reload();
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleCloseSuccessAlarm() {
        try {
            this.setState({
                showSuccessAlarm: false
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     */
    handleSave() {
        let copyState = [...this.state.featureCollectionLayers]
        copyState.map((fcLayer) => {
            fcLayer.displayed = true
            return fcLayer
        })
        const {anchorEl, ...copyAppState} = this.state
        let hashedmap = {
            SavedState: {
                ...copyAppState,
                featureCollectionLayers: [...copyState]
            },
            Layers: {
                Annotations: this.state.annotationList,
                Whatsnews: this.state.whatsnewList,
                DetectedObjects: this.state.detectedObjects
            },
            User: this.props.user.id
        }

        const urlSearchParams=new URLSearchParams(window.location.search)
        try {
            axios.post(`/flightsapi/hashedmap`, hashedmap)
                .then((res) => {
                    this.setState({
                        showSuccessAlarm: true,
                        successMessage: 'Generated a share link',
                        hashLink: `${BASE_URL}/timelapse/${res.data}?${urlSearchParams}`,
                        alarmAction: 'Copy Link'
                    })
                })
                .catch((error) => {
                    console.log('ERROR: ', error)
                })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     *
     * @param {*} id
     * @param {*} featureCollection
     */
    handleCheckOneMapFeature(id, featureCollection) {
        let layers = this.map.current.getStyle().layers
        let sourcedLayers = layers.filter((layer) => layer.source)
        let filteredLayers = sourcedLayers.filter((layer) => layer.source.split('-').slice(1,layer.source.length-1).join('-') === String(id))
        filteredLayers.forEach((layer) => {
            let visibility = this.map.current.getLayoutProperty(layer.id, 'visibility')
            let newValue = visibility === 'visible' ? 'none' : 'visible'
            this.map.current.setLayoutProperty(layer.id, 'visibility', newValue)
        })
        let featureCollectionLayers = this.state[featureCollection]
        /**handle fcLayers state */
        let fcLayer = featureCollectionLayers.find((layer) => layer.id === id)
        let copyFcLayer = { ...fcLayer }
        let i = featureCollectionLayers.findIndex((layer) => layer.id === id)
        let copyFcLayers = [...featureCollectionLayers]
        copyFcLayer.Displayed = !fcLayer.Displayed
        copyFcLayers.splice(i, 1, copyFcLayer)
        /**Filter the ones on the displayed id*/
        let arrayForCheck = copyFcLayers.filter((fcLayer) => fcLayer.Storage == Number(this.state.displayedLayerId.split('-').reverse()[0]))
        /**Assign states*/
        this.setState({
            [featureCollection]: copyFcLayers,
        })
        switch (featureCollection) {
            case 'annotationList':
                this.setState({
                    displayAllAnnos: allDisplayed(arrayForCheck).displayed,
                    hideAllAnnos: allDisplayed(arrayForCheck).noDisplayed
                })
                break;
            case 'detectedObjects':
                this.setState({
                    displayAllDetectedObjects: allDisplayed(arrayForCheck).displayed,
                    hideAllDetectedObjects: allDisplayed(arrayForCheck).noDisplayed
                })
                break;
            case 'whatsnewList':
                    this.setState({
                        displayAllWhatIsNew: allDisplayed(arrayForCheck).displayed,
                        hideAllWhatIsNew: allDisplayed(arrayForCheck).noDisplayed
                    })
                    break;
            default:
                break;
        }
    }
    /* */
    handleCheckOneConfirmedObject(id) {
        let label = extractLabel(id)
        try {
            let copyArr=[...this.props.objectList.filter(obj=>obj.Label==label&&obj.Storage==this.state.displayedLayer.id)]
            copyArr.forEach(feature=>{
                let polygonId=`draw-polygon-object-${feature.id}-${feature.Label}-${feature.Storage}`
                let outlineId=`draw-outline-object-${feature.id}-${feature.Label}-${feature.Storage}`
                let visibility = this.map.current.getLayoutProperty(polygonId, 'visibility')
                let newValue = visibility === 'visible' ? 'none' : 'visible'
                this.map.current.setLayoutProperty(polygonId, 'visibility', newValue)
                this.map.current.setLayoutProperty(outlineId, 'visibility', newValue)   
            })
            /* handle fcLayers state */
            let objGroup = this.state.confirmedObjectGroups.find((group) => group.id==id)
            let copyObjGroup = {...objGroup}
            let i = this.state.confirmedObjectGroups.findIndex((group) => group.id==id)
            let copyObjGroups = [...this.state.confirmedObjectGroups]
            copyObjGroup.Displayed = !copyObjGroup.Displayed
            copyObjGroups.splice(i, 1, copyObjGroup)
            let arrayForCheck = copyObjGroups.filter((objGroup) => objGroup.Storage == this.state.displayedLayer.id)
            /* */
            this.setState({
                confirmedObjectGroups: [...copyObjGroups],
                displayAllConfirmedObjects: allDisplayed(arrayForCheck).displayed,
                hideAllConfirmedObjects: allDisplayed(arrayForCheck).noDisplayed
            })
        } catch (error) {
            
        }
    }
    /* */
    handleCheckAllConfirmedObjects(checked) {
        try {
            let copyArr = [...this.state.confirmedObjectGroups]
            let drawLayers = this.map.current.getStyle().layers.filter((layer)=>layer.id.split('-')[2]=='object'&&layer.id.split('-').reverse()[0]==this.state.displayedLayer.id)
            copyArr.map((group)=>{
                if(group.Storage==this.state.displayedLayer.id){
                    group.Displayed = checked
                }
                return group
            })
            drawLayers.forEach((layer) => {
                this.map.current.setLayoutProperty(layer.id, 'visibility', checked?'visible':'none')
            })
            this.setState({
                confirmedObjectGroups: [...copyArr],
                displayAllConfirmedObjects: checked,
                hideAllConfirmedObjects: !checked
            })
        } catch (error) {
            
        }
    }
    /**
     *
     * @param {*} checked
     */
     handleCheckAllFeatureList(checked, featureCollection) {
        let fcLayers = this.state[featureCollection]
        try {
            let copyArr = [...fcLayers]
            copyArr.map((fcLayer) => {
                if (Number(fcLayer.Storage) === Number(this.state.displayedLayerId.split('-').reverse()[0])) {
                    /** handle check state */
                    fcLayer.Displayed = checked
                    /** handle draw layers */
                    let drawLayers = this.map.current.getStyle().layers.filter((layer) => layer.source)
                    let featureDrawLayers = drawLayers.filter((layer)=>layer.source.split('-').slice(1,layer.source.length-1).join('-')==String(fcLayer.id))
                    featureDrawLayers.forEach((layer) => {
                        this.map.current.setLayoutProperty(layer.id, 'visibility', checked?'visible':'none')
                    })
                }
                return fcLayer
            })
            this.setState({
                [featureCollection]: [...copyArr],
            })
            switch (featureCollection) {
                case 'annotationList':
                    this.setState({
                        displayAllAnnos: checked,
                        hideAllAnnos: !checked,
                    })
                    break;
                case 'detectedObjects':
                    this.setState({
                        displayAllDetectedObjects: checked,
                        hideAllDetectedObjects: !checked
                    })
                    break;
                case 'whatsnewList':
                    this.setState({
                        displayAllWhatIsNew: checked,
                        hideAllWhatIsNew: !checked
                    })
                    break;
                case 'confirmedObjects':
                    this.setState({
                        displayAllConfirmedObjects: checked,
                        hideAllConfirmedObjects: !checked
                    })
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     * Set selected fcLayer id, change the color of the layer
     */
    handleClickOnFcLayer(id, fType) {
        let feature
        let geoData
        let zoom
        if(fType=='anno'){
            feature=this.state.annotationList.find((anno) => anno.id == id)
            geoData = feature.Geojson
            zoom = feature.Zoom
        }
        if(fType=='whatsnew'){
            feature=this.state.whatsnewList.find((feature) => feature.id == id)
            geoData = feature.Geojson
            zoom = feature.Zoom
        }
        let coord
        if(geoData.geometry.type=="Polygon"){
            coord = polygon(geoData.geometry.coordinates)
        }
        if(geoData.geometry.type=="LineString")
        {
            coord = lineString(geoData.geometry.coordinates)
        }
        if(geoData.geometry.type=="Point"){
            coord = point(geoData.geometry.coordinates)
        }
        let center = centerOfMass(coord).geometry.coordinates
        this.map.current.setZoom(zoom?zoom:18)
        this.map.current.setCenter(
            center
        )
        if(fType=='anno'){
            this.setState({
                selectedAnno: this.props.annotationList.find((anno) => anno.id == id),
                selectedFcLayerId: id,
                showAnnotationInfo: true,
                showWhatsnewInfo: false,
                selectedWhatsnewId: null,
                showDetectionList: false,
                showObjectList: false,
                clickedPropId: null,
                showWhereelseForm: false,
                confirmWhereelse: false
            })
            if(!this.props.isEditAnnotationModeOn){
                paintAnnotation(this.map.current, id, this.state.annotationList)
            }
        }
        if(fType=='whatsnew'){
            paintWhatsnew(this.map.current, id, this.state.whatsnewList)
            this.setState({
                selectedWhatsnew: this.state.whatsnewList.find((obj) => obj.id == id),
                selectedWhatsnewId: id,
                showWhatsnewInfo: true,
                showAnnotationInfo: false,
                selectedFcLayerId: null,
                showDetectionList: false,
                showObjectList: false,
                clickedPropId: null,
                showWhereelseForm: false,
                confirmWhereelse: false
            })
        }
    }
    /* */
    handleClickOnWhatsnewLayer(id) {
        let whatIsNew = this.state.whatsnewList.find((feature) => feature.id == id)
        let geoData = whatIsNew.Geojson
        let zoom = whatIsNew.Zoom
        let coord
        if(geoData.geometry.type=="Polygon"){
            coord = polygon(geoData.geometry.coordinates)
        }
        if(geoData.geometry.type=="LineString")
        {
            coord = lineString(geoData.geometry.coordinates)
        }
        if(geoData.geometry.type=="Point"){
            coord = point(geoData.geometry.coordinates)
        }
        let center = centerOfMass(coord).geometry.coordinates
        this.map.current.setZoom(zoom?zoom:18)
        this.map.current.setCenter(center)
        this.setState({
            selectedWhatsnew: this.state.whatsnewList.find((obj) => obj.id == id),
            selectedWhatsnewId: id,
            showWhatsnewInfo: true
        })
    }
    /* */
    handleEditAnnotation(id) {
        /**edit annotations*/
        this.props.editAnnotationModeOn(this.map.current, id)
        this.handleClickOnFcLayer(id, 'anno')
        paintAnnotation(this.map.current, id, this.state.annotationList)
        let annotation=this.props.annotationList.find((anno)=>anno.id==id)
        let annotationPolygon=annotation.Geojson
        this.setState({
            selectedAnno: annotation,
            lastEditedAnno: annotation
        })
        annotationPolygon.id = 'instantAnnotationPolygon'
        let featureCollection = {
            'type':"FeatureCollection",
            'features': [
                annotationPolygon
            ]
        }
        this.draw.current.add(featureCollection)
    }
    /* */
    handleConfirmEdit() {
        let drawData=this.draw.current.getAll()
        let feature=drawData.features[0]
        let {id, ...annotationGeojson} = feature
        this.setState({
            annoName: this.state.selectedAnno.Name,
            annoDescription: this.state.selectedAnno.Description,
            showMeasurement: false,
            showAnnotationForm: true,
            areaSaved: this.state.areaMeasured,
            lengthSaved: this.state.lengthMeasured,
            annotationGeojson: {...annotationGeojson},
        })
    }
    /* */
    handleCancelEdit() {
        this.draw.current.deleteAll()
        paintAnnotation(this.map.current, this.state.selectedAnno.id, this.state.annotationList, "#ffd663")
        this.setState({
            selectedFcLayerId: null,
            showAnnotationInfo: false,
            selectedAnno: {},
            lastEditedAnno: {},
            selectedWhatsnewId: null,
            showWhatsnewInfo: false,
            showDetectionList: false,
            showObjectList: false,
            clickedPropId: null,
            showWhereelseForm: false,
            confirmWhereelse: false
        })
        this.props.editAnnotationModeOff()
    }
    /* */
    handleDeleteAnno(id){
        this.handleClickOnFcLayer(id, 'anno')
        paintAnnotation(this.map.current, id, this.state.annotationList)
        let annotation=this.props.annotationList.find((anno)=>anno.id==id)
        this.setState({
            selectedAnno: annotation
        })
        this.setAlert(
            // 'Do you want to delete the annotation?',
            i18n.t("Timelapse.DoYouWantToDeleteTheAnnotation"),
            () => this.handleConfirmDeleteAnno(id),
            () => this.handleCancelDeleteAnno(id),
            i18n.t("Timelapse.Delete"),
            i18n.t("Timelapse.Cancel"),
            true,
            'warning',
            false,
            true
        )
    }
    /* */
    handleConfirmDeleteAnno(id) {
        /**delete from the map*/
        let sourcedLayers = this.map.current.getStyle().layers.filter((layer)=>layer.source)
        let layersToBeDeleted = sourcedLayers.filter((layer) => Number(layer.source.split('-')[1]) === id)
        layersToBeDeleted.forEach((layer) => {
            this.map.current.removeLayer(layer.id)
        })
        this.props.deleteAnnotation(id)
        setTimeout(() => {
            this.props.resetAnnotationForm()
        }, 500)
    }
    /* */
    handleCancelDeleteAnno(id) {
        this.setState({
            selectedAnno: {}
        })
        this.unsetAlert()
    }
    /* */
    handleDeleteWhatsnew(id) {
        /**delete from the map*/
        let sourcedLayers = this.map.current.getStyle().layers.filter((layer)=>layer.source)
        let layersToBeDeleted = sourcedLayers.filter((layer) => Number(layer.source.split('-')[1]) === id)
        layersToBeDeleted.forEach((layer) => {
            this.map.current.removeLayer(layer.id)
        })
        this.props.deleteWhatsnew(id)
        setTimeout(() => {
            this.props.resetWhatsnewForm()
        }, 500)
    }
    /* */
    goTo(page) {
        window.location.replace(`${BASE_URL}/${page}`)
    }
    /**
     * used when the header disappears
     */
    handleSignOut() {
        window.location.assign('/')
        window.localStorage.removeItem('token')
    }
    /**
     * switch between 3d-2d
     */
    handleExtrusion() {
        let d = this.state.threeDimension
        setDimensionTerrain(this.map.current, !d)
        this.setState({
            threeDimension: !d
        })
    }
    /**
     *
     * https://docs.mapbox.com/mapbox-gl-js/example/setstyle/
     */
    handleStyleChange() {
        if (this.state.mapStyle === 'google') {
            this.map.current.setLayoutProperty('google', 'visibility', 'none')
            let allStreetsLayers = this.map.current.getStyle().layers.filter((layer) => layer.source === 'composite')
            allStreetsLayers.forEach((layer) => {
                this.map.current.setLayoutProperty(layer.id, 'visibility', 'visible')
            })
            this.setState({ mapStyle: 'streets' })
        } else {
            this.map.current.setLayoutProperty('google', 'visibility', 'visible')
            let allStreetsLayers = this.map.current.getStyle().layers.filter((layer) => layer.source === 'composite')
            allStreetsLayers.forEach((layer) => {
                this.map.current.setLayoutProperty(layer.id, 'visibility', 'none')
            })
            this.setState({ mapStyle: 'google' })
        }
    }
    /**
     * Closes forms, and info windows
     */
    handleClose() {
        this.setState({
            showAnnotationForm: false,
            showAnnotationInfo: false,
            showUploadForm: false,
            annoName: '',
            annoDescription: '',
            selectedAnno:{},
            newGeojson: '',
            newAnnoLink: '',
            newKML: '',
            annoImported: false,
            showWhatsnewInfo: false,
            showDetectionList: false,
            showDetectionForm: false,
            showObjectList: false,
            showObjectForm: false,
            clickedPropId: null,
            selectedFcLayerId: null,
            showWhereelseForm: false,
            showWhereelseList: false,
            confirmWhereelse: false,
            showWhereelseSearchForm: false,
        })
        this.draw.current.deleteAll()
        this.props.editAnnotationModeOff()
        paintAnnotation(this.map.current, 'none', this.state.annotationList)
        removeWhereelseCircle(this.map.current)
        removeWhereelseResults(this.map.current)
        removeTmpWhereelsePolygons(this.map.current)
        this.draw.current.deleteAll()
    }
    /**
     * opens the upload form
     */
    handleUpload() {
        this.setState({
            showUploadForm: true
        })
    }
    /**
     *
     * @returns
     */
    handleImport(via) {
        let sharedAnno
        let featureCollection
        let newGeojson

        if(via=='geojson'){
            try {
                newGeojson = JSON.parse(this.state.newGeojson)  
            } catch (error) {
                window.alert('Wrong format, the input should be in the geojson format: \n {"type": "Feature","properties": {},"geometry": {"type": "Polygon","coordinates": [...]}}')
            }
        } else if(via=='link') {
            try {
                // sharedAnno=JSON.parse(atob(`${this.state.newAnnoLink}`))
                sharedAnno=JSON.parse(decodeURIComponent(escape(window.atob(`${this.state.newAnnoLink}`))))
                let zoom = sharedAnno.Zoom
                this.map.current.setZoom(zoom?zoom:18)
                newGeojson = sharedAnno.Geojson
                    this.setState({
                        annoName: sharedAnno.Name,
                        annoDescription: sharedAnno.Description
                    })
            } catch (error) {

            }
        }
        else {

            // sharedAnno=JSON.parse(atob(`${this.state.newAnnoLink}`))
            try {
                let newGeojson_ = convertKML(this.state.newKML)
                newGeojson = newGeojson_['features'][0]
            } catch (error) {
                window.alert('Wrong format, the input should be in the geojson format: \n {"type": "Feature","properties": {},"geometry": {"type": "Polygon","coordinates": [...]}}')
            }
        }

        if(newGeojson){
            let type = newGeojson.geometry.type
            featureCollection = {
                'type': 'FeatureCollection',
                'features': [
                    newGeojson
                ]
            }
            switch (type) {
                case 'Point':
                    this.map.current.setCenter(newGeojson.geometry.coordinates)
                    break;
                case 'Polygon':
                    let thisPolygon = polygon(newGeojson.geometry.coordinates)
                    let centerPoly = centerOfMass(thisPolygon).geometry.coordinates
                    let thisArea = (area(newGeojson)/1000000).toFixed(4)
                    let thisLength = length(newGeojson).toFixed(4)
                    this.setState({
                        areaSaved: thisArea,
                        lengthSaved: thisLength
                    })
                    // this.map.current.flyTo({
                    //     center:centerPoly,
                    //     essential: true
                    // })
                    this.map.current.setCenter(centerPoly)
                    break;
                case 'LineString':
                    let thisLine = lineString(newGeojson.geometry.coordinates)
                    let centerLine = centerOfMass(thisLine).geometry.coordinates
                    let lengthLine = length(newGeojson).toFixed(4)
                    this.setState({
                        lengthSaved: lengthLine
                    })
                    // this.map.current.flyTo({
                    //     center:centerLine,
                    //     essential: true
                    // })
                    this.map.current.setCenter(centerLine)
                    break;
                default:
                    break;
            }
            try {
                this.draw.current.add(featureCollection)
                this.setState({
                    newGeojson: newGeojson,
                    annoImported: true
                })
            } catch (error) {
                console.log('ERROR: ', error)
            }
        }
    }
    /**
     *
     */
    handleUploadToDb() {
        this.setState({
            showUploadForm: false,
            showAnnotationForm: true,
            annotationGeojson: {...this.state.newGeojson}
        })
    }
    /**
     *
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /** */
    handleSaveAnnotation(id) {
        let fetcedAnnotation=this.state.annotationList.find(anno=>anno.id==id)
        let {User, ...savedAnnotation}=fetcedAnnotation
        savedAnnotation.Owner=this.props.user.username
        this.props.createAnnotation({
            ...savedAnnotation
        })
        let sourcedLayers = this.map.current.getStyle().layers.filter((layer)=>layer.source)
        let layersToBeDeleted = sourcedLayers.filter((layer) => Number(layer.source.split('-')[1]) === id)
        layersToBeDeleted.forEach((layer) => {
            this.map.current.removeLayer(layer.id)
        })
        let hashedAnnotationList=JSON.parse(window.localStorage.getItem('annotationList'))
        let annoIndex=hashedAnnotationList.findIndex(anno=>anno.id==id)
        hashedAnnotationList.splice(annoIndex, 1)
        window.localStorage.setItem('annotationList', JSON.stringify(hashedAnnotationList))
        setTimeout(() => {
            this.props.resetAnnotationForm()
        }, 2000)
    }
    /* */
    handleClickOnDetectionGroup(id) {
        let clickedObjectGroup = this.state.detectedObjects.find(group=>group.id==id)
        this.props.getDetectionList(clickedObjectGroup)
        this.setState({
            showDetectionList: false,
            showObjectList: false,
            showAnnotationInfo: false,
            showWhatsnewInfo: false,
            clickedDetectionGroup: {...clickedObjectGroup},
            detectedObjectsPage: 0,
            showWhereelseForm: false,
            confirmWhereelse: false
        })
    }
    /* */
    handleClickOnObjectGroup(id) {
        this.setState({
            showObjectList: false
        })
        let idArr=id.split('-')
        let length=idArr.length
        let label=id.split('-').slice(0,length-1).join('-')
        this.setState({
            clickedObjectLabel: label
        })
        this.props.getObjectList({Area: this.state.areaId})
    }
    /* */
    handleFlyToDetectedObject(id) {
        removeTmpDetectedPolygons(this.map.current)
        let clickedDetection = {
            ...this.props.detection,
            id: id.split('-').reverse()[0]
        }
        let clickedObjectGroup = this.state.detectedObjects.find(group=>group.id==this.props.detection.Name)
        this.setState({
            clickedDetectionGroup: {...clickedObjectGroup} 
        })
        this.props.getOneDetection(clickedDetection, this.map.current)
    }
    /* */
    handleFlyToConfirmedObject(id) {
        removeTmpConfirmedPolygons(this.map.current)
        let clickedObject = this.props.objectList.find(object=>`${object.Name}`==id)
        this.setState({
            clickedObject: {...clickedObject}
        })
        this.props.getOneObject(clickedObject.id, this.map.current)
    }
    /* */
    handleConfirmObject(id){
        this.unsetAlert()
        removeTmpDetectedPolygons(this.map.current)
        let clickedDetection = {
            ...this.props.detection,
            id: id.split('-').reverse()[0]
        }
        let label = extractLabel(id)
        let clickedObjectGroup = this.state.detectedObjects.find(group=>group.id==this.props.detection.Name)
        this.setState({
            clickedDetectionGroup: {...clickedObjectGroup},
            clickedDetection: {...clickedDetection},
            clickedObjectLabel: label
        })
        this.props.getOneDetection(clickedDetection, this.map.current)
        this.setState({
            showDetectionForm: true
        })
    }
    /* */
    handleDeleteDetectedObject(id){
        removeTmpConfirmedPolygons(this.map.current)
        let clickedDetection = {
            ...this.props.detection,
            id: id.split('-').reverse()[0]
        }
        let clickedObjectGroup = this.state.detectedObjects.find(group=>group.id==this.props.detection.Name)
        removeDetectedPolygons(this.map.current, clickedDetection)
        this.props.deleteOneDetection(clickedDetection)
        this.setState({
            showDetectionList: false,
            showObjectList: false,
            showAnnotationInfo: false,
            showWhatsnewInfo: false,
            clickedDetectionGroup: {...clickedObjectGroup},
            clickedPropId: null,
            showWhereelseForm: false,
            confirmWhereelse: false
        })
    }
    /* deletes the whole rkey */
    handleDeleteDetectedObjects(rkey){
        let clickedDetection={
            id: null,
            Name: this.props.whereelseResults.rkey,
            url: this.props.whereelseResults.url,
            Displayed: true,
            Storage: this.state.displayedLayer.id
        }
        try {
            removeTmpConfirmedPolygons(this.map.current)
            removeDetectedPolygons(this.map.current, clickedDetection)
            let clickedDetectionGroup = this.state.detectedObjects.find(group=>group.id==clickedDetection.Name)
            clickedDetectionGroup.id = rkey
            this.props.deleteOneDetection(clickedDetection)
            this.setState({
                clickedDetectionGroup: {...clickedDetectionGroup}
            })
        } catch (error) {
            clickedDetection.id = clickedDetection.Name
            this.props.getDetectionList(clickedDetection)
            let detectedObjects = [...this.state.detectedObjects]
            detectedObjects.push(clickedDetection)
            this.setState({
                clickedDetectionGroup: clickedDetection,
                detectedObjects: [...detectedObjects],
            })
            addObjectDetectionLayers(this.map.current, [clickedDetection], this.state.displayedLayerId)
        }
        this.setState({
            showObjectList: false,
            showAnnotationInfo: false,
            showWhatsnewInfo: false,
            clickedPropId: null,
            showWhereelseForm: false,
            confirmWhereelse: false,
            showWhereelseList: false,
            showDetectionList: true
        })
    }
    /* */
    handleDeleteConfirmedObject(id){
        let clickedObject = this.props.objectList.find(object=>`${object.Name}`==id)
        this.props.deleteOneObject(clickedObject.id, this.map.current, this.state.displayedLayer.id, clickedObject.Label)
        this.setState({
            showObjectList: false
        })
    }
    /* */
    handleEditConfirmedObject(id){
        this.unsetAlert()
        let clickedObject = this.props.objectList.find(obj=>`${obj.Name}`==id)
        this.handleFlyToConfirmedObject(id)
        this.setState({
            showObjectForm: true,
            clickedObject: {...clickedObject}
        })
    }
    /* */
    setAlert(message, actionFunction, cancelAction, confirmText, cancelText, isWithAction, type, withIcon, hasCancel) {
        this.setState({
            alert: (
                <BasicAlert
                    message={message}
                    onConfirm={actionFunction}
                    onCancel={cancelAction}
                    onClose={()=>this.unsetAlert()}
                    confirmText={confirmText}
                    cancelText={cancelText}
                    isWithAction={isWithAction}
                    type={type}
                    withIcon={withIcon}
                    hasCancel={hasCancel}
                />
            )
        });
    }
    /* */
    unsetAlert() {
        this.setState({
            alert: null
        });
    }
    /* */
    handleHideShowTileBoundaries() {
        //map.showTileBoundaries=true
        this.map.current.showTileBoundaries=!this.state.showTileBoundaries
        this.setState({
            showTileBoundaries: !this.state.showTileBoundaries
        })
    }
    /* */
    handleDetectedObjectsPageChange(pageNumber) {
        this.setState({
            clickedPropId: null,
            detectedObjectsPage: pageNumber
        })
    }
    /* */
    handleGridToggle(){
        if(this.state.gridToggeled ){
            this.map.current.removeLayer('graticule')
            this.map.current.removeLayer('graticule2')
            this.map.current.removeSource('graticule')
        }
        else{
            if(this.state.zoom>8){
                addGrid(this.map.current,0.1 , 0.1)
            }
            else{
                addGrid(this.map.current,2 , 2)

            }
        }
        let current_grid = this.state.gridToggeled
        this.setState({
            gridToggeled: !current_grid
        })
    }
    /* */
    handleWhereelse(){
        // removeWhereelseCircle(this.map.current)
        // this.setAlert("Draw a circle to set the search region.",this.handleCircleDraw,this.unsetAlert,"Start Drawing", "Close", true, 'warning', false, false)
        this.setState({
            showWhereelseSearchForm: true
        })
    }
    /* */
    handleCircleDraw(){
        this.draw.current.changeMode('draw_radius')
        this.unsetAlert()
    }
    /* */
    handleCircleRedraw(){
        this.draw.current.deleteAll()
        removeWhereelseCircle(this.map.current)
        this.unsetAlert()
        this.draw.current.changeMode('draw_radius')
        this.setState({
            showWhereelseForm: false,
            confirmWhereelse: false
        })
    }
    /* */
    handleFlyToWhereelseObject(id) {
        removeTmpWhereelsePolygons(this.map.current)
        let clickedWhereelseObject = this.props.whereelseResults.find(whereelse => whereelse.properties.id === id)
        let coord = polygon(clickedWhereelseObject.geometry.coordinates)
        let center = centerOfMass(coord).geometry.coordinates
        this.map.current.setCenter(center)
        addTmpWhereelsePolygons(this.map.current, clickedWhereelseObject)
        this.setState({
            clickedWhereelseObject: {...clickedWhereelseObject}
        })
    }
    /* */
    handleConfirmWhereelseObject(id){
        this.handleFlyToWhereelseObject(id)
        this.setState({
            confirmWhereelse: true,
            showWhereelseForm: true
        })
    }
    /* */
    handleFlyToEnteredLocation(flyToLocation){
        try {
            let lngLat
            if(isMGRS(flyToLocation)){
                lngLat = toPoint(flyToLocation)
            } else {
                let arr = flyToLocation.split(',')
                lngLat = [arr[0],arr[1]]
            }
            let end = {
                center: lngLat,
                zoom: 16
            }
            this.map.current.flyTo({
                ...end,
                speed: 0.8,
                essential: true
            })
            this.flyToMarker.current.setLngLat([...lngLat]);
            this.flyToMarker.current.addTo(this.map.current);
        } catch (error) {
            this.setAlert(
                <div style={{display: "flex", flexDirection: "column"}}>
                    <span>
                    {`${String(error).split(':')[1]}`}
                    </span>
                    <br></br>
                    <span>
                    {i18n.t("Timelapse.WrongFlyToFormat")}
                    </span>
                    <br></br>
                    <span>
                    {i18n.t("Timelapse.ExampleFormat")}
                    </span>
                </div>
                ,
                 null, null, '', '', false, 'error', true, true)
        }
    }

    /**
     *
     * @returns the component
     */
    render() {
        /* */
        return (
            <Tooltip open={this.state.showMeasurement} followCursor={true} title={
                <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '15px',
                        fontFamily: 'Poppins'
                    }}>
                    {this.state.areaMeasured > 0 ? <span>{this.state.areaMeasured} km²</span> : null}
                    <span>{this.state.lengthMeasured > 0 ? <span>{this.state.lengthMeasured} km</span> : null}</span>
                </div>
            }>  

                <div ref={this.mapContainer} id='map-timelapse'>
                    {this.state.alert}
                    {
                        this.state.drawerOpen ?
                            <Tooltip 
                                // title="Change map style"
                                title={i18n.t("Timelapse.ChangeMapStyle")}
                            >
                                <div id='timelapse-mini-style-window'>
                                    <Box sx={{ width: "96%", height: "96%", borderRadius: 4 }} onClick={this.handleStyleChange}>
                                        <img alt="satellite" className="map-style-thumbnail" src={this.state.mapStyle === "google" ? streets : google} height="100%" width="100%" style={{ borderRadius: "4px" }} />
                                    </Box>
                                </div>
                            </Tooltip>
                            :
                            <Tooltip 
                                // title="Change map style"
                                title={i18n.t("Timelapse.ChangeMapStyle")}
                            >
                                <div id='timelapse-mini-style-window-hidden'>
                                    <Box sx={{ width: "96%", height: "96%", borderRadius: 4 }} onClick={this.handleStyleChange}>
                                        <img alt="streets" className="map-style-thumbnail" src={this.state.mapStyle === "google" ? streets : google} height="100%" width="100%" style={{ borderRadius: "4px" }} />
                                    </Box>
                                </div>
                            </Tooltip>
                    }
                    {
                        this.state.hashedMapLoading
                            ?
                            <div id={this.state.satelliteLoading ? 'timelapse-page-container-loading' : 'timelapse-page-container-loading-trans'}>
                                <CircularProgress 
                                    sx={{ color: this.state.satelliteLoading ? '#121f33' : 'white'}} 
                                />
                            </div>
                            :
                            null
                    }
                    {
                        this.state.showSuccessAlarm
                            ?
                            <CopySuccessNotif
                                message={this.state.successMessage}
                                hashLink={this.state.hashLink}
                                handleCloseSuccessAlarm={this.handleCloseSuccessAlarm}
                                alarmAction={this.state.alarmAction}
                            />
                            :
                            null
                    }
                    <div id='timelapse-fullscreen-container'>
                        <Tooltip 
                            // title={this.state.fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
                            title={this.state.fullscreen ? i18n.t("Timelapse.ExitFullScreen") : i18n.t("Timelapse.EnterFullScreen")}
                        >
                            <IconButton onClick={this.toggleFullScreen}>
                                {
                                    !this.state.fullscreen ?
                                        <FullscreenIcon sx={{ fontSize: '19px', color: 'white' }} /> :
                                        <FullscreenExitIcon sx={{ fontSize: '19px', color: 'white' }} />
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id='timelapse-grid-container'>
                        <Tooltip 
                            // title={'Grid'}
                            title={i18n.t("Timelapse.Grid")}
                        >
                            <IconButton onClick={this.handleGridToggle}>
                                <Grid4x4Icon sx={{ fontSize: '15px', color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id='timelapse-tile-boundaries-container'>
                        <Tooltip 
                            // title={'Tile Boundaries'} 
                            title={i18n.t("Timelapse.TileBoundaries")}
                        >
                            <IconButton onClick={this.handleHideShowTileBoundaries}>
                                <GridViewIcon sx={{ fontSize: '15px', color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id='timelapse-save-button-container'>
                        <Tooltip 
                            // title={'Share'} 
                            title={i18n.t("Timelapse.Share")}
                        >
                            <IconButton onClick={this.handleSave}>
                                <ShareIcon sx={{ fontSize: '15px', color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id='timelapse-3d-button-container'>
                        <Tooltip 
                            // title={this.state.threeDimension ? 'Switch to 2D' : 'Switch t}o 3D'
                            title={this.state.threeDimension?i18n.t("Timelapse.SwitchTo2d"):i18n.t("Timelapse.SwitchTo3d")}
                        >
                            <IconButton onClick={this.handleExtrusion}>
                                {
                                    this.state.threeDimension ?
                                        <Typography sx={{ fontSize: '11px', color: 'white', fontFamily: 'Poppins', fontWeight: 'bolder' }}>2D</Typography> :
                                        <Typography sx={{ fontSize: '11px', color: 'white', fontFamily: 'Poppins', fontWeight: 'bolder' }}>3D</Typography>
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div id='timelapse-gallery-logout-button-container'>
                        <IconButton
                            color="inherit"
                            aria-label="to gallery page"
                            onClick={() => this.goTo('gallery')}
                            edge="start">
                            <Tooltip 
                                // title='Gallery'
                                title={i18n.t("Timelapse.Gallery")}
                            >
                                <GalleryIcon sx={{ fontSize: '20px', ml: '12px' }} />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="to login page"
                            onClick={this.handleSignOut}
                            edge="start">
                            <Tooltip 
                                // title='sign out'
                                title={i18n.t("Timelapse.SignOut")}
                            >
                                <LogoutIcon sx={{ fontSize: '20px' }}/>
                            </Tooltip>
                        </IconButton>
                    </div>
                    {
                        this.state.drawerOpen
                            ?
                            <Header marginTop='25px'/>
                            :
                            null
                    }
                    {
                        this.state.rasterLayers.length > 0
                            ?
                            <TimelapsePanel
                                mapCenter={this.state.mapCenter}
                                regionName={this.state.regionName}
                                clickedLocation={this.state.clickedLocation}
                                zoom={this.state.zoom}
                                elevation={this.state.elevation}
                                rasterLayers={this.state.selectedRasterLayers}
                                timelapseLayers={this.state.timelapseLayers}
                                timelapseLayerIndex={this.state.timelapseLayerIndex}
                                selectedAll={this.state.selectedAll}
                                handleSelectAll={this.handleSelectAll}
                                handleDeselectAll={this.handleDeselectAll}
                                handleCheckOne={this.handleCheckOne}
                                baseLayer={this.state.baseLayer}
                                displayedLayer={this.state.displayedLayer}
                                displayedLayerIndex={this.state.displayedLayerIndex}
                                handleChangeBaseLayer={this.handleChangeBaseLayer}
                                handleNextDisplayedLayer={this.handleNextDisplayedLayer}
                                handlePrevDisplayedLayer={this.handlePrevDisplayedLayer}
                                handleNextTimelapseLayer={this.handleNextTimelapseLayer}
                                handlePrevTimelapseLayer={this.handlePrevTimelapseLayer}
                                handleClickOnLayer={this.handleClickOnLayer}
                                displayAll={this.state.displayAll}
                                displayBaseLayer={this.state.displayBaseLayer}
                                handleHideShowBaseLayer={this.handleHideShowBaseLayer}
                                handleTimeChange={this.handleTimeChange}
                                displayTimelapseControl={this.state.displayTimelapseControl}
                                handleShowHideTimelapse={this.handleShowHideTimelapse}
                                handlePlay={this.handlePlay}
                                played={this.state.played}
                                handlePause={this.handlePause}
                                handleReset={this.handleReset}
                                handleDrawerOpener={this.handleDrawerOpener}
                                drawerOpen={this.state.drawerOpen}
                                handleMapIcon={this.handleMapIcon}
                                anchorEl={this.state.anchorEl}
                                handlePlaybackSpeed={this.handlePlaybackSpeed}
                                setPlaybackSpeed={this.setPlaybackSpeed}
                                showPlaybackOptions={this.state.showPlaybackOptions}
                                playbackRate={this.state.playbackRate}
                                handleReverse={this.handleReverse}
                                featureCollectionLayers={this.state.featureCollectionLayers}
                                handleCheckOneFeatureCollection={this.handleCheckOneFeatureCollection}
                                handleCheckOneMapFeature={this.handleCheckOneMapFeature}
                                handleClickOnFcLayer={this.handleClickOnFcLayer}
                                //anno
                                selectedFcLayerId={this.state.selectedFcLayerId}
                                handleDeleteAnnotation={this.handleDeleteAnno}
                                handleEditAnnotation={this.handleEditAnnotation}
                                detectedObjects={this.state.detectedObjects}
                                selectedAllDetectedObjs={this.state.displayAllDetectedObjects}
                                selectedAllConfirmedObjs={this.state.displayAllConfirmedObjects}
                                selectedWhatIsNew={this.state.displayAllWhatIsNew}
                                selectedAllFcLayers={this.state.displayAllAnnos}
                                notAnyDetectedObjSelected={this.state.hideAllDetectedObjects}
                                notAnyFcSelected={this.state.hideAllAnnos}
                                notAnyWhatIsNew={this.state.hideAllWhatIsNew}
                                handleCheckAllFeatureList={this.handleCheckAllFeatureList}
                                annotations={this.state.annotationList}
                                handleUpload={this.handleUpload}
                                whatIsNew={this.state.whatsnewList}
                                // handleClickOnChangeLayer={this.handleClickOnChangeLayer}
                                selectedWhatsnewId={this.state.selectedWhatsnewId}
                                handleSaveAnnotation={this.handleSaveAnnotation}
                                match={this.props.match}
                                handleClickOnDetectionGroup={this.handleClickOnDetectionGroup}
                                handleClickOnObjectGroup={this.handleClickOnObjectGroup}
                                objects={this.state.objectList}
                                confirmedObjectGroups={this.state.confirmedObjectGroups}
                                handleCheckOneConfirmedObject={this.handleCheckOneConfirmedObject}
                                handleCheckAllConfirmedObjects={this.handleCheckAllConfirmedObjects}
                                selectedAllConfirmedObjects={this.state.displayAllConfirmedObjects}
                                notAnyConfirmedObjectSelected={this.state.hideAllConfirmedObjects}
                                handleDeleteWhatsnew={this.handleDeleteWhatsnew}
                                flyToLocation={this.state.flyToLocation}
                                handleFlyToQuery={()=>this.handleChange("flyToLocation")}
                                handleFlyToEnteredLocation={this.handleFlyToEnteredLocation}
                            />
                            :
                            null
                    }
                    {
                        this.state.showAnnotationForm
                        ?
                        <AnnotationForm
                            isDialogOpen={this.state.showAnnotationForm}
                            handleClose={this.handleClose}
                            map={this.map.current}
                            data={this.state.annotationGeojson}
                            displayedLayer={this.state.displayedLayer}
                            area={this.state.areaId}
                            coverage={this.state.areaSaved}
                            length={this.state.lengthSaved}
                            name={this.state.annoName}
                            description={this.state.annoDescription}
                            id={this.state.selectedAnno.id}
                        />
                        :
                        null
                    }
                    {
                        this.state.showAnnotationInfo
                        ?
                        <AnnotationInfo
                            isDialogOpen={this.state.showAnnotationInfo}
                            handleClose={this.handleClose}
                            map={this.map.current}
                            data={this.state.selectedAnno}
                            displayedLayer={this.state.displayedLayer}
                            area={this.state.areaId}
                            handleWhereelse={this.handleWhereelse}
                        />
                        :
                        null
                    }
                    {
                        this.state.showWhereelseSearchForm
                        ?
                        <WhereelseSearchForm 
                            handleClose={this.handleClose}
                            radius={this.state.whereelseSearchRadius}
                            handleRadiusChange={()=>this.handleChange("whereelseSearchRadius")}
                            handleStart={this.handleWhereelse}
                            handleCancel={this.handleClose}
                            setAlert={this.setAlert}
                            unsetAlert={this.unsetAlert}
                            displayedLayer={this.state.displayedLayer}
                            center={this.state.mapCenter}
                            handleFlyTo={this.handleFlyToEnteredLocation}
                            data={this.state.selectedAnno}
                        />
                        :
                        null
                    }
                    {
                        this.state.showWhereelseForm
                        ?
                        <WhereelseForm
                            handleClose={this.handleClose}
                            data={this.state.selectedAnno}
                            displayedLayer={this.state.displayedLayer}
                            area={this.state.areaId}
                            center={this.state.whereelseCenter}
                            radius={this.state.whereelseSearchRadius}
                            handleWhereelse={this.handleWhereelse}
                            handleCircleDraw={this.handleCircleDraw}
                            handleRedraw={this.handleCircleRedraw}
                            map={this.map.current}
                            input={this.state.confirmWhereelse}
                            whereelseObject={this.state.clickedWhereelseObject}
                        />
                        :
                        null
                    }
                    {
                        this.state.showUploadForm
                        ?
                        <UploadAnnotation
                            handleClose={this.handleClose}
                            handleImport={this.handleImport}
                            data={this.state.newGeojson}
                            onLinkChange={() => this.handleChange('newAnnoLink')}
                            onGeojsonChange={() => this.handleChange('newGeojson')}
                            onKMLChange={() => this.handleChange('newKML')}
                            link={this.state.newAnnoLink}
                            kml={this.state.newKML}
                            imported={this.state.annoImported}
                            handleUpload={this.handleUploadToDb}
                        />
                        :
                        null
                    }
                    {
                        this.state.showWhatsnewInfo
                        ?
                        <ChangeInfo
                            isDialogOpen={this.state.showWhatsnewInfo}
                            handleClose={this.handleClose}
                            map={this.map.current}
                            data={this.state.selectedWhatsnew}
                            displayedLayer={this.state.displayedLayer}
                            area={this.state.areaId}
                            confirmed={true}
                        />
                        :
                        null
                    }
                    {
                        this.state.showDetectionList
                        ?
                        <DetectedObjects
                            handleFlyToAnObject={this.handleFlyToDetectedObject}
                            handleConfirm={this.handleConfirmObject}
                            handleDelete={this.handleDeleteDetectedObject}
                            detectionList={this.props.detectionList}
                            handleClose={this.handleClose}
                            loading={this.props.detectionsLoading}
                            name={this.state.clickedDetectionGroup.Name}
                            setAlert={this.setAlert}
                            unsetAlert={this.unsetAlert}
                            currentPage={this.state.detectedObjectsPage}
                            onPageChange={this.handleDetectedObjectsPageChange}
                        />
                        :
                        null
                    }
                    {
                        this.state.showWhereelseList
                        ?
                        <WhereelseObjects 
                            label={this.state.selectedAnno.Name}
                            handleClose={this.handleClose}
                            handleFlyTo={this.handleFlyToWhereelseObject}
                            handleConfirm={this.handleConfirmWhereelseObject}
                        />
                        :
                        null
                    }
                    {
                        this.state.showObjectList
                        ?
                        <ConfirmedObjects
                            handleFlyToAnObject={this.handleFlyToConfirmedObject}
                            handleEdit={this.handleEditConfirmedObject}
                            handleDelete={this.handleDeleteConfirmedObject}
                            objectList={this.state.confirmedObjects}
                            label={this.state.clickedObjectLabel}
                            handleClose={this.handleClose}
                            setAlert={this.setAlert}
                            unsetAlert={this.unsetAlert}
                            displayedLayer={this.state.displayedLayer}
                        />
                        :
                        null
                    }
                    {
                        this.state.showDetectionForm&&this.props.detectionFetched
                        ?
                        <DetectionForm
                            handleClose={()=>this.handleClose()}
                            clickedDetection={this.state.clickedDetection}
                            displayedLayer={this.state.displayedLayer}
                            areaId={this.state.areaId}
                            map={this.map.current}
                        />
                        :
                        null
                    }
                    {
                        this.state.showObjectForm
                        ?
                        <ConfirmedObjectForm
                            handleClose={()=>this.handleClose()}
                            clickedObject={this.state.clickedObject}
                            displayedLayer={this.state.displayedLayer}
                            map={this.map.current}
                        />
                        :
                        null
                    }
                    {
                        <div style={{
                            width: '100%',
                            display:this.props.isEditAnnotationModeOn ? 'flex' : 'none',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <div id="timelapse-alert-container">
                                <Alert severity='warning'
                                    action={<AlertAction 
                                        onConfirm={this.handleConfirmEdit}
                                        onDelete={this.handleDelete}
                                        onCancel={this.handleCancelEdit}
                                        confirmText='Save'
                                        deleteText='Delete'
                                        cancelText='Cancel'
                                        disableConfirm={false}
                                        disableDelete={true}
                                        disableCancel={false}
                                    />}
                                    icon={false}
                                    >
                                        {`You are in the edit mode. Click on the annotation to edit it.`}
                                </Alert> 
                            </div>
                        </div>
                    }
                    {
                        this.state.drawerOpen ?
                        <div id= "baykar-logo" >
                            <img src={logoBaykar} />
                        </div>
                        :
                        <div id= "baykar-logo-dark" >
                            <img src={logoBaykar} />
                        </div>
                    }
                </div>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        areaId: state.map.areaId,
        lng: state.map.lng,
        lat: state.map.lat,
        zoom: state.map.zoom,
        rasterLayers: state.map.rasterLayers,
        selectedRasterLayers: state.map.selectedRasterLayers,
        timelapseLayers: state.map.timelapseLayers,
        baseLayer: state.map.baseLayer,
        displayedLayer: state.map.displayedLayer,
        clickedLocation: state.map.clickedLocation,
        mapCenter: state.map.mapCenter,
        mapRef: state.map.mapRef,
        featureCollectionLayers: state.map.featureCollectionLayers,
        fcLayers: state.map.fcLayers,
        mapStateLoaded: state.map.mapStateLoaded,
        regionName: state.map.regionName,
        user: state.auth.user,
        isAnnotationListLoading: state.annotation.isAnnotationListLoading,
        isAnnotationCreated: state.annotation.isAnnotationCreated,
        isFailedToCreateAnnotation: state.annotation.isFailedToCreateAnnotation,
        annotationList: state.annotation.annotationList,
        annotation: state.annotation.annotation,
        isEditAnnotationModeOn: state.annotation.isEditMode,
        isAnnotationPut: state.annotation.isAnnotationPut,
        isAnnotationDeleted: state.annotation.isAnnotationDeleted,
        isFailedToDeleteAnnotation: state.annotation.isFailedToDeleteAnnotation,
        whatsnewList: state.whatsnew.whatsnewList,
        whatsnewsFetched: state.whatsnew.isWhatsnewsFetched,
        whatsnew: state.whatsnew.whatsnew,
        whatsnewDeleted: state.whatsnew.isWhatsnewDeleted,
        detectionList: state.detection.detectionList,
        detectionsLoading: state.detection.isDetectionListLoading,
        detectionsFetched: state.detection.isDetectionListFetched,
        detection: state.detection.detection,
        fetchedDetection: state.detection.fetchedDetection,
        detectionFetched: state.detection.isDetectionFetched,
        detectionDeleted: state.detection.isDetectionDeleted,
        isObjectCreated: state.object.isObjectCreated,
        objectList: state.object.objectList,
        objectsFetched: state.object.isObjectsFetched,
        objectsLoading: state.object.isObjectListLoading,
        objectDeleted: state.object.isObjectDeleted,
        objectPut: state.object.isObjectPut,
        /* generateWhereelses */
        isWhereelsesRequested: state.whereelse.isWhereelsesRequested,
        isWhereelsesGenerated: state.whereelse.isWhereelsesGenerated,
        whereelseResults: state.whereelse.whereelseResults,
        isFailedToGenerateWhereelses: state.whereelse.isFailedToGenerateWhereelses,
        /* getWhereelseList */
        iswhereelseListLoading: state.whereelse.iswhereelseListLoading,
        isWhereelseListFetched: state.whereelse.isWhereelseListFetched,
        whereelseList: state.whereelse.whereelseList,
        isFailedToFetchWhereelseList: state.whereelse.isFailedToFetchWhereelseList,
    }
}

export default connect(mapStateToProps, { 
    loadMap, 
    resetMapState, 
    loadUser,
    editAnnotationModeOn,
    editAnnotationModeOff,
    getAnnotationList, 
    getAnnotation, 
    deleteAnnotation, 
    resetAnnotationForm, 
    getWhatsnewList, 
    getWhatsnew,
    deleteWhatsnew,
    resetWhatsnewForm,
    createAnnotation, 
    getDetectionList, 
    getOneDetection, 
    deleteOneDetection, 
    getObjectList, 
    getOneObject, 
    deleteOneObject, 
    resetObjects 
})(Timelapse)