import {
  S3_BUCKETS_LOADING,
  S3_BUCKETS_RETRIEVED,
  S3_BUCKET_DELETING,
  S3_BUCKET_DELETED,
  S3_BUCKET_CREATING,
  CREATED_S3_BUCKET,
  FAILED_TO_CREATE_S3_BUCKET,
  RESET_S3_BUCKET_FORM,
  S3_BUCKET_POLICY_FETCHED,
  S3_BUCKET_CORS_FETCHED,
  S3_BUCKET_EDITING,
  S3_BUCKET_PUT,
  FAILED_TO_PUT_S3_BUCKET,
  FAILED_TO_DELETE_S3_BUCKET,
  FAILED_S3_BUCKETS,
  FAILED_TO_FETCH_S3_BUCKET_POLICY,
  FAILED_TO_FETCH_S3_BUCKET_CORS,
  S3_BUCKET_LOADING
} from "../actions/types";

const initialState = {
  alert: "",
  bucketList: [],
  isBucketListLoading: false,
  isBucketListFetched: false,
  isBucketListFailedToFetch: false,
  isBucketCreating: false,
  isBucketCreated: false,
  isBucketFailedToCreate: false,
  bucketPolicy: '',
  bucketCors: [],
  newBucket: { name: null },
  isBucketLoading: false,
  isBucketFetched: false,
  isBucketFailedToFetch: false,
  isBucketPolicyFetched: false,
  isBucketPolicyFailedToFetch: false,
  isBucketCorsFetched: false,
  isBucketCorsFailedToFetch: false,
  isBucketDeleting: false,
  isBucketDeleted: false,
  isBucketFailedToDelete: false,
  isBucketEditing: false,
  isBucketPut: false,
  isBucketFailedToPut: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case S3_BUCKETS_LOADING:
      return {
        ...state,
        isBucketListLoading: true
      }
    case S3_BUCKETS_RETRIEVED:
      return {
        ...state,
        bucketList: [...action.payload],
        isBucketListFetched: true,
        isBucketListFailedToFetch: false,
        isBucketListLoading: false
      };
    case FAILED_S3_BUCKETS:
      return {
        ...state,
        bucketList: [],
        isBucketListFetched: false,
        isBucketListFailedToFetch: true,
        isBucketListLoading: false
      };
    case S3_BUCKET_CREATING:
      return {
        ...state,
        isBucketCreating: true
      };
    case CREATED_S3_BUCKET:
      return {
        ...state,
        alert: action.payload,
        isBucketCreated: true,
        isBucketFailedToCreate: false,
        isBucketCreating: false
      };
    case FAILED_TO_CREATE_S3_BUCKET:
      return {
        ...state,
        isBucketCreated: false,
        isBucketFailedToCreate: true,
        alert: action.payload,
        isBucketCreating: false
      };
    case S3_BUCKET_LOADING:
      return {
        ...state,
        isBucketLoading: true
      };
    case S3_BUCKET_POLICY_FETCHED:
      return {
        ...state,
        bucketPolicy: action.payload,
        isBucketFetched: true,
        isBucketFailedToFetch: false,
        isBucketLoading: false,
        isBucketPolicyFetched: true,
        isBucketPolicyFailedToFetch: false,
      };
    case FAILED_TO_FETCH_S3_BUCKET_POLICY:
      return {
        ...state,
        // bucketCors: action.payload,
        bucketPolicy: action.payload,
        isBucketFetched: false,
        isBucketFailedToFetch: true,
        isBucketLoading: false,
        isBucketPolicyFetched: false,
        isBucketPolicyFailedToFetch: true,
        alert: action.payload
      };
    case S3_BUCKET_CORS_FETCHED:
      return {
        ...state,
        bucketCors: action.payload,
        isBucketFetched: true,
        isBucketFailedToFetch: false,
        isBucketLoading: false,
        isBucketCorsFetched: true,
        isBucketCorsFailedToFetch: false,
      };
    case FAILED_TO_FETCH_S3_BUCKET_CORS:
      return {
        ...state,
        bucketCors: action.payload,
        isBucketFetched: false,
        isBucketFailedToFetch: true,
        isBucketLoading: false,
        isBucketCorsFetched: false,
        isBucketCorsFailedToFetch: true,
        alert: action.payload
      };
    case S3_BUCKET_DELETING:
      return {
        ...state,
        isBucketDeleting: true
      };
    case S3_BUCKET_DELETED:
      return {
        ...state,
        isBucketDeleted: true,
        isBucketFailedToDelete: false,
        isBucketDeleting: false
      };
    case FAILED_TO_DELETE_S3_BUCKET:
      return {
        ...state,
        isBucketDeleted: false,
        isBucketFailedToDelete: true,
        alert: action.payload,
        isBucketDeleting: false
      };
    case S3_BUCKET_EDITING:
      return {
        ...state,
        isBucketEditing: true
      };
    case S3_BUCKET_PUT:
      return {
        ...state,
        isBucketPut: true,
        isBucketFailedToPut: false,
        isBucketEditing: false
      };
    case FAILED_TO_PUT_S3_BUCKET:
      return {
        ...state,
        isBucketPut: false,
        isBucketFailedToPut: true,
        isBucketEditing: false,
        alert: action.payload
      };
    case RESET_S3_BUCKET_FORM:
      return {
        ...state,
        alert: "",
        // bucketList: [],
        isBucketListLoading: false,
        isBucketListFetched: false,
        isBucketListFailedToFetch: false,
        isBucketCreating: false,
        isBucketCreated: false,
        isBucketFailedToCreate: false,
        bucketPolicy: '',
        bucketCors: [],
        newBucket: { name: null },
        isBucketLoading: false,
        isBucketFetched: false,
        isBucketFailedToFetch: false,
        isBucketPolicyFetched: false,
        isBucketPolicyFailedToFetch: false,
        isBucketCorsFetched: false,
        isBucketCorsFailedToFetch: false,
        isBucketDeleting: false,
        isBucketDeleted: false,
        isBucketFailedToDelete: false,
        isBucketEditing: false,
        isBucketPut: false,
        isBucketFailedToPut: false,
      };
    default:
      return state;
  }
}