import {
    /* generateWhereelses */
    WHEREELSES_REQUESTED,
    WHEREELSES_GENERATED,
    FAILED_TO_GENERATE_WHEREELSES,
    /* getWhereelseList */
    RESET_WHEREELSE_FORM
  } from '../actions/types';

const initialState = {
  /* generateWhereelses */
  isWhereelsesRequested: false,
  isWhereelsesGenerated: false,
  whereelseResults: [],
  isFailedToGenerateWhereelses: false,
  /* getWhereelseList */
  iswhereelseListLoading: false,
  isWhereelseListFetched: false,
  whereelseList: [],
  isFailedToFetchWhereelseList: false,
  /* message */
  message: ''
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case WHEREELSES_REQUESTED:
        return {
            ...state,
            isWhereelsesRequested: true,
            isWhereelsesGenerated: false,
            whereelseResults: [],
            isFailedToGenerateWhereelses: false,
        }
      case WHEREELSES_GENERATED:
        return {
            ...state,
            isWhereelsesRequested: false,
            isWhereelsesGenerated: true,
            whereelseResults: {...action.payload},
            isFailedToGenerateWhereelses: false,
        }
      case FAILED_TO_GENERATE_WHEREELSES:
        return {
            ...state,
            isWhereelsesRequested: false,
            isWhereelsesGenerated: false,
            whereelseResults: [],
            isFailedToGenerateWhereelses: true,
        }
      case RESET_WHEREELSE_FORM:
        return {
            ...state,
            /* generateWhereelses */
            isWhereelsesRequested: false,
            isWhereelsesGenerated: false,
            // whereelseResults: [],
            isFailedToGenerateWhereelses: false,
            /* getWhereelseList */
            iswhereelseListLoading: false,
            isWhereelseListFetched: false,
            // whereelseList: [],
            isFailedToFetchWhereelseList: false,
            /* message */
            message: ''
        }
      default:
        return state;
    }
}
  




