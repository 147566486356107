/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { getMapList } from '../redux/actions/maps';

/* custom components */

/* mui material components */

/* mui icons-material components */
import MapTable from './MapTable'
import StorageTable from './StorageTable'


class Maps extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            showLinks: ''
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
        
    }

    /**
     * shows links in a tooltip window
     * that would allow the user to copy them
     * @param {*} id 
     */
     handleShowLinks = (id) => {
        this.setState({
            showLinks: this.state.showLinks==id ? null : id
        })
    }    

    /**
     * Click away to hide the tooltip
     */
    handleHideLinks = () => {
        this.setState({
            showLinks: null
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div 
                    id='maps-page-container'
                >
                    <StorageTable 
                        handleShowLinks={this.handleShowLinks} 
                        handleHideLinks={this.handleHideLinks}
                        showLinks={this.state.showLinks}
                    />
                    <MapTable 
                        handleShowLinks={this.handleShowLinks} 
                        handleHideLinks={this.handleHideLinks}
                        showLinks={this.state.showLinks}
                    />
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        mapList: state.maps.mapList
    }
}

export default connect(mapStateToProps, { getMapList })(Maps)