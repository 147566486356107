/* eslint-disable eqeqeq */
import axios from 'axios';
import { polygon, centerOfMass} from "@turf/turf"
import {
    OBJECTS_LOADING,
    OBJECTS_FETCHED,
    FAILED_OBJECTS,
    OBJECT_FETCHED,
    CREATED_OBJECT,
    FAILED_NEW_OBJECT,
    OBJECTS_FETCHED_IND,
    RESET_OBJECT_FORM,
    OBJECT_DELETED,
    FAILED_TO_DELETE_OBJECT,
    OBJECT_PUT,
    FAILED_OBJECT_PUT //this is used for fetching objects with given names
  } from './types';
import { addObjectsToMap, addTmpConfirmedPolygons, removeConfirmedPolygons, removeTmpConfirmedPolygons } from '../../helpers/helpers';
/* 
Important Note:
Detection components will be used for unconfirmed detections, the output of object detection scripts 
We have another redux components for dealing with confirmed object detections
*/
// const SERVER = process.env.REACT_APP_DJANGO_SERVER
const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}
/**
 * Filters all OBJECTSs by storage and user id
 * user id is loaded automatically via config
 * @param {*} body 
 * @returns 
 */
export const getObjectList = (params,map,displayedLayerId) => (dispatch) => {
    let copyConfig={...config}
    copyConfig.params={...params}
    dispatch({
        type: OBJECTS_LOADING
    })
    axios.get(`/flightsapi/object`, copyConfig).then((res) => {
        let paramKeys=Object.keys(params)
        if(paramKeys.includes('Area')){
          dispatch({
            type: OBJECTS_FETCHED,
            payload: res.data
          })
          if(map){
            addObjectsToMap(map, res.data, displayedLayerId)
          }
        }
        if(paramKeys.includes('Storage')){
          dispatch({
            type: OBJECTS_FETCHED_IND,
            payload: res.data
          })
        }
    }).catch((error) => {
        dispatch({
            type: FAILED_OBJECTS,
            payload: 'Failed to fetch changes'
        })
    })
}
/* */
export const createObject = (object) => (dispatch) => {
    dispatch({
        type: OBJECTS_LOADING
    })
    axios.post(`/flightsapi/object`, object, config).then((res) => {
        if(res.data=='Added successfully.'){
          dispatch({
            type: CREATED_OBJECT,
            payload: res.data
          }) 
        } else {
          dispatch({
            type: FAILED_NEW_OBJECT,
            payload: 'Failed to add the object.'
          })
        }
    }).catch((e) => {
      dispatch({
        type: FAILED_NEW_OBJECT,
        payload: `Faild to add the object.`
      })
    })
}
/*Fetch one confirmed object from metastore*/
export const getOneObject = (id, map) => (dispatch) => {
    dispatch({
        type: OBJECTS_LOADING,
        payload: "object is loading"
    })
    axios.get(`/flightsapi/object/${id}`, config).then((res) => {
        dispatch({
            type: OBJECT_FETCHED,
            payload: res.data
        })
        let coord = polygon(res.data.Geojson.geometry.coordinates)
        let center = centerOfMass(coord).geometry.coordinates
        // map.flyTo({
        //     center,
        //     essential: true,
        //     zoom: 21,
        //     speed: 1.5
        // })
        map.setZoom(20)
        map.setCenter(center)
        addTmpConfirmedPolygons(map, res.data.Geojson)
    }).catch((error) => {
        dispatch({
            type: FAILED_OBJECTS,
            payload: 'Failed to fetch object'
        })
    })
}
/* */
export const resetObjects = () => (dispatch) => {
  dispatch({
    type:   RESET_OBJECT_FORM
  })
}
/* */
export const deleteOneObject = (id, map, storageId, label) => (dispatch) => {
  axios.delete(`/flightsapi/object/${id}`,config).then((res)=>{
    dispatch({
      type: OBJECT_DELETED,
      payload: res.data
    })
    removeTmpConfirmedPolygons(map)
    removeConfirmedPolygons(map, id, storageId)
    try {
        map.removeLayer(`draw-polygon-object-${id}-${label}-${storageId}`)
        map.removeLayer(`draw-outline-object-${id}-${label}-${storageId}`)
        map.removeSource(`object-${id}`)
    } catch (error) {
      
    }
  }).catch((error)=>{
    dispatch({
      type: FAILED_TO_DELETE_OBJECT,
      payload: error
    })
  })
}
/* */
export const editOneObject = (editedObject) => (dispatch) => {
  axios.put(`/flightsapi/object/${editedObject.id}`, editedObject, config).then((res)=>{
    dispatch({
      type: OBJECT_PUT,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_OBJECT_PUT,
      payload: e
    })
  })
}
