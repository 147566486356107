import { ATLAS_ML } from '../../configs/hosts';

const initialState = {
    atlasML: ATLAS_ML
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_ATLASML":
            return {
                atlasML: action.payload 
            }
        default:
            return {...state}
    }
}

