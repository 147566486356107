/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';

/* mui icons-material components */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

/* Redux */
import { getFlightInfo, getMapGeojson } from '../redux/actions/maps';


class FlightInfo extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                    >
                        <div style={{width: '475px', height: '500px'}}>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '10px'}}>
                                <IconButton onClick={this.props.handleHideInfo}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Typography
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '30px',
                                    fontFamily: 'Poppins',
                                    color: '#121f33'
                                }}
                            >
                                {/* Flight Info {this.props.flightName} */}
                                {`${i18n.t("FlightInfo.FlightInfo")} ${this.props.flightName}`}
                            </Typography>
                            {Object.keys(this.props.flightInfo).length==0?
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {/* Not found */}
                                    {`${i18n.t("FlightInfo.NotFound")} ${this.props.flightName}`}
                                </Typography>
                                :
                                Object.keys(this.props.flightInfo).map((key)=>
                                    <div key={key}>
                                        <InputLabel 
                                            htmlFor={`${key}-${this.props.flightName}`}
                                            sx={{
                                                fontSize: '12px',

                                            }}
                                        >{`${key}-${this.props.flightName}`}</InputLabel>
                                        <div
                                            className='map-table-link-container'
                                        >
                                            <FilledInput 
                                                sx={{
                                                fontFamily: 'Poppins'

                                                }} 
                                                value={typeof this.props.flightInfo[key]=='object'? JSON.stringify(this.props.flightInfo[key]):this.props.flightInfo[key]} 
                                                fullWidth
                                                size='small'
                                                variant='standard'
                                                label={`${key}-${this.props.flightName}`}
                                                id={`${key}-${this.props.flightName}`}
                                            />
                                            <IconButton 
                                                onClick={() => this.copyToClipboard(`${key}-${this.props.flightName}`)}

                                            >
                                                <ContentCopyIcon/>
                                            </IconButton>
                                        </div>
                                        <br></br>
                                    </div>
                                )}
                                <br></br>
                        </div>
                    </Box>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo: state.maps.flightInfo,
        flightName: state.maps.flightName,
        processedDirs: state.maps.processedDirs,
        language: state.language.language
    }
}

export default connect(mapStateToProps, {getFlightInfo, getMapGeojson})(FlightInfo)