/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { getAreaList } from '../redux/actions/areas';
import { getMapList, getFlightInfo, addFlightFromStorage, resetMapForm } from '../redux/actions/maps';

/* custom components */
import { BlackCheckbox }from './CustomCheckbox'

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ClickAwayListener from '@mui/material/ClickAwayListener';

/* mui icons-material components */
import FolderIcon from '@mui/icons-material/Folder';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LinkIcon from '@mui/icons-material/Link';
import LayersIcon from '@mui/icons-material/LayersRounded'
import DateRangeIcon from '@mui/icons-material/DateRange';

class MapNewForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            FlightName: '',
            Layers:'',
            flight: null,
            desc: null,
            PolygonData: '',
            FlightDate: '',
            altitude: '',
            MFStitch: '',
            WFStitch: '',
            userGroup: [],
            listOpen: false,
            groups: []
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserGroup = this.handleUserGroup.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        /* add checked field to the user groups */
        let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
        this.setState({
            groups: [...checkedGroups]
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isDialogOpen!==this.props.isDialogOpen && !this.props.isDialogOpen){
            this.setState({
                MFStitch: '',
                WFStitch: '',
                HeatMap: '',
                DepthMap: '',
            })
            /* add checked field to the user groups */
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
            this.setState({
                groups: [...checkedGroups]
            })
        }
        if(prevProps.isMapAdded !== this.props.isMapAdded && this.props.isMapAdded){
            this.props.getMapList()
        }
    }

    /**
     * This is used for both editing and creating new areas
     */
    handleSubmit(){
        let map={
            Layers:JSON.parse(this.state.Layers),
            MFStitch: this.state.MFStitch,
            WFStitch: this.state.WFStitch,
            FlightName: this.state.FlightName,
            PolygonData: JSON.parse(this.state.PolygonData),
            FlightDate: this.state.FlightDate,
            UserGroup: this.state.userGroup==-1?null:this.state.userGroup,
            Changes: {}
        }
        this.props.addFlightFromStorage(map)
        this.props.handleClose()
        setTimeout(() => {
            this.props.resetMapForm()
        }, 1000)
        this.setState({
            Layers: '',
            MFStitch:'',
            FlightName:'',
            PolygonData:'',
            FlightDate:''
        })
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }


    /* */
    handleUserGroup(e) {
        this.setState({
            userGroup: e.target.value
        })
    }

    /* */
    handleOpen(e) {
        this.setState({
            listOpen: !this.state.listOpen
        })
    }

    /* */
    handleClose(e) {
        this.setState({
            listOpen: false
        })
    }

    /* */
    handleCheckbox(id) {
        this.setState({
            listOpen: true
        })
        let editedGroups=[...this.state.groups]
        let checkedGroup=editedGroups.find(group=>group.id==id)
        let index=this.state.groups.findIndex(group=>group.id==id)
        let checked=checkedGroup.checked
        checkedGroup.checked=!checked
        editedGroups.splice(index, 1, checkedGroup)
        let userGroups=[]
        editedGroups.forEach(group=>group.checked?userGroups.push(group.id):'')
        this.setState({
            groups: editedGroups,
            userGroup: userGroups
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* Add New Layer */}
                            {i18n.t("MapNewForm.AddNewLayer")}
                        </Typography>
                        <div id='admin-panel-formcontrol-container'>
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <Select
                                        value={-1} 
                                        defaultValue={-1} 
                                        sx={{height: '42px', color: this.state.userGroup.length==0?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                        open={this.state.listOpen}
                                        onClick={this.handleOpen}
                                    >
                                        <MenuItem value={-1} disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                            {/* Select User Group */}
                                            {i18n.t("MapNewForm.SelectUserGroup")}
                                        </MenuItem>
                                        {
                                            this.state.groups.map((group)=>
                                                <MenuItem value={group.id} sx={{fontFamily: 'Poppins'}}>
                                                    <BlackCheckbox checked={group.checked} onChange={() => this.handleCheckbox(group.id)}/>
                                                    <Typography sx={{fontFamily: 'Poppins'}}>{group.name}</Typography>                                                
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </ClickAwayListener>
                            </FormControl>
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("MapNewForm.FlightName")}>
                                <FolderIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("MapNewForm.FlightName")}
                                value={this.state.FlightName}
                                onChange={this.handleChange('FlightName')}
                                required
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='MF Stitch'>
                                <LinkIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={'MF Stitch'}
                                required
                                value={this.state.MFStitch}
                                onChange={this.handleChange('MFStitch')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='WF Stitch'>
                                <LinkIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={'WF Stitch'}
                                required
                                value={this.state.WFStitch}
                                onChange={this.handleChange('WFStitch')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("MapNewForm.Layers")}>
                                <LayersIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("MapNewForm.Layers")}
                                required
                                value={this.state.Layers}
                                onChange={this.handleChange('Layers')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("MapNewForm.PolygonData")}>
                                <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("MapNewForm.PolygonData")}
                                required
                                value={this.state.PolygonData}
                                onChange={this.handleChange('PolygonData')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("MapNewForm.FlightDate")}>
                                <DateRangeIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("MapNewForm.YYYYMMDD")}
                                required
                                value={this.state.FlightDate}
                                onChange={this.handleChange('FlightDate')}
                            />
                        </div>
                        <br/>
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("MapNewForm.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("MapNewForm.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo: state.maps.flightInfo,
        ipAddress: state.maps.ipAddress,
        flightsDir: state.maps.flightsDir,
        flightName: state.maps.flightName,
        processedDirs: state.maps.processedDirs,
        isMapAdded: state.maps.isMapAdded,
        isFailedToAddMap: state.maps.isFailedToAddMap,
        groups: state.groups.groups,
        language: state.language.language
    }
}
export default connect(mapStateToProps, {getFlightInfo, addFlightFromStorage, getMapList, resetMapForm, getAreaList})(MapNewForm)