/* eslint-disable eqeqeq */
import React from 'react'
// import mapboxgl from 'mapbox-gl'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { 
    drawStyle, 
    createMap, 
    addSatelliteLayers, 
    hideStreetLayers,
    addRasterLayer,
    addChangeDetectionLayer,
    setZoomAndCoordinates,
    setDimensionTerrain,
    addChangePolygon,
    moveDrawLayersToTop,
    getOlderNewerLayer,
} from '../helpers/helpers'
import ChangeDetectionPanel from '../components/ChangeDetectionPanel.jsx';
import axios from 'axios'
import i18n from 'i18next'

/* redux */
import { connect } from 'react-redux'
import { getWhatsnewList, getWhatsnew, createWhatsnew, deleteWhatsnew, resetWhatsnewForm } from '../redux/actions/whatsnew';
import { getChangeList, getChange, createChange, putChange, deleteChange, resetChangeForm, startChangeDetection, resetChangeDetection } from '../redux/actions/change';
import { putMap } from '../redux/actions/maps';

/* custom components */
import Header from '../components/Header'
import WhatsnewForm from '../components/WhatsnewForm'
import PanelFooter from '../components/PanelFooter';
import AlertAction from '../components/AlertAction';
import ChangeInfo from '../components/ChangeInfo';
import BasicAlert from '../components/BasicAlert';

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

/* mui icons-material components */

/* assets */
import streets from '../assets/streets.png'
import google from '../assets/google.png'
import ChangeDetectionForm from '../components/ChangeDetectionForm';

/**
 * 
 */
mapboxgl.accessToken = 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg'
mapboxgl.workerClass = MapboxWorker

/**
 * Set in docker-compose.yml
 */
//  const SERVER = process.env.REACT_APP_DJANGO_SERVER
//  let BASE_URL = process.env.REACT_APP_BASE_URL

 /**
 * 
 */
const config = {
    headers: {
      Authorization: `token ${window.localStorage.getItem('token')}`
    }
}

class ChangeDetection extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            firstLayerDate: '',
            firstLayerOpacity: 100,
            secondLayerDate: '',
            secondLayerOpacity: 100,
            changeLayerOpacity: 100,
            bottomLayers: [],
            topLayers: [],
            areaId: null,
            regionName: '',
            firstLayer: {displayed: true},
            secondLayer: {displayed: true},
            changeLayer: {displayed: true},
            mapCenter: [0,0],
            areaCenter: [0,0],
            clickedLocation: [0,0],
            elevation: 0,
            zoom: 0,
            mapLoading: true,
            threeDimension: false,
            isDrawMode: false,
            changePolygon: {},
            changePolygonId: null, //id for example on click change layer
            showForm: false,
            changeName: '',
            changeDescription: '',
            showChangeInfo: false,
            mapStyle: 'google',
            showAnnotationForm: false,
            annotationGeojson: {},
            isEditMode: false,
            olderLayer: {},
            newerLayer: {},
            alert: null,
            changeDetectionThreshold: 95,
            showChangeDetectionForm: false,
        }

        /* Function bindings here */
        this.handleSelectFirst=this.handleSelectFirst.bind(this)
        this.handleSelectSecond=this.handleSelectSecond.bind(this)
        this.handleOpacityFirst=this.handleOpacityFirst.bind(this)
        this.handleOpacitySecond=this.handleOpacitySecond.bind(this)
        this.handleCheck=this.handleCheck.bind(this)
        this.handleOpacityChange=this.handleOpacityChange.bind(this)
        this.handleMapIcon = this.handleMapIcon.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleConfirm = this.handleConfirm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCloseInfo = this.handleCloseInfo.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.setChangePolygonForCreate = this.setChangePolygonForCreate.bind(this)
        this.setChangePolygonForEdit = this.setChangePolygonForEdit.bind(this)
        this.handleStyleChange = this.handleStyleChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.setAlert = this.setAlert.bind(this)
        this.unsetAlert = this.unsetAlert.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.createNewChangeDetection = this.createNewChangeDetection.bind(this)
        this.handleClickOnFeature = this.handleClickOnFeature.bind(this)
        this.handleChangeDetection = this.handleChangeDetection.bind(this)
        this.startChangeDetection = this.startChangeDetection.bind(this)
        this.handleChangeDetectionThreshold = this.handleChangeDetectionThreshold.bind(this)
        this.setEmptyDetectionAlert = this.setEmptyDetectionAlert.bind(this)
        this.handleCloseEmptyDetectionAlert = this.handleCloseEmptyDetectionAlert.bind(this)

        /* Refs */
        this.map = React.createRef()
        this.mapContainer = React.createRef()
        this.draw = React.createRef()
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        document.title=params.regionName
        let areaLng=Number(params.areaLng)
        let areaLat=Number(params.areaLat)
        let lngNow=Number(params.lng)
        let latNow=Number(params.lat)
        let zoomNow=Number(params.zoom)
        let areaId=params.areaId
        let regionName=params.regionName

        this.setState({
            clickedLocation: [lngNow, latNow],
            mapCenter: [lngNow, latNow],
            zoom: zoomNow,
            areaId: areaId,
            areaCenter: [areaLng, areaLat]
        })
        /*Render the map only once*/
        if(this.map.current) return
        /*Create the draw control*/
        this.draw.current = new MapboxDraw({
            displayControlsDefault: true,
            styles: drawStyle("#ffd663")
        })
        const draw = this.draw.current
        this.setState({
            areaId: areaId,
            regionName: regionName
        })
        axios.get(`/flightsapi/storage?Area=${areaId}`, config).then((res)=>{
            let bottomLayers=[]
            let topLayers=[]
            let filteredRes=res.data.filter((layer)=>layer.MFStitch)
            /*build bottom and top layers*/
            for(let i=0; i<filteredRes.length; i++){
                let bottomLayer={...filteredRes[i]}
                let topLayer={...filteredRes[i]}
                if(i==0){
                    bottomLayer.displayed=true
                    topLayer.displayed=false
                } else if(i==(filteredRes.length-1)){
                    bottomLayer.displayed=false
                    topLayer.displayed=true
                } else {
                    bottomLayer.displayed=false
                    topLayer.displayed=false
                }
                bottomLayer.opacity=1
                topLayer.opacity=1
                bottomLayers.push(bottomLayer)
                topLayers.push(topLayer)
            }
            let changeLayer={...this.state.changeLayer}
            // COMPARE DATE HERE
            // TYPE KEY
            let oldNew=getOlderNewerLayer(bottomLayers[0],topLayers[topLayers.length-1])
            try {
                changeLayer.Detections=topLayers[topLayers.length-1].Changes[`${oldNew.newer.id}-${oldNew.older.id}`]
            } catch (error) {
                changeLayer.Detections=null
            }
            this.setState({
                bottomLayers: bottomLayers,
                topLayers: topLayers,
                firstLayer: bottomLayers[0],
                olderLayer: bottomLayers[0],
                firstLayerDate: bottomLayers[0].FlightDate,
                secondLayer: topLayers[topLayers.length-1],
                newerLayer: topLayers[topLayers.length-1],
                secondLayerDate: topLayers[topLayers.length-1].FlightDate,
                changeLayer: {...changeLayer}
            })
            /** Get coordinates from the local storage */
            let lng = lngNow
            let lat = latNow
            let center = [lng, lat]
            /*Create mapbox map*/
            this.map.current = createMap(this.mapContainer.current, center, zoomNow)
            /** on load */
            this.map.current.on('load',()=>{
                hideStreetLayers(this.map.current)
                addSatelliteLayers(this.map.current)
                bottomLayers.forEach((layer)=>{
                    addRasterLayer(this.map.current, `bottomLayer-${layer.id}`, layer, layer.displayed, 700)
                })
                topLayers.forEach((layer)=>{
                    addRasterLayer(this.map.current, `topLayer-${layer.id}`, layer, layer.displayed, 700)
                })
                /*fill the changeLayer object*/
                if(changeLayer.Detections!=null){
                    changeLayer.opacity=1
                    let oldNew = getOlderNewerLayer(bottomLayers[0],topLayers[topLayers.length-1])
                    changeLayer.id=`change-${oldNew.older.id}-${oldNew.newer.id}`
                    this.props.getChangeList(changeLayer.Detections, changeLayer.id)
                    this.setState({
                        changeLayer: {...changeLayer}
                    })
                    /* click on a polygon, line or a point */
                    this.handleClickOnFeature(changeLayer, 'fill')
                    this.handleClickOnFeature(changeLayer, 'line')
                    this.handleClickOnFeature(changeLayer, 'circle')
                    this.map.current.on('draw.delete', () => {
                        this.setState({
                            isDrawMode: false,
                            isEditMode: false
                        })
                    })
                }
                setTimeout(() => {
                    this.setState({
                        mapLoading: false
                    })
                },500)
                /** Add 3D */
                setDimensionTerrain(this.map.current, this.state.threeDimension)
            })
            /** on move end */
            this.map.current.on('moveend', () => {
                let center = this.map.current.getCenter()
                let zoom = this.map.current.getZoom()
                this.setState({
                    mapCenter: [center.lng, center.lat],
                    zoom: zoom
                })
                setZoomAndCoordinates(this.map.current, urlSearchParams)
            })
            /** Mouse move events */
            this.map.current.on('mousemove', (e) => {
                let point = (e.lngLat)
                let elevationNow = this.map.current.queryTerrainElevation(point, {exaggerated: false})*3.28084
                this.setState({ 
                    clickedLocation: [point.lng, point.lat], 
                    elevation: elevationNow < 0 || elevationNow==null ? 0 : elevationNow
                })
            })
            /** add draw control to map */
            this.map.current.addControl(draw, 'top-right')
            this.map.current.addControl(new mapboxgl.NavigationControl())
            this.map.current.on('draw.create', (e) => {
                let drawData=draw.getAll()
                let feature=drawData.features[0]
                let {id, ...changePolygon} = feature
                changePolygon.properties = {
                    "description":"unlabeled",
                    "fill-color":"red",
                    "fill-opacity":1,
                    "name":"unlabeled"
                }
                this.setChangePolygonForCreate(changePolygon)
                this.setState({
                    showForm: true,
                    changePolygon: {...changePolygon},
                    isEditMode: false
                })
                moveDrawLayersToTop(this.map.current)
            })
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps, prevState){
        if(prevProps.isChangesFetched!=this.props.isChangesFetched && this.props.isChangesFetched){
            addChangeDetectionLayer(this.map.current, this.state.changeLayer, this.props.changes)
            this.setEmptyDetectionAlert()
        }
        if(prevProps.isChangeFetched!=this.props.isChangeFetched && this.props.isChangeFetched){
            let changePolygon = {...this.props.change}
            changePolygon.id = this.state.changePolygonId
            addChangePolygon(this.draw.current, changePolygon)
            this.setChangePolygonForEdit(changePolygon)
        }
        if(prevProps.isChangeCreated!=this.props.isChangeCreated && this.props.isChangeCreated){
            this.props.getChangeList(this.state.changeLayer.Detections, this.state.changeLayer.id)
            this.props.resetWhatsnewForm()
        }
        if(prevProps.isChangePut!=this.props.isChangePut && this.props.isChangePut){
            this.props.getChangeList(this.state.changeLayer.Detections, this.state.changeLayer.id)
            this.props.resetWhatsnewForm()
        }
        if(prevProps.isChangeDeleted!=this.props.isChangeDeleted && this.props.isChangeDeleted){
            this.props.getChangeList(this.state.changeLayer.Detections, this.state.changeLayer.id)
            this.unsetAlert()
            this.handleCancel()
        }
        if(prevProps.isChangeDetectionsGenerated!=this.props.isChangeDetectionsGenerated && this.props.isChangeDetectionsGenerated){
            this.props.deleteChange(this.map.current, this.draw.current, null, this.state.changeLayer.id)
        }
    }

    /**
     * 
     */
    handleSelectFirst(e) {
        try {
            this.map.current.removeLayer(`${this.state.changeLayer.id}-fill`)
            this.map.current.removeLayer(`${this.state.changeLayer.id}-line`)
            this.map.current.removeLayer(`${this.state.changeLayer.id}-circle`)
            this.map.current.removeSource(this.state.changeLayer.id)   
        } catch (error) {
            console.log('ERROR: ', error)
        }
        this.setState({
            firstLayerDate: e.target.value
        })
        let bottomLayers=[...this.state.bottomLayers]
        let selectedLayer = bottomLayers.find(layer=>layer.FlightDate==e.target.value)
        let newBottomLayers = bottomLayers.map(layer=>({...layer,displayed:layer.FlightDate==e.target.value}))
        let oldNew = getOlderNewerLayer({...selectedLayer, displayed: true},this.state.secondLayer)
        this.setState({
            bottomLayers: newBottomLayers,
            firstLayer: {...selectedLayer, displayed: true},
            olderLayer: oldNew.older,
            newerLayer: oldNew.newer
        })
        newBottomLayers.forEach((layer)=>{
            let visible=layer.displayed
            let layerId=`bottomLayer-${layer.id}`
            this.map.current.setLayoutProperty('WF'+layerId, 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty('WF'+layerId + '-png', 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty(layerId + '-png', 'visibility', visible ? 'visible' : 'none')
        })
        let changeLayer={displayed: true}
        try {
            changeLayer.Detections=this.state.secondLayer.Changes[`${oldNew.newer.id}-${oldNew.older.id}`]
        } catch (error) {
            changeLayer.Detections=null
        }
        if(changeLayer.Detections!=null){
            changeLayer.opacity=1
            changeLayer.id=`change-${oldNew.older.id}-${oldNew.newer.id}`
            this.props.getChangeList(changeLayer.Detections, changeLayer.id)
            this.setState({
                changeLayer: {...changeLayer}
            })
            /* click on a polygon, line or point*/
            this.handleClickOnFeature(changeLayer, 'fill')
            this.handleClickOnFeature(changeLayer, 'line')
            this.handleClickOnFeature(changeLayer, 'circle')
            this.map.current.on('draw.delete', () => {
                this.setState({
                    isDrawMode: false,
                    isEditMode: false
                })
            })
        }
        this.props.resetChangeDetection()
        this.setState({
            changeLayer: {...changeLayer},
        })
    }

    /**
     * 
     */
    handleOpacityFirst(e, newValue) {
        this.setState({
            firstLayerOpacity: newValue
        })
        this.map.current.setPaintProperty(
            `WFbottomLayer-${this.state.firstLayer.id}`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `WFbottomLayer-${this.state.firstLayer.id}-png`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `bottomLayer-${this.state.firstLayer.id}`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `bottomLayer-${this.state.firstLayer.id}-png`,
            'raster-opacity',
            newValue/100
        )
    }

    /**
     * 
     * @returns 
     */
    handleCheck(layer, name) {
        let layerId=layer.id
        let isVisible=layer.displayed
        layer.displayed=!isVisible
        this.setState({
            [name]:{...layer}
        })
        if(name=='firstLayer'){
            this.map.current.setLayoutProperty(`WFbottomLayer-${layerId}`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`WFbottomLayer-${layerId}-png`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`bottomLayer-${layerId}`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`bottomLayer-${layerId}-png`, 'visibility', !isVisible?'visible':'none')
        }
        if(name=='secondLayer'){
            this.map.current.setLayoutProperty(`WFtopLayer-${layerId}`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`WFtopLayer-${layerId}-png`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`topLayer-${layerId}`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`topLayer-${layerId}-png`, 'visibility', !isVisible?'visible':'none')
        }
        if(name=='changeLayer'){
            this.map.current.setLayoutProperty(`${layerId}-fill`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`${layerId}-line`, 'visibility', !isVisible?'visible':'none')
            this.map.current.setLayoutProperty(`${layerId}-circle`, 'visibility', !isVisible?'visible':'none')
        }
    }

    /**
     * 
     */
    handleOpacitySecond(e, newValue) {
        this.setState({
            secondLayerOpacity: newValue
        })
        this.map.current.setPaintProperty(
            `WFtopLayer-${this.state.secondLayer.id}`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `WFtopLayer-${this.state.secondLayer.id}-png`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `topLayer-${this.state.secondLayer.id}`,
            'raster-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `topLayer-${this.state.secondLayer.id}-png`,
            'raster-opacity',
            newValue/100
        )
    }

    /**
     * 
     */
    handleSelectSecond(e) {
        try {
            this.map.current.removeLayer(`${this.state.changeLayer.id}-fill`)
            this.map.current.removeLayer(`${this.state.changeLayer.id}-line`)
            this.map.current.removeLayer(`${this.state.changeLayer.id}-circle`)
            this.map.current.removeSource(this.state.changeLayer.id)   
        } catch (error) {
            console.log('ERROR: ', error)
        }
        this.setState({
            secondLayerDate: e.target.value
        })
        let topLayers=[...this.state.topLayers]
        let selectedLayer = topLayers.find(layer=>layer.FlightDate==e.target.value)
        let newTopLayers = topLayers.map(layer=>({...layer,displayed:layer.FlightDate==e.target.value}))
        let oldNew = getOlderNewerLayer(this.state.firstLayer,{...selectedLayer, displayed: true})
        this.setState({
            topLayers: newTopLayers,
            secondLayer: {...selectedLayer, displayed: true},
            olderLayer: oldNew.older,
            newerLayer: oldNew.newer
        })
        newTopLayers.forEach((layer)=>{
            let visible=layer.displayed
            let layerId=`topLayer-${layer.id}`
            this.map.current.setLayoutProperty('WF'+ layerId, 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty('WF'+ layerId + '-png', 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none')
            this.map.current.setLayoutProperty(layerId + '-png', 'visibility', visible ? 'visible' : 'none')
        })
        let changeLayer={displayed: true}
        try {
            changeLayer.Detections=selectedLayer.Changes[`${oldNew.newer.id}-${oldNew.older.id}`]   
        } catch (error) {
            changeLayer.Detections=null
        }
        if(changeLayer.Detections!=null){
            changeLayer.opacity=1
            changeLayer.id=`change-${oldNew.older.id}-${oldNew.newer.id}`
            // addChangeDetectionLayer(this.map.current, changeLayer)
            this.props.getChangeList(changeLayer.Detections, changeLayer.id)
            this.setState({
                changeLayer: {...changeLayer}
            })
            /* click on a polygon, a line or a point*/
            this.handleClickOnFeature(changeLayer, 'fill')
            this.handleClickOnFeature(changeLayer, 'line')
            this.handleClickOnFeature(changeLayer, 'circle')
            this.map.current.on('draw.delete', () => {
                this.setState({
                    isDrawMode: false,
                    isEditMode: false
                })
            })
        }
        this.props.resetChangeDetection()
        this.setState({
            changeLayer: {...changeLayer}
        })
    }

    /**
     * 
     */
    handleOpacityChange(e, newValue) {
        this.setState({
            changeLayerOpacity: newValue
        })
        this.map.current.setPaintProperty(
            `${this.state.changeLayer.id}-fill`,
            'fill-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `${this.state.changeLayer.id}-line`,
            'line-opacity',
            newValue/100
        )
        this.map.current.setPaintProperty(
            `${this.state.changeLayer.id}-circle`,
            'circle-opacity',
            newValue/100
        )
    }

    /**
     * 
     */
    handleMapIcon() {
        try {
            this.map.current.setZoom(12)
            this.map.current.flyTo({
                center: this.state.areaCenter
            })
        } catch (error) {
            
        }
    }

    /** */
    handleSubmit() {
        if(this.state.changeLayer.Detections==null){
            this.createNewChangeDetection()
            return null;
        }
        let drawData=this.draw.current.getAll()
        let feature=drawData.features[0]
        this.props.createWhatsnew({
            Name: this.state.changeName,
            Description: this.state.changeDescription,
            Storage: this.state.newerLayer.id,
            Area: this.state.areaId,
            Geojson: this.state.changePolygon,
            Displayed: true,
            Zoom: this.state.zoom.toFixed(2),
            Since: this.state.olderLayer.FlightDate
        })
        this.setState({
            changeName: '',
            changeDescription: '',
            isDrawMode: false,
            isEditMode: false,
            showChangeInfo: false
        })
        this.handleClose()
        let id=Number(feature.id)
        let editedChange={...this.state.changePolygon, properties: {...this.state.changePolygon.properties,  "fill-color":"#00FF00", "name": this.state.changeName, "description": this.state.changeDescription}}
        if(this.state.isEditMode && !this.state.isDrawMode){
            this.props.putChange(this.map.current, this.draw.current, id, this.state.changeLayer.id, editedChange)
        }
        if(this.state.isDrawMode && !this.state.isEditMode){
            let newChange={...editedChange}
            this.props.createChange(this.map.current, this.draw.current, this.state.changeLayer.id, newChange)
        }
    }
    /* When there is not any change detection json */
    createNewChangeDetection() {
        // edit storage add Detections newer older to both storage 
        let displayedLayers = getOlderNewerLayer(this.state.firstLayer, this.state.secondLayer)
        let olderLayer = displayedLayers.older
        let newerLayer = displayedLayers.newer
        olderLayer.Changes[`${newerLayer.id}-${olderLayer.id}`]="http://ui"
        newerLayer.Changes[`${newerLayer.id}-${olderLayer.id}`]="http://ui"
        this.props.putMap({
            map: {...olderLayer},
            token: `token ${window.localStorage.getItem('token')}`
        })
        this.props.putMap({
            map: {...newerLayer},
            token: `token ${window.localStorage.getItem('token')}`
        })
        // createChange then metastore should add the key to redis
        this.props.createWhatsnew({
            Name: this.state.changeName,
            Description: this.state.changeDescription,
            Storage: this.state.newerLayer.id,
            Area: this.state.areaId,
            Geojson: this.state.changePolygon,
            Displayed: true,
            Zoom: this.state.zoom.toFixed(2),
            Since: this.state.olderLayer.FlightDate
        })
        this.setState({
            changeName: '',
            changeDescription: '',
            isDrawMode: false,
            isEditMode: false,
            showChangeInfo: false
        })
        this.handleClose()
        let createdChangePolygon = {...this.state.changePolygon, id: 1}
        this.setState({
            changePolygon: {...createdChangePolygon}
        })
        let newChange={...createdChangePolygon, properties: {...this.state.changePolygon.properties,  "fill-color":"#00FF00", "name": this.state.changeName, "description": this.state.changeDescription}}
        let rkey = `change-${olderLayer.id}-${newerLayer.id}`
        let changeLayer={...this.state.changeLayer}
        //fill change layer
        changeLayer.opacity=1
        changeLayer.id=rkey
        changeLayer.Detections="http://ui"
        this.setState({
            changeLayer: {...changeLayer}
        })
        let changes = {"type": "FeatureCollection", "features": [newChange]}
        addChangeDetectionLayer(this.map.current, changeLayer, changes)
        this.props.createChange(this.map.current, this.draw.current, rkey, newChange)
        /* click on a polygon, a line or a point */
        this.handleClickOnFeature(changeLayer, 'fill')
        this.handleClickOnFeature(changeLayer, 'line')
        this.handleClickOnFeature(changeLayer, 'circle')
    }
    /** */
    handleConfirm() {
        let drawData=this.draw.current.getAll()
        let feature=drawData.features[0]
        let {id, ...changeGeojson}=feature
        this.setState({
            changePolygon: {...changeGeojson},
            showForm: true
        })
    }
    /* */
    handleDelete() {
        this.setAlert(
            i18n.t("ChangeDetection.DoYouWantToDeleteTheChangePolygon"),
            () => this.handleConfirmDelete(),
            this.unsetAlert,
            i18n.t("ChangeDetection.Delete"),
            i18n.t("ChangeDetection.Cancel"),
            true,
            'warning',
            false,
            true
        )
        this.setState({
            isEditMode: false
        })
    }
    /* */
    handleConfirmDelete() {
        this.props.deleteChange(this.map.current, this.draw.current, this.state.changePolygon.id, this.state.changeLayer.id)
    }
    /** */
    handleCancel() {
        this.draw.current.deleteAll()
        this.setState({
            changePolygon: {},
            isDrawMode: false,
            isEditMode: false,
            showChangeInfo: false
        })
    }
    /** */
    handleClose() {
        this.setState({
            showForm: false,
            changePolygon: {},
            showChangeInfo: false,
            showWhatIsNewForm: false,
            isDrawMode: false,
            changeName: '',
            changeDescription: '',
            showChangeDetectionForm: false
        })
        this.draw.current.deleteAll()
    }

    /** */
    handleCloseInfo() {
        this.setState({
            showChangeInfo: false,
            showForm: false,
            isDrawMode: false,
            isEditMode: false
        })
    }

    /** */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /* */
    setChangePolygonForCreate(drawnPolygon) {
        this.setState({
            isDrawMode: true,
            changePolygon: {...drawnPolygon}
        })
    }

    /* used when the user click on a polygon */
    setChangePolygonForEdit(editedChange) {
        this.setState({
            isEditMode: true,
            changePolygon: {...editedChange},
            showChangeInfo: true
        })
    }

    /**
     * 
     * https://docs.mapbox.com/mapbox-gl-js/example/setstyle/
     */
     handleStyleChange() {
        if (this.state.mapStyle === 'google') {
            this.map.current.setLayoutProperty('google', 'visibility', 'none')
            let allStreetsLayers = this.map.current.getStyle().layers.filter((layer) => layer.source === 'composite')
            allStreetsLayers.forEach((layer) => {
                this.map.current.setLayoutProperty(layer.id, 'visibility', 'visible')
            })
            this.setState({ mapStyle: 'streets' })
        } else {
            this.map.current.setLayoutProperty('google', 'visibility', 'visible')
            let allStreetsLayers = this.map.current.getStyle().layers.filter((layer) => layer.source === 'composite')
            allStreetsLayers.forEach((layer) => {
                this.map.current.setLayoutProperty(layer.id, 'visibility', 'none')
            })
            this.setState({ mapStyle: 'google' })
        }
    }
    /* */
    setAlert(message, actionFunction, cancelAction, confirmText, cancelText, isWithAction, type, withIcon, hasCancel) {
        this.setState({
            alert: (
                <BasicAlert
                    message={message}
                    onConfirm={actionFunction}
                    onCancel={cancelAction}
                    onClose={()=>this.unsetAlert()}
                    confirmText={confirmText}
                    cancelText={cancelText}
                    isWithAction={isWithAction}
                    type={type}
                    withIcon={withIcon}
                    hasCancel={hasCancel}
                />
            )
        });
    }
    /* */
    unsetAlert() {
        this.setState({
            alert: null
        });
    }
    /** changeLayer is constructed on this page, type can be fill, line or circle */
    handleClickOnFeature(changeLayer, type) {
        this.map.current.on('click', `${changeLayer.id}-${type}`, (e) => {
            let allDraws=this.draw.current.getAll()
            if(allDraws.features.length!=0){
                this.draw.current.deleteAll()
                this.setState({
                    isEditMode: true
                })
            }
            let polygonId=e.features[0].properties.id
            this.setState({
                changePolygonId: Number(polygonId)
            })
            let url = changeLayer.Detections
            let rkey = changeLayer.id
            this.props.getChange(polygonId ,url, rkey)
        })
    }
    /* */
    startChangeDetection(){
        this.handleClose()
        this.unsetAlert()
        let geojsonServer = this.props.atlasML.split(":")[0]
        const queryData = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: {
                "threshold": this.state.changeDetectionThreshold,
                "zoom": 16,
                "polygon": this.state.changePolygon['geometry']['coordinates'][0],
                "server": `http://${geojsonServer}`
            },
            params: {}
        }
        this.props.startChangeDetection(
            this.state.newerLayer.id, 
            this.state.olderLayer.id, 
            queryData, 
            this.props.atlasML
        )
    }
    /* */
    handleChangeDetection(){
        this.setState({
            showChangeDetectionForm: true,
            showForm: false
        })
    }
    /* */
    handleChangeDetectionThreshold(e, newValue) {
        this.setState({
            changeDetectionThreshold: newValue
        })
    }
    /* */
    setEmptyDetectionAlert(){
        if(this.props.isChangeDetectionsGenerated&&this.props.changes.features.length==0){
            this.setAlert(
                i18n.t("ChangeDetection.CouldNotDetectAnyChange"),
                this.handleCloseEmptyDetectionAlert,
                this.unsetAlert,
                i18n.t("ChangeDetection.Ok"),
                i18n.t("ChangeDetection.Cancel"),
                true,
                'warning',
                false,
                false
            )
        }
    }
    /* */
    handleCloseEmptyDetectionAlert() {
        this.unsetAlert()
        this.props.resetChangeDetection()
    }
    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div>
                <div ref={this.mapContainer} id='map-change-detection'>
                    {this.state.alert}
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 48,
                            height: '115px',
                            width: '50px',
                            background: 'red',
                            zIndex: 1000000,
                            opacity: '0',
                            display: this.state.isEditMode && !this.state.isDrawMode ? '' : 'none'
                        }}
                    ></div>
                    <Header marginTop='25px'></Header>
                    <Tooltip title="Change map style">
                        <div id='timelapse-mini-style-window-hidden'>
                            <Box sx={{ width: "96%", height: "96%", borderRadius: 4 }} onClick={this.handleStyleChange}>
                                <img className="map-style-thumbnail" alt="map style" src={this.state.mapStyle === "google" ? streets : google} height="100%" width="100%" style={{ borderRadius: "4px" }} />
                            </Box>
                        </div>
                    </Tooltip>
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            height: '155px',
                            width: '50px',
                            background: 'red',
                            zIndex: 1000000,
                            opacity: '0',
                            display:  this.state.isEditMode ? '' : 'none'
                        }}
                    ></div>
                    <div style={{
                        width: '100%',
                        display: this.state.isEditMode ? 'flex' : 'none',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div id="change-detection-alert-container">
                            <Alert severity='warning'
                                action={<AlertAction 
                                    onConfirm={this.handleConfirm}
                                    onDelete={this.handleDelete}
                                    onCancel={this.handleCancel}
                                    confirmText={i18n.t("ChangeDetection.Save")}
                                    deleteText={i18n.t("ChangeDetection.Delete")}
                                    cancelText={i18n.t("ChangeDetection.Cancel")}
                                    disableConfirm={false}
                                    disableDelete={false}
                                    disableCancel={false}
                                />}
                                icon={false}
                                >
                                    {/* {`Click on the polygon to edit it.`} */}
                                    {i18n.t("ChangeDetection.ClickOnThePolygonToEditIt")}
                            </Alert> 
                        </div>
                    </div>
                    {
                        this.state.showForm
                        ?
                        <WhatsnewForm
                            isDialogOpen={this.state.showForm}
                            handleSubmit={this.handleSubmit}
                            handleClose={this.handleClose}
                            handleChange={this.handleChange}
                            handleChangeDetection={this.handleChangeDetection}
                            map={this.map.current}
                            data={this.state.changePolygon}
                            bottomLayer={this.state.firstLayer}
                            topLayer={this.state.secondLayer}
                            area={this.state.areaId}
                            coverage={0}
                            length={0}
                            name={this.state.changeName}
                            description={this.state.changeDescription}
                            handleThreshold={this.handleChangeDetectionThreshold}
                            threshold={this.state.changeDetectionThreshold}
                        />
                        :
                        null
                    }
                    {
                        this.state.showChangeInfo
                        ?
                        <ChangeInfo
                            isDialogOpen={this.state.showChangeInfo}
                            handleClose={this.handleCloseInfo}
                            map={this.map.current}
                            data={this.state.changePolygon}
                            displayedLayer={this.state.displayedLayer}
                            area={this.state.areaId}
                            since={this.state.olderLayer.FlightDate}
                        />
                        :
                        null
                    }
                    {
                        this.state.showChangeDetectionForm
                        ?
                        <ChangeDetectionForm
                            handleClose={this.handleClose}
                            threshold={this.state.changeDetectionThreshold}
                            handleThreshold={this.handleChangeDetectionThreshold}
                            handleStart={this.startChangeDetection}
                            handleCancel={this.handleClose}
                        />
                        :
                        null
                    }
                </div>
                {
                    this.state.mapLoading
                    ?
                    <div id={'timelapse-page-container-loading'}>
                        <CircularProgress sx={{ color:'#121f33'}}/>
                    </div>
                    :
                    null
                }
                {
                    this.props.isChangeListLoading || this.props.isChangeCreating || this.props.isChangeEditing || this.props.isChangeDeleting 
                    ?
                    <div id={'timelapse-page-container-loading-trans'}>
                        <CircularProgress sx={{ color: 'white'}} />
                    </div>
                    :
                    null
                }
                <ChangeDetectionPanel
                    firstLayer={this.state.firstLayer}
                    firstLayerDate={this.state.firstLayerDate}
                    secondLayer={this.state.secondLayer}
                    secondLayerDate={this.state.secondLayerDate}
                    bottomLayers={this.state.bottomLayers}
                    topLayers={this.state.topLayers}
                    handleSelectFirst={this.handleSelectFirst}
                    handleSelectSecond={this.handleSelectSecond}
                    firstLayerOpacity={this.state.firstLayerOpacity}
                    handleOpacityFirst={this.handleOpacityFirst}
                    secondLayerOpacity={this.state.secondLayerOpacity}
                    handleOpacitySecond={this.handleOpacitySecond}
                    handleCheck={this.handleCheck}
                    mapCenter={this.state.mapCenter}
                    clickedLocation={this.state.clickedLocation}
                    zoom={this.state.zoom}
                    elevation={this.state.elevation}
                    changeLayer={this.state.changeLayer}
                    changeLayerOpacity={this.state.changeLayerOpacity}
                    handleOpacityChange={this.handleOpacityChange}
                    handleMapIcon={this.handleMapIcon}
                />
                <PanelFooter 
                    mapCenter={this.state.mapCenter}
                    clickedLocation={this.state.clickedLocation}
                    zoom={this.state.zoom}
                    elevation={this.state.elevation}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        annotationList: state.annotation.annotationList,
        annotation: state.annotation.annotation,
        isWhatsnewListLoading: state.whatsnew.isWhatsnewListLoading,
        isWhatsnewCreated: state.whatsnew.isWhatsnewCreated,
        isFailedToCreateWhatsnew: state.whatsnew.isFailedToCreateWhatsnew,
        isWhatsnewDeleted: state.whatsnew.isWhatsnewDeleted,
        isFailedToDeleteWhatsnew: state.whatsnew.isFailedToDeleteWhatsnew,
        change: state.change.change, //clicked individual change
        changeList: state.change.changeList,
        changes: state.change.changes, //changesFeatureCollection
        isChangesFetched: state.change.isChangesFetched,
        isChangeListLoading: state.change.isChangeListLoading,
        isChangeLoading: state.change.isChangeLoading,
        isChangeFetched: state.change.isChangeFetched,
        isChangeFailedToFetch: state.change.isChangeFailedToFetch,
        isChangeCreating: state.change.isChangeCreating,
        isChangeCreated: state.change.isChangeCreated,
        isFailedToCreateChange: state.change.isFailedToCreateChange,
        isChangeEditing: state.change.isChangeEditing,
        isChangePut: state.change.isChangePut,
        isChangeFailedToPut: state.change.isChangeFailedToPut,
        isChangeDeleting: state.change.isChangeDeleting,
        isChangeDeleted: state.change.isChangeDeleted,
        isFailedToDeleteChange: state.change.isFailedToDeleteChange,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML,
        isChangeDetectionStarted: state.change.isChangeDetectionStarted,
        isChangeDetectionsGenerated: state.change.isChangeDetectionsGenerated,
        changeDetectionResults: state.change.changeDetectionResults,
        isFailedToGenerateChangeDetections: state.change.isFailedToGenerateChangeDetections
    }
}

export default connect(mapStateToProps, {
    getWhatsnewList, 
    getWhatsnew, 
    deleteWhatsnew, 
    resetWhatsnewForm, 
    createWhatsnew,
    getChangeList, 
    getChange, 
    createChange,
    putChange,
    deleteChange, 
    resetChangeForm,
    putMap,
    startChangeDetection,
    resetChangeDetection
})(ChangeDetection)