/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* custom components */

/* mui material components */
import Slider from '@mui/material/Slider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem';


/* mui icons-material components */
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import ReplayIcon from '@mui/icons-material/Replay'

/* assets */


class ControlBar extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null
        }

        /* Function bindings here */
        this.handleAnchorEl = this.handleAnchorEl.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){

    }

    /* */
    handleAnchorEl(anchorEl){
        return anchorEl
    }
    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div id={this.props.drawerOpen ? 'timelapse-main-control-bar-container' : 'timelapse-main-control-bar-container-short'}>
                <div id='timelapse-control-bar-container'>
                    <div id='timelapse-playback-button-container'>
                        {
                            this.props.played
                            ?
                            <IconButton onClick={this.props.handlePause}>
                                <Tooltip 
                                    // title='Pause' 
                                    title={i18n.t("ControlBar.Pause")}
                                >
                                    <PauseIcon sx={{color: 'white'}}/>
                                </Tooltip>
                            </IconButton>
                            :
                            <IconButton onClick={this.props.handlePlay}>
                                <Tooltip 
                                    // title='Play'
                                    title={i18n.t("ControlBar.Play")}
                                >
                                    <PlayArrowIcon sx={{color: 'white'}}/>
                                </Tooltip>
                            </IconButton>
                        }
                    </div>
                    <div id='timelapse-main-slider-container'>
                        <div id='timelapse-slider-container'>
                            <Slider 
                                value={this.props.timelapseLayerIndex}
                                onChange={this.props.handleTimeChange}
                                max={this.props.timelapseLayers.length-1}
                                min={0}
                                size='small'
                                sx={{color: 'white'}}
                            />
                        </div>
                    </div>
                    <div id='timelapse-replay-button-container'>
                        <IconButton onClick={this.props.handleReset}>
                            <Tooltip 
                                // title='Reset'
                                title={i18n.t("ControlBar.Reset")}
                            >
                                <ReplayIcon sx={{color: 'white'}}/>
                            </Tooltip>
                        </IconButton>
                    </div>
                    <div id='timelapse-playback-speed-button-container'>
                        <IconButton 
                            aria-controls="speed-menu"
                            aria-haspopup="true"
                            aria-expanded={this.props.showPlaybackOptions ? 'true' : undefined}
                            onClick={this.props.handlePlaybackSpeed}>
                            <Tooltip 
                                // title='Playback speed'
                                title={i18n.t("ControlBar.PlaybackSpeed")}
                            >
                                <Typography sx={{color: "white", fontFamily: "Poppins", fontSize: "14px"}}>{this.props.playbackRate}</Typography>
                            </Tooltip>
                        </IconButton>
                        <Menu
                            id="speed-menu"
                            anchorEl={()=>this.handleAnchorEl(this.props.anchorEl)}
                            open={this.props.showPlaybackOptions}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                            sx={{mt: "-8px"}}
                        >
                            <MenuItem onClick={this.props.setPlaybackSpeed}>0.5x</MenuItem>
                            <MenuItem onClick={this.props.setPlaybackSpeed}>0.75x</MenuItem>
                            <MenuItem onClick={this.props.setPlaybackSpeed}>1x</MenuItem>
                            <MenuItem onClick={this.props.setPlaybackSpeed}>1.5x</MenuItem>
                            <MenuItem onClick={this.props.setPlaybackSpeed}>2x</MenuItem>
                        </Menu>
                    </div>
                    {/* <div id='timelapse-reverse-button-container'>
                        <IconButton onClick={this.props.handleReverse}>
                            {
                                window.localStorage.getItem("backward")
                                ?
                                <Tooltip title='Switch to oldest to newest'>
                                    <UpdateIcon sx={{color: 'white'}}/>
                                </Tooltip>
                                :
                                <Tooltip title='Switch to newest to oldest'>
                                    <RestoreIcon sx={{color: 'white'}}/>
                                </Tooltip>
                            }
                        </IconButton>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default (ControlBar)