/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    ANNOTATIONS_LOADING,
    ANNOTATIONS_FETCHED,
    FAILED_ANNOTATIONS,
    CREATED_ANNOTATION,
    FAILED_NEW_ANNOTATION,
    RESET_ANNOTATION_FORM,
    ANNOTATION_FETCHED,
    FAILED_ANNOTATION,
    ANNOTATION_DELETED,
    FAILED_TO_DELETE_ANNOTATION,
    ANNOTATION_EDIT_MODE_ON,
    ANNOTATION_EDIT_MODE_OFF,
    ANNOTATION_PUT,
    FAILED_ANNOTATION_PUT
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}

/**
 * Filters all annotations by storage and user id
 * user id is loaded automatically via config
 * @param {*} body 
 * @returns 
 */
export const getAnnotationList = (area) => (dispatch) => {
    dispatch({
        type: ANNOTATIONS_LOADING
    })
    axios.get(`/flightsapi/annotation?Area=${area}`, config).then((res) => {
        dispatch({
            type: ANNOTATIONS_FETCHED,
            payload: res.data
        })
    }).catch((error) => {
        dispatch({
            type: FAILED_ANNOTATIONS,
            payload: 'Failed to fetch annotations'
        })
    })
}

/**
 * 
 * @param {*} area 
 * @returns 
 */
export const createAnnotation = (annotation) => (dispatch) => {
    axios.post(`/flightsapi/annotation`, annotation, config).then((res) => {
        if(res.data=='Added successfully.'){
          dispatch({
            type: CREATED_ANNOTATION,
            payload: res.data
          }) 
        } else {
          dispatch({
            type: FAILED_NEW_ANNOTATION,
            payload: 'Failed to add the annotation.'
          })
        }
    }).catch((error) => {
      dispatch({
        type: FAILED_NEW_ANNOTATION,
        payload: `Faild to add the annotation.`
      })
    })
}

/**
 * reset annotations
 */
 export const resetAnnotationForm = () => (dispatch) => {
  dispatch({
    type: RESET_ANNOTATION_FORM
  })
}

/**
 * 
 */
export const getAnnotation = (link) => (dispatch) => {
  axios.get(link, config).then((res) => {
    dispatch({
      type: ANNOTATION_FETCHED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_ANNOTATION,
      payload: 'Failed to fetch annotation'
    })
  })
}

/**
 * 
 */
export const deleteAnnotation = (id) => (dispatch) => {
  axios.delete(`/flightsapi/annotation/${id}`, config).then((res)=>{
    dispatch({
      type: ANNOTATION_DELETED,
      payload: res.status
    })
  }).catch((e)=>{
    dispatch({
      type: FAILED_TO_DELETE_ANNOTATION,
      payload: 'Failed to delete'
    })
  })
}
/**
 * gets into edit mode on edit click
 * @returns 
 */
 export const editAnnotationModeOn = (map, annotationId, storageId) => (dispatch) => {
  dispatch({
    type: ANNOTATION_EDIT_MODE_ON,
    payload: annotationId
  })
}
/**
 * gets into edit mode on edit click
 * @returns 
 */
 export const editAnnotationModeOff = () => (dispatch) => {
  dispatch({
    type: ANNOTATION_EDIT_MODE_OFF
  })
}
/**
 * 
 * @param {*} id 
 * @returns 
 */
 export const putAnnotation = (annotation) => (dispatch) => {
  axios.put(`/flightsapi/annotation/${annotation.id}`, annotation, config).then((res)=>{
    dispatch({
      type: ANNOTATION_PUT,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_ANNOTATION_PUT,
      payload: e.response.statusText
    })
  })
}