import {
    MAPS_RETRIEVED,
    STORAGE_FLIGHTS_RETRIEVED,
    FAILED_STORAGE_FLIGHTS,
    FLIGHT_INFO_FETCHED,
    FAILED_FLIGHT_INFO,
    PROCESSED_DIRS_RETRIEVED,
    FAILED_PROCESSED_DIRS,
    MAP_DELETED,
    MAP_FETCHED,
    FAILED_MAP_FETCH,
    FAILED_TO_DELETE_MAP,
    RESET_MAP_FORM,
    IS_XYZ,
    FAILED_IS_XYZ,
    MAP_ADDED,
    FAILED_TO_ADD_MAP,
    MAP_PUT,
    FAILED_MAP_PUT
  } from '../actions/types';

const initialState = {
    mapList: [],
    ipAddress: '',
    flightsDir: '',
    flightName: '',
    flightInfo: '',
    isFailedToFetchFlightInfo: false,
    storageFlights: [],
    isFailedToFetchFlights: false,
    processedDirs: [],
    isFailedToFetchProcessedDirs: false,
    message: '',
    isMapDeleted: false,
    isFailedToDeleteMap: false,
    isXyz: false,
    isMapAdded: false,
    isFailedToAddMap: false,
    isMapPut:false,
    editedMap: {},
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case MAPS_RETRIEVED:
        return {
            ...state,
            mapList: action.payload,
            message: 'Maps are retrieved successfully.'
        }
      case MAP_ADDED:
        return {
          ...state,
          isMapAdded: true,
          isFailedToAddMap: false,
          message: action.payload
        }
      case FAILED_TO_ADD_MAP:
        return {
          ...state,
          isMapAdded: false,
          isFailedToAddMap: true,
          message: action.payload
        }
      case MAP_DELETED:
        return {
          ...state,
          message: action.payload,
          isMapDeleted: true,
          isFailedToDeleteMap: false,
        }
      case FAILED_TO_DELETE_MAP:
        return {
          ...state,
          message: action.payload,
          isMapDeleted: false,
          isFailedToDeleteMap: true
        }
      case RESET_MAP_FORM:
        return {
          ...state,
          isMapAdded: false,
          isFailedToAddMap: false,
          isMapDeleted: false,
          isFailedToDeleteMap: false,
          isMapPut:false
        }
      case STORAGE_FLIGHTS_RETRIEVED:
        return {
          ...state,
          storageFlights: action.payload.flightList,
          ipAddress: action.payload.ipAddress,
          flightsDir: action.payload.flightsDir,
          message: 'Flights are retrieved successfully.',
          isFailedToFetchFlights: false,
        }
      case FAILED_STORAGE_FLIGHTS:
        return {
          ...state,
          storageFlights: [],
          ipAddress: '',
          flightsDir: '',
          isFailedToFetchFlights: true,
          message: action.payload,
          
        }
      case PROCESSED_DIRS_RETRIEVED:
        return {
          ...state,
          processedDirs: action.payload,
          isFailedToFetchProcessedDirs: false,
        }
      case FAILED_PROCESSED_DIRS:
        return {
          ...state,
          isFailedToFetchProcessedDirs: true,
          message: action.payload,
          processedDirs: []
        }
      case FLIGHT_INFO_FETCHED:
        return {
          ...state,
          flightInfo: action.payload.flightInfo,
          flightName: action.payload.flightName,
          isFailedToFetchFlightInfo: false,
        }
      case MAP_FETCHED:
        return {
          ...state,
          editedMap: {...action.payload}
        }
      case FAILED_MAP_FETCH:
          return {
            ...state,
            isMapPut:false
          }
      case MAP_PUT:
          return {
            ...state,
            editedMap: action.payload,
            isMapPut:true

          }
      case FAILED_MAP_PUT:
          return {
            ...state,
            isMapPut:false
          }
      case FAILED_FLIGHT_INFO:
          return {
            ...state,
            flightInfo: {},
            flightName: action.payload.flightName,
            isFailedToFetchFlightInfo: true
          }
      case IS_XYZ:
          return {
            ...state,
            isXyz: action.payload
          }
      case FAILED_IS_XYZ:
          return {
            ...state,
            isXyz: false
          }
      default:
          return state;
    }
}
  




