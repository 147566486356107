/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    AREAS_RETRIEVED,
    AREA_CREATED,
    AREA_DELETED,
    FAILED_AREAS,
    FAILED_NEW_AREA,
    FAILED_TO_DELETE_AREA,
    RESET_AREA_FORM,
    AREA_PUT,
    FAILED_AREA_PUT,
    EDIT_MODE_ON,
    EDIT_MODE_OFF
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}

/**
 * 
 * @param {*} body 
 * @returns 
 */
export const getAreaList = () => (dispatch) => {
    axios.get(`/flightsapi/areas`, config).then((res) => {
        dispatch({
            type: AREAS_RETRIEVED,
            payload: res.data
        })
    }).catch((e) => {
        dispatch({
            type: FAILED_AREAS,
            payload: e.response.statusText
        })
    })
}

/**
 * 
 * @param {*} area 
 * @returns 
 */
export const createArea = (area) => (dispatch) => {
    axios.post(`/flightsapi/areas`, area, config).then((res) => {
        if(res.data=='Added successfully.'){
          dispatch({
            type: AREA_CREATED,
            payload: res.data
          }) 
        } else {
          dispatch({
            type: FAILED_NEW_AREA,
            payload: res.data.RegionName?'Area name must be unique':'Unknown issues'
          })
        }
    }).catch((e) => {
      dispatch({
        type: FAILED_NEW_AREA,
        payload: `Faild to add area: ${e}`
      })
    })
}

/**
 * reset areas
 */
 export const resetAreaForm = () => (dispatch) => {
  dispatch({
    type: RESET_AREA_FORM
  })
}


/**
 * 
 * @param {*} id 
 * @returns 
 */
export const deleteArea = (id) => (dispatch) => {
  axios.delete(`/flightsapi/areas/${id}`, config).then((res) => {
    dispatch({
      type: AREA_DELETED,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_TO_DELETE_AREA,
      payload: e.response.statusText
    })
  })
}

/**
 * gets into edit mode on edit click
 * @returns 
 */
export const editModeOn = (map, id) => (dispatch) => {
  map.removeLayer(`fill-areaPolygon-${id}`)
  map.removeLayer(`outline-areaPolygon-${id}`)
  map.removeSource(`areaPolygon-${id}`)
  dispatch({
    type: EDIT_MODE_ON,
    payload: id
  })
}

/**
 * gets into edit mode on edit click
 * @returns 
 */
 export const editModeOff = () => (dispatch) => {
  dispatch({
    type: EDIT_MODE_OFF
  })
}


/**
 * 
 * @param {*} id 
 * @returns 
 */
 export const putArea = (area) => (dispatch) => {
  axios.put(`/flightsapi/areas/${area.id}`, area, config).then((res)=>{
    dispatch({
      type: AREA_PUT,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_AREA_PUT,
      payload: e.response.statusText
    })
  })
}