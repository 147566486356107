/* eslint-disable eqeqeq */
import React from 'react'
// import mapboxgl from 'mapbox-gl'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { polygon, centerOfMass } from "@turf/turf"
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { createArea, getAreaList, editModeOn, editModeOff } from '../redux/actions/areas';
import { getGroupList } from '../redux/actions/groups';

/*helper functions */
import {
    createMap,
    addAreaPolygonsToMap,
    updateDrawing,
    paintAreaColor,
    drawStyle
} from '../helpers/helpers'

/* custom components */
import AlertAction from './AlertAction'
import AreaForm from './AreaForm'
import AreaTable from './AreaTable'
import GeojsonForm from './GeojsonForm'

/* mui material components */
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

import {convertKML} from '../helpers/togeojson';
/* mui icons-material components */


mapboxgl.accessToken = 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg'
const MapboxGeocoder = window.MapboxGeocoder
mapboxgl.workerClass = MapboxWorker

class AddArea extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showAlert: false,
            alertText: '',
            areaPolygon: {},
            areaPolygonText: '',
            areaKmlText:'',
            centerOfArea: [],
            showAreaForm: false,
            showGeojsonForm: false,
            mapLoaded: false,
            editMode: false,
            wrongFormat: false,
            via: 'geojson'
        }

        /* Function bindings here */
        this.handleChange = this.handleChange.bind(this)
        this.handleConfirm = this.handleConfirm.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleFly = this.handleFly.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleImportAreaGeojson = this.handleImportAreaGeojson.bind(this)
        this.handleImport = this.handleImport.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)

        /* Refs */
        this.map = React.createRef()
        this.mapContainer = React.createRef()
        this.draw = React.createRef()
    }

    /**
     * When the component is rendered
     */
    componentDidMount() {
        this.props.getAreaList()
        /**
         * Render the map only once
         */
        if (this.map.current) return

        /**
         * Create a draw control
         */
        this.draw.current = new MapboxDraw({
                displayControlsDefault: true,
                styles: drawStyle("orange")
            })

        /**
         * Create mapbox map
         */
        this.map.current = createMap(this.mapContainer.current, [0, 0], -1)

        this.map.current.on('load', () => {
            this.setState({
                mapLoaded: true
            })
            /**
             * Add controls
             */
            this.map.current.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                }), 'top-left'
            )

            this.map.current.addControl(this.draw.current, 'top-right')
            this.map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
            addAreaPolygonsToMap(this.map.current, this.props.areaList)
        })


        /**
         * 
         */
        this.map.current.on('draw.create', () => {
            let drawData=this.draw.current.getAll()
            let feature=drawData.features[0]
            let{id, ...polygon}=feature
            this.setState({
                showAlert: this.props.isEditMode ? false : true,
                // alertText: 'Do you want to add this polygon as an area?',
                alertText: i18n.t("AddArea.DoYouWantToAdd"),
                areaPolygon: {...polygon}
            })
        })

        /**
         * 
         */
        this.map.current.on('draw.update', () => {
            let drawData=this.draw.current.getAll()
            let feature=drawData.features[0]
            let{id, ...polygon}=feature
            this.setState({
                showAlert: this.props.isEditMode ? false : true,
                // alertText: 'You have just edited the polygon, do you want to add the updated polygon as an area?',
                alertText: i18n.t("AddArea.DoYouWantToEdit"),
                areaPolygon: {...polygon}
            })
        })

        /**
         * 
         */
        this.map.current.on('draw.delete', () => {
            this.setState({
                showAlert: false,
                // alertText: 'You have just edited the polygon, do you want to add the updated polygon as an area?',
                alertText: i18n.t("AddArea.DoYouWantToEdit"),
                areaPolygon: {}
            })
        })

        /**
         * reset the colors on click
         */
        this.map.current.on('click', () => {
            paintAreaColor(this.map.current, null, this.props.areaList)
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps) {
        if(prevProps.isAreaCreated !== this.props.isAreaCreated && this.props.isAreaCreated){
            this.props.getAreaList()
            addAreaPolygonsToMap(this.map.current, this.props.areaList)
        }

        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
            this.props.editModeOff()
            updateDrawing(this.map.current, this.props.editedAreaId)
        }
    }


    /**
     * This is used in input text boxes
     */
    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value
        })
    }

    handleModeChange (newValue){
        this.setState({
            'via': newValue
        })
    }
    /**
     * There is an alertbox opening once a draw is created
     * Whereas the user can confirm before the area form is displayed
     */
    handleConfirm() {
        let confirmed = polygon(this.state.areaPolygon.geometry.coordinates)
        let center = centerOfMass(confirmed).geometry.coordinates
        this.setState({
            showAlert: false,
            showAreaForm: true,
            centerOfArea: center
        })
    }

    /**
     * 
     * @returns 
     */
    handleCancel() {
        this.setState({
            showAlert: false,
            showAreaForm: false
        })
        this.props.editModeOff()
        this.draw.current.deleteAll()
        addAreaPolygonsToMap(this.map.current, this.props.areaList)
    }

    /**
     * 
     * @returns 
     */
    handleClose() {
        this.setState({
            showAreaForm: false,
            showGeojsonForm: false,
        })
        this.props.editModeOff()
        this.draw.current.deleteAll()
        addAreaPolygonsToMap(this.map.current, this.props.areaList)
    }

    /**
     * 
     * @returns 
     */
    handleFly(center, id) {
        this.map.current.setZoom(9)
        this.map.current.flyTo({
            center: center,
            essential: true
        })
        paintAreaColor(this.map.current, id, this.props.areaList)
    }

    /**
     * this.props.draw passed in AddArea component
     * draw.add accepts feature collection not standalone features
     * @param {*} id 
     */
     handleEdit(id) {
        this.handleFly(this.props.areaList.find((area) => area.id == id).center)
        this.props.editModeOn(this.map.current, id)
        this.setState({
            selectedAreaId: id,
        })
        let areaPolygon = this.props.areaList.find((area)=>area.id==id).PolygonData
        this.setState({
            areaPolygon:{...areaPolygon}
        })
        areaPolygon.id = 'instantEditPolygon'
        let featureCollection = {
            'type':'FeatureCollection',
            'features': [
                areaPolygon
            ]
        }
        this.draw.current.add(featureCollection)
    }

    /**
     * This opens the form
     */
    handleImportAreaGeojson() {
        this.setState({
            showGeojsonForm: true
        })
    }

    /**
     * parse the geojson text
     * add geojson to the map
     * assign areaPolygon
     * show alert
     */
    handleImport() {
        let newAreaPolygon = {}
        try {
            if(this.state.via === 'kml'){
            let newGeojson_ = convertKML(this.state.areaKmlText)
            newAreaPolygon = newGeojson_['features'][0]
                console.log("new area polygon", newAreaPolygon)
        }
            else
                newAreaPolygon = JSON.parse(this.state.areaPolygonText)
            let confirmed = polygon(newAreaPolygon.geometry.coordinates)
            let center = centerOfMass(confirmed).geometry.coordinates 
            this.handleFly(center)
        } catch (error) {
            this.setState({
                wrongFormat: true
            })
            setTimeout(()=>{
                this.setState({
                    wrongFormat: false,
                    areaPolygonText: '',
                    areaKmlText:''
                })
            },2000)
        }
        
        newAreaPolygon.id = 'newAreaPolygon'
        let featureCollection = {
            'type':'FeatureCollection',
            'features': [
                newAreaPolygon
            ]
        }
        try {
            this.draw.current.add(featureCollection)
            this.setState({
                areaPolygon: newAreaPolygon,
                showGeojsonForm: false,
                showAlert: this.props.isEditMode ? false : true,
                // alertText: 'Do you want to add this polygon as an area?',
                alertText: i18n.t("AddArea.DoYouWantToAdd"),
                areaPolygonText: '',
                areaKmlText:''
            })
        } catch (error) {
            this.setState({
                wrongFormat: true
            })
            setTimeout(()=>{
                this.setState({
                    wrongFormat: false,
                    areaPolygonText: '',
                    areaKmlText:''
                })
            },2000)
        }   
    }

    /**
     * 
     * @returns the component 
     */
    render() {

        return (
            <div id='areas-page-container'>
                <div id="add-area-page-container">
                    <div id="add-area-page-alert-container">
                        {
                            this.state.showAlert
                            ?
                            <Alert sx={{fontFamily: 'Poppins'}}icon={false} 
                                action={<AlertAction 
                                    onConfirm={this.handleConfirm}
                                    onCancel={this.handleCancel}
                                    // confirmText='Yes'
                                    // cancelText='No'
                                    confirmText={i18n.t("AddArea.Yes")}
                                    cancelText={i18n.t("AddArea.No")}
                                    disableDelete={true}
                                />}
                                severity='success'>
                            {this.state.alertText} 
                            </Alert>
                            :
                            null
                        }
                        {
                            this.props.isAreaCreated ?
                            <Alert severity='success'>{`${this.props.message}`}</Alert> 
                            :
                            null
                        }
                        {
                            this.props.isFailedToCreateArea ?
                            <Alert severity='error'>{`${i18n.t("AddArea.FailedToCreate")}: ${this.props.message}`}</Alert> 
                            :
                            null
                        }
                        {
                            this.props.isEditMode ?
                            <Alert severity='warning'
                            action={<AlertAction 
                                onConfirm={this.handleConfirm}
                                onCancel={this.handleCancel}
                                // confirmText='Save'
                                // cancelText='Cancel'
                                confirmText={i18n.t("AddArea.Save")}
                                cancelText={i18n.t("AddArea.Cancel")}
                                disableConfirm={this.state.areaPolygon.geometry==undefined}
                                disableCancel={false}
                                disableDelete={true}
                            />}
                            >
                                {/* {`You are in the edit mode. Click on the polygon to edit.`} */}
                                {`${i18n.t("AddArea.YouAreInTheEditMode")}`}
                            </Alert> 
                            :
                            null
                        }
                    </div> 
                    <div ref={this.mapContainer} id={this.props.language=="GB"?"map-add-area":"map-add-area-tr"}>
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                height: '50px',
                                width: '400px',
                                background: '',
                                zIndex: 1000000,
                                opacity: '0',
                                display: this.props.isEditMode || this.state.showAlert ? '' : 'none'
                            }}
                        ></div>
                        {
                            this.state.mapLoaded 
                            ?
                            <div id='add-area-import-geojson'>
                                <Button
                                    sx={{
                                        width: '100%',
                                        color: 'white',
                                        fontSize: '16px',
                                        fontFamily: 'Poppins',
                                        borderRadius: '4px',
                                        textTransform: 'none'
                                        
                                    }}
                                    onClick={this.handleImportAreaGeojson}
                                >
                                    {/* Import Area Geojson */}
                                    {`${i18n.t("AddArea.ImportArea")}`}
                                </Button>
                            </div>
                            :
                            null
                        }
                        <GeojsonForm
                            isDialogOpen={this.state.showGeojsonForm}
                            handleClose={this.handleClose}
                            onSubmit={this.handleImport}
                            areaPolygon={this.state.areaPolygon}
                            centerOfArea={this.state.centerOfArea}
                            map={this.map.current}
                            draw={this.draw.current}
                            editMode={this.state.editMode}
                            id={this.state.selectedAreaId}
                            onChange={ this.handleChange}
                            value={this.state.areaPolygonText}
                            kmlValue={this.state.areaKmlText}
                            error={this.state.wrongFormat}
                            via={this.state.via}
                            handleModeChange={ this.handleModeChange}        
                        />
                        <AreaForm
                            isDialogOpen={this.state.showAreaForm}
                            handleClose={this.handleClose}
                            onSubmit={this.handleSubmit}
                            areaPolygon={this.state.areaPolygon}
                            centerOfArea={this.state.centerOfArea}
                            map={this.map.current}
                            editMode={this.state.editMode}
                            id={this.state.selectedAreaId}
                        />
                    </div>
                </div>
                {
                this.state.mapLoaded
                ?
                <AreaTable 
                    handleEdit={this.handleEdit}
                    handleFly={this.handleFly}
                    map={this.map.current}
                    draw={this.draw.current}
                    />
                :
                <div id='area-table-page-container'>
                    <div style={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '600px'
                    }}
                    >
                        <CircularProgress sx={{color: "#121f33"}}/>
                    </div>
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAreaCreated: state.areas.isAreaCreated,
        isFailedToCreateArea: state.areas.isFailedToCreateArea,
        message: state.areas.message,
        areaList: state.areas.areaList,
        isEditMode: state.areas.isEditMode,
        isAreaPut: state.areas.isAreaPut,
        isFailedToPutArea: state.areas.isFailedToPutArea,
        editedAreaId: state.areas.editedAreaId,
        groups: state.groups.groups,
        language: state.language.language,
    }
}

export default connect(mapStateToProps, { createArea, getAreaList, editModeOn, editModeOff, getGroupList })(AddArea)