/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { getAreaList } from '../redux/actions/areas';
import { getMapList, getFlightInfo, addFlightFromStorage, resetMapForm } from '../redux/actions/maps';

/* custom components */
import { BlackCheckbox }from './CustomCheckbox'

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ClickAwayListener from '@mui/material/ClickAwayListener';

/* mui icons-material components */
import FolderIcon from '@mui/icons-material/Folder';

class MapForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            flightName: '',
            ipAddress: '',
            imagerySize: null,
            stitchZoom: null,
            flightPath: '',
            layers: {
                MFStitch: '',
                WFStitch: '',
                HeatMap: '',
                DepthMap: '',
            },
            flight: null,
            desc: null,
            polygonData: {

            },
            flightDate: '',
            altitude: '',
            userGroup: [],
            listOpen: false,
            groups: [],
            //Layers
            MFStitch: 'none',
            WFStitch: 'none',
            HeatMap: 'none',
            DepthMap: 'none',
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserGroup = this.handleUserGroup.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        /* add checked field to the user groups */
        let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
        this.setState({
            groups: [...checkedGroups]
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isDialogOpen!==this.props.isDialogOpen && !this.props.isDialogOpen){
            this.setState({
                MFStitch: 'none',
                WFStitch: 'none',
                HeatMap: 'none',
                DepthMap: 'none',
            })
            /* add checked field to the user groups */
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: false}))
            this.setState({
                groups: [...checkedGroups]
            })
        }

        if(prevProps.isMapAdded !== this.props.isMapAdded && this.props.isMapAdded){
            this.props.getMapList()
        }
    }

    /**
     * This is used for both editing and creating new areas
     */
    handleSubmit(){
        let layers = {
            MFStitch: this.state.MFStitch=='none'?'': this.state.MFStitch.includes('xyz')?`/processed/${this.state.MFStitch}/{z}/{x}/{y}.jpg`:`/processed/${this.state.MFStitch}/a{quadkey}.jpg`,
            WFStitch: this.state.WFStitch=='none'?'': this.state.WFStitch.includes('xyz')?`/processed/${this.state.WFStitch}/{z}/{x}/{y}.jpg`:`/processed/${this.state.WFStitch}/a{quadkey}.jpg`,
            HeatMap: this.state.HeatMap=='none'?'': this.state.HeatMap.includes('xyz')?`/processed/${this.state.HeatMap}/{z}/{x}/{y}.jpg`:`/processed/${this.state.HeatMap}/a{quadkey}.jpg`,
            DepthMap: this.state.DepthMap=='none'?'':this.state.DepthMap.includes('xyz')?`/processed/${this.state.DepthMap}/{z}/{x}/{y}.jpg`:`/processed/${this.state.DepthMap}/a{quadkey}.jpg`,
        }
        let map={
            Altitude: this.props.flightInfo.Altitude,
            IPAddress: this.props.ipAddress,
            ImagerySize: this.props.flightInfo.ImagerySize,
            StitchZoom: this.props.flightInfo.StitchZoom,
            Layers: layers,
            MFStitch: `http://${this.props.ipAddress}${this.props.flightsDir}/${this.props.flightName}${layers.MFStitch}`,
            WFStitch: `http://${this.props.ipAddress}${this.props.flightsDir}/${this.props.flightName}${layers.WFStitch}`,
            FlightName: this.props.flightName,
            PolygonData: this.props.flightInfo.PolygonData,
            FlightDate: this.props.flightInfo.FlightDate?this.props.flightInfo.FlightDate.split(' ')[0]:null,
            FlightPath: `${this.props.flightsDir}/${this.props.flightName}`,
            UserGroup: this.state.userGroup,
            Changes: {}
        }
        this.props.addFlightFromStorage(map)
        this.props.handleClose()
        setTimeout(() => {
            this.props.resetMapForm()
        }, 1000)
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /* */
    handleUserGroup(e) {
        this.setState({
            userGroup: e.target.value
        })
    }

    /* */
    handleOpen(e) {
        this.setState({
            listOpen: !this.state.listOpen
        })
    }

    /* */
    handleClose(e) {
        this.setState({
            listOpen: false
        })
    }

    /* */
    handleCheckbox(id) {
        this.setState({
            listOpen: true
        })
        let editedGroups=[...this.state.groups]
        let checkedGroup=editedGroups.find(group=>group.id==id)
        let index=this.state.groups.findIndex(group=>group.id==id)
        let checked=checkedGroup.checked
        checkedGroup.checked=!checked
        editedGroups.splice(index, 1, checkedGroup)
        let userGroups=[]
        editedGroups.forEach(group=>group.checked?userGroups.push(group.id):'')
        this.setState({
            groups: editedGroups,
            userGroup: userGroups
        })
    }


    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* Add New Layer */}
                            {i18n.t("MapForm.AddNewLayer")}
                        </Typography>
                        <div id='admin-panel-formcontrol-container'>
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <Select
                                        value={-1} 
                                        defaultValue={-1} 
                                        sx={{height: '42px', color: this.state.userGroup.length==0?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                        open={this.state.listOpen}
                                        onClick={this.handleOpen}
                                    >
                                        <MenuItem value={-1} disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                            {/* Select User Group */}
                                            {i18n.t("MapForm.SelectUserGroup")}
                                        </MenuItem>
                                        {
                                            this.state.groups.map((group)=>
                                                <MenuItem value={group.id} sx={{fontFamily: 'Poppins'}}>
                                                    <BlackCheckbox checked={group.checked} onChange={() => this.handleCheckbox(group.id)}/>
                                                    <Typography sx={{fontFamily: 'Poppins'}}>{group.name}</Typography>
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </ClickAwayListener>
                            </FormControl>
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='Flight Name'>
                                <FolderIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder='Flight name'
                                required
                                value={this.props.flightName}
                                disabled
                            />
                        </div>
                        <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder={i18n.t("MapForm.StitchedMFTileDirectory")}
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                                
                                }}>
                                <Select
                                    value={this.state.MFStitch} 
                                    defaultValue='none' 
                                    sx={{height: '42px', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleChange('MFStitch')}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}} >
                                        {/* Select MF Tile Directory */}
                                        {i18n.t("MapForm.SelectMFTileDirectory")}
                                    </MenuItem>
                                    {this.props.processedDirs.map((dir)=>
                                        <MenuItem value={dir} sx={{fontFamily: 'Poppins'}}>
                                        <div 
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Tooltip title='Stitched MF'>
                                                <FolderIcon sx={{color: 'lightgray', mr: '5px'}}/>
                                            </Tooltip>
                                            <Typography sx={{
                                                fontFamily: 'Poppins',
                                            }}
                                            fullWidth={false}
                                            >{dir}</Typography>
                                        </div>
                                    </MenuItem>    
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder={i18n.t("MapForm.StitchedWFTileDirectory")}
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Select
                                    value={this.state.WFStitch} 
                                    defaultValue='none' 
                                    sx={{height: '42px', color: this.state.selectedFlight=='none'?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleChange('WFStitch')}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}} >
                                        {/* Select WF Tile Directory */}
                                        {i18n.t("MapForm.SelectWFTileDirectory")}
                                    </MenuItem>
                                    {this.props.processedDirs.map((dir)=>
                                        <MenuItem value={dir} sx={{fontFamily: 'Poppins'}}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                         >
                                            <Tooltip title='Stitched WF'>
                                                <FolderIcon sx={{color: 'lightgray', mr: '5px'}}/>
                                            </Tooltip>
                                            <Typography sx={{
                                                fontFamily: 'Poppins'
                                            }}>{dir}</Typography>
                                        </div>
                                        </MenuItem>
                                        
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        {/* <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder='Stitched WF Tile Directory'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Select
                                    value={this.state.HeatMap} 
                                    defaultValue='none' 
                                    sx={{height: '42px', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleChange('HeatMap')}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}} >
                                        Select HeatMap Tile Directory
                                    </MenuItem>
                                    {this.props.processedDirs.map((dir)=>
                                        <MenuItem value={dir} sx={{fontFamily: 'Poppins'}}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                         >
                                            <Tooltip title='HeatMap'>
                                                <FolderIcon sx={{color: 'lightgray', mr: '5px'}}/>
                                            </Tooltip>
                                            <Typography sx={{
                                                fontFamily: 'Poppins'
                                            }}>{dir}</Typography>
                                        </div>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder='Stitched WF Tile Directory'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Select
                                    value={this.state.HeatMap} 
                                    defaultValue='none' 
                                    sx={{height: '42px', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleChange('HeatMap')}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}} >
                                        Select DepthMap Tile Directory
                                    </MenuItem>
                                    {this.props.processedDirs.map((dir)=>
                                        <MenuItem value={dir} sx={{fontFamily: 'Poppins'}}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}
                                         >
                                            <Tooltip title='DepthMap'>
                                                <FolderIcon sx={{color: 'lightgray', mr: '5px'}}/>
                                            </Tooltip>
                                            <Typography sx={{
                                                fontFamily: 'Poppins'
                                            }}>{dir}</Typography>
                                        </div>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div> */}
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("MapForm.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("MapForm.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo: state.maps.flightInfo,
        ipAddress: state.maps.ipAddress,
        flightsDir: state.maps.flightsDir,
        flightName: state.maps.flightName,
        processedDirs: state.maps.processedDirs,
        isMapAdded: state.maps.isMapAdded,
        isFailedToAddMap: state.maps.isFailedToAddMap,
        groups: state.groups.groups,
        language: state.language.language
    }
}

export default connect(mapStateToProps, {getFlightInfo, addFlightFromStorage, getMapList, resetMapForm, getAreaList})(MapForm)