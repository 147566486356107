/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { localStorageIp, localFlightsDir } from '../configs/localStorage'
import i18n from 'i18next'

/* custom components */
import MapForm from './MapForm'
import MapNewForm from './MapNewForm.jsx'
import FlightInfo from './FlightInfo'

/* mui material components */
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { DataGrid, enUS,  trTR} from '@mui/x-data-grid';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

/* mui icons-material components */
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import StorageIcon from '@mui/icons-material/Storage';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';

/* Redux */
import { getAreaList } from '../redux/actions/areas';
import { getMapList, getFlightList, getFlightInfo, getProcessedDirs, isXyz } from '../redux/actions/maps';

class StorageTable extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            ipAddress: localStorageIp,
            flightsDir: localFlightsDir,
            flightName: '',
            showMapForm: false,
            showMapNewForm:false,
            showInfo: false,
            flightPath: ''
        }

        /* Function bindings here */
        this.handleChange = this.handleChange.bind(this)
        this.fetchFlights = this.fetchFlights.bind(this)
        this.fetchFlightsAdd = this.fetchFlightsAdd.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInfo = this.handleInfo.bind(this)
        this.handleHideInfo = this.handleHideInfo.bind(this)

        /* Refs */
        this.textArea = React.createRef()
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){

    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){

    }
    /**
     * 
     */
    handleSubmit(){

    }
    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * Fetch flight list from the given storage device
     */
    fetchFlights() {
        this.props.getFlightList(`${this.state.ipAddress}${this.state.flightsDir}`)
    }

    /* */
    fetchFlightsAdd() {
        this.props.handleHideLinks()
        this.props.getAreaList()
        this.setState({
            showMapNewForm: true
        })
    }

    /**
     * Shows the flight info dialog that allows the user to modify layers
     */
    handleAdd(flightName) {
        this.props.handleHideLinks()
        this.props.getAreaList()
        let flightPath=`${this.props.ipAddress}${this.props.flightsDir}/${flightName}`
        this.props.getFlightInfo(flightPath, flightName)
        this.props.getProcessedDirs(`${flightPath}/processed`)
        this.setState({
            showMapForm: true
        })
    }

    /**
     * Closes the flight form
     */
    handleClose() {
        this.setState({
            showMapForm: false,
            showMapNewForm: false
        })
    }

    /**
     * 
     * @param {*} id 
     */
    handleInfo(flightName) {
        let flightPath=`${this.props.ipAddress}${this.props.flightsDir}/${flightName}`
        this.props.getFlightInfo(flightPath, flightName)
        this.setState({
            showInfo: true,
            flightName: flightName,
            flightPath: flightPath
        })
    }

    /**
     * 
     */
    handleHideInfo() {
        this.setState({
            showInfo: false
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 600,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
                },
        }));

        const Info = (props) =>
            <HtmlTooltip title={
                    <div style={{width: '475px'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '10px'}}>
                            <IconButton onClick={this.handleHideInfo}>
                                <CloseIcon fontSize='small'/>
                            </IconButton>
                        </div>
                        {Object.keys(this.props.flightInfo).map((key)=>
                            <div key={key}>
                                <InputLabel 
                                    htmlFor={`${key}-${props.id}`}
                                    sx={{
                                        fontSize: '12px',

                                    }}
                                >{`${key}-${props.id}`}</InputLabel>
                                <div
                                    className='map-table-link-container'
                                >
                                    <FilledInput 
                                        sx={{
                                        fontSize: '12px'
                                        }} 
                                        value={JSON.stringify(this.props.flightInfo[key])} 
                                        fullWidth
                                        size='small'
                                        variant='standard'
                                        label={`${key}-${props.id}`}
                                        id={`${key}-${props.id}`}
                                    />
                                    <IconButton 
                                        onClick={() => this.copyToClipboard(`${key}-${props.id}`)}

                                    >
                                        <ContentCopyIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                }
                placement='bottom-end'
                sx={{
                    mt: '10px'
                }}
                open={this.state.showInfo==props.id}
            >
                    

                    <IconButton
                        onClick={() => this.handleInfo(props.id)}
                    >
                        <InfoIcon/>
                    </IconButton>
            </HtmlTooltip>

        const Add = (props) =>                 
        <IconButton >
            <AddIcon onClick={() => this.handleAdd(props.id)}/>
        </IconButton>

        // const columns = [
        //     {field: 'id', headerName: `${this.props.ipAddress}${this.props.flightsDir}` , width: 670},
        //     {field: 'info', headerName: 'Info' , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
        //     {field: 'add', headerName: 'Add' , width: 98, sortable: false, disableColumnMenu: true, renderCell: (props) => Add(props)},
        // ]

        const columns = [
            {field: 'id', headerName: `${this.props.ipAddress}${this.props.flightsDir}` , width: 670},
            {field: 'info', headerName: i18n.t("StorageTable.Info") , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
            {field: 'add', headerName: i18n.t("StorageTable.Add") , width: 98, sortable: false, disableColumnMenu: true, renderCell: (props) => Add(props)},
        ]

        const rows = this.props.storageFlights.map((flightDir)=>(
            {
                id: flightDir, 
                info: '',
                add: '',
            }
        ))

        return (
                <div 
                    id='map-table-page-container'
                >
                    <FlightInfo
                        isDialogOpen={this.state.showInfo}
                        handleHideInfo={this.handleHideInfo}
                        flightPath={this.state.flightPath}
                    />
                    <MapForm
                        isDialogOpen={this.state.showMapForm}
                        handleClose={this.handleClose}
                    />
                     <MapNewForm
                        isDialogOpen={this.state.showMapNewForm}
                        handleClose={this.handleClose}
                    />
                    <div id='maps-page-alert-container'>
                        
                    </div>
                    <div id='storage-table-input-container'>
                        <div className='storage-table-input'>
                            <Tooltip title={i18n.t("StorageTable.StorageIP")}>
                                <StorageIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '100%',
                                }}
                                // placeholder='Storage IP'
                                placeholder={i18n.t("StorageTable.StorageIP")}
                                autoFocus
                                required
                                value={this.state.ipAddress}
                                onChange={this.handleChange('ipAddress')}
                            />
                        </div>
                        <div className='storage-table-input'>
                            <Tooltip title={i18n.t("StorageTable.FlightsDirectory")}>
                                <FolderIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '100%',
                                }}
                                // placeholder='Flights directory'
                                placeholder={i18n.t("StorageTable.FlightsDirectory")}
                                required
                                value={this.state.flightsDir}
                                onChange={this.handleChange('flightsDir')}
                            />
                        </div>
                        <div id='storage-table-fetch-button-container'>
                            <Button
                                sx={{
                                    width: '95%',
                                    height: '100%',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={() => this.fetchFlights()}
                            >
                                {/* GET */}
                                {i18n.t("StorageTable.Get")}
                            </Button>
                        </div>

                        <div id='storage-table-fetch-button-container'>
                            <Button
                                sx={{
                                    width: '95%',
                                    height: '100%',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={() => this.fetchFlightsAdd()}
                            >
                                {/* ADD */}
                                {i18n.t("StorageTable.Add")}
                            </Button>
                        </div>
                    </div>
                    <div id='map-table-container'>
                        <DataGrid 
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowHeight={43}
                            rowsPerPageOptions={[10]}
                            sx={{
                                fontFamily: 'Poppins',
                                overflowY: 'hidden'
                            }}
                            disableSelectionOnClick={true}
                            localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
        )}
}

const mapStateToProps = (state) => {
    return {
        mapList: state.maps.mapList,
        areaList: state.areas.areaList,
        ipAddress: state.maps.ipAddress,
        flightsDir: state.maps.flightsDir,
        storageFlights: state.maps.storageFlights,
        flightInfo: state.maps.flightInfo,
        isXyz: state.maps.isXyz,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { getMapList, getAreaList, getFlightList, getFlightInfo, getProcessedDirs, isXyz})(StorageTable)