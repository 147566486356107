/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* helpers */

/* Redux */
import { connect } from 'react-redux'
import { getWhatsnewList, createWhatsnew, resetWhatsnewForm } from '../redux/actions/whatsnew';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Alert from '@mui/material/Alert';

/* mui icons-material components */


class ChangeDetectionForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
    }
    /* */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };
    /* */
    render () {
            return (
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        marginTop: 'auto', 
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                        <Box sx={{
                            height: 'auto', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'center',
                            alignItems: 'center', 
                            width: '500px',
                            borderRadius: '10px',
                            background: 'white'
                            }}
                            component="form" 
                            type="submit"
                            onSubmit={this.props.handleSubmit}>
                            <Typography
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '30px',
                                    fontFamily: 'Poppins',
                                    color: '#121f33'
                                }}
                            >
                                {i18n.t("ChangeDetectionForm.ChangeDetectionSettings")}
                            </Typography>
                            <Alert 
                                severity={"success"}
                                sx={{width:"84%", fontFamily: "Poppins"}}
                                onClose={this.props.handleClose}
                                closeText={i18n.t("BasicAlert.Close")}
                                icon={false}
                            >
                                {i18n.t("ChangeDetectionForm.DoYouWantToRunChangeDetection")}
                            </Alert> 
                            <div
                                id='change-detection-form-row'
                            >
                                <Typography
                                    sx={{
                                        // height: '12px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '15px',
                                        fontFamily: 'Poppins',
                                        color: '#121f33'
                                    }}
                                >
                                    {`${i18n.t("ChangeDetectionForm.Threshold")}: ${this.props.threshold}`}
                                </Typography>
                                <Slider  
                                    sx={{
                                    width: '70%',
                                    color: '#121f33',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: 16,
                                        height: 16,
                                        backgroundColor: '#121f33',
                                        '&:before': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                        },
                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                        },
                                        },
                                    }}
                                    min={10}
                                    max={99}
                                    value={this.props.threshold}
                                    onChange={this.props.handleThreshold}
                                />
                            </div>
                        </Box>
                        <div id='edit-form-button-container'>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleStart}
                            >
                                {/* Submit */}
                                {i18n.t("ChangeDetectionForm.Start")}
                            </Button>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#DC143C',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#b22222',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleClose}
                            >
                                {/* Close */}
                                {i18n.t("ChangeDetectionForm.Cancel")}
                            </Button>                 
                        </div>
                    </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        // isAreaCreated: state.whatsnew.isChangeCreated,
        // isFailedToCreateChange: state.whatsnew.isFailedToCreateChange,
        isWhatsnewCreated: state.whatsnew.isWhatsnewCreated,
        isFailedToCreateWhatsnew: state.whatsnew.isFailedToCreateWhatsnew,
        whatsnewList: state.whatsnew.whatsnewList,
        message: state.whatsnew.message,
        language: state.language.language,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML,
        isChangeDetectionStarted: state.change.isChangeDetectionStarted,
        isChangeDetectionsGenerated: state.change.isChangeDetectionsGenerated,
        changeDetectionResults: state.change.changeDetectionResults,
        isFailedToGenerateChangeDetections: state.change.isFailedToGenerateChangeDetections
    }
}

export default connect(mapStateToProps, { getWhatsnewList, createWhatsnew, resetWhatsnewForm })(ChangeDetectionForm)