/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { getAnnotationList, createAnnotation, resetAnnotationForm } from '../redux/actions/annotation';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* mui icons-material components */
import ShareIcon from '@mui/icons-material/Share';
import DataObjectIcon from '@mui/icons-material/DataObject';
import CodeIcon from '@mui/icons-material/Code';

class UploadAnnotation extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            geojson: '',
            link: '',
            via: 'geojson',
            kml: ''
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleAlwaysDisplay = this.handleAlwaysDisplay.bind(this)
        this.handleOption = this.handleOption.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
        }

        if(prevProps.isEditMode !== this.props.isEditMode && this.props.isEditMode){
            let editedArea=this.props.areaList.find((area)=>area.id==this.props.editedAreaId)
            this.setState({
                area: {...editedArea}
            })
        }
    }

    /**
     * This is used for both editing and creating new areas
     */
    handleSubmit(){
        this.props.createAnnotation({
            Name: this.state.name,
            Description: this.state.description,
            Storage: this.props.displayedLayer.id,
            Area: this.props.area,
            IsAlways: this.state.alwaysDisplay,
            Geojson: this.props.data,
            Displayed: true,
            Coverage: Number(this.props.coverage),
            Length: Number(this.props.length),
            Owner: this.props.user.username
        })
        this.setState({
            name: '',
            description: '',
            alwaysDisplay: false
        })
        this.props.handleClose()
        setTimeout(() => {
            this.props.resetAnnotationForm()
        }, 2000)
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleAlwaysDisplay(e) {
        this.setState({
            alwaysDisplay: !this.state.alwaysDisplay
        })
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * 
     * @param {*} e 
     * @param {*} newValue 
     */
    handleOption(e, newValue) {
        if(newValue!==null){
            this.setState({
                via: newValue
            })
        }
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <ClickAwayListener onClickAway={this.props.handleClose}>
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        left: 0,
                        right: 0,
                        top: 100,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                        <Box sx={{
                            height: 'auto', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'flex-start',
                            alignItems: 'center', 
                            width: '500px',
                            borderRadius: '10px',
                            background: 'white'
                            }}
                            component="form" 
                            type="submit"
                            onSubmit={this.handleSubmit}>
                                <Typography
                                    sx={{
                                        height: '70px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '30px',
                                        fontFamily: 'Poppins',
                                        color: '#121f33'
                                    }}
                                >
                                    {/* {`Import Annotation`} */}
                                    {i18n.t("UploadAnnotation.ImportAnnotation")}
                                </Typography>
                                <div >
                                    <ToggleButtonGroup value={this.state.via} onChange={this.handleOption} exclusive disabled={this.props.imported}>
                                        <ToggleButton value='geojson'>
                                            <DataObjectIcon/>
                                        </ToggleButton>
                                        <ToggleButton value='kml'>
                                            <CodeIcon/>
                                        </ToggleButton>
                                        <ToggleButton value='link'>
                                            <ShareIcon/>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                                <br></br>
                                {
                                    this.state.via == 'geojson'
                                    ?
                                    <div id='login-page-username-container'>
                                        <Tooltip title={this.state.via}>
                                            <DataObjectIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                        </Tooltip>
                                        <InputBase
                                            sx={{
                                                borderRadius: '3px',
                                                background: 'white',
                                                fontFamily: 'Poppins',
                                                width: '93%',
                                            }}
                                            placeholder="GeoJSON"
                                            required
                                            value={typeof this.props.data == 'object' ? JSON.stringify(this.props.data) : this.props.data}
                                            onChange={this.props.onGeojsonChange('newGeojson')}
                                            autoFocus={true}
                                            disabled={this.props.imported}
                                        />
                                    </div>
                                    : this.state.via == 'link'
                                    ?
                                    <div id='login-page-username-container'>
                                        <Tooltip title={this.state.via}>
                                            <ShareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                        </Tooltip>
                                        <InputBase
                                            sx={{
                                                borderRadius: '3px',
                                                background: 'white',
                                                fontFamily: 'Poppins',
                                                width: '93%',
                                            }}
                                            placeholder="Link"
                                            required
                                            value={this.props.link}
                                            onChange={this.props.onLinkChange('newAnnoLink')}
                                            autoFocus={true}
                                            disabled={this.props.imported}
                                        />
                                    </div>
                                    :
                                    <div id='login-page-username-container'>
                                    <Tooltip title={this.state.via}>
                                        <CodeIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        placeholder="KML"
                                        required
                                        value={this.props.kml}
                                        onChange={this.props.onKMLChange('newKML')}
                                        autoFocus={true}
                                        disabled={this.props.imported}
                                    />
                                    </div>
                                }
                        </Box>
                        {
                            !this.props.imported
                            ?
                            <div id='edit-form-button-container'>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#1aaa55',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#168f48',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={() => this.props.handleImport(this.state.via)}
                                >
                                    {/* Import */}
                                    {i18n.t("UploadAnnotation.Import")}
                                </Button>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#DC143C',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#b22222',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={this.props.handleClose}
                                >
                                    {/* Close */}
                                    {i18n.t("UploadAnnotation.Close")}
                                </Button>                 
                            </div>
                            :
                            <div id='edit-form-button-container'>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#1aaa55',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#168f48',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={this.props.handleUpload}
                                >
                                    {/* Save */}
                                    {i18n.t("UploadAnnotation.Save")}
                                </Button>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#DC143C',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#b22222',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={this.props.handleClose}
                                >
                                    {/* Cancel */}
                                    {i18n.t("UploadAnnotation.Cancel")}
                                </Button>                 
                            </div>
                        }
                    </div>
                </ClickAwayListener>
            )}
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        isAnnotationCreated: state.annotation.isAnnotationCreated,
        isFailedToCreateAnnotation: state.annotation.isFailedToCreateAnnotation,
        annotationList: state.annotation.annotationList,
        message: state.annotation.message,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { getAnnotationList, createAnnotation, resetAnnotationForm })(UploadAnnotation)