/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { getUserGroupNames } from '../helpers/helpers'
import i18n from 'i18next'

/* custom components */
import EditMap from './EditMap'
import AdminSearchBar from './AdminSearchBar'

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton'
import { DataGrid, enUS,  trTR } from '@mui/x-data-grid';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

/* mui icons-material components */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';

/* Redux */
import { getAreaList } from '../redux/actions/areas';
import { getMapList, deleteMap, resetMapForm,getEditedMap } from '../redux/actions/maps';

class MapTable extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isDialogOpen: false,
            selectedAreaId: null,
            query: ''
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleClose = this.handleClose.bind(this)

        /* Refs */
        this.textArea = React.createRef()
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isMapDeleted !== this.props.isMapDeleted && this.props.isMapDeleted){
            this.props.getMapList()
        }
    }

    /**
     * 
     */
    handleSubmit(){

    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {

    }

    /**
     * 
     * @param {*} id 
     */
    handleDelete(id) {
        this.props.deleteMap(id)
        setTimeout(() => {
            this.props.resetMapForm()
        }, 1000)
    }

    /**
     * this.props.draw passed in AddArea component
     * draw.add accepts feature collection not standalone features
     * @param {*} id 
     */
     handleEdit(id) {
        this.props.handleHideLinks()
        this.setState({
            isDialogOpen: true
        })
        this.props.getEditedMap(id)
    }

    /**
     * 
     */
    handleClose() {
        this.setState({
            isDialogOpen: false
        })
    }
    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * 
     * @returns the component 
     */
    render () {

        const Edit = (props) =>                 
        <IconButton onClick={() => this.handleEdit(props.id)}>
            <EditIcon/>
        </IconButton> 

        const Delete = (props) =>                 
        <IconButton onClick={() => this.handleDelete(props.id)} disabled={this.props.isEditMode}>
            <DeleteIcon/>
        </IconButton> 

        const Info = (props) =>
            <Tooltip placement='left' title={this.props.groups?getUserGroupNames(this.props.mapList.find(map=> map.id==props.id),this.props.groups):''}>
                <IconButton onClick={()=>console.log()} disabled={this.props.isEditMode}>
                    <GroupIcon/>
                </IconButton> 
            </Tooltip>                 

        const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 600,
                fontSize: theme.typography.pxToRem(12),
                border: '1px solid #dadde9',
                },
            }));

        const Links = (props) =>
            <HtmlTooltip title={
                    <div style={{width: '475px'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', height: '10px'}}>
                            <IconButton onClick={this.props.handleHideLinks}>
                                <CloseIcon fontSize='small'/>
                            </IconButton>
                        </div>
                        {this.props.mapList.findIndex(map=>map.id==props.id)!=-1?
                        Object.keys(this.props.mapList.find(map=>map.id==props.id).Layers).map((key)=>
                            <div key={key}>
                                <InputLabel 
                                    htmlFor={`stitchedMf-${props.id}`}
                                    sx={{
                                        fontSize: '12px',

                                    }}
                                >{`${key}-${props.id}`}</InputLabel>
                                <div
                                    className='map-table-link-container'
                                >
                                    <FilledInput 
                                        sx={{
                                        fontSize: '12px'
                                        }} 
                                        value={`http://${this.props.mapList.find(map=>map.id==props.id).IPAddress}${this.props.mapList.find(map=>map.id==props.id).FlightPath}${this.props.mapList.find(map=>map.id==props.id).Layers[key]}`}
                                        fullWidth
                                        size='small'
                                        variant='standard'
                                        label={`${key}-${props.id}`}
                                        id={`${key}-${props.id}`}
                                        disabled={this.props.mapList.find(map=>map.id==props.id).Layers[key]==''}
                                    />
                                    <IconButton 
                                        onClick={() => this.copyToClipboard(`${key}-${props.id}`)}

                                    >
                                        <ContentCopyIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        ):null}
                    </div>
                }
                placement='bottom-end'
                sx={{
                    mt: '10px'
                }}
                open={this.props.showLinks==props.id}
            >
                    <IconButton
                        onClick={() => this.props.handleShowLinks(props.id)}
                    >
                        <LinkIcon/>
                    </IconButton>
            </HtmlTooltip>

        const columns = [
            {field: 'id', headerName: 'ID' , width: 60},
            {field: 'group', headerName: i18n.t("MapTable.Group") , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
            {field: 'flightDate', headerName: i18n.t("MapTable.Date") , width: 120},
            {field: 'area', headerName: i18n.t("MapTable.Region") , width: 140},
            {field: 'ipAddress', headerName: i18n.t("MapTable.SourceIP") , width: 120},
            {field: 'added', headerName: i18n.t("MapTable.Added"), width: 120},
            {field: 'links', headerName: i18n.t("MapTable.Links") , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Links(props)},
            {field: 'edit', headerName: i18n.t("MapTable.Edit") , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Edit(props)},
            {field: 'delete', headerName: i18n.t("MapTable.Delete") , width: 70, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
        ]

        const rows = this.props.mapList.map((map)=>(
            {
                id: map.id, 
                group: map.UserGroup,
                flightDate: map.FlightDate,
                flightName: map.FlightName, 
                area: this.props.areaList.find((area)=>area.id==map.Area) ? this.props.areaList.find((area)=>area.id==map.Area).RegionName : '',
                ipAddress: map.IPAddress,
                added: map.Added,
                edit: '',
                delete: '',
                location: '',
            }
        ))

        const result = rows.filter((map) => map.area.toLowerCase().includes(this.state.query.toLowerCase()))

        return (
                <div 
                    id='map-table-page-container'
                >
                    <div id='maps-page-alert-container'>
                    {
                        this.props.isMapAdded?
                        <Alert severity='success'>{i18n.t("MapTable.SuccessfullyAdded")}</Alert> 
                        :
                        null
                    }
                    {
                        this.props.isFailedToAddMap?
                        <Alert severity='error'>{`${i18n.t("MapTable.FailedToAdd")}: ${this.props.message}`}</Alert> 
                        :
                        null
                    }
                     {
                    this.props.isMapPut ?
                    <div id='admin-page-alert-container'>
                        <Alert sx={{width:"161%"}} onClose={this.props.resetMapForm} severity='success'>{i18n.t("MapTable.EditedTheMap")}</Alert> 
                    </div>
                    :
                    null
                }
                    {
                        this.props.isMapDeleted?
                        <Alert severity='success'>{i18n.t("MapTable.SuccessfullyDeleted")}</Alert> 
                        :
                        null
                    }
                    {
                        this.props.isFailedToDeleteMap ?
                        <Alert severity='error'>{`${i18n.t("MapTable.FailedToDelete")}: ${this.props.message}`}</Alert> 
                        :
                        null
                    }
                    </div>
                    <AdminSearchBar
                        handleChange={this.handleChange}
                        text={i18n.t("MapTable.SearchByRegion")}
                        iconTitle={i18n.t("MapTable.RegionName")}
                        value={this.state.query}
                        width='840px'
                    />
                    <div id='map-table-container'>
                        <DataGrid 
                            rows={result}
                            columns={columns}
                            pageSize={10}
                            rowHeight={43}
                            rowsPerPageOptions={[10]}
                            sx={{
                                fontFamily: 'Poppins',
                                overflowY: 'hidden'
                            }}
                            disableSelectionOnClick={true}
                            localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                    <EditMap
                        isDialogOpen={this.state.isDialogOpen}
                        handleClose={this.handleClose}
                    />
                </div>
                
        )}
}

const mapStateToProps = (state) => {
    return {
        mapList: state.maps.mapList,
        areaList: state.areas.areaList,
        storageFlights: state.areas.storageFlights,
        isMapDeleted: state.maps.isMapDeleted,
        isMapPut:state.maps.isMapPut,
        isFailedToDeleteMap: state.maps.isFailedToDeleteMap,
        message: state.maps.message,
        editedMap:state.maps.editedMap,
        isMapAdded: state.maps.isMapAdded,
        isFailedToAddMap: state.maps.isFailedToAddMap,
        groups: state.groups.groups,
        language: state.language.language
    }
}


export default connect(mapStateToProps, { getMapList, getAreaList, deleteMap,getEditedMap, resetMapForm})(MapTable)