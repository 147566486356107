/* eslint-disable eqeqeq */import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'

/* helper functions */

/* mui material components */
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGrid, enUS, trTR } from '@mui/x-data-grid';
/* custom components */
import CustomCircularProgress from './CustomCircularProgress'
/* mui icons-material components */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CloseIcon from '@mui/icons-material/Close'


class WhereelseObjects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        const Location = (props) =>
        <Tooltip 
            title={i18n.t("WhereelseObjects.Find")}
            // title={i18n.t("DetectionObjects.Find")} 
            placement='left'
        >           
            <IconButton 
                onClick={() => this.props.handleFlyTo(props.id, 'detected')}
            >
                <LocationOnOutlinedIcon sx={{color: 'black'}} fontSize='small'/>
            </IconButton>
        </Tooltip>
        const Add = (props) =>
        <Tooltip 
            title={i18n.t("WhereelseObjects.Confirm")}
            // title={i18n.t("DetectionObjects.Confirm")} 
            placement='left'
        >             
            <IconButton onClick={() => this.props.handleConfirm(props.id)}>
                <AddCircleIcon color='success' fontSize='small'/>
            </IconButton>
        </Tooltip>
        const columns = [
            {field: 'id', headerName: i18n.t("WhereelseObjects.ObjectName") , width: 25},
            {field: 'conf', headerName: i18n.t("WhereelseObjects.ConfidenceLevels") , width: 125},
            {field: 'location', headerName: `` , width: 60, sortable: false, disableColumnMenu: true, renderCell: (props) => Location(props)},
            {field: 'add', headerName: `` , width: 60, sortable: false, disableColumnMenu: true, renderCell: (props) => Add(props)},
            // {field: 'delete', headerName: `` , width: 50, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
        ]
        // const rows = this.props.whereelseResults.map((whereelse, i)=>(
        //     {
        //         id: whereelse.properties.id,
        //         conf: Number(whereelse.properties.conf).toFixed(3)
        //     }
        // ))
        const rows = []
        return (
            <div 
                style={{
                position: 'absolute', 
                right: 55,
                top: 60,
                bottom: 0,
                zIndex: 200, 
                minWidth: '300px', 
                height: 'fit-content',
                fontFamily: 'Poppins', 
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}>

                    <Typography
                        sx={{
                            height: '50px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            color: '#121f33',
                            width: '100%'
                        }}
                    >
                        <span style={{
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'center', 
                            alignItems: 'center'}}
                        >{this.props.label}</span>
                        <IconButton onClick={this.props.handleClose} sx={{position: 'absolute', right: '3px'}}>
                            <Tooltip title='Close'>
                                <CloseIcon />
                            </Tooltip>
                        </IconButton>
                    </Typography>
                </div>
                {
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        headerHeight={0}
                        rowsPerPageOptions={[10]}
                        sx={{
                            fontFamily: 'Poppins',
                            overflowY: 'hidden',
                            fontSize: '13px'
                        }}
                        disableSelectionOnClick={true}
                        showColumnRightBorder={true}
                        autoHeight={true}
                        autoPageSize={true}
                        density='compact'
                        page={this.props.currentPage}
                        onPageChange={(pageNumber)=>this.props.onPageChange(pageNumber)}
                        localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                        loading={this.props.isWhereelsesRequested}
                        components={{
                            LoadingOverlay: CustomCircularProgress
                        }}
                        hideFooter={this.props.isWhereelsesRequested}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        areaId: state.map.areaId,
        user: state.auth.user,
        language: state.language.language,
        /* generateWhereelses */
        isWhereelsesRequested: state.whereelse.isWhereelsesRequested,
        isWhereelsesGenerated: state.whereelse.isWhereelsesGenerated,
        whereelseResults: state.whereelse.whereelseResults,
        isFailedToGenerateWhereelses: state.whereelse.isFailedToGenerateWhereelses,
        /* getWhereelseList */
        iswhereelseListLoading: state.whereelse.iswhereelseListLoading,
        isWhereelseListFetched: state.whereelse.isWhereelseListFetched,
        whereelseList: state.whereelse.whereelseList,
        isFailedToFetchWhereelseList: state.whereelse.isFailedToFetchWhereelseList,
    }
}

export default connect(mapStateToProps, {})(WhereelseObjects)