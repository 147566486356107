/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'
/* Redux */
import { connect } from 'react-redux'
import { getAnnotationList, createAnnotation, putAnnotation, resetAnnotationForm } from '../redux/actions/annotation';
/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton';
/* mui icons-material components */
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import InfoIcon from '@mui/icons-material/Info';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import StraightenIcon from '@mui/icons-material/Straighten';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlaceIcon from '@mui/icons-material/Place';
/* helper functions */
import { getMGRS, inverse } from '../helpers/mgrs';
import tokml from '../helpers/tokml';


class AnnotationForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: '',
            alwaysDisplay: false,
            longitude: "",
            latitude: "",
            geojson: {},
            MGRS:""
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleAlwaysDisplay = this.handleAlwaysDisplay.bind(this)
        this.handleChangeLonLat = this.handleChangeLonLat.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.setState({
            name: this.props.name,
            description: this.props.description,
            geojson: this.props.data,
        })
        if(this.props.data.geometry.type==="Point"){
            let mgrs_value = getMGRS(this.props.data.geometry.coordinates[0], this.props.data.geometry.coordinates[1])
            this.setState({
                longitude: this.props.data.geometry.coordinates[0],
                latitude: this.props.data.geometry.coordinates[1],
                MGRS: mgrs_value
            })
        }
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
        }

        if(prevProps.isEditMode !== this.props.isEditMode && this.props.isEditMode){
            let editedArea=this.props.areaList.find((area)=>area.id===this.props.editedAreaId)
            this.setState({
                area: {...editedArea}
            })
        }
    }

    /**
     * This is used for both editing and creating new areas
     */
    handleSubmit(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        let geojsonProps = {
            Name: this.state.name,
            Description: this.state.description,
            Storage: this.props.displayedLayer.id,
            Area: this.props.area,
            IsAlways: this.state.alwaysDisplay,
            // Geojson: this.state.geojson,
            Displayed: true,
            Coverage: Number(this.props.coverage),
            Length: Number(this.props.length),
            Zoom: parseFloat(params.zoom).toFixed(2),
            Owner: this.props.user.username,
            id: this.props.id
        }
        let geojsonWithProps={...this.state.geojson}
        geojsonWithProps.properties={...geojsonProps}
        if(this.props.isEditAnnotationModeOn){
            this.props.putAnnotation({
                Name: this.state.name,
                Description: this.state.description,
                Storage: this.props.displayedLayer.id,
                Area: this.props.area,
                IsAlways: this.state.alwaysDisplay,
                // Geojson: this.state.geojson,
                Geojson: {...geojsonWithProps},
                Displayed: true,
                Coverage: Number(this.props.coverage),
                Length: Number(this.props.length),
                Zoom: parseFloat(params.zoom).toFixed(2),
                Owner: this.props.user.username,
                id: this.props.id
            })
        } else {
            this.props.createAnnotation({
                Name: this.state.name,
                Description: this.state.description,
                Storage: this.props.displayedLayer.id,
                Area: this.props.area,
                IsAlways: this.state.alwaysDisplay,
                // Geojson: this.state.geojson,
                Geojson: {...geojsonWithProps},
                Displayed: true,
                Coverage: Number(this.props.coverage),
                Length: Number(this.props.length),
                Zoom: parseFloat(params.zoom).toFixed(2),
                Owner: this.props.user.username,
            })
        }
        this.setState({
            name: '',
            description: '',
            alwaysDisplay: false
        })
        this.props.handleClose()
        setTimeout(() => {
            this.props.resetAnnotationForm()
        }, 2000)
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
        if(name==="longitude"){
            let updatedGeojson = {...this.state.geojson}
            let geometryString = `{"coordinates":[${event.target.value},${updatedGeojson.geometry.coordinates[1]}],"type":"Point"}`
            updatedGeojson.geometry = JSON.parse(geometryString)
            this.setState({
                geojson: {...updatedGeojson},
                MGRS: getMGRS(this.state.longitude, this.state.latitude)
            })
        }
        if(name==="latitude"){
            let updatedGeojson = {...this.state.geojson}
            let geometryString = `{"coordinates": [${updatedGeojson.geometry.coordinates[0]},${event.target.value}],"type":"Point"}` 
            updatedGeojson.geometry = JSON.parse(geometryString)
            this.setState({
                geojson: {...updatedGeojson},
                MGRS: getMGRS(this.state.longitude, this.state.latitude)
            })
        }
        if(name==="MGRS"){
            let updatedGeojson = {...this.state.geojson}
            let new_coordinates = inverse(event.target.value)

            let geometryString = `{"coordinates": [${new_coordinates[0]},${new_coordinates[1]}],"type":"Point"}`

            updatedGeojson.geometry = JSON.parse(geometryString)
            this.setState({
                longitude: new_coordinates[0].toString(),
                latitude: new_coordinates[1].toString(),
                geojson: {...updatedGeojson},
                // MGRS: event.target.value
            })
        }
    }

    /**
     * 
     */
    handleAlwaysDisplay(e) {
        this.setState({
            alwaysDisplay: !this.state.alwaysDisplay
        })
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**/
    handleChangeLonLat = () => {

    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <ClickAwayListener onClickAway={this.props.handleClose}>
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        marginTop: 'auto', 
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                        <Box sx={{
                            height: 'auto', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            justifyContent: 'flex-start',
                            alignItems: 'center', 
                            width: '500px',
                            borderRadius: '10px',
                            background: 'white'
                            }}
                            component="form" 
                            type="submit"
                            onSubmit={this.handleSubmit}>
                            <Typography
                                sx={{
                                    height: '70px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '30px',
                                    fontFamily: 'Poppins',
                                    color: '#121f33'
                                }}
                            >
                                {/* {this.props.isEditMode ? `Edit Annotation` : `Save Annotation`} */}
                                {this.props.isEditAnnotationModeOn ? i18n.t("AnnotationForm.EditAnnotation"): i18n.t("AnnotationForm.SaveAnnotation")}
                            </Typography>
                            <div id='login-page-username-container'>
                                <Tooltip 
                                    // title='Name'
                                    title={i18n.t("AnnotationForm.Name")}
                                >
                                    {
                                        this.props.isEditMode
                                        ?
                                        <EditLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                        :
                                        <AddLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    }
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    // placeholder={'Name'}
                                    placeholder={i18n.t("AnnotationForm.Name")}
                                    required
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    autoFocus={true}
                                />
                            </div>
                            <br/>
                            <div id='login-page-username-container'>
                                <Tooltip 
                                    // title='Description'
                                    title={i18n.t("AnnotationForm.Description")}
                                >
                                    <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    // placeholder={'Description'}
                                    placeholder={i18n.t("AnnotationForm.Description")}
                                    value={this.state.description}
                                    onChange={this.handleChange('description')}
                                />
                            </div>
                            <br></br>
                            {
                                this.props.data.geometry.type === "Polygon" ?
                                <div
                                    id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Area km²'
                                        title={`${i18n.t("AnnotationForm.Area")} km²`}
                                    >
                                        <CropSquareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.coverage} km²`}
                                    >
                                    </InputBase>
                                </div>
                                :
                                null
                            }
                            {
                                this.props.data.geometry.type === "Polygon" ? <br></br> : null
                            }
                            {
                                this.props.data.geometry.type != "Point" ?
                                <div
                                    id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Length km'
                                        title={`${i18n.t("AnnotationForm.Length")} km²`}
                                    >
                                        <StraightenIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.length} km`}
                                    >
                                    </InputBase>
                                </div>
                                :
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Longitude'
                                        title={i18n.t("AnnotationForm.Longitude")}
                                    >
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        // placeholder="Longitude"
                                        placeholder={i18n.t("AnnotationForm.Longitude")}
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.state.longitude}`}
                                        onChange={this.handleChange('longitude')}
                                    >
                                    </InputBase>
                                </div>
                            }
                            {this.props.data.geometry.type == "Point"?<br></br>:null}
                            {
                                this.props.data.geometry.type == "Point" ?
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Latitude'
                                        title={i18n.t("AnnotationForm.Latitude")}
                                    >
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        // placeholder="Latitude"
                                        placeholder={i18n.t("AnnotationForm.Longitude")}
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.state.latitude}`}
                                        onChange={this.handleChange('latitude')}
                                    >
                                    </InputBase>
                                </div>
                                :
                                null
                            }
                            {/* <br></br> */}
                            {this.props.data.geometry.type == "Point"?<br></br>:null}
                            {
                                this.props.data.geometry.type == "Point" ?
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip title='MGRS'>
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        placeholder="MGRS"
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${ this.state.MGRS}`}
                                        onChange={this.handleChange('MGRS')}
                                    >
                                    </InputBase>
                                </div>
                                :
                                null
                            }
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip title='Geojson'>
                                    <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={JSON.stringify(this.props.data)}
                                    id='form-geojson'
                                >
                                </InputBase>
                                <IconButton 
                                    onClick={() => this.copyToClipboard('form-geojson')}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                            <div
                                    id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Area km²'
                                        title='KML'
                                    >
                                        <CropSquareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={tokml(this.props.data)}
                                        id= 'form-kml'
                                    >
                                    </InputBase>
                                    <IconButton 
                                    onClick={() => this.copyToClipboard('form-kml')}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>

                        </Box>
                        <div id='edit-form-button-container'>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.handleSubmit}
                            >
                                {/* Submit */}
                                {i18n.t("AnnotationForm.Submit")}
                            </Button>
                            <Button
                                sx={{
                                    width: '44%',
                                    height: '37px',
                                    background: '#DC143C',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#b22222',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleClose}
                            >
                                {/* Close */}
                                {i18n.t("AnnotationForm.Close")}
                            </Button>                 
                        </div>
                    </div>
                </ClickAwayListener>
            )}
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user, 
        isAnnotationCreated: state.annotation.isAnnotationCreated,
        isFailedToCreateAnnotation: state.annotation.isFailedToCreateAnnotation,
        annotationList: state.annotation.annotationList,
        message: state.annotation.message,
        isEditAnnotationModeOn: state.annotation.isEditMode
    }
}

export default connect(mapStateToProps, { getAnnotationList, createAnnotation, putAnnotation, resetAnnotationForm })(AnnotationForm)