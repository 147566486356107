/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next';

/* confgis */
import { IS_ATLAS_ML } from '../configs/hosts';

/* Redux */
import { connect } from 'react-redux'
import { loadUser } from '../redux/actions/auth'
import { openLanguage, closeLanguage } from '../redux/actions/language'

/* custom components */
import ChangeLanguage from '../ChangeLanguage'
import ChangeHosts from './ChangeHosts';

/* mui material components */
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

/* mui icons-material components*/
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

/* helpers */
import { toUpperCaseAtZero } from '../helpers/helpers'

/* assets */

const BASE_URL = process.env.REACT_APP_BASE_URL

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pageName: null,
            anchorEl: null,
            menuOpen: false,
            avatarOpen:false,
            username:null,
            language: false,
            hostsOpen: false
        }

        /* Function bindings here */
        this.goTo = this.goTo.bind(this)
        this.handleSignOut = this.handleSignOut.bind(this)
        this.handleRoute = this.handleRoute.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLanguage = this.handleLanguage.bind(this)
        this.handleHosts = this.handleHosts.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        const re=/\/|\?/g
        this.setState({pageName: toUpperCaseAtZero(window.location.href.split(re)[3]),username:''})
    }

    /**
     *
     */
    handleMenuClick = (e) => {
        this.setState ({
            menuOpen: true,
            anchorEl: e.target.value
        });
    }

    /**
     *
     */
    handleRoute = (route) =>{
        this.setState({
            anchorEl: null,
            menuOpen: false,
        })
        this.goTo(route)
    }

    /**
     * 
     * @param {*} e
     */
    handleAvatar = (e)=>{
        this.setState ({
            avatarOpen: true,
            anchorEl: e.target.value,
            username:this.props.user.username
        });
    }

    /**
     *
     */
    goTo(page){
        window.location.replace(`${BASE_URL}/${page}`)
    }

    /**
     *
     */
    handleSignOut(){
        window.location.replace(`${BASE_URL}`)
        window.localStorage.removeItem('token')
        this.setState({
            menuOpen: false,

        })
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            menuOpen: false,
            avatarOpen:false,
            hostsOpen: false
        })
        this.props.closeLanguage()
    }

    handleLoad() {

    }

    handleLanguage() {
        this.props.openLanguage()
    }

    handleHosts() {
        this.setState({
            hostsOpen: !this.state.hostsOpen,
            menuOpen: false
        })
    }

    /**
     *
     * @returns the component
     */
    render () {
        // const username=this.props.user.username
        return (
            <AppBar position='fixed' open={this.state.drawerOpen}>
                <Toolbar variant='dense' sx={{ background: '#121f33', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div id='header-logo-brand-container'>
                        <Tooltip 
                        // title="Gallery"
                         title={i18n.t("Header.Gallery")}
                        >
                            <Typography variant="h6" noWrap component="div" sx={{fontFamily: 'Poppins', mb: '3px', mr: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px'}} onClick={() => this.goTo('gallery')}>
                                {/* Baykar Mapping */}
                                {i18n.t("Header.BaykarMapping")}
                            </Typography>
                        </Tooltip>
                    </div>
                <div id='header-buttons-container'>
                    <div id='header-buttons-container'>
                        <IconButton sx={{mr: "-10px"}}>
                            <Tooltip 
                            //   title="User"
                              title={i18n.t("Header.User")}
                            >
                                <Avatar onClick={this.handleAvatar}  sx={{ bgcolor:'#27aa55', color :'white',width:'25px',height:'25px',fontSize:'12px'}} >{this.props.user.username?this.props.user.username[0].toUpperCase():''}</Avatar>
                            </Tooltip>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            sx ={{left:"47%",top:"8px",mt:this.props.marginTop, fontFamily: 'Poppins'}}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={this.state.avatarOpen}
                            onClose={this.handleClose}
                        >  
                            <div id='header-avatar-style'>
                                <div>
                                    <Avatar  sx={{marginLeft:'10px', bgcolor:'#27aa55' ,color :'white',width:'40px',height:'40px',fontSize:'25px'}} >{this.props.user.username?this.props.user.username[0].toUpperCase():''}</Avatar>
                                </div>
                                <div id='header-avatar-menu'>
                                    <span>{`${this.props.user.username}, ${this.props.user.group}`}</span>
                                    <span>{this.props.user.is_superuser ? i18n.t("Header.Admin") : i18n.t("Header.Viewer")} </span>
                                </div>
                            </div>
                        </Menu>
                    </div>
                        <IconButton
                            onClick={this.handleMenuClick}
                            sx={{width:'24px',marginLeft:'20px'}}
                            color="inherit"
                            edge="start">
                            <Tooltip 
                            //  title='Options'
                              title={i18n.t("Header.Options")}
                            >
                                <MenuOutlinedIcon/>
                            </Tooltip>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            sx ={{top:"8px",mt:this.props.marginTop, fontFamily: 'Poppins'}}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={this.state.menuOpen && !this.props.isLanguageOpen}
                            onClose={this.handleClose}
                        >
                            {
                                this.props.user.is_staff
                                ?
                                <MenuItem sx={{fontFamily: 'Poppins'}} onClick={() => this.handleRoute('adminpanel')}>{i18n.t("Header.AdminPanel")}</MenuItem>
                                :
                                null
                            }
                            <MenuItem sx={{fontFamily: 'Poppins'}}  onClick={() => this.handleRoute('gallery')}>{i18n.t("Header.Gallery")}</MenuItem>
                            {
                                IS_ATLAS_ML?
                                <MenuItem sx={{fontFamily: 'Poppins'}} onClick={() => this.handleHosts()}>{i18n.t("Header.Hosts")}</MenuItem>:
                                null
                            }
                            <MenuItem sx={{fontFamily: 'Poppins'}} onClick={() => this.props.openLanguage()}>{i18n.t("Header.Language")}</MenuItem>
                            <MenuItem sx={{fontFamily: 'Poppins'}} onClick={this.handleSignOut}>{i18n.t("Header.Logout")}</MenuItem>
                        </Menu>
                        <Menu
                            id="menu-appbar"
                            sx ={{top:"8px",mt:this.props.marginTop, fontFamily: 'Poppins'}}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={this.props.isLanguageOpen}
                            onClose={this.handleClose}
                        >
                            <MenuItem sx={{fontFamily: 'Poppins'}} >
                                <ChangeLanguage width={150}/>
                            </MenuItem>
                        </Menu>
                        <Menu
                            id="menu-appbar"
                            sx ={{top:"8px",mt:this.props.marginTop, fontFamily: 'Poppins'}}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={this.state.hostsOpen}
                            onClose={this.handleClose}
                        >
                            <MenuItem sx={{fontFamily: 'Poppins'}} >
                                <ChangeHosts />
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userGroup: state.auth.user.userGroup,
        language: state.language.language,
        isLanguageOpen: state.language.isLanguageOpen,
        atlasML: state.hosts.atlasML
    }
}

export default  connect(mapStateToProps, {loadUser, openLanguage, closeLanguage})(Header)
