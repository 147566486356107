/* eslint-disable eqeqeq */
import React from 'react'

/* Redux */
import { connect } from 'react-redux'
import { setAtlasML } from '../redux/actions/hosts';

/* custom components */

/* mui material components */
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

/* mui icons-material components*/
import SaveIcon from '@mui/icons-material/Save';

/* helpers */
import { } from '../helpers/helpers'

/* assets */

class ChangeHosts extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            atlasML: "10.0.35.26"
        }

        /* Function bindings here */
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.setState({
            atlasML: this.props.atlasML
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /** */
    handleChange = (name) => (event) => {
        this.setState({
          [name]: event.target.value,
        })
    }

    /* */
    handleSave() {
        this.props.setAtlasML(this.state.atlasML)
    }

    /**
     * 
     * @returns the component 
     */
    render () {

        return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", minWidth: "150px"}}>
                <Tooltip title={this.state.atlasML==this.props.atlasML?"Saved":"Not Saved"}>
                    <TextField id="AtlasML" label="AtlasML" variant="standard" onChange={this.handleChange("atlasML")} value={this.state.atlasML}/>
                </Tooltip>
                <IconButton onClick={this.handleSave} disabled={this.state.atlasML==this.props.atlasML}>
                    <Tooltip title="Save">
                        <SaveIcon color={this.state.atlasML==this.props.atlasML?"success":""}/>
                    </Tooltip>
                </IconButton>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        atlasML: state.hosts.atlasML
    }
}

export default  connect(mapStateToProps, {setAtlasML})(ChangeHosts)