/* eslint-disable eqeqeq */
import React from "react";
import i18n from 'i18next'

/* custom components */
import Header from "../components/Header";
import Users from "../components/Users";
import Areas from "../components/Areas";
import Maps from "../components/Maps";
import Groups from "../components/Groups";
import Buckets from "../components/Buckets";

/* mui material components */
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

/* mui icons-material components */

/* assets */
/* Redux */
import { connect } from "react-redux";
import { loadUser } from "../redux/actions/auth";
import { getGroupList } from "../redux/actions/groups";
import { getUserList } from "../redux/actions/users";
import { getAreaList } from "../redux/actions/areas";
import { getMapList, getFlightList } from "../redux/actions/maps";
import { getBucketList } from "../redux/actions/bucket";

// const SERVER = process.env.REACT_APP_DJANGO_SERVER;

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };

    /* Function bindings here */
  }

  /**
   * When the component is rendered
   */
  componentDidMount() {
    this.props.getGroupList();
    this.props.getUserList();
    this.props.getAreaList();
    this.props.getMapList();
    this.props.getBucketList({
      accessKeyId: window.sessionStorage.getItem("accessKeyId"),
      secretAccessKey: window.sessionStorage.getItem("secretAccessKey")
    })
  }

  /**
   * When the component is updated
   */
  componentDidUpdate() {}

  /**
   *
   */
  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  /**
   *
   * @returns the component
   */
  render() {
    return (
      <div style={{ fontFamily: "Poppins", width: "100vw" }}>
        <Header marginTop="0px" />
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          sx={{ mt: "45px" }}
          variant="fullWidth"
          textColor="inherit"
          TabIndicatorProps={{
            sx: {
              bgcolor: "#121f33",
            },
          }}
        >
          <Tab 
            // label="Groups"
            label={i18n.t("AdminPanel.Groups")}
            sx={{ fontFamily: "Poppins" }} 
          />
          <Tab 
            // label="Users"
            label={i18n.t("AdminPanel.Users")}
            sx={{ fontFamily: "Poppins" }} 
          />
          <Tab 
            // label="Areas"
            label={i18n.t("AdminPanel.Areas")}
            sx={{ fontFamily: "Poppins" }} 
          />
          <Tab 
            // label="Maps"
            label={i18n.t("AdminPanel.Maps")}
            sx={{ fontFamily: "Poppins" }} 
          />
          <Tab 
            // label="Buckets"
            label={i18n.t("AdminPanel.Buckets")}
            sx={{ fontFamily: "Poppins" }} 
          />
        </Tabs>
        {this.state.tabValue == 0 ? (
          <Groups tabValue={this.state.tabValue} />
        ) : null}
        {this.state.tabValue == 1 ? (
          <Users tabValue={this.state.tabValue} />
        ) : null}
        {this.state.tabValue == 2 ? (
          <Areas tabValue={this.state.tabValue} />
        ) : null}
        {this.state.tabValue == 3 ? (
          <Maps tabValue={this.state.tabValue} />
        ) : null}
        {this.state.tabValue == 4 ? (
          <Buckets tabValue={this.state.tabValue} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.users,
    groups: state.groups.groups,
    areaList: state.areas.areaList,
    mapList: state.maps.mapList,
    storageFlights: state.maps.storageFlights,
  };
};

export default connect(mapStateToProps, {
  loadUser,
  getUserList,
  getAreaList,
  getMapList,
  getFlightList,
  getGroupList,
  getBucketList
})(AdminPanel);
