/* eslint-disable eqeqeq */
import React from 'react'
// import mapboxgl from 'mapbox-gl'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import mapboxglComp from 'mapbox-gl-compare';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getMapStyle, addRasterLayer, addSatelliteLayers } from '../helpers/helpers.js'
import ComparePanel from '../components/ComparePanel.jsx';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

/* mui icons-material components*/
import PublicIcon from '@mui/icons-material/Public'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus'
import Header from '../components/Header.jsx';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg';
mapboxgl.workerClass = MapboxWorker

class Compare extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //fetch lng, lat, zoom from a database later
            // lng: 26.6062,
            // lat: 40.7930,
            // zoom: 13.5,
            compContainer: '#comparison-container',
            compare: null,
            sliderPosition: null,
            firstLayer: null,
            secondLayer: null,
        }

        /* Function bindings here */
        this.handleHideShow = this.handleHideShow.bind(this)
        this.handleMapIcon = this.handleMapIcon.bind(this)
        
        /* Refs */
        this.mapBefore = React.createRef()
        this.mapAfter = React.createRef()
        this.mapBeforeContainer = React.createRef()
        this.mapAfterContainer = React.createRef()
        this.comparisonContainer = React.createRef()
        this.compare = React.createRef()
    }

    /**
     * When the component is rendered
     */
    componentDidMount() {
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        document.title=params.regionName
        let lng = Number(params.lng)
        let lat = Number(params.lat)
        // let areaLng = Number(params.areaLng)
        // let areaLat = Number(params.areaLat)
        let zoom = Number(params.zoom)

        if (this.mapBefore.current) return
        this.mapBefore.current = new mapboxgl.Map({
            //container: this.mapBeforeContainer.current,
            container: 'map-before',
            style: getMapStyle('google'),
            center: [lng, lat],
            zoom: zoom
        })

        let firstLayer = JSON.parse(window.localStorage.getItem('firstLayer'))
        let secondLayer = JSON.parse(window.localStorage.getItem('secondLayer'))

        this.mapAfter.current = new mapboxgl.Map({
            // container: this.mapAfterContainer.current,
            container: 'map-after',
            style: getMapStyle('google'),
            center: [lng, lat],
            zoom: zoom
        })

        this.mapBefore.current.on('load', () => {
            try {
                addSatelliteLayers(this.mapBefore.current)
                addRasterLayer(this.mapBefore.current, 'before-raster', firstLayer, true, 1000)
            } catch (error) {
                console.log('ERROR: ', error)
            }
        })

        this.mapAfter.current.on('load', () => {
            try {
                addSatelliteLayers(this.mapAfter.current)
                addRasterLayer(this.mapAfter.current, 'after-raster', secondLayer, true, 1000)
            } catch (error) {
                console.log('ERROR: ', error)
            }
        })

        this.compare.current = new mapboxglComp(this.mapBefore.current, this.mapAfter.current, this.comparisonContainer.current, {})
        this.compare.current.on('slideend', (e) => {
            this.setState({ sliderPosition: e.currentPosition })
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate() {
        this.compare.current.on('slideend', (e) => {
            this.setState({ sliderPosition: e.currentPosition })
        })
        const urlSearchParams=new URLSearchParams(window.location.search)
        urlSearchParams.set('lng', this.mapBefore.current.getCenter().lng)
        urlSearchParams.set('lat', this.mapBefore.current.getCenter().lat)
        window.history.replaceState({}, '', `${window.location.pathname}?${urlSearchParams}`)
    }

    /**
     *  
     */
    handleHideShow() {
        try {
            this.mapBefore.current.removeLayer('before-raster')
            this.mapBefore.current.removeSource('before-raster')
            this.mapBefore.current.setStyle(getMapStyle('dark'))
            setTimeout(() => {
                let firstLayer = JSON.parse(window.localStorage.getItem('firstLayer'))
                addRasterLayer(this.mapBefore.current, 'before-raster', firstLayer, true, 1000)
            }, 200)
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /**
     * 
     */
     handleMapIcon() {
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        try {
            this.mapBefore.current.setZoom(12)
            this.mapBefore.current.flyTo({
                center: [params.areaLng, params.areaLat]
            })
        } catch (error) {
            
        }
    }

    /**
     * 
     * @returns the component 
     */
    render() {
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())

        return (
            <div ref={this.comparisonContainer} id="comparison-container">
                <button onClick={this.handleHideShow} style={{ position: 'absolute', left: '250px', top: '60px', zIndex: 1000, display: 'none' }}>hide/show</button>
                <div ref={this.mapBeforeContainer} className="map-container" id='map-before' />
                <div ref={this.mapAfterContainer} className="map-container" id='map-after' />
                <Header marginTop='25px'/>
                <ComparePanel
                    firstLayer={window.localStorage.getItem('firstLayer')}
                    secondLayer={window.localStorage.getItem('secondLayer')}
                    handleMapIcon={this.handleMapIcon}
                />
                {
                    true
                        ?
                        <div id='panel-footer-container'>
                            <Tooltip title='Coordinate system' placement='top-end'>
                                <PublicIcon sx={{ color: 'white' }} fontSize='small' />
                            </Tooltip>
                            <Typography sx={{ color: 'white', fontSize: '11px', ml: '5px' }}>
                                WGS84 - EPSG:4326
                            </Typography>
                            <Tooltip title='Center of the map' placement='top-start'>
                                <FilterCenterFocusIcon sx={{ color: 'white', ml: '30px' }} fontSize='small' />
                            </Tooltip>
                            <Typography sx={{ color: 'white', fontSize: '11px', ml: '5px' }}>
                                {`${Math.abs(Number.parseFloat(Number(params.lng)).toFixed(4))}° ${Number(params.lng) > 0 ? 'E' : 'W'}`}
                            </Typography>
                            <Typography sx={{ color: 'white', fontSize: '11px', position: 'absolute', left: '253px' }}>
                                {`| ${Math.abs(Number(params.lat).toFixed(4))}° ${Number(params.lat) > 0 ? 'N' : 'S'}`}
                            </Typography>
                        </div>
                        :
                        null
                }
            </div>
        )
    }
}

export default (Compare)