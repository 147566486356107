import {
    AREAS_RETRIEVED,
    AREA_CREATED,
    AREA_DELETED,
    FAILED_NEW_AREA,
    FAILED_TO_DELETE_AREA,
    RESET_AREA_FORM,
    AREA_PUT,
    FAILED_AREA_PUT,
    EDIT_MODE_ON,
    EDIT_MODE_OFF
  } from '../actions/types';

const initialState = {
    areaList: [],
    isAreaCreated: false,
    isFailedToCreateArea: false,
    isAreaDeleted: false,
    isFailedToDeleteArea: false, 
    message: '',
    isAreaPut: false,
    isFailedToPutArea: false,
    isEditMode: false,
    editedAreaId: null
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case AREAS_RETRIEVED:
        return {
            ...state,
            areaList: action.payload
        }
      case AREA_CREATED:
        return {
          ...state,
          isAreaCreated: true,
          isFailedToCreateArea: false,
          message: action.payload
        }
      case FAILED_NEW_AREA:
        return {
          ...state,
          isAreaCreated: false,
          isFailedToCreateArea: true,
          message: action.payload
        }
      case RESET_AREA_FORM:
        return {
          ...state,
          isAreaCreated: false,
          isFailedToCreateArea: false,
          isAreaDeleted: false,
          isFailedToDeleteArea: false, 
          message: '',
          isAreaPut: false,
          isFailedToPutArea: false,
          isEditMode: false,
        }
      case AREA_DELETED:
        return {
          ...state,
          isAreaDeleted: true,
          isFailedToDeleteArea: false,
          message: ''
        }
      case FAILED_TO_DELETE_AREA:
        return {
          ...state,
          isFailedToDeleteArea: true,
          isAreaDeleted: false,
          message: ''
        }
      case AREA_PUT:
        return {
          ...state,
          isAreaPut: true,
          isFailedToPutArea: false
        }
      case FAILED_AREA_PUT:
        return {
          ...state,
          isAreaPut: false,
          isFailedToPutArea: true
        }
      case EDIT_MODE_ON:
        return {
          ...state,
          isEditMode: true,
          editedAreaId: action.payload
        }
      case EDIT_MODE_OFF:
        return {
          ...state,
          isEditMode: false,
        }
      default:
        return state;
    }
}
  




