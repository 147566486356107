import {
    OPEN_LANGUAGE,
    CLOSE_LANGUAGE,
    SET_LANGUAGE
} from '../actions/types';

const initialState = {
    language: "TR",
    isLanguageOpen: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                language: action.payload
            }
        case OPEN_LANGUAGE:
            return {
                isLanguageOpen: true,
                language:  window.localStorage.getItem("language")
            }
        case CLOSE_LANGUAGE:
            return {
                isLanguageOpen: false,
                language:  window.localStorage.getItem("language")
            }
        default:
            return {
                language:  window.localStorage.getItem("language"),
                isLanguageOpen: false
            };
    }
}

