/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'
/* Redux */
import { connect } from 'react-redux'
/* mui material components */
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGrid, enUS,  trTR} from '@mui/x-data-grid';
/* mui icons-material components */
import ClearIcon from '@mui/icons-material/Clear';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit';

class ConfirmedObjects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredObjectList: [{Name: ''}]
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        if(this.props.objectList){
            let result = this.props.objectList.filter((confirmedObject) => this.props.label==confirmedObject.Label && confirmedObject.Storage==this.props.displayedLayer.id)
            this.setState({
                filteredObjectList: [...result]
            })
        }
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.displayedLayer.id!==this.props.displayedLayer.id){
            if(this.props.objectList){
                let result = this.props.objectList.filter((confirmedObject) => this.props.label==confirmedObject.Label && confirmedObject.Storage==this.props.displayedLayer.id)
                this.setState({
                    filteredObjectList: [...result]
                })
            }
        }
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        const Location = (props) =>
        <Tooltip title={i18n.t("ConfirmedObjects.Find")} placement='left'>           
            <IconButton onClick={() => this.props.handleFlyToAnObject(props.id, 'detected')}>
                <LocationOnOutlinedIcon sx={{color: 'black'}} fontSize='small'/>
            </IconButton>
        </Tooltip>
        const Edit = (props) =>
        <Tooltip title={i18n.t("ConfirmedObjects.Edit")} placement='left'>             
            <IconButton onClick={() => this.props.handleEdit(props.id)}>
                <EditIcon color='info' fontSize='small'/>
            </IconButton>
        </Tooltip>
        const Delete = (props) =>
        <Tooltip title={i18n.t("ConfirmedObjects.Delete")} placement='left'>
            <IconButton  onClick={() => this.props.setAlert(`${i18n.t("ConfirmedObjects.DoYouWantToDelete")} ${props.id}?`, ()=>this.props.handleDelete(props.id), () => this.props.unsetAlert(), 'Delete', 'Cancel', true, 'warning')} disabled={this.props.isEditMode}>
                <ClearIcon color='error' fontSize='small'/>
            </IconButton> 
        </Tooltip>
        const columns = [
            {field: 'id', headerName: `Object Name` , width: 135},
            {field: 'location', headerName: `` , width: 50, sortable: false, disableColumnMenu: true, renderCell: (props) => Location(props)},
            {field: 'edit', headerName: `` , width: 50, sortable: false, disableColumnMenu: true, renderCell: (props) => Edit(props)},
            {field: 'delete', headerName: `` , width: 50, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
        ]
        const rows = this.state.filteredObjectList.map((object)=>(
            {
                id: `${object.Name}`
            }
        ))
        return (
            <div 
                style={{
                position: 'absolute', 
                right: 55,
                top: 60,
                bottom: 0,
                zIndex: 200, 
                minWidth: '300px', 
                height: 'fit-content',
                fontFamily: 'Poppins', 
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}> 
                    <Typography
                        sx={{
                            height: '50px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '16px',
                            fontFamily: 'Poppins',
                            color: '#121f33',
                            width: '100%'
                        }}
                    >
                        <span style={{
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'center', 
                            alignItems: 'center'}}
                        >{this.props.label}</span>
                        <IconButton onClick={this.props.handleClose} sx={{position: 'absolute', right: '3px'}}>
                            <Tooltip title={i18n.t("ConfirmedObjects.Close")}>
                                <CloseIcon />
                            </Tooltip>
                        </IconButton>
                    </Typography>
                </div>
                {
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        headerHeight={0}
                        rowsPerPageOptions={[10]}
                        sx={{
                            fontFamily: 'Poppins',
                            overflowY: 'hidden',
                            fontSize: '13px'
                        }}
                        disableSelectionOnClick={true}
                        loading={this.props.isObjectListLoading}
                        showColumnRightBorder={true}
                        autoHeight={true}
                        autoPageSize={true}
                        density='compact'
                        localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      language: state.language.language
    };
};

export default connect(mapStateToProps, {
})(ConfirmedObjects);