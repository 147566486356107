/* eslint-disable eqeqeq */
import React from 'react';
import i18n from 'i18next'

/* redux */
import { connect } from 'react-redux'
import {  getEditedMap ,getMapList,putMap, resetMapForm} from '../redux/actions/maps';
import {  resetUserForm} from '../redux/actions/users';
import { getAreaList } from '../redux/actions/areas';

/* custom components */
import { BlackCheckbox }from './CustomCheckbox'

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ClickAwayListener from '@mui/material/ClickAwayListener'

/* mui icons-material components */
import StorageIcon from '@mui/icons-material/Storage';
import LinkIcon from '@mui/icons-material/Link';
import FolderIcon from '@mui/icons-material/Folder';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LayersIcon from '@mui/icons-material/LayersRounded'

class EditMap extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            Added: '',
            IPAddress: '',
            FlightDate:'',
            loggedIn: false,
            loginFailed: false,
            FlightName:'',
            MFStitch:'',
            WFStitch: '',
            FlightPath :'',
            userGroup: [],
            listOpen: false,
            groups: [],
            PolygonData: '',
            DetectedObjects: '{}',
            Changes: '{}'
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.props.getMapList()
        window.addEventListener('keypress', (e) =>{
            if(e.key === 'Enter'){
                this.handleSubmit()
            }
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isMapPut !== this.props.isMapPut && this.props.isMapPut){
            this.props.getMapList()
        }
        if(prevProps.editedMap !== this.props.editedMap && Object.keys(this.props.editedMap).length>0){
            let editedMap={...this.props.editedMap}
            let checkedGroups=this.props.groups.map((group,i)=>({...group, checked: editedMap.UserGroup.includes(group.id)}))
            this.setState({
                groups: checkedGroups,
                userGroup: [...editedMap.UserGroup],
                MFStitch: this.props.editedMap.MFStitch,
                WFStitch: this.props.editedMap.WFStitch,
                IPAddress: this.props.editedMap.IPAddress, 
                FlightDate: this.props.editedMap.FlightDate,
                FlightName: this.props.editedMap.FlightName,
                PolygonData: JSON.stringify(this.props.editedMap.PolygonData),
                DetectedObjects: JSON.stringify(this.props.editedMap.DetectedObjects),
                Changes: JSON.stringify(this.props.editedMap.Changes)
            })
        }
    }

    /**
     * 
     */
    handleSubmit =()=>{
        this.props.putMap({
            map:{
                id: this.state.id == '' ? this.props.editedMap.id :this.state.id,
                FlightName : this.state.FlightName == '' ? this.props.editedMap.FlightName :this.state.FlightName,
                FlightDate : this.state.FlightDate == '' ? this.props.editedMap.FlightDate :this.state.FlightDate,
                MFStitch :this.state.MFStitch == '' ? this.props.editedMap.MFStitch :this.state.MFStitch,
                WFStitch :this.state.WFStitch == '' ? this.props.editedMap.WFStitch :this.state.WFStitch,
                IPAddress:this.state.IPAddress == '' ? this.props.editedMap.IPAddress : this.state.IPAddress,
                Added :this.state.Added =='' ? this.props.editedMap.Added :this.state.Added,
                FlightPath: this.props.editedMap.FlightPath,
                UserGroup: this.state.userGroup,
                PolygonData: JSON.parse(this.state.PolygonData),
                DetectedObjects: JSON.parse(this.state.DetectedObjects),
                Changes: JSON.parse(this.state.Changes)
            }, 
            token: `token ${window.localStorage.getItem('token')}`
        })
        this.props.handleClose()
        this.setState({
            id: '',
            FlightName: '',
            FlightDate: '',
            IPAddress: '',
            Added:'',
            MFStitch:'',
            WFStitch:'',
            FlightPath:'',
            userGroup: [],
            groups: [],
            listOpen: false,
            DetectedObjects: '{}',
            Changes: '{}'
        })
        setTimeout(() => {
            this.props.resetMapForm()
        }, 2000)
    }


    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /* */
    handleOpen(e) {
        this.setState({
            listOpen: !this.state.listOpen
        })
    }

    /* */
    handleClose(e) {
        this.setState({
            listOpen: false
        })
    }

    /* */
    handleCheckbox(id) {
        this.setState({
            listOpen: true
        })
        let editedGroups=[...this.state.groups]
        let checkedGroup=editedGroups.find(group=>group.id==id)
        let index=this.state.groups.findIndex(group=>group.id==id)
        let checked=checkedGroup.checked
        checkedGroup.checked=!checked
        editedGroups.splice(index, 1, checkedGroup)
        let userGroups=[]
        editedGroups.forEach(group=>group.checked?userGroups.push(group.id):'')
        this.setState({
            groups: editedGroups,
            userGroup: userGroups
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* {`Edit Map`} */}
                            {i18n.t("EditMap.EditMap")}
                        </Typography>
                        <Typography
                            sx={{
                                height: '50px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '15px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {`${"ID :"}  ${this.props.editedMap.id}`}
                        </Typography>
                        <div id='admin-panel-formcontrol-container'>
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <Select
                                        value={-1} 
                                        defaultValue={-1} 
                                        sx={{height: '42px', color: this.state.userGroup.length==0?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                        open={this.state.listOpen}
                                        onClick={this.handleOpen}
                                    >
                                        <MenuItem value={-1} disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                            {/* Select User Group */}
                                            {i18n.t("EditMap.SelectUserGroup")}
                                        </MenuItem>
                                        {
                                            this.state.groups.map((group)=>
                                                <MenuItem value={group.id} sx={{fontFamily: 'Poppins'}}>
                                                    <BlackCheckbox checked={group.checked} onChange={() => this.handleCheckbox(group.id)}/>
                                                    <Typography sx={{fontFamily: 'Poppins'}}>{group.name}</Typography>
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </ClickAwayListener>
                            </FormControl>
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='MF Stitch'>
                                <LinkIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedMap.MFStitch}`}
                                required
                                value={this.state.MFStitch}
                                onChange={this.handleChange('MFStitch')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title='WF Stitch'>
                                <LinkIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedMap.WFStitch}`}
                                required
                                value={this.state.WFStitch}
                                onChange={this.handleChange('WFStitch')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.FlightName")}>
                                <FolderIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedMap.FlightName}`}
                                required
                                value={this.state.FlightName}
                                onChange={this.handleChange('FlightName')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.FlightDate")}>
                                <DateRangeIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedMap.FlightDate}`}
                                value={this.state.FlightDate}
                                onChange={this.handleChange('FlightDate')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.PolygonData")}>
                                <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("EditMap.PolygonData")}
                                required
                                value={this.state.PolygonData}
                                onChange={this.handleChange('PolygonData')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.IPAddress")}>
                                <StorageIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedMap.IPAddress}`}
                                required
                                value={this.state.IPAddress}
                                onChange={this.handleChange('IPAddress')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.DetectedObjects")}>
                                <LayersIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${JSON.stringify(this.props.editedMap.DetectedObjects)}`}
                                required
                                value={this.state.DetectedObjects}
                                onChange={this.handleChange('DetectedObjects')}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("EditMap.Changes")}>
                                <LayersIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${JSON.stringify(this.props.editedMap.Changes)}`}
                                required
                                value={this.state.Changes}
                                onChange={this.handleChange('Changes')}
                            />
                        </div>
                        <br/>
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                // mb:'20px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("EditMap.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("EditMap.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo:state.maps.flightInfo,
        editedMap: state.maps.editedMap,
        isMapPut : state.maps.isMapPut,
        areaList: state.areas.areaList,
        groups: state.groups.groups,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { getEditedMap,getMapList,putMap,getAreaList,resetUserForm,resetMapForm })(EditMap)