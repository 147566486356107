/* eslint-disable eqeqeq */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: window.localStorage.getItem("language")=="TR"||window.localStorage.getItem("language")=="GB"?window.localStorage.getItem("language"):"TR",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        GB: {
            translation: {
            ChangeLanguage: {
                Language: "Language"
            },
            AdminPanel: {
                Groups: "Groups",
                Users: "Users",
                Areas: "Areas",
                Maps: "Maps",
                Buckets: "AWS"
            },
            ChangeDetection: {
                Save: "Save",
                Delete: "Delete",
                Cancel: "Cancel",
                ClickOnThePolygonToEditIt: "Click on the polygon to edit it.",
                DoYouWantToDeleteTheChangePolygon: "Do you want to delete the change polygon?",
                DoYouWantToRunChangeDetection: "Do you want to run Change Detection?",
                Start: "Start",
                CouldNotDetectAnyChange: "Could not detect any change.",
                Ok: "Okay"
            },
            Compare: {
                /*Nothing*/
            },
            Gallery: {
                SearchArea: "Search Area"
            },
            Login: {
                InvalidUsernameOrPassword: "Invalid username or password",
                BaykarMapping: "Baykar Mapping",
                SignIn: "Sign in",
                Username: "Username",
                Password: "Password",
                Language: "Language"
            },
            Timelapse: {
                SaveOrDelete: "Save or delete",
                Save: "Save",
                Edit: "Edit",
                Delete: "Delete",
                EditOrDelete: "Edit or Delete",
                ChangeMapStyle: "Change Map Style",
                ExitFullScreen: "Exit Full Screen",
                EnterFullScreen: "Enter Full Screen",
                TileBoundaries: "Tile Boundaries",
                Share: "Share",
                SwitchTo2d: "Switch to 2D",
                SwitchTo3d: "Switch to 3D",
                Gallery: "Gallery",
                SignOut: "Sign out",
                Grid: "Longitude, Latitude Lines",
                DoYouWantToDeleteTheAnnotation: "Do you want to delete the annotation?",
                Cancel: "Cancel",
                SuccessfullyDeleted: "Successfully deleted",
                SuccessfullyEdited: "Successfully edited",
                WrongFlyToFormat: "Coordinates should be input in decimal degrees as longitude, latitude or in the MGRS format.",
                ExampleFormat: "E.g: 32.80, 39.93 or 36S VK 83163 20439"
            },
            AddArea: {
                DoYouWantToAdd: "Do you want to add this polygon as an area?",
                DoYouWantToEdit: "You have just edited the polygon, do you want to add the updated polygon as an area?",
                Yes: "Yes",
                No: "No",
                FailedToCreate: "Failed to create",
                Save: "Save",
                Cancel: "Cancel",
                YouAreInTheEditMode: "You are in the edit mode. Click on the polygon to edit.",
                ImportArea: "Import Geojson/KML",
            },
            AddGroup: {
                FailedOperation: "Failed Operation",
                CreateUserGroup: "Create User Group",
                UserGroupName: "User Group Name",
                Submit: "Submit"
            },
            AddUser: {
                CreatedNewUser: "Created a new user",
                NotAbleToCreateUser: "Not able to create user",
                CreateUser: "Create User",
                Username: "Username",
                Password: "Password",
                SelectUserRole: "Select user role",
                Viewer: "Viewer",
                Admin: "Admin",
                SelectUserGroup: "Select user group",
                Submit: "Submit"
            },
            AdminSearchBar: {
                /* Nothing */
            },
            AlertAction: {
                /* Nothing */
            },
            AnnotationForm: {
                SaveAnnotation: "Save Annotation",
                EditAnnotation: "Edit Annotation",
                Name: "Name",
                Description: "Description",
                Area: "Area",
                Length: "Length",
                Submit: "Submit",
                Close: "Close",
                Longitude: "Longitude",
                Latitude: "Latitude"
            },
            AnnotationInfo: {
                AnnotationInfo: "Annotation Info",
                Owner: "Owner",
                Name: "Name",
                Description: "Description",
                Area: "Area",
                Length: "Length",
                Longitude: "Longitude",
                Latitude: "Latitude",
                Geojson: "Geojson",
                ShareLink: "Share Link",
                Close: "Close",
            },
            AreaForm: {
                EditArea: "Edit Area",
                CreateArea: "CreateArea",
                SelectUserGroups: "Select User Groups",
                RegionName: "Region Name",
                Terrain: "Terrain",
                ThumbnailLink: "ThumbnailLink",
                Thumbnail: "Thumbnail",
                Submit: "Submit",
                Close: "Close"
            },
            Areas: {
                /* Nothing */
            },
            AreaTable: {
                Area: "Area",
                NumberOfFlights: "# of Flights",
                Group: "Group",
                Fly: "Fly",
                Edit: "Edit",
                Delete: "Delete",
                SuccessfullyDeleted: "Successfully deleted!",
                FailedToDelete: "Failed to delete!",
                SearchArea: "Search area",
                RegionName: "Region Name"
            },
            AwsCredentials: {
                EnterCredentials: "Enter Credentials",
                Submit: "Submit",
                Close: "Close"
            },
            BasicAlert: {
                Close: "Close"
            },
            BucketCors: {
                LoadingBucketCors: "Loading Bucket Cors",
                BucketCORS: "Bucket CORS",
                WrongFormat: "Wrong format",
                PleaseInputInTheCorrectFormat: "Please input in the correct format",
                Default: "Default",
                Submit: "Submit",
                Close: "Close"
            },
            BucketPolicy: {
                LoadingBucketPolicy: "Loading Bucket Policy",
                BucketPolicy: "Bucket Policy",
                WrongFormat: "Wrong format",
                PleaseInputInTheCorrectFormat: "Please input in the correct format",
                Default: "Default",
                Submit: "Submit",
                Close: "Close"
            },
            Buckets: {
                CreatingBuckets: "Creating Bucket",
                LoadingBuckets: "Loading Buckets"
            },
            BucketTable: {
                DoYouWantToDelete: "Do you want to delete",
                Delete: "Delete",
                Cancel: "Cancel",
                Name: "Name",
                Date: "Date",
                Policy: "Policy",
                EnterCredentials: "Enter Credentials"
            },
            ChangeDetectionForm: {
                ChangeDetectionSettings: "Change Detection Settings",
                DoYouWantToRunChangeDetection: "Do you want to run change detection?",
                Threshold: "Threshold",
                Start: "Start",
                Cancel: "Cancel"
            },
            ChangeDetectionPanel: {
                DetectedChanges: "Detected Changes"
            },
            ChangeHosts: {
                Saved: "Saved",
                NotSaved: "Not Saved",
                Save: "Save"
            },
            ChangeInfo: {
                ChangeInfo: "Change Info",
                Name: "Name",
                Description: "Description",
                Since: "Since",
                ShareLink: "Share Link",
                Close: "Close"
            },
            ComparePanel: {
                FlyToMapArea: "Fly to map area",
                Difftool: "Difftool"
            },
            ConfirmedObjectForm: {
                Edit: "Edit",
                Name: "Name",
                Description: "Description",
                Reset: "Reset",
                Copy: "Copy",
                Submit: "Submit",
                Close: "Close"
            },
            ConfirmedObjects: {
                Find: "Find",
                Confirm: "Confirm",
                Delete: "Delete",
                DoYouWantToDelete: "Do you want to delete",
                Close: "Close"
            },
            ControlBar:{
                Pause: "Pause",
                Play: "Play",
                Reset: "Reset",
                PlaybackSpeed: "Playback Speed"
            },
            CopySuccessNotif: {
                ShareYourMapViaTheLink: "Share your map via the link",
                Copied: "Copied"
            },
            CreateBucket: {
                CreateS3Bucket: "Create S3 Bucket",
                BucketName: "Bucket Name",
                SelectRegion: "Select Region",
                Submit: "Submit"
            },
            CustomCheckBox: {
                /* nothing */
            },
            GallerySearchBar: {
                /* nothing */
            },
            /* Not used */
            DataGrid: `{
                "noRowsLabe": "No rows",
                "noResultsOverlayLabel": "No results found.",
                "errorOverlayDefaultLabel": "An error occurred.",
                "columnMenuLabel": "Menu",
                "columnMenuShowColumns": "Show columns",
                "columnMenuManageColumns": "Manage columns",
                "columnMenuFilter": "Filter",
                "columnMenuHideColumn": "Hide column",
                "columnMenuUnsort": "Unsort",
                "columnMenuSortAsc": "Sort by ASC",
                "columnMenuSortDesc": "Sort by DESC",
                "columnHeaderFiltersLabel": "Show Filters",
                "columnHeaderSortIconLabel": "Sort",
                "columnsPanelTextFieldLabel": "Find column",
                "columnsPanelTextFieldPlaceholder": "Column title",
                "columnsPanelDragIconLabel": "Reorder column",
                "columnsPanelShowAllButton": "Show all",
                "columnsPanelHideAllButton": "Hide all"
            }`,
            DataSetCard: {
                /* nothing */
            },
            DateDropdown: {
                /* nothing */
            },
            DetectionObjects: {
                Find: "Find",
                Confirm: "Confirm",
                Delete: "Delete",
                DoYouWantToDelete: "Do you want to delete",
                Close: "Close"
            },
            DetectionForm: {
                Confirm: "Confirm",
                Description: "Description",
                Submit: "Submit",
                Close: "Close"
            },
            EditGroup: {
                EditGroup: "Edit group",
                Submit: "Submit",
                Close: "Close"
            },
            EditMap: {
                EditMap: "Edit Map",
                SelectUserGroup: "Select User Group",
                FlightName: "Flight Name",
                FlightDate: "Flight Date",
                PolygonData: "Polygon Data",
                IPAddress: "IP Address",
                Submit: "Submit",
                Close: "Close",
                Changes: "Changes, JSON",
                DetectedObjects: "Detected Objects, JSON",
            },
            EditUser: {
                EditUser: "Edit User",
                Admin: "Admin",
                Viewer: "Viewer",
                SelectUserRole: "Select User Role",
                Submit: "Submit",
                Close: "Close"
            },
            FlightInfo: {
                FlightInfo: "Flight Info",
                NotFound: "Not found"
            },
            GeojsonForm: {
                TypeInGeojson: "Type in Geojson",
                TypeInKml: "Type in KML",
                PleaseInputInTheCorrectFormat: "Please input in the correct format",
                Import: "Import",
                Close: "Close"
            },
            Groups: {
                /* nothing */
            },
            GroupsTable: {
                Name: "Name",
                Edit: "Edit",
                Delete: "Delete",
                SuccessfullyDeleted: "Successfully deleted",
                FailedToDelete: "Failed to delete",
                EditedTheGroupsName: "Edited the groups name",
                FailedToEditTheGroupsName: "Failed to edit the groups name"
            },
            Header: {
                BaykarMapping: "Baykar Mapping",
                Gallery: "Gallery",
                Options: "Options",
                User: "User",
                Admin: "Admin",
                Viewer: "Viewer",
                AdminPanel: "Admin Panel",
                Load: "Load",
                Logout: "Logout",
                Language: "Language",
                Hosts: "Hosts"
            },
            Loading: {
                /* nothing */
            },
            MapFeatureList:{
                Import: "Import",
                ExpandTheList: "Expand the list",
                CollapseTheList: "Collapse the list",
                Save: "Save"
            },
            MapForm: {
                AddNewLayer: "Add New Layer",
                SelectUserGroup: "Select User Group",
                StitchedMFTileDirectory: "Stitched MF Tile Directory",
                SelectMFTileDirectory: "Select MF Tile Directory",
                StitchedWFTileDirectory: "Stitched WF Tile Directory",
                SelectWFTileDirectory: "Select WF Tile Directory",
                Submit: "Submit",
                Close: "Close"
            },
            MapNewForm: {
                AddNewLayer: "Add New Layer",
                SelectUserGroup: "Select User Group",
                FlightName: "Flight Name",
                Layers: "Layers",
                PolygonData: "Polygon Data",
                FlightDate: "Flight Date",
                YYYYMMDD: "YYYY-MM-DD",
                Submit: "Submit",
                Close: "Close"
            },
            Maps: {
                /* nothing */
            },
            MapTable: {
                Group: "Group",
                Date: "Date",
                Region: "Region",
                SourceIP: "Source IP",
                Added: "Added",
                Links: "Links",
                Edit: "Edit",
                Delete: "Delete",
                SuccessfullyAdded: "Successfully added",
                FailedToAdd: "Failed to add",
                EditedTheMap: "Edited the map",
                SuccessfullyDeleted: "Successfully deleted",
                FailedToDelete: "Failed to delete",
                SearchByRegion: "Search by region",
                RegionName: "Region Name"
            },
            PanelFooter: {
                CoordinateSystem: "Coordinate System",
                CursorLocation: "Cursor Location: lng | lat",
                MGRS: "Cursor Location: MGRS",
                ZoomLevel: "Zoom level",
                Elevation: "Elevation",
            },
            StorageTable: {
                Info: "Info",
                Add: "Add",
                StorageIP: "Storage IP",
                FlightsDirectory: "Flights Directory",
                Get: "GET"
            },
            TimelapsePanel:{
                HideHighResMap: "Hide High Res Map",
                DisplayHighResMap: "Display High Res Map",
                FlyToMapArea: "Fly to map area",
                ImportAnnotations: "Import annotations",
                DisableTimelapseControl: "Disable timelapse control",
                EnableTimelapseControl: "Enable timelapse control",
                Compare: "Compare",
                Previous: "Previous",
                Next: "Next",
                Annotations: "Annotations",
                Detections: "Detections",
                WhatIsNew: "What is new",
                Objects: "Objects",
                FlyToTheCoordinate: "Fly to the coordinate",
                DecimalDegreesWithCommaInBetweenLongitudeAndLatitudeOrMGRS: "Decimal Degrees with comma in between longitude and latitude or MGRS"
            },
            UploadAnnotation: {
                ImportAnnotation: "Import Annotation",
                Import: "Import",
                Close: "Close",
                Save: "Save",
                Cancel: "Cancel"
            },
            Users: {
                /* nothing */
            },
            UserTable: {
                Group: "Group",
                User: "User",
                Type: "Type",
                Edit: "Edit",
                Delete: "Delete",
                Admin: "Admin",
                Viewer: "Viewer",
                SuccessfullyDeleted: "Successfully deleted",
                FailedToDelete:"Failed to delete",
                EditedTheUser:"Edited the user",
                FailedToEditTheUser: "Failed to edit the user"
            },
            WhatsnewForm: {
                EditWhatsnew: "Edit Whatsnew",
                SaveWhatsnew: "Save Whatsnew",
                Name: "Name",
                Description: "Description",
                Submit: "Submit",
                Close: "Close",
                RunChangeDetection: "Run Change Detection"
            },
            WhereelseForm: {
                Confirm: "Confirm",
                Reset: "Reset",
                Name: "Name",
                Description: "Description",
                Copy: "Copy",
                Submit: "Submit",
                Close: "Close",
                DoYouWantToStartSearchingFor: "Do you want to start searching for",
                Start: "Start",
                Redraw: "Redraw"
            },
            WhereelseObjects: {
                Find: "Find",
                Confirm: "Confirm",
                Delete: "Delete",
                ObjectName: "Object Name",
                ConfidenceLevels: "Confidence Levels",
                Close: "Close"
            },
            WhereelseResult: {
                Find: "Find",
                Confirm: "Confirm",
                Delete: "Delete",
                DoYouWantToDelete: "Do you want to delete",
                ObjectName: "Object Name",
                Close: "Close"
            },
            WhereelseSearchForm: {
                WhereelseSettings: "Whereelse settings",
                DoYouWantToRunWhereelse: "Set the center and the radius of where else circle in km.",
                Radius: "Radius of the query circle in km",
                QueryCircleRadius: "Query Circle Radius",
                InputShouldBeANumber: "Radius should be a number.",
                Center: "Center of the where else circle",
                CenterInputShouldBeInDecimal: "Center coordinates should be input in decimal degrees as longitude, latitude.",
            }
        }},
        TR: {
            translation: {
            ChangeLanguage: {
                Language: "Dil"
            },
            AdminPanel: {
                Groups: "Gruplar",
                Users: "Kullanıcılar",
                Areas: "Bölgeler",
                Maps: "Harİtalar",
                Buckets: "AWS"
            },
            ChangeDetection: {
                Save: "Kaydet",
                Delete: "Sil",
                Cancel: "İptal",
                ClickOnThePolygonToEditIt: "Düzenlemek için poligonun üzerine tıkla.",
                DoYouWantToDeleteTheChangePolygon: "Poligonu silmek istiyor musunuz?",
                DoYouWantToRunChangeDetection: "Değişiklik tespitini başlatmak istiyor musunuz?",
                Start: "Başlat",
                CouldNotDetectAnyChange: "Herhangi bir değişiklik tespit edilmedi.",
                Ok: "Tamam"
            },
            Compare: {
                /*Nothing*/
            },
            Gallery: {
                SearchArea: "Bölge Ara"
            },
            Login: {
                InvalidUsernameOrPassword: "Geçersiz kullanıcı ismi ya da şifre.",
                BaykarMapping: "Baykar Haritalama",
                SignIn: "Giriş yap",
                Username: "Kullanıcı adı",
                Password: "Şifre",
                Language: "Dil"
            },
            Timelapse: {
                SaveOrDelete: "Kaydet ya da sil",
                Save: "Kaydet",
                Edit: "Düzenle",
                Delete: "Sil",
                EditOrDelete: "Düzenle ya da sil",
                ChangeMapStyle: "Harita stilini değiştir",
                ExitFullScreen: "Tam ekrandan çık",
                EnterFullScreen: "Tam ekran",
                TileBoundaries: "Tile sınırları",
                Share: "Paylaş",
                SwitchTo2d: "2 Boyutlu",
                SwitchTo3d: "3 Boyutlu",
                Gallery: "Galeri",
                SignOut: "Çıkış yap",
                Grid: "Boylam, Enlem çizgileri",
                DoYouWantToDeleteTheAnnotation: "Açıklamayı silmek istiyor musunuz?",
                Cancel: "İptal",
                SuccessfullyDeleted: "Başarıyla silindi",
                SuccessfullyEdited: "Başarıyla düzenlendi",
                WrongFlyToFormat: "Koordinatlar ondalık derecelerde enlem, boylam şeklinde veya Askeri Grid Referans Sistemi (MGRS) formatında girilmelidir.",
                ExampleFormat: "Örnek: 32.80, 39.93 veya 36S VK 83163 20439"
            },
            AddArea: {
                DoYouWantToAdd: "Poligonu bölge olarak eklemek istiyor musunuz?",
                DoYouWantToEdit: "Poligonu değiştirdiniz, değiştirilmiş poligonu kaydetmek istiyor musunuz?",
                Yes: "Evet",
                No: "Hayır",
                FailedToCreate: "Oluşturulamadı",
                Save: "Kaydet",
                Cancel: "İptal",
                YouAreInTheEditMode: "Düzenleme modundasınız. Düzenlemek için poligonun üzerine tıklayın.",
                ImportArea: "Geojson/KML aktar",
            },
            AddGroup: {
                FailedOperation: "Hata",
                CreateUserGroup: "Yeni Kullanıcı Grubu",
                UserGroupName: "Grup İsmi",
                Submit: "Ekle"
            },
            AddUser: {
                CreatedNewUser: "Kullanıcı oluşturuldu",
                NotAbleToCreateUser: "Hata",
                CreateUser: "Yeni Kullanıcı",
                Username: "Kullanıcı ismi",
                Password: "Şifre",
                SelectUserRole: "Kullanıcı Rolü seçin",
                Viewer: "İzleyici",
                Admin: "Yönetici",
                SelectUserGroup: "Kullanıcı grubu seçin",
                Submit: "Ekle"
            },
            AdminSearchBar: {
                /* Nothing */
            },
            AlertAction: {
                /* Nothing */
            },
            AnnotationForm: {
                SaveAnnotation: "Yeni Açıklama",
                EditAnnotation: "Açıklamayı Düzenle",
                Name: "Açıklama adı",
                Description: "Detaylı açıklama",
                Area: "Alan",
                Length: "Uzunluk",
                Submit: "Kaydet",
                Close: "Kapat",
                Longitude: "Boylam",
                Latitude: "Enlem"
            },
            AnnotationInfo: {
                AnnotationInfo: "Açıklama Bilgisi",
                Owner: "Oluşturan",
                Name: "Açıklama adı",
                Description: "Detaylı açıklama",
                Area: "Alan",
                Length: "Uzunluk",
                Longitude: "Boylam",
                Latitude: "Enlem",
                Geojson: "Geojson",
                ShareLink: "Paylaşım Bağlantısı",
                Close: "Kapat",
            },
            AreaForm: {
                EditArea: "Bölgeyi Düzenle",
                CreateArea: "Bölge Oluştur",
                SelectUserGroups: "Kullanıcı Grubu Seç",
                RegionName: "Bölge adı",
                Terrain: "Arazi",
                ThumbnailLink: "Küçük Resim Linki",
                Thumbnail: "Küçük Resim",
                Submit: "Kaydet",
                Close: "Kapat"
            },
            Areas: {
                /* Nothing */
            },
            AreaTable: {
                Area: "Bölge",
                NumberOfFlights: "Uçuş Sayısı",
                Group: "Grup",
                Fly: "Git",
                Edit: "Düzenle",
                Delete: "Sil",
                SuccessfullyDeleted: "Başarıyla silindi!",
                FailedToDelete: "Silinmedi!",
                SearchArea: "Bölge ara",
                RegionName: "Bölge Adı"
            },
            AwsCredentials: {
                EnterCredentials: "Kullanıcı Bilgileri",
                Submit: "Gönder",
                Close: "Kapat"
            },
            BasicAlert: {
                Close: "Kapat"
            },
            BucketCors: {
                LoadingBucketCors: "Bucket Cors yükleniyor",
                BucketCORS: "Bucket CORS",
                WrongFormat: "Yanlış format",
                PleaseInputInTheCorrectFormat: "Lütfen doğru formatta yazın.",
                Default: "Varsayılan",
                Submit: "Gönder",
                Close: "Kapat"
            },
            BucketPolicy: {
                LoadingBucketPolicy: "Bucket Policy yükleniyor",
                BucketPolicy: "Bucket Policy",
                WrongFormat: "Yanlıi format",
                PleaseInputInTheCorrectFormat: "Lütfen doğru formatta yazın.",
                Default: "Varsayılan",
                Submit: "Gönder",
                Close: "Kapat"
            },
            Buckets: {
                CreatingBuckets: "Bucket oluşturuluyor",
                LoadingBuckets: "Bucketlar yükleniyor"
            },
            BucketTable: {
                DoYouWantToDelete: "Silmek istiyor musunuz",
                Delete: "Sil",
                Cancel: "İptal",
                Name: "Adı",
                Date: "Tarih",
                Policy: "Policy",
                EnterCredentials: "Kullanıcı Bilgileri"
            },
            ChangeDetectionForm: {
                ChangeDetectionSettings: "Değişiklik Tespiti Ayarları",
                DoYouWantToRunChangeDetection: "Değişiklik tespitini başlatmak istiyor musunuz?",
                Threshold: "Eşik",
                Start: "Başlat",
                Cancel: "İptal"
            },
            ChangeDetectionPanel: {
                DetectedChanges: "Değişiklikler"
            },
            ChangeHosts: {
                Saved: "Kaydedildi",
                NotSaved: "Kaydedilmedi",
                Save: "Kaydet"
            },
            ChangeInfo: {
                ChangeInfo: "Değişiklik Bilgisi",
                Name: "Değişiklik adı",
                Description: "Açıklama",
                Since: "Tarihinden beri",
                ShareLink: "Paylaşım Bağlantısı",
                Close: "Kapat"
            },
            ComparePanel: {
                FlyToMapArea: "Harita Bölgesine Git",
                Difftool: "Karşılaştırma"
            },
            ConfirmedObjectForm: {
                Edit: "Düzenle",
                Name: "Adı",
                Description: "Açıklama",
                Reset: "Sıfırla",
                Copy: "Kopyala",
                Submit: "Kaydet",
                Close: "Kapat"
            },
            ConfirmedObjects: {
                Find: "Bul",
                Confirm: "Onayla",
                Delete: "Sil",
                DoYouWantToDelete: "Silmek istiyor musunuz: ",
                Close: "Kapat"
            },
            ControlBar:{
                Pause: "Durdur",
                Play: "Oynat",
                Reset: "Başa al",
                PlaybackSpeed: "Oynatma hızı"
            },
            CopySuccessNotif: {
                ShareYourMapViaTheLink: "Link üzerinden haritanızı paylaşın",
                Copied: "Kopyalandı"
            },
            CreateBucket: {
                CreateS3Bucket: "S3 Bucket Oluştur",
                BucketName: "Bucket Adı",
                SelectRegion: "Bölge Seç",
                Submit: "Gönder"
            },
            CustomCheckBox: {
                /* nothing */
            },
            GallerySearchBar: {
                /* nothing */
            },
            /* Not used */
            DataGrid: `{
                "noRowsLabe": "Satır yok",
                "noResultsOverlayLabel": "Sonuç bulunamadı",
                "errorOverlayDefaultLabel": "Hata oluştu",
                "columnMenuLabel": "Menü",
                "columnMenuShowColumns": "Kolonları göster",
                "columnMenuManageColumns": "Kolonları yönet",
                "columnMenuFilter": "Filtrele",
                "columnMenuHideColumn": "Kolonu gizle",
                "columnMenuUnsort": "Sıralamayı kaldır",
                "columnMenuSortAsc": "Küçükten Büyüğe",
                "columnMenuSortDesc": "Büyükten Küçüğe",
                "columnHeaderFiltersLabel": "Filtreleri göster",
                "columnHeaderSortIconLabel": "Sırala",
                "columnsPanelTextFieldLabel": "Kolonu bul",
                "columnsPanelTextFieldPlaceholder": "Kolon başlığı",
                "columnsPanelDragIconLabel": "Kolonu tekrar sırala",
                "columnsPanelShowAllButton": "Hepsini göster",
                "columnsPanelHideAllButton": "Hepsini gizle"
            }`,
            DataSetCard: {
                /* nothing */
            },
            DateDropdown: {
                /* nothing */
            },
            DetectionObjects: {
                Find: "Bul",
                Confirm: "Onayla",
                Delete: "Sil",
                DoYouWantToDelete: "Silmek istiyor musunuz: ",
                Close: "Kapat"
            },
            DetectionForm: {
                Confirm: "Onayla",
                Description: "Açıklama",
                Submit: "Kaydet",
                Close: "Kapat"
            },
            EditGroup: {
                EditGroup: "Grubu Düzenle",
                Submit: "Kaydet",
                Close: "Kapat"
            },
            EditMap: {
                EditMap: "Haritayı Düzenle",
                SelectUserGroup: "Kullanıcı grubu seç",
                FlightName: "Uçuş adı",
                FlightDate: "Uçuş tarihi",
                PolygonData: "Poligon Verisi",
                IPAddress: "IP Adresi",
                Submit: "Kaydet",
                Close: "Kapat",
                Changes: "Değişiklikler, JSON",
                DetectedObjects: "Nesne Tespitleri, JSON"
            },
            EditUser: {
                EditUser: "Kullanıcıyı Düzenle",
                Admin: "Yönetici",
                Viewer: "İzleyici",
                SelectUserRole: "Kullanıcı Rolü Seçin",
                Submit: "Kaydet",
                Close: "Kapat"
            },
            FlightInfo: {
                FlightInfo: "Uçuş Bilgisi",
                NotFound: "Bulunamadı"
            },
            GeojsonForm: {
                TypeInGeojson: "Geojson girin",
                TypeInKml: "KML girin",
                PleaseInputInTheCorrectFormat: "Lütfen doğru format kullanın.",
                Import: "Aktar",
                Close: "Kapat"
            },
            Groups: {
                /* nothing */
            },
            GroupsTable: {
                Name: "Adı",
                Edit: "Düzenle",
                Delete: "Sil",
                SuccessfullyDeleted: "Başarıyla silindi",
                FailedToDelete: "Silinemedi",
                EditedTheGroupsName: "Grup adı değiştirildi",
                FailedToEditTheGroupsName: "Grup adı değiştirilemedi"
            },
            Header: {
                BaykarMapping: "Baykar Haritalama",
                Gallery: "Galeri",
                Options: "Seçenekler",
                User: "Kullanıcı",
                Admin: "Yönetici",
                Viewer: "İzleyici",
                AdminPanel: "Yönetici Konsolu",
                Load: "Yükle",
                Logout: "Çıkış yap",
                Language: "Dil",
                Hosts: "Sunucular"
            },
            Loading: {
                /* nothing */
            },
            MapFeatureList:{
                Import: "İçe aktar",
                ExpandTheList: "Listeyi göster",
                CollapseTheList: "Listeyi gizle",
                Save: "Kaydet"
            },
            MapForm: {
                AddNewLayer: "Yeni Harita Ekle",
                SelectUserGroup: "Kullanıcı grubu seç",
                StitchedMFTileDirectory: "Stitched MF Tile Klasörü",
                SelectMFTileDirectory: "MF Klasör yolu seç",
                StitchedWFTileDirectory: "Stitched MF Tile Klasörü",
                SelectWFTileDirectory: "WF Klasör yolu seç",
                Submit: "Ekle",
                Close: "Kapat"
            },
            MapNewForm: {
                AddNewLayer: "Yeni Harita Ekle",
                SelectUserGroup: "Kullanıcı grubu seç",
                FlightName: "Uçuş Adı",
                Layers: "Katmanlar",
                PolygonData: "Poligon verisi",
                FlightDate: "Uçuş tarihi",
                YYYYMMDD: "YYYY-AA-GG",
                Submit: "Ekle",
                Close: "Kapat"
            },
            Maps: {
                /* nothing */
            },
            MapTable: {
                Group: "Grup",
                Date: "Tarih",
                Region: "Bölge",
                SourceIP: "IP",
                Added: "Eklendi",
                Links: "Linkler",
                Edit: "Düzenle",
                Delete: "Sil",
                SuccessfullyAdded: "Başarıyla eklendi",
                FailedToAdd: "Eklenemedi",
                EditedTheMap: "Harita düzenlendi",
                SuccessfullyDeleted: "Başarıyla silindi",
                FailedToDelete: "Silinemedi",
                SearchByRegion: "Bölgeye göre ara",
                RegionName: "Bölge Adı"
            },
            PanelFooter: {
                CoordinateSystem: "Koordinat Sistemi",
                CursorLocation: "İmlecin Pozisyonu: Boylam | Enlem",
                MGRS: "İmlecin Pozisyonu: MGRS",
                ZoomLevel: "Yakınlaştırma Seviyesi",
                Elevation: "Yükselti"
            },
            StorageTable: {
                Info: "Bilgi",
                Add: "Ekle",
                StorageIP: "Bellek IP",
                FlightsDirectory: "Uçuşlar Klasörü",
                Get: "LİSTELE"
            },
            TimelapsePanel:{
                HideHighResMap: "HD Haritayı Gizle",
                DisplayHighResMap: "HD Haritayı Göster",
                FlyToMapArea: "Harita bölgesine git",
                ImportAnnotations: "İçeri açıklama aktar",
                DisableTimelapseControl: "Hızlandırılmış gösterimi kapat",
                EnableTimelapseControl: "Hızlandırılmış geçişi aç",
                Compare: "Karşılaştır",
                Previous: "Önceki",
                Next: "Sonraki",
                Annotations: "Açıklamalar",
                Detections: "Tespitler",
                WhatIsNew: "Değişiklikler",
                Objects: "Nesneler",
                FlyToTheCoordinate: "Koordinata git",
                DecimalDegreesWithCommaInBetweenLongitudeAndLatitudeOrMGRS: "ondalık derecelerde enlem ve boylam arasında virgül olacak şekilde veya Askeri Grid Referans Sistemi (MGRS)"
            },
            UploadAnnotation: {
                ImportAnnotation: "Açıklama Aktar",
                Import: "Aktar",
                Close: "Kapat",
                Save: "Kaydet",
                Cancel: "İptal"
            },
            Users: {
                /* nothing */
            },
            UserTable: {
                Group: "Grup",
                User: "Kullanıcı",
                Type: "Yetki",
                Edit: "Düzenle",
                Delete: "Sil",
                Admin: "Yönetici",
                Viewer: "İzleyici",
                SuccessfullyDeleted: "Başarıyla silindi",
                FailedToDelete:"Silinemedi",
                EditedTheUser:"Kullanıcı düzenlendi",
                FailedToEditTheUser: "Kullanıcı düzenlenmedi"
            },
            WhatsnewForm: {
                EditWhatsnew: "Değişikliği Düzenle",
                SaveWhatsnew: "Değiğişikliği Kaydet",
                Name: "Adı",
                Description: "Açıklama",
                Submit: "Kaydet",
                Close: "Kapat",
                RunChangeDetection: "Değişiklik Tespitini Başlat"
            },
            WhereelseForm: {
                Confirm: "Onayla",
                Reset: "Sıfırla",
                Name: "Adı",
                Description: "Açıklama",
                Copy: "Kopyala",
                Submit: "Kaydet",
                Close: "Kapat",
                DoYouWantToStartSearchingFor: "Aramayı başlatmak istiyor musunuz ",
                Start: "Başlat",
                Redraw: "Yeniden Çiz"
            },
            WhereelseObjects: {
                Find: "Ara",
                Confirm: "Onayla",
                Delete: "Sil",
                ObjectName: "Nesne adı",
                ConfidenceLevels: "Güven Seviyesi",
                Close: "Kapat"
            },
            WhereelseResult: {
                Find: "Ara",
                Confirm: "Onayla",
                Delete: "Sil",
                DoYouWantToDelete: "Silmek istiyor musunuz",
                ObjectName: "Nesne Adı",
                Close: "Kapat"
            },
            WhereelseSearchForm: {
                WhereelseSettings: "Benzerini Arama Ayarları",
                DoYouWantToRunWhereelse: "Arama çemberinin merkezini belirleyin, ve yarıçapını km cinsinden girin.",
                Radius: "Arama yapılacak alanın km cinsinden yarıçapı",
                QueryCircleRadius: "Arama Çemberinin Yarıçapı",
                InputShouldBeANumber: "Yarıçap bir sayı olmalı.",
                Center: "Arama çemberinin merkezi",
                CenterInputShouldBeInDecimal: "Merkezin koordinatları ondalık derecelerde enlem, boylam şeklinde olmalıdır",
            }
        }}
}})


export default i18n;