/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'
/* configs */
import { IS_ATLAS_ML } from '../configs/hosts' 
/* helpers */
import { getMGRS } from '../helpers/mgrs'
import tokml from '../helpers/tokml';
/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
/* mui icons-material components */
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ShareIcon from '@mui/icons-material/Share';
import PlaceIcon from '@mui/icons-material/Place';
/* Redux */
import { getFlightInfo, getMapGeojson } from '../redux/actions/maps';

/* Env variables */
// const SERVER = process.env.REACT_APP_DJANGO_SERVER

class AnnotationInfo extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            shareLink: '',
            geometry: ""
        }

    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        let {id, ...sharedObject} = this.props.data
        this.setState({
            // shareLink: btoa(JSON.stringify(sharedObject)),
            shareLink: btoa(unescape(encodeURIComponent(JSON.stringify(sharedObject)))),
            geometry: this.props.data.Geojson.geometry
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * 
     */
    handleMap = () => {
        this.props.getMapGeojson(this.props.flightPath, this.props.flightName)
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                    <div style={{
                        position: 'absolute', 
                        right: 55,
                        top: 60,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px'
                    }}>
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '300px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                    >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%'
                            }}> 
                                <Typography
                                    sx={{
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        fontFamily: 'Poppins',
                                        color: '#121f33',
                                        ml: '55px'
                                    }}
                                >
                                    {/* Annotation Info */}
                                    {i18n.t("AnnotationInfo.AnnotationInfo")}
                                </Typography>
                                <IconButton onClick={this.props.handleClose} sx={{
                                    
                                }}>
                                    <Tooltip 
                                        // title='Close'
                                        title={i18n.t("AnnotationInfo.Close")}
                                    >
                                        <CloseIcon />
                                    </Tooltip>
                                </IconButton>
                            </div>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip title='Id'>
                                    <FingerprintIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.data.id}
                                    id={`${this.props.data.id}-id`}
                                >
                                </InputBase>
                                {/* <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-id`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton> */}
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Owner'
                                    title={i18n.t("AnnotationInfo.Owner")}
                                >
                                    <PersonIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.data.Owner?this.props.data.Owner:''}
                                    id={`${this.props.data.Owner}-owner`}
                                >
                                </InputBase>
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Name'
                                    title={i18n.t("AnnotationInfo.Name")}
                                >
                                    <AddLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.data.Name}
                                    id={`${this.props.data.id}-name`}
                                >
                                </InputBase>
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Description'
                                    title={i18n.t("AnnotationInfo.Description")}
                                >
                                    <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.data.Description}
                                    id={`${this.props.data.id}-description`}
                                >
                                </InputBase>
                            </div>
                            <br></br>
                            {
                                this.state.geometry.type!="Point"?
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Area km²'
                                        title={`${i18n.t("AnnotationInfo.Area")} km²`}
                                    >
                                        <CropSquareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.data.Coverage} km²`}
                                        id={`${this.props.data.id}-coverage`}
                                    >
                                    </InputBase>
                                </div>
                                :
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Longitude'
                                        title={i18n.t("AnnotationInfo.Longitude")}
                                    >
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.data.Geojson.geometry.coordinates[0]}`}
                                        id={`${this.props.data.id}-longitude`}
                                    >
                                    </InputBase>
                                </div>
                            }
                            <br></br>
                            {
                                this.state.geometry.type!="Point"?
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Length km'
                                        title={`${i18n.t("AnnotationInfo.Length")} km`}
                                    >
                                        <CropSquareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.data.Length} km`}
                                        id={`${this.props.data.id}-length`}
                                    >
                                    </InputBase>
                                </div>
                                :
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Latitude'
                                        title={i18n.t("AnnotationInfo.Latitude")}
                                    >
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${this.props.data.Geojson.geometry.coordinates[1]}`}
                                        id={`${this.props.data.id}-latitude`}
                                    >
                                    </InputBase>
                                </div>
                            }
                            {
                                this.state.geometry.type=='Point'?
                                <br></br>:
                                null
                            }
                            {
                                this.state.geometry.type!="Point"?
                                null
                                :
                                <div
                                id='login-page-username-container'
                                >
                                    <Tooltip title='MGRS'>
                                        <PlaceIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={`${getMGRS(this.props.data.Geojson.geometry.coordinates[0], this.props.data.Geojson.geometry.coordinates[1])}`}
                                        id={`${this.props.data.id}-MGRS`}
                                    >
                                    </InputBase>
                                </div>
                            }
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip title='Geojson'>
                                    <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={JSON.stringify(this.props.data.Geojson)}
                                    id={`${this.props.data.id}-geojson`}
                                >
                                </InputBase>
                                <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-geojson`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                            <div
                                    id='login-page-username-container'
                                >
                                    <Tooltip 
                                        // title='Area km²'
                                        title='KML'
                                    >
                                        <CropSquareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        value={this.props.data.Geojson?tokml(this.props.data.Geojson):""}
                                        id={`${this.props.data.id}-kml`}
                                    >
                                    </InputBase>
                                    <IconButton 
                                    onClick={() => this.copyToClipboard(`${this.props.data.id}-kml`)}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Share Link'
                                    title={i18n.t("AnnotationInfo.ShareLink")}
                                >
                                    <ShareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.state.shareLink}
                                    id={`${this.props.data.id}-link`}
                                >
                                </InputBase>
                                <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-link`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                            {
                                IS_ATLAS_ML
                                ?
                                <Button
                                    sx={{
                                        width: '91%',
                                        height: '37px',
                                        mb:'10px',
                                        background: '#1aaa55',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#168f48',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={this.props.handleWhereelse}
                                    type='submit'
                                >
                                    Where Else?
                                </Button>
                                :
                                null
                            }
                        </Box>
                    </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo: state.maps.flightInfo,
        flightName: state.maps.flightName,
        processedDirs: state.maps.processedDirs,
        /*AtlasML*/
        atlasML: state.hosts.atlasML
    }
}

export default connect(mapStateToProps, {getFlightInfo, getMapGeojson})(AnnotationInfo)