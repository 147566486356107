/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { getAnnotationList } from '../redux/actions/annotation';
import { getWhatsnewList } from '../redux/actions/whatsnew';
import { getObjectList } from '../redux/actions/object';

/* custom components */
import PanelFooter from './PanelFooter'
import ControlBar from './ControlBar'
import LocationSearchBar from './LocationSearchBar'

/* mui material components */
import Drawer from '@mui/material/Drawer'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

/* mui icons-material components */
import LayersIcon from '@mui/icons-material/LayersRounded'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import CompareIcon from '@mui/icons-material/Compare'
import MapFeatureList from './MapFeatureList'
import DownloadIcon from '@mui/icons-material/Download';
import DateDropdown from './DateDropdown';

/* assets */ 

const BASE_URL = process.env.REACT_APP_BASE_URL

class TimelapsePanel extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            collapseLayerList: false,
            collapseAnnotationList: false,
            collapseObjectList: false,
            collapseWhatsnewList: false,
            isDropdownOpen: false
        }

        /* Function bindings here */
        this.handleCollapseRasters = this.handleCollapseRasters.bind(this)
        this.handleCollapseAnnotations = this.handleCollapseAnnotations.bind(this)
        this.handleCollapseObjects = this.handleCollapseObjects.bind(this)
        this.handleCollapseWhatsnews = this.handleCollapseWhatsnews.bind(this)
        this.goTo = this.goTo.bind(this)
        this.handleDropdown = this.handleDropdown.bind(this)
        this.handleCloseDropdown = this.handleCloseDropdown.bind(this)
        this.handleClickOnDate = this.handleClickOnDate.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /** */
    handleCollapseRasters(e) {
        try {
            this.setState({
                collapseLayerList: !this.state.collapseLayerList
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /** */
    handleCollapseAnnotations(e) {
        try {
            this.setState({
                collapseAnnotationList: !this.state.collapseAnnotationList
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /** */
    handleCollapseObjects(e) {
        try {
            this.setState({
                collapseObjectList: !this.state.collapseObjectList
            })
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    /** */
    handleCollapseWhatsnews(e) {
        try {
            this.setState({
                collapseWhatsnewList: !this.state.collapseWhatsnewList
            })
        } catch (error) {
            console.log('ERROR: ',error)
        }
    }

    /** */
    goTo(page){
        const urlSearchParams=new URLSearchParams(window.location.search)
        window.location.replace(`${BASE_URL}/${page}?${urlSearchParams}`)
    }

    /**
     * 
     * @param {*} e 
     * @param {*} value 
     */
    handleDropdown(e) {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        })
    }

    /* */
    handleCloseDropdown() {
        this.setState({
            isDropdownOpen: false
        })
    }

    /* */
    handleClickOnDate(flightDate) {
        let displayedLayer=this.props.rasterLayers.find((layer)=>layer.FlightDate===flightDate)
        this.props.handleClickOnLayer(displayedLayer.id, true)
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div >
                <Drawer
                    sx={{
                        width: 270,
                        '& .MuiDrawer-paper': {
                            width: 260,
                            boxSizing: 'border-box',
                            top: 48,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            height:168,
                            overflowX:'hidden',
                        },
                    }}
                    transitionDuration={0}
                    variant='persistent'
                    anchor="left"
                    open={this.props.drawerOpen}
                >   
                    <div id='timelapse-area-container' >
                        <div id='timelapse-panel-map-area'>
                            {
                                this.props.selectedAll
                                ?
                                <IconButton onClick={this.props.handleDeselectAll} sx={{ml: '5px'}} disabled={this.props.displayTimelapseControl}>
                                    <Tooltip 
                                        // title='Hide High Res Map'
                                        title={i18n.t("TimelapsePanel.HideHighResMap")} 
                                        placement='bottom'
                                    >
                                        <LayersIcon sx={{color: this.props.displayTimelapseControl ? '#b1b2b0' :'black'}}/>
                                    </Tooltip>
                                </IconButton>
                                :
                                <IconButton onClick={this.props.handleSelectAll} sx={{ml: '5px'}} disabled={this.props.displayTimelapseControl}>
                                    <Tooltip 
                                    //   title='Display High Res Map'
                                      title={i18n.t("TimelapsePanel.DisplayHighResMap")}
                                      placement='bottom'
                                    >
                                        <LayersOutlinedIcon sx={{color: this.props.displayTimelapseControl ? '#b1b2b0' :'black'}}/>
                                    </Tooltip>
                                </IconButton>
                            }
                            <Tooltip 
                            //   title='Fly to map area'
                               title={i18n.t("TimelapsePanel.FlyToMapArea")}
                            >
                                <Typography onClick={this.props.handleMapIcon} sx={{fontSize: '16px', fontFamily: 'Poppins'}}>{this.props.regionName}</Typography>
                            </Tooltip>
                        </div>
                        <div id="timelapse-layers-mini-button-container">
                            {
                                this.props.annotations.filter(anno => anno.Storage==this.props.displayedLayer.id).length==0
                                ?
                                <Tooltip 
                                //   title={`Import annotations`}
                                    title={i18n.t("TimelapsePanel.ImportAnnotations")}
                                >
                                    <IconButton onClick={this.props.handleUpload} sx={{fontSize: '8px', mr: '-8px'}}>
                                        <DownloadIcon sx={{color: 'black'}}/>
                                    </IconButton>
                                </Tooltip>
                                :
                                null
                            }
                            <IconButton 
                                sx={{color: 'black', fontSize: '8px', mr: '-8px'}}
                                disabled={this.props.timelapseLayers.length < 3}
                                onClick={this.props.handleShowHideTimelapse}
                            >
                                {
                                    this.props.displayTimelapseControl
                                    ?
                                    <Tooltip 
                                        // title='Disable timelapse control'
                                        title={i18n.t("TimelapsePanel.DisableTimelapseControl")}
                                    >
                                        <PlayCircleFilledIcon />
                                    </Tooltip>
                                    :
                                    <Tooltip 
                                        // title='Enable timelapse control'
                                        title={i18n.t("TimelapsePanel.EnableTimelapseControl")}
                                    >
                                        <PlayCircleOutlineIcon />
                                    </Tooltip>
                                }
                            </IconButton>
                            <IconButton sx={{color: 'black'}} disabled={this.props.timelapseLayers.length < 2 || this.props.displayTimelapseControl} onClick={() => this.goTo('compare')}>
                                <Tooltip 
                                    // title='Compare'
                                    title={i18n.t("TimelapsePanel.Compare")}
                                >
                                    <CompareIcon />
                                </Tooltip>
                            </IconButton>
                        </div>
                    </div>
                    <div id='timelapse-searchbar-container'>
                        <LocationSearchBar 
                            handleChange={this.props.handleFlyToQuery}
                            text='lng, lat | MGRS'
                            iconTitle={i18n.t("TimelapsePanel.FlyToTheCoordinate")}
                            value={this.props.flyToLocation}
                            width='90%'
                            handleSearch={() => this.props.handleFlyToEnteredLocation(this.props.flyToLocation)}
                            hasInfo={true}
                            infoText={i18n.t("TimelapsePanel.DecimalDegreesWithCommaInBetweenLongitudeAndLatitudeOrMGRS")}
                        />
                    </div>
                {
                    this.props.drawerOpen?
                    <div id='timelapse-date-container'>
                        <IconButton onClick={this.props.handlePrevTimelapseLayer} disabled={this.props.timelapseLayerIndex === 0 || !this.props.selectedAll}>
                            <Tooltip 
                                // title='Previous'
                                title={i18n.t("TimelapsePanel.Previous")}
                            >
                                <KeyboardArrowLeftRoundedIcon sx={{color : this.props.timelapseLayerIndex === 0 || !this.props.selectedAll ? '#b1b2b0' : 'black'}}/>
                            </Tooltip>
                        </IconButton>
                        <DateDropdown
                            textColor='black'
                            left='0px'
                            width='258px'
                            rasterLayers={this.props.rasterLayers}
                            displayedLayer={this.props.displayedLayer}
                            handleClickOnLayer={this.props.handleClickOnLayer}
                        />
                        <IconButton onClick={this.props.handleNextTimelapseLayer} disabled={this.props.timelapseLayerIndex === this.props.timelapseLayers.length-1 || !this.props.selectedAll }>
                            <Tooltip 
                                // title='Next'
                                title={i18n.t("TimelapsePanel.Next")}
                            >
                                <KeyboardArrowRightRoundedIcon sx={{color : this.props.timelapseLayerIndex === this.props.timelapseLayers.length-1 || !this.props.selectedAll  ? '#b1b2b0' : 'black'}}/>
                            </Tooltip>
                        </IconButton>
                    </div>
                    :
                    null
                }
                <Drawer
                    sx={{
                        width: 270,
                        '& .MuiDrawer-paper': {
                            width: 260,
                            boxSizing: 'border-box',
                            top: 216,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            height:'calc(100vh - 246px)',
                            overflowY:'scroll',
                            overflowX:'hidden',
                            overflow:'auto',
 
                            "&::-webkit-scrollbar": {
                             width: 10
                           },
                           "&::-webkit-scrollbar-track": {
                             backgroundColor: "#ffffff"
                           },
                           "&::-webkit-scrollbar-thumb": {
                             backgroundColor: "lightgray",
                             borderRadius: 2
                           
                         },
                       
                         
                        },
                    }}
                    transitionDuration={0}
                    variant='persistent'
                    anchor="left"
                    open={this.props.drawerOpen}
                >   
                    {
                        this.props.annotations.filter(anno => anno.Storage==this.props.displayedLayer.id).length>0
                        ?
                        <MapFeatureList
                            isDisabled={this.props.displayTimelapseControl}
                            checkedAll={this.props.selectedAllFcLayers}
                            isIndeterminate={!this.props.selectedAllFcLayers&&!this.props.notAnyFcSelected}
                            handleCheckAll={this.props.handleCheckAllFeatureList}
                            // nameOfList='Annotations'
                            nameOfList={i18n.t("TimelapsePanel.Annotations")}
                            handleCollapse={this.handleCollapseAnnotations}
                            isCollapsed={this.state.collapseAnnotationList}
                            isDefined={this.props.featureCollectionLayers.length !== 0}
                            features={this.props.annotations.filter(anno => anno.Storage==this.props.displayedLayer.id)}
                            displayedLayer={this.props.displayedLayer}
                            handleCheckOne={this.props.handleCheckOneMapFeature}
                            featureCollection='annotationList'
                            fType='anno'
                            handleClickOnFeature={this.props.handleClickOnFcLayer}
                            handleDeleteOne={this.props.handleDeleteAnnotation}
                            handleEditOne={this.props.handleEditAnnotation}
                            id={this.props.selectedFcLayerId}
                            delete={true}
                            upload={true}
                            edit={true}
                            handleUpload={this.props.handleUpload}
                            handleSaveAnnotation={this.props.handleSaveAnnotation}
                        />
                        :
                        null
                    }
                    {
                        this.props.detectedObjects.filter(obj=>obj.Storage==this.props.displayedLayer.id).length>0
                        ?
                        <MapFeatureList
                            isDisabled={this.props.displayTimelapseControl}
                            checkedAll={this.props.selectedAllDetectedObjs}
                            isIndeterminate={!this.props.selectedAllDetectedObjs&&!this.props.notAnyDetectedObjSelected}
                            handleCheckAll={this.props.handleCheckAllFeatureList}
                            // nameOfList='Detections'
                            nameOfList={i18n.t("TimelapsePanel.Detections")}
                            handleCollapse={this.handleCollapseObjects}
                            isCollapsed={this.state.collapseObjectList}
                            isDefined={this.props.detectedObjects.length !== 0}
                            features={this.props.detectedObjects.filter(obj=>obj.Storage==this.props.displayedLayer.id)}
                            displayedLayer={this.props.displayedLayer}
                            handleCheckOne={this.props.handleCheckOneMapFeature}
                            featureCollection='detectedObjects'
                            handleClickOnFeature={this.props.handleClickOnDetectionGroup}
                            id={this.props.selectedFcLayerId}
                            delete={false}
                        />
                        :
                        null
                    }
                    {
                        this.props.whatIsNew.filter(obj=>obj.Storage==this.props.displayedLayer.id).length>0
                        ?
                        <MapFeatureList
                            isDisabled={this.props.displayTimelapseControl}
                            checkedAll={this.props.selectedWhatIsNew}
                            isIndeterminate={!this.props.selectedWhatIsNew&&!this.props.notAnyWhatIsNew}
                            handleCheckAll={this.props.handleCheckAllFeatureList}
                            // nameOfList="What is new"
                            nameOfList={i18n.t("TimelapsePanel.WhatIsNew")}
                            handleCollapse={this.handleCollapseWhatsnews}
                            isCollapsed={this.state.collapseWhatsnewList}
                            isDefined={this.props.whatsnewList.length !== 0}
                            features={this.props.whatIsNew.filter(obj=>obj.Storage==this.props.displayedLayer.id)}
                            displayedLayer={this.props.displayedLayer}
                            handleCheckOne={this.props.handleCheckOneMapFeature}
                            featureCollection='whatsnewList'
                            fType='whatsnew'
                            handleClickOnFeature={this.props.handleClickOnFcLayer}
                            id={this.props.selectedWhatsnewId}
                            delete={true}
                            handleDeleteOne={this.props.handleDeleteWhatsnew}
                        />
                        :
                        null
                    }
                    {
                        this.props.objectList.filter(obj=>obj.Storage==this.props.displayedLayer.id).length>0
                        ?
                        <MapFeatureList
                            isDisabled={this.props.displayTimelapseControl}
                            checkedAll={this.props.selectedAllConfirmedObjects}
                            isIndeterminate={!this.props.selectedAllConfirmedObjects&&!this.props.notAnyConfirmedObjectSelected}
                            handleCheckAll={this.props.handleCheckAllConfirmedObjects}
                            // nameOfList="Objects"
                            nameOfList={i18n.t("TimelapsePanel.Objects")}
                            handleCollapse={this.handleCollapseWhatsnews}
                            isCollapsed={this.state.collapseWhatsnewList}
                            isDefined={this.props.objectList.length !== 0}
                            features={this.props.confirmedObjectGroups.filter(obj=>obj.Storage==this.props.displayedLayer.id)}
                            displayedLayer={this.props.displayedLayer}
                            handleCheckOne={this.props.handleCheckOneConfirmedObject}
                            featureCollection='objectList'
                            fType='objectList'
                            handleClickOnFeature={this.props.handleClickOnObjectGroup}
                            id={this.props.selectedWhatsnewId}
                            delete={false}
                        />
                        :
                        null
                    }
                </Drawer>
                </Drawer>
                {
                    this.props.displayTimelapseControl
                    ?
                    <ControlBar
                        handleTimeChange={this.props.handleTimeChange}
                        timelapseLayers={this.props.timelapseLayers}
                        timelapseLayerIndex={this.props.timelapseLayerIndex}
                        handlePlay={this.props.handlePlay}
                        handlePause={this.props.handlePause}
                        handleReset={this.props.handleReset}
                        played={this.props.played}
                        displayedLayer={this.props.displayedLayer}
                        drawerOpen={this.props.drawerOpen}
                        anchorEl={this.props.anchorEl}
                        handlePlaybackSpeed={this.props.handlePlaybackSpeed}
                        setPlaybackSpeed={this.props.setPlaybackSpeed}
                        showPlaybackOptions={this.props.showPlaybackOptions}
                        playbackRate={this.props.playbackRate}
                        handleReverse={this.props.handleReverse}
                    />
                    :
                    null
                }
                <PanelFooter 
                    mapCenter={this.props.mapCenter}
                    clickedLocation={this.props.clickedLocation}
                    zoom={this.props.zoom}
                    elevation={this.props.elevation}
                />
                {
                    this.props.drawerOpen
                    ?
                    <div id='close-drawer-button-container'>
                        <IconButton onClick={this.props.handleDrawerOpener}>
                            <KeyboardArrowLeftRoundedIcon sx={{color: 'white'}} fontSize='small'/>
                        </IconButton>
                    </div>
                    :
                    <div id='open-drawer-button-container'>
                        <IconButton onClick={this.props.handleDrawerOpener}>
                            <KeyboardArrowRightRoundedIcon sx={{color: 'white'}} fontSize='small'/>
                        </IconButton>
                    </div>
                }
                {
                    !this.props.drawerOpen
                    ?
                    <div id='timelapse-area-date-container-hidden'>
                        <div id='timelapse-area-container-hidden'>
                            <div id='timelapse-panel-map-area'>
                                {
                                    this.props.selectedAll
                                    ?
                                    <IconButton onClick={this.props.handleDeselectAll} sx={{ml: '5px'}} disabled={this.props.displayTimelapseControl}>
                                        <Tooltip 
                                            // title='Hide High Res Map' 
                                            title={i18n.t("TimelapsePanel.HideHighResMap")}
                                            placement='bottom'
                                        >
                                            <LayersIcon sx={{color: this.props.displayTimelapseControl ? '#b1b2b0' :'white'}}/>
                                        </Tooltip>
                                    </IconButton>
                                    :
                                    <IconButton onClick={this.props.handleSelectAll} sx={{ml: '5px'}} disabled={this.props.displayTimelapseControl}>
                                        <Tooltip 
                                            // title='Display High Res Map' 
                                            title={i18n.t("TimelapsePanel.DisplayHighResMap")}
                                            placement='bottom'
                                        >
                                            <LayersOutlinedIcon sx={{color: this.props.displayTimelapseControl ? '#b1b2b0' :'white'}}/>
                                        </Tooltip>
                                    </IconButton>
                                }
                                <Tooltip 
                                    // title='Fly to map area'
                                    title={i18n.t("TimelapsePanel.FlyToMapArea")}
                                >
                                    <Typography onClick={this.props.handleMapIcon} sx={{fontSize: '16px', fontFamily: 'Poppins', color: 'white'}}>{this.props.regionName}</Typography>
                                </Tooltip>
                            </div>
                            <div id="timelapse-layers-mini-button-container">
                                <IconButton 
                                    sx={{color: 'black', fontSize: '8px', mr: '-8px'}}
                                    disabled={this.props.timelapseLayers.length < 3}
                                    onClick={this.props.handleShowHideTimelapse}
                                >
                                    {
                                        this.props.displayTimelapseControl
                                        ?
                                        <Tooltip 
                                            // title='Disable timelapse control'
                                            title={i18n.t("TimelapsePanel.DisableTimelapseControl")}
                                        >
                                            <PlayCircleFilledIcon sx={{color: 'white'}}/>
                                        </Tooltip>
                                        :
                                        <Tooltip 
                                            // title='Enable timelapse control'
                                            title={i18n.t("TimelapsePanel.EnableTimelapseControl")}
                                        >
                                            <PlayCircleOutlineIcon sx={{color: 'white'}}/>
                                        </Tooltip>
                                    }
                                </IconButton>
                                <IconButton sx={{color: 'white'}} disabled={this.props.timelapseLayers.length < 2 || this.props.displayTimelapseControl} onClick={() => this.goTo('compare')}>
                                    <Tooltip 
                                        // title='Compare'
                                        title={i18n.t("TimelapsePanel.Compare")}
                                    >
                                        <CompareIcon sx={{color: this.props.displayTimelapseControl ? '#b1b2b0' :'white'}}/>
                                    </Tooltip>
                                </IconButton>
                            </div>
                        </div>
                        <div id='timelapse-date-container-hidden'>
                            <IconButton onClick={this.props.handlePrevTimelapseLayer} disabled={this.props.timelapseLayerIndex === 0 || !this.props.selectedAll}>
                                <Tooltip 
                                    // title='Previous'
                                    title={i18n.t("TimelapsePanel.Previous")}
                                >
                                    <KeyboardArrowLeftRoundedIcon sx={{color : this.props.timelapseLayerIndex === 0 || !this.props.selectedAll ? '#b1b2b0' : 'white'}}/>
                                </Tooltip>
                            </IconButton>
                            <DateDropdown
                                textColor='white'
                                left='20px'
                                width='263px'
                                rasterLayers={this.props.rasterLayers}
                                displayedLayer={this.props.displayedLayer}
                                handleClickOnLayer={this.props.handleClickOnLayer}
                            />
                            <IconButton onClick={this.props.handleNextTimelapseLayer} disabled={this.props.timelapseLayerIndex === this.props.timelapseLayers.length-1 || !this.props.selectedAll }>
                                <Tooltip 
                                    // title='Next'
                                    title={i18n.t("TimelapsePanel.Next")}
                                >
                                    <KeyboardArrowRightRoundedIcon sx={{color : this.props.timelapseLayerIndex === this.props.timelapseLayers.length-1 || !this.props.selectedAll  ? '#b1b2b0' : 'white'}}/>
                                </Tooltip>
                            </IconButton>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAnnotationListLoading: state.annotation.isAnnotationListLoading,
        isAnnotationCreated: state.annotation.isAnnotationCreated,
        isFailedToCreateAnnotation: state.annotation.isFailedToCreateAnnotation,
        annotationList: state.annotation.annotationList,
        whatsnewList: state.whatsnew.whatsnewList,
        objectList: state.object.objectList,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { getAnnotationList, getWhatsnewList, getObjectList })(TimelapsePanel)