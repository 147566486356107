/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'

/* custom components */
import { BlackCheckbox}from './CustomCheckbox'

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Slider from '@mui/material/Slider'
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components */
import CompareIcon from '@mui/icons-material/Compare'
import OpacityIcon from '@mui/icons-material/Opacity';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

/* assets */ 
// const SERVER = process.env.REACT_APP_DJANGO_SERVER
const BASE_URL = process.env.REACT_APP_BASE_URL

class ChangeDetectionPanel extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            collapseLayerList: true,
            rasterLayers: [],
            changeFeatures: {
                'type': 'FeatureCollection',
                'features': []
              },
            mapArea: null,
            firstLayerDate: null,
            secondLayerDate: null,
            regionName: '',
            opacityBars: [false,false,false]
        }

        /* Function bindings here */
        this.handleCollapseRasters = this.handleCollapseRasters.bind(this)
        this.goTo = this.goTo.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleMapSelectFirst = this.handleMapSelectFirst.bind(this)
        this.handleMapSelectSecond = this.handleMapSelectSecond.bind(this)
        this.showOpacityBar = this.showOpacityBar.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        let regionName=params.regionName
        this.setState({
            regionName: regionName
        })
        try {
            this.setState({
                firstLayerDate: this.props.firstLayer.FlightDate,
                secondLayerDate: this.props.secondLayer.FlightDate,
            })
        } catch (error) {

        }
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps, prevState){
        if(prevProps.isChangesFetched!=this.props.isChangesFetched && this.props.isChangesFetched){
            this.setState({
                changeFeatures: {...this.props.changes}
            })
        }
    }

    /**
     * 
     */
    handleChange(){
        window.localStorage.setItem('firstLayer', JSON.stringify(this.state.rasterLayers[3]))
        window.location.reload()
    }

    /**
     * 
     */
    handleCollapseRasters(e) {
        try {
            this.setState({
                collapseLayerList: !this.state.collapseLayerList
            })
        } catch (error) {

        }
    }

    /**
     * 
     */
     goTo(page){
        const urlSearchParams=new URLSearchParams(window.location.search)
        window.location.replace(`${BASE_URL}/${page}?${urlSearchParams}`)
    }


    /**
     * 
     */
    handleMapSelectFirst(e){
        this.setState({firstLayerDate: e.target.value})
        let layer = this.state.rasterLayers.find((layer) => layer.FlightDate === e.target.value)
        window.localStorage.setItem('firstLayer', JSON.stringify(layer))
        window.location.reload()
    }

    /**
     * 
     */
    handleMapSelectSecond(e){
        this.setState({secondLayerDate: e.target.value})
        let layer = this.state.rasterLayers.find((layer) => layer.FlightDate === e.target.value)
        window.localStorage.setItem('secondLayer', JSON.stringify(layer))
        window.location.reload()
    }


    /* */
    showOpacityBar(i){
        let isDisplayed = !this.state.opacityBars[i]
        let copyArr = [...this.state.opacityBars]
        copyArr.splice(i,1,isDisplayed)
        this.setState({
            opacityBars: [...copyArr]
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div id="change-detection-panel-main-container">
                <div id="change-detection-panel-map-area-name-container">
                    <Tooltip title='Timelapse'>
                        <IconButton onClick={() => this.goTo('timelapse')} >
                            <ArrowBackIosNewIcon sx={{color: 'black', fontSize: '18px'}}/>
                        </IconButton>
                    </Tooltip>
                    <div id="compare-panel-map-area-text">
                        <Tooltip title='Fly to map area'>
                            <IconButton onClick={this.props.handleMapIcon } sx={{'&:hover': { backgroundColor: 'transparent'}}}>
                                <Typography sx={{fontFamily: 'Poppins',color: 'black'}}>
                                {this.state.regionName}
                                </Typography>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Tooltip title='Compare View'>
                        <IconButton onClick={() => this.goTo('compare')} >
                            <CompareIcon sx={{color: 'black'}}/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div id="change-detection-panel-firstlayer-container">
                    <BlackCheckbox size='medium' checked={this.props.firstLayer.displayed} onChange={() => this.props.handleCheck(this.props.firstLayer, 'firstLayer')}/>
                    <div className="change-detection-panel-formcontrol-container">
                        <FormControl sx={{width: '140px'}}>
                            <Select sx={{height: '30px', color: 'black', fontFamily: 'Poppins'}} value={this.props.firstLayerDate} onChange={this.props.handleSelectFirst}>
                                {
                                    this.props.bottomLayers.map((layer, i) => 
                                    <MenuItem value={layer.FlightDate}>
                                        {layer.FlightDate}
                                    </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Tooltip placement='right' open={this.state.opacityBars[0]} title={<div> 
                            <Slider  
                                sx={{
                                width: '40px',
                                color: 'white',
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 8,
                                    height: 8,
                                    backgroundColor: 'white',
                                    '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                    },
                                    },
                                }}
                                min={0}
                                max={100}
                                value={this.props.firstLayerOpacity}
                                onChange={this.props.handleOpacityFirst}
                            />
                        </div>}>
                            <IconButton onClick={()=>this.showOpacityBar(0)}>
                                <OpacityIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div id="change-detection-panel-secondlayer-container">
                    <BlackCheckbox size='medium' checked={this.props.secondLayer.displayed} onChange={() => this.props.handleCheck(this.props.secondLayer, 'secondLayer')}/>
                    <div className="change-detection-panel-formcontrol-container">
                        <FormControl sx={{width: '140px'}}>
                            <Select value={this.props.secondLayerDate} labelId='map-id' sx={{height: '30px', color: 'black', fontFamily: 'Poppins'}} onChange={this.props.handleSelectSecond}>
                                {
                                    this.props.topLayers.map((layer, i) => 
                                    <MenuItem value={layer.FlightDate}>
                                        {layer.FlightDate}
                                    </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Tooltip placement='right' open={this.state.opacityBars[1]} title={<div> 
                            <Slider sx={{
                            width: '40px',
                            color: 'white',
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                width: 8,
                                height: 8,
                                backgroundColor: 'white',
                                '&:before': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                },
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                boxShadow: 'none',
                                },
                                },
                            }}
                            min={0}
                            max={100}
                            value={this.props.secondLayerOpacity}
                            onChange={this.props.handleOpacitySecond}

                        />
                        </div>}>
                            <IconButton onClick={()=>this.showOpacityBar(1)}>
                                <OpacityIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {/* &&this.state.changeFeatures.features.length>0 */}
                {
                    this.props.changeLayer.Detections!=null&&this.state.changeFeatures.features.length>0&&!this.props.isChangeDetectionStarted
                    ?
                    <div id="change-detection-panel-thirdlayer-container">
                        <BlackCheckbox size='medium' checked={this.props.changeLayer.displayed} onChange={() => this.props.handleCheck(this.props.changeLayer, 'changeLayer')}/>
                        <div className="change-detection-panel-formcontrol-container">
                            <Typography sx={{
                                height: '30px', 
                                width: '140px',
                                color: 'black', 
                                fontFamily: 'Poppins', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                fontSize: '14px'
                            }}>
                                {/* Detected Changes */}
                                {i18n.t("ChangeDetectionPanel.DetectedChanges")}
                            </Typography>
                            <Tooltip placement='right' open={this.state.opacityBars[2]} title={<div> 
                                <Slider  sx={{
                                width: '40px',
                                color: 'white',
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 8,
                                    height: 8,
                                    backgroundColor: 'white',
                                    '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                    },
                                    },
                                }}
                                min={0}
                                max={50}
                                value={this.props.changeLayerOpacity}
                                onChange={this.props.handleOpacityChange}
                            />
                            </div>}>
                                <IconButton onClick={()=>this.showOpacityBar(2)}>
                                    <OpacityIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    :
                    null
                }
                {
                    this.props.isChangeDetectionStarted&&!this.props.isChangeDetectionsGenerated
                    ?
                    <div id="change-detection-panel-thirdlayer-container">
                        <div style={{display:"flex", flexDirection: "column"}}>
                            <CircularProgress sx={{ color:'#121f33'}} size={23}/>
                            <br></br>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

// export default (ChangeDetectionPanel)
const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userGroup: state.auth.user.userGroup,
        language: state.language.language,
        changes: state.change.changes,
        isChangesFetched: state.change.isChangesFetched,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML,
        isChangeDetectionStarted: state.change.isChangeDetectionStarted,
        isChangeDetectionsGenerated: state.change.isChangeDetectionsGenerated,
        changeDetectionResults: state.change.changeDetectionResults,
        isFailedToGenerateChangeDetections: state.change.isFailedToGenerateChangeDetections
    }
}

export default  connect(mapStateToProps, {})(ChangeDetectionPanel)