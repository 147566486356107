/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { createArea, getAreaList, resetAreaForm, putArea } from '../redux/actions/areas';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* mui icons-material components */
import DataObjectIcon from '@mui/icons-material/DataObject';
import CodeIcon from '@mui/icons-material/Code';

class GeojsonForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            regionName: '',
            terrain: '',
            thumbnailLink: '',
            area: {
                RegionName: '',
                Terrain: '',
                Thumbnail: ''
            }
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
        this.handleOption = this.handleOption.bind(this)

    }

    /**
     * When the component is rendered
     */
    componentDidMount(){

    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
        }

        if(prevProps.isEditMode !== this.props.isEditMode && this.props.isEditMode){
            let editedArea=this.props.areaList.find((area)=>area.id==this.props.editedAreaId)
            this.setState({
                area: {...editedArea}
            })
        }
    }

    /**
     * This is used for both editing and creating new areas
     */
    handleSubmit(){
        if(this.props.isEditMode){
            this.props.putArea({
                RegionName: this.state.regionName ? this.state.regionName : this.state.area.RegionName,
                Terrain: this.state.terrain ? this.state.terrain : this.state.area.Terrain,
                Thumbnail: this.state.thumbnailLink ? this.state.terrain : this.state.area.Thumbnail,
                Polygon: this.props.areaPolygon,
                center: this.props.centerOfArea,
                PolygonData: this.props.areaPolygon,
                id: this.props.id
            })
            this.setState({
                regionName: '',
                terrain: '',
                thumbnailLink: ''
            })
            this.props.handleClose()
            setTimeout(() => {
                this.props.resetAreaForm()
            }, this.props.isFailedToPutArea ? 4000 : 2000)
        } else {
            this.props.createArea({
                RegionName: this.state.regionName,
                Terrain: this.state.terrain,
                Thumbnail: this.state.thumbnailLink,
                Polygon: this.props.areaPolygon,
                center: this.props.centerOfArea,
                PolygonData: this.props.areaPolygon
            })
            this.setState({
                regionName: '',
                terrain: '',
                thumbnailLink: ''
            })
            this.props.handleClose()
            setTimeout(() => {
                this.props.resetAreaForm()
            }, this.props.isFailedToCreateArea? 4000 : 2000)
        }
    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {
        this.setState({
            userRole: e.target.value
        })
    }
    handleOption(e, newValue) {
        if(newValue!==null){
    
            this.props.handleModeChange(newValue)
        }
    }
    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                            <br/>
                            <div >
                            <ToggleButtonGroup value={this.props.via} onChange={this.handleOption} exclusive>
                                <ToggleButton value='geojson'>
                                    <DataObjectIcon/>
                                </ToggleButton>
                                <ToggleButton value='kml'>
                                    <CodeIcon/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {this.props.via=='geojson' ?
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* Type in Geojson */}
                            {i18n.t("GeojsonForm.TypeInGeojson")}
                        </Typography>
                        :
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* Type in KML */}
                            {i18n.t("GeojsonForm.TypeInKml")}
                        </Typography>
                        }
                        <div style={{
                            width: '91%',
                            height: '40px',
                            marginBottom: '25px'
                        }}>
                        {
                            this.props.error ?
                            <Alert severity='error'>{`Wrong format`}</Alert> 
                            :
                            // <Alert severity='warning'>{`Please input in the correct format`}</Alert> 
                            <Alert severity='warning'>{i18n.t("GeojsonForm.PleaseInputInTheCorrectFormat")}</Alert> 
                        }
                        </div>
                        {this.props.via=='geojson' ?
                        <div id='add-area-geojson-container'>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={'{"type": "Feature","properties": {},"geometry": {"type": "Polygon","coordinates": [...]}}'}
                                required
                                value={this.props.value}
                                rows={8}
                                multiline
                                onChange={this.props.onChange('areaPolygonText')}
                            />
                        <br></br>

                        </div>
                        :
                        <div id='add-area-geojson-container'>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={'<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><Placemark>  .....  </Placemark></Document></kml>'}
                                required
                                value={this.props.kmlValue}
                                rows={8}
                                multiline
                                onChange={this.props.onChange('areaKmlText')}
                            />
                            <br></br>

                        </div>
                        }
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.onSubmit}
                        >
                            {/* Import */}
                            {i18n.t("GeojsonForm.Import")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("GeojsonForm.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        isAreaCreated: state.areas.isAreaCreated,
        isFailedToCreateArea: state.areas.isFailedToCreateArea,
        areaList: state.areas.areaList,
        message: state.areas.message,
        isAreaPut: state.areas.isAreaPut,
        isFailedToPutArea: state.areas.isFailedToPutArea,
        isEditMode: state.areas.isEditMode,
        editedAreaId: state.areas.editedAreaId,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { createArea, getAreaList, resetAreaForm, putArea })(GeojsonForm)