/* eslint-disable eqeqeq */
import React from "react";

/* custom components */

/* mui material components */
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components */


class Loading extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isOpen: true
        }

        /* Function bindings here */
    }
    /**
     * When the component is rendered
     */
    componentDidMount(){
    }
    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }
    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                zIndex: 10000000,
                width: "100vw",
                height: "100vh",
                background: 'black',
                opacity: 0.5,
                display: "flex",
                flex: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress 
                sx={{
                    color: 'white'
                }}/>
            </div>
        )
    }
}

export default (Loading)