/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { createUser, resetUserForm, getUserList } from '../redux/actions/users';

/* custom components */

/* mui material components */

/* mui icons-material components */
import AddUser from './AddUser'
import UserTable from './UserTable'


class Users extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.props.getUserList()
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div 
                    id='users-page-container'
                >
                    <AddUser/>
                    <UserTable/>
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users
    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList })(Users)