/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    RESET_LOGIN,
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

/**
 * checks token & loads user
 */
 export const loadUser = () => (dispatch, getState) => {
    dispatch({type: USER_LOADING})
    axios.get(`/usersapi/auth/user`, { 
      headers: {
        Authorization: `token ${window.localStorage.getItem('token')}`
      }
    })
    .then((res) => {
        let userStr=res.data.username
        let userJson=JSON.parse(userStr)
        dispatch({
            type: USER_LOADED,
            payload: userJson
        })
    })
    .catch((error) => {
        dispatch({
            type: AUTH_ERROR
        })
    })
}

/**
 * login user
*/
export const submitLoginForm = (user) => (dispatch) => {
  const body = {...user};
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  axios
    .post(`/usersapi/auth/login`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      window.location.assign('gallery')
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAIL,
      })
    })
}


/**
 * reset login
 */
export const resetLogin = () => (dispatch) => {
  dispatch({
    type: RESET_LOGIN
  })
}
