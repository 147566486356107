/* eslint-disable eqeqeq */
import React from 'react'

/* Redux */
import { connect } from 'react-redux'

/* custom components */

/* mui material components */
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

/* mui icons-material components */
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

class LocationSearchBar extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){

    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){

    }

    /**
     * https://stackoverflow.com/questions/43384039/how-to-get-the-textfield-value-when-enter-key-is-pressed-in-react
     */
    handleSubmit(e) {
        if(e.keyCode == 13){
            this.props.handleSearch(this.props.value)
         }
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Box sx={{
                    height: 'auto', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start',
                    alignItems: 'center', 
                    width: this.props.width,
                    borderRadius: '10px',
                    background: 'white'
                    }}>
                        <div id='location-searchbar-container'>
                            <Tooltip title={`${this.props.iconTitle}`}>
                                <IconButton onClick={this.props.handleSearch}>
                                    <SearchIcon sx={{color: 'gray'}} fontSize='small'/>
                                </IconButton>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '95%',
                                    fontSize: '13px'
                                }}
                                placeholder={this.props.text}
                                required
                                value={this.props.value}
                                onChange={this.props.handleChange('query')}
                                onKeyDown={this.handleSubmit}
                            />
                            {
                                this.props.hasInfo
                                ?
                                <Tooltip title={`${this.props.infoText}`}>
                                    <InfoOutlinedIcon sx={{color: 'gray', mr: "5px"}} fontSize='small'/>
                                </Tooltip>
                                :
                                null
                            }
                        </div>
                </Box>
            )}
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {})(LocationSearchBar)