/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './App.css';
import {
  BrowserRouter as 
  Router, Switch, Route, Redirect
} from "react-router-dom"

/* Component Imports */

/* Page imports */
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import Timelapse from './pages/Timelapse';
import Compare from './pages/Compare';
import ChangeDetection from './pages/ChangeDetection';

/* Redux */
import { loadUser } from './redux/actions/auth';
import { getUserList } from './redux/actions/users';
import { getAnnotationList } from './redux/actions/annotation';
import AdminPanel from './pages/AdminPanel';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const App = (props) => {

  useEffect(()=>{
    props.loadUser()
    if(!window.localStorage.getItem("language")){
      window.localStorage.setItem("language","TR")
    }
  },[])

  return (
      props.isAuthenticated
      ?
      <Router>
        <Switch>
          <Route path='/gallery'>
            <Gallery></Gallery>
          </Route>
          <Route path='/adminpanel'>
            <AdminPanel {...props}></AdminPanel>
          </Route>
          <Route path='/timelapse/:hashId' render={(props) => <Timelapse {...props}/>}>
          </Route>
          <Route path='/timelapse'>
            <Timelapse></Timelapse>
          </Route>
          <Route path='/compare'>
            <Compare></Compare>
          </Route>
          <Route path='/difftool'>
            <ChangeDetection></ChangeDetection>
          </Route>
          <Route path='/'>
            <Redirect to='/gallery'/>
          </Route>
        </Switch>
      </Router>
      :
      <Router >
        <Route path='/'>
          <Login loading={props.isLoading}/>
        </Route>
      </Router>
  );
}

const mapStateToProps = (state) => {
  return {
      isAuthenticated: state.auth.isAuthenticated,
      isLoading: state.auth.isLoading,
      user: state.auth.user,
      users: state.users.users,
      language: state.language.language
  }
}

export default connect(mapStateToProps, { loadUser, getUserList, getAnnotationList })(App)
