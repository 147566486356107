/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import i18n from 'i18next'

import {
  createGroup,
  resetGroupForm,
  getGroupList,
  deleteGroup,
  getEditedGroup,
  putGroup,
} from "../redux/actions/groups";

/* custom components */

/* mui components */
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { DataGrid, enUS, trTR } from "@mui/x-data-grid";

/* mui icons-material components */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditGroup from "./EditGroup";

class GroupsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };

    /* Function bindings here */
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUserRole = this.handleUserRole.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  /**
   * When the component is rendered
   */
  componentDidMount() {}

  /**
   * When the component is updated
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isDeleted !== this.props.isDeleted && this.props.isDeleted) {
      this.props.getGroupList();
    }
  }

  /**
   *
   */
  handleSubmit() {}

  /**
   *
   */
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });

  };

  /**
   *
   */
  handleUserRole(e) {}

  /**
   *
   * @param {*} id
   */
  handleDelete(id) {
    this.props.deleteGroup(id);
    setTimeout(() => {
      this.props.resetGroupForm();
    }, 2000);
  }

  /**
   *
   * @param {*} id
   */
  handleEdit(id) {
    this.setState({
      isDialogOpen: true,
    });
    this.props.getEditedGroup(id);
  }

  /**
   *
   */
  handleClose() {
    this.setState({
      isDialogOpen: false,
    });
    this.props.resetGroupForm()
  }

  /**
   *
   * @returns the component
   */
  render() {
    const Delete = (props) => (
      <IconButton onClick={() => this.handleDelete(props.id)}>
        <DeleteIcon />
      </IconButton>
    );

    const Edit = (props) => (
      <IconButton onClick={() => this.handleEdit(props.id)}>
        <EditIcon />
      </IconButton>
    );

    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "name", headerName: i18n.t("GroupsTable.Name"), width: 260 },
      {
        field: "edit",
        headerName: i18n.t("GroupsTable.Edit"),
        width: 84,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Edit(props),
      },
      {
        field: "delete",
        headerName: i18n.t("GroupsTable.Delete"),
        width: 84,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Delete(props),
      },
    ];

    const rows = this.props.groups.map((group) => ({
      id: group.id,
      name: group.name,

      edit: "edit",
      delete: "delete",
    }));

    return (
      <div id="group-table-page-container">
        <div id="group-page-alert-container">
          {this.props.isDeleted ? (
            <Alert severity="success">{i18n.t("GroupsTable.SuccessfullyDeleted")}</Alert>
          ) : null}
          {this.props.isFailedToDelete ? (
            <Alert severity="error">{i18n.t("GroupsTable.FailedToDelete")}</Alert>
          ) : null}
          {this.props.isGroupPut ? (
            <div id="group-page-alert-container">
              <Alert
                onClose={this.props.resetGroupForm}
                severity="success"
              >{i18n.t("GroupsTable.EditedTheGroupsName")}</Alert>
            </div>
          ) : null}
          {this.props.isFailedToPutGroup ? (
            <div id="group-page-alert-container">
              <Alert
                onClose={this.handleClose}
                severity="error"
              >{i18n.t("GroupsTable.FailedToEditTheGroupsName")}</Alert>
            </div>
          ) : null}
        </div>
        <div id="group-table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowHeight={58}
            rowsPerPageOptions={[5]}
            sx={{
              fontFamily: "Poppins",
              overflowY: "hidden",
            }}
            disableSelectionOnClick
            localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        <EditGroup
          isDialogOpen={this.state.isDialogOpen}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups.groups,
    isDeleted: state.groups.isDeleted,
    isFailedToDelete: state.groups.isFailedToDelete,
    editedGroup: state.groups.editedGroup,
    isSuccess: state.groups.isSuccess,
    isFailed: state.groups.isFailed,
    newGroup: state.groups.newGroup,
    error: state.groups.error,
    isGroupPut: state.groups.isGroupPut,
    isFailedToPutGroup: state.groups.isFailedToPutGroup,
    language: state.language.language
  };
};

export default connect(mapStateToProps, {
  createGroup,
  resetGroupForm,
  getGroupList,
  getEditedGroup,
  deleteGroup,
  putGroup,
})(GroupsTable);
