import {
  GROUPS_RETRIEVED,
  CREATED_GROUP,
  RESET_GROUP_FORM,
  GROUP_DELETED,
  FAILED_TO_DELETE_GROUP,
  GROUP_FETCHED,
  FAILED_GROUP_FETCH,
  GROUP_PUT,
  FAILED_GROUP_PUT,
  FAILED_NEW_GROUP,
} from "../actions/types";

const initialState = {
  error: "",
  groups: [],
  editedGroup: "",
  newGroup: { name: null },
  isSuccess: false,
  isFailed: false,
  isDeleted: false,
  isFailedToDelete: false,
  isGroupFetched: false,
  isGroupFailedToFetch: false,
  isGroupPut: false,
  isFailedToPutGroup: false,
  isFailedToDeleteGroup: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GROUPS_RETRIEVED:
      return {
        ...state,
        groups: [...action.payload],
      };
    case CREATED_GROUP:
      return {
        ...state,
        isSuccess: true,
        isFailed: false,
        newGroup: action.payload,
      };
    case FAILED_NEW_GROUP:
      return {
        ...state,
        isSuccess: false,
        isFailed: true,
        error: action.payload,
      };
    case RESET_GROUP_FORM:
      return {
        ...state,
        isSuccess: false,
        isFailed: false,
        newGroup: { name: null },
        editedGroup: { name: null },
        isGroupFetched: false,
        isGroupFailedToFetch: false,
        isDeleted: false,
        isFailedToDeleteGroup: false,
        isFailedToDelete: false,
        isGroupPut: false,
        isFailedToPutGroup: false,
      };
    case GROUP_FETCHED:
      return {
        ...state,
        editedGroup: action.payload,
        isGroupFetched: true,
        isGroupFailedToFetch: false,
      };
    case FAILED_GROUP_FETCH:
      return {
        ...state,
        isGroupFetched: false,
        isGroupFailedToFetch: false,
      };
    case GROUP_DELETED:
      return {
        ...state,
        isDeleted: true,
        isFailedToDeleteGroup: false,
      };
    case FAILED_TO_DELETE_GROUP:
      return {
        ...state,
        isDeleted: false,
        isFailedToDeleteGroup: true,
      };
    case GROUP_PUT:
      return {
        ...state,
        editedGroup: action.payload,
        isGroupPut: true,
        isFailedToPutGroup: false,
      };
    case FAILED_GROUP_PUT:
      return {
        ...state,
        editedGroup: "",
        isGroupPut: false,
        error: action.payload,
        isFailedToPutGroup: true,
      };

    default:
      return state;
  }
}
