/* eslint-disable eqeqeq */
import axios from 'axios';
import {
    CREATED_USER,
    FAILED_NEW_USER,
    RESET_USER_FORM,
    USERS_RETRIEVED,
    FAILED_USERS,
    USER_DELETED,
    FAILED_TO_DELETE,
    USER_FETCHED,
    FAILED_USER_FETCH,
    USER_PUT,
    FAILED_USER_PUT,
  } from './types';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

const config = {
  headers: {
    Authorization: `token ${window.localStorage.getItem('token')}`
  }
}

/**
 * 
 */
export const createUser = (user) => (dispatch) => {
  const userGroup=user.user.group
  const {group, ...body} = user
  let paramString=userGroup?`?group=${userGroup}`:''
  axios.post(`/usersapi/auth/register${paramString}`, body.user, config)
       .then((res) => {
         dispatch({
          type: CREATED_USER,
          payload: res.data.user
         })
       })
       .catch((error) => {         
         dispatch({
           type: FAILED_NEW_USER,
           payload: error.response.statusText
         })
       })
}

/**
 * reset login
 */
 export const resetUserForm = () => (dispatch) => {
  dispatch({
    type: RESET_USER_FORM
  })
}

/**
 * 
 */
export const getUserList = () => (dispatch) => {
  axios.get(`/usersapi/auth/users`, config).then((res)=>{
    dispatch({
      type: USERS_RETRIEVED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_USERS,
      payload: e.response.statusText
    })
  })
}

/**
 * 
 */
export const deleteUser = (id) => (dispatch) => {
  axios.delete(`/usersapi/auth/users/${id}`, config).then((res)=>{
    dispatch({
      type: USER_DELETED,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_TO_DELETE,
      payload: e.response.statusText
    })
  })
}

/**
 * 
 */
 export const getEditedUser = (id) => (dispatch) => {
  axios.get(`/usersapi/auth/users/${id}`, config).then((res)=>{
    dispatch({
      type: USER_FETCHED,
      payload: res.data
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_USER_FETCH,
      payload: e.response.statusText
    })
  })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const putUser = (body) => (dispatch) => {
  axios.put(`/usersapi/auth/users/${body.user.id}`, body.user, config).then((res)=>{
    dispatch({
      type: USER_PUT,
      payload: res.status
    })
  }).catch((e) => {
    dispatch({
      type: FAILED_USER_PUT,
      payload: 'Failed to edit'
    })
  })
}