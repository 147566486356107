//auth
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_LOGIN = "RESET_LOGIN";
export const GET_ERRORS = "GET_ERRORS";
//map
export const LOAD_MAP = "LOAD_MAP";
export const RESET_MAP_STATE = "RESET_MAP_STATE";
//user, group
export const CREATED_USER = "CREATE_USER";
export const CREATED_GROUP = "CREATE_GROUP";
export const FAILED_NEW_USER = "FAILED_NEW_USER";
export const FAILED_NEW_GROUP = "FAILED_NEW_GROUP";
export const RESET_USER_FORM = "RESET_USER_FORM";
export const RESET_GROUP_FORM = "RESET_GROUP_FORM";
export const USERS_RETRIEVED = "USERS_RETRIEVED";
export const GROUPS_RETRIEVED = "GROUPS_RETRIEVED";
export const FAILED_USERS = "FAILED_USERS";
export const FAILED_GROUPS = "FAILED_GROUPS";
export const USER_DELETED = "USER_DELETED";
export const GROUP_DELETED = "GROUP_DELETED";
export const FAILED_TO_DELETE = "FAILED_TO_DELETE";
export const FAILED_TO_DELETE_GROUP = "FAILED_TO_DELETE_GROUP";
export const USER_FETCHED = "USER_FETCHED";
export const GROUP_FETCHED = "GROUP_FETCHED";
export const MAP_FETCHED = "MAP_FETCHED";
export const FAILED_USER_FETCH = "FAILED_USER_FETCH";
export const FAILED_GROUP_FETCH = "FAILED_GROUP_FETCH";
export const FAILED_MAP_FETCH = "FAILED_MAP_FETCH";
export const USER_PUT = "USER_PUT";
export const GROUP_PUT = "GROUP_PUT";
export const FAILED_GROUP_PUT = "FAILED_GROUP_PUT";
export const MAP_PUT = "MAP_PUT";
export const FAILED_USER_PUT = "FAILED_USER_PUT";
export const FAILED_MAP_PUT = "FAILED_MAP_PUT";
//area
export const AREAS_RETRIEVED = "AREAS_RETRIEVED";
export const FAILED_AREAS = "FAILED_AREAS";
export const AREA_CREATED = "AREA_CREATED";
export const FAILED_NEW_AREA = "FAILED_NEW_AREA";
export const RESET_AREA_FORM = "RESET_AREA_FORM";
export const AREA_DELETED = "AREA_DELETED";
export const FAILED_TO_DELETE_AREA = "FAILED_TO_DELETE_AREA";
export const EDIT_MODE_ON = "EDIT_MODE_ON";
export const EDIT_MODE_OFF = "EDIT_MODE_OFF";
export const AREA_PUT = "AREA_PUT";
export const FAILED_AREA_PUT = "FAILED_AREA_PUT";
//maps
export const MAPS_RETRIEVED = "MAPS_RETRIEVED";
export const FAILED_MAPS = "FAILED_MAPS";
export const STORAGE_FLIGHTS_RETRIEVED = "STORAGE_FLIGHTS_RETRIEVED";
export const FAILED_STORAGE_FLIGHTS = "FAILED_STORAGE_FLIGHTS";
export const PROCESSED_DIRS_RETRIEVED = "PROCESSED_DIRS_RETRIEVED";
export const FAILED_PROCESSED_DIRS = "FAILED_PROCESSED_DIRS";
export const FLIGHT_INFO_FETCHED = "FLIGHT_INFO_FETCHED";
export const FAILED_FLIGHT_INFO = "FAILED_FLIGHT_INFO";
export const MAP_DELETED = "MAP_DELETED";
export const FAILED_TO_DELETE_MAP = "FAILED_TO_DELETE_MAP";
export const RESET_MAP_FORM = "RESET_MAP_FORM";
export const IS_XYZ = "IS_XYZ";
export const FAILED_IS_XYZ = "FAILED_IS_XYZ";
export const MAP_ADDED = "MAP_ADDED";
export const FAILED_TO_ADD_MAP = "FAILED_TO_ADD_MAP";
//annotation
export const ANNOTATIONS_LOADING = "ANNOTATIONS_LOADING";
export const ANNOTATIONS_FETCHED = "ANNOTATIONS_FETCHED";
export const ANNOTATION_FETCHED = "ANNOTATION_FETCHED";
export const FAILED_ANNOTATIONS = "FAILED_ANNOTATIONS";
export const FAILED_ANNOTATION = "FAILED_ANNOTATION";
export const CREATED_ANNOTATION = "ADDED_ANNOTATION";
export const FAILED_NEW_ANNOTATION = "FAILED_NEW_ANNOTATION";
export const RESET_ANNOTATION_FORM = "RESET_ANNOTATION_FORM";
export const ANNOTATION_DELETED = "ANNOTATION_DELETED";
export const FAILED_TO_DELETE_ANNOTATION = "FAILED_TO_DELETE_ANNOTATION";
export const ANNOTATION_EDIT_MODE_ON = "ANNOTATION_EDIT_MODE_ON";
export const ANNOTATION_EDIT_MODE_OFF = "ANNOTATION_EDIT_MODE_OFF";
export const ANNOTATION_PUT = "ANNOTATION_AREA_PUT";
export const FAILED_ANNOTATION_PUT = "FAILED_ANNOTATION_PUT";
//change
export const CHANGES_LOADING = "CHANGES_LOADING";
export const CHANGES_FETCHED = "CHANGES_FETCHED";
export const CHANGE_LOADING = "CHANGE_LOADING";
export const CHANGE_FETCHED = "CHANGE_FETCHED";
export const FAILED_CHANGES = "FAILED_CHANGES";
export const FAILED_CHANGE = "FAILED_CHANGE";
export const CHANGE_CREATING = "CHANGE_CREATING";
export const CREATED_CHANGE = "ADDED_CHANGE";
export const FAILED_NEW_CHANGE = "FAILED_NEW_CHANGE";
export const CHANGE_EDITING = "CHANGE_EDITING";
export const CHANGE_PUT = "CHANGE_PUT";
export const FAILED_TO_PUT_CHANGE = "FAILED_TO_PUT_CHANGE";
export const CHANGE_DELETING = "CHANGE_DELETING";
export const CHANGE_DELETED = "CHANGE_DELETED";
export const FAILED_TO_DELETE_CHANGE = "FAILED_TO_DELETE_CHANGE";
export const RESET_CHANGE_FORM = "RESET_CHANGE_FORM";
export const CHANGE_EDIT_MODE_ON = "CHANGE_EDIT_MODE_ON";
export const CHANGE_EDIT_MODE_OFF = "CHANGE_EDIT_MODE_OFF";
export const CHANGE_DETECTION_STARTED = "CHANGE_DETECTION_STARTED";
export const CHANGES_GENERATED = "CHANGES_GENERATED";
export const FAILED_TO_GENERATE_CHANGES = "FAILED_TO_GENERATE_CHANGES";
//whatsnew
export const WHATSNEWS_LOADING = "WHATSNEWS_LOADING";
export const WHATSNEWS_FETCHED = "WHATSNEWS_FETCHED";
export const WHATSNEW_FETCHED = "WHATSNEW_FETCHED";
export const FAILED_WHATSNEWS = "FAILED_WHATSNEWS";
export const FAILED_WHATSNEW = "FAILED_WHATSNEW";
export const CREATED_WHATSNEW = "ADDED_WHATSNEW";
export const FAILED_NEW_WHATSNEW = "FAILED_NEW_WHATSNEW";
export const RESET_WHATSNEW_FORM = "RESET_WHATSNEW_FORM";
export const WHATSNEW_DELETED = "WHATSNEW_DELETED";
export const FAILED_TO_DELETE_WHATSNEW = "FAILED_TO_DELETE_WHATSNEW";
export const WHATSNEW_EDIT_MODE_ON = "WHATSNEW_EDIT_MODE_ON";
export const WHATSNEW_EDIT_MODE_OFF = "WHATSNEW_EDIT_MODE_OFF";
export const WHATSNEW_PUT = "WHATSNEW_AREA_PUT";
export const FAILED_WHATSNEW_PUT = "FAILED_WHATSNEW_PUT";
//detection
export const DETECTIONS_LOADING = "DETECTIONS_LOADING";
export const DETECTIONS_FETCHED = "DETECTIONS_FETCHED";
export const FAILED_DETECTIONS = "FAILED_DETECTIONS";
export const DETECTION_FETCHED = "DETECTION_FETCHED";
export const DETECTION_DELETED = "DETECTION_DELETED"
//object
export const OBJECTS_LOADING = "OBJECTS_LOADING";
export const OBJECTS_FETCHED = "OBJECTS_FETCHED";
export const FAILED_OBJECTS = "FAILED_OBJECTS";
export const OBJECT_FETCHED = "OBJECT_FETCHED";
export const CREATED_OBJECT = "CREATED_OBJECT";
export const FAILED_NEW_OBJECT = "FAILED_NEW_OBJECT";
export const OBJECT_DELETED = "OBJECT_DELETED";
export const RESET_OBJECT_FORM = "RESET_OBJECT_FORM";
export const FAILED_TO_DELETE_OBJECT = "FAILED_TO_DELETE_OBJECT";
export const OBJECTS_FETCHED_IND = "OBJECTS_FETCHED_IND";
export const OBJECT_PUT = "OBJECT_PUT";
export const FAILED_OBJECT_PUT = "FAILED_OBJECT_PUT";
//language
export const OPEN_LANGUAGE = "OPEN_LANGUAGE";
export const CLOSE_LANGUAGE = "CLOSE_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
//bucket
export const S3_BUCKETS_LOADING = "S3_BUCKETS_LOADING"
export const S3_BUCKETS_RETRIEVED = "S3_BUCKETS_RETRIEVED"
export const FAILED_S3_BUCKETS = "FAILED_S3_BUCKETS"
export const S3_BUCKET_CREATING = "S3_BUCKET_CREATING"
export const CREATED_S3_BUCKET = "CREATED_S3_BUCKET"
export const FAILED_TO_CREATE_S3_BUCKET= "FAILED_TO_CREATE_S3_BUCKET"
export const RESET_S3_BUCKET_FORM = "RESET_S3_BUCKET_FORM"
export const S3_BUCKET_DELETING = "S3_BUCKET_DELETING"
export const S3_BUCKET_DELETED = "S3_BUCKET_DELETED"
export const S3_BUCKET_LOADING = "S3_BUCKET_LOADING"
export const S3_BUCKET_FETCHED = "S3_BUCKET_FETCHED"
export const FAILED_TO_FETCH_S3_BUCKET = "FAILED_TO_FETCH_S3_BUCKET"
export const S3_BUCKET_POLICY_FETCHED = "S3_BUCKET_POLICY_FETCHED"
export const FAILED_TO_FETCH_S3_BUCKET_POLICY = "FAILED_TO_FETCH_S3_BUCKET_POLICY"
export const S3_BUCKET_CORS_FETCHED = "S3_BUCKET_CORS_FETCHED"
export const FAILED_TO_FETCH_S3_BUCKET_CORS = "FAILED_TO_FETCH_S3_BUCKET_CORS"
export const FAILED_TO_DELETE_S3_BUCKET = "FAILED_TO_DELETE_S3_BUCKETS"
export const S3_BUCKET_EDITING = "S3_BUCKET_EDITING"
export const S3_BUCKET_PUT = "S3_BUCKET_PUT"
export const FAILED_TO_PUT_S3_BUCKET = "FAILED_TO_PUT_S3_BUCKET"
//whereelse
export const WHEREELSES_REQUESTED = "WHEREELSES_REQUESTED"
export const WHEREELSES_GENERATED = "WHEREELSES_GENERATED"
export const FAILED_TO_GENERATE_WHEREELSES = "FAILED_TO_GENERATE_WHEREELSES"
export const WHEREELSES_LOADING = "WHEREELSES_LOADING"
export const WHEREELSES_RETRIEVED = "WHEREELSES_RETRIEVED"
export const FAILED_WHEREELSES = "FAILED_WHEREELSES"
export const WHEREELSE_CREATING = "WHEREELSE_CREATING"
export const CREATED_WHEREELSE = "CREATED_WHEREELSE"
export const FAILED_TO_CREATE_WHEREELSE = "FAILED_TO_CREATE_WHEREELSE"
export const WHEREELSE_LOADING = "WHEREELSE_LOADING"
export const WHEREELSE_FETCHED = "WHEREELSE_FETCHED"
export const FAILED_TO_FETCH_WHEREELSE = "FAILED_TO_FETCH_WHEREELSE"
export const WHEREELSE_DELETING = "WHEREELSE_DELETING"
export const WHEREELSE_DELETED = "WHEREELSE_DELETED"
export const FAILED_TO_DELETE_WHEREELSE = "FAILED_TO_DELETE_WHEREELSES"
export const WHEREELSE_EDITING = "WHEREELSE_EDITING"
export const WHEREELSE_PUT = "WHEREELSE_PUT"
export const FAILED_TO_PUT_WHEREELSE = "FAILED_TO_PUT_WHEREELSE"
export const RESET_WHEREELSE_FORM = "RESET_WHEREELSE_FORM"
// hosts
export const SET_ATLASML = "SET_ATLASML"