/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'
/* custom components */
/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
/* mui icons-material components */
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ShareIcon from '@mui/icons-material/Share';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
/* Redux */
import { getFlightInfo, getMapGeojson } from '../redux/actions/maps';
/* Env variables */
// const SERVER = process.env.REACT_APP_DJANGO_SERVER

class ChangeInfo extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            shareLink: ''
        }

    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        let {id, ...sharedObject} = this.props.data
        this.setState({
            // shareLink: btoa(JSON.stringify(sharedObject))
            shareLink: btoa(unescape(encodeURIComponent(JSON.stringify(sharedObject))))
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
    }

    /**
     * 
     * @param {*} e 
     */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };

    /**
     * 
     */
    handleMap = () => {
        this.props.getMapGeojson(this.props.flightPath, this.props.flightName)
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                    <div style={{
                        position: 'absolute', 
                        right: 55,
                        top: 60,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px'
                    }}>
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '300px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                    >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%'
                            }}> 
                                <Typography
                                    sx={{
                                        height: '50px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '20px',
                                        fontFamily: 'Poppins',
                                        color: '#121f33',
                                        ml: '55px'
                                    }}
                                >
                                    {/* Change Info */}
                                    {i18n.t("ChangeInfo.ChangeInfo")}
                                </Typography>
                                <IconButton onClick={this.props.handleClose} sx={{
                                    
                                }}>
                                    <Tooltip 
                                        // title='Close'
                                        title={i18n.t("ChangeInfo.Close")}
                                    >
                                        <CloseIcon />
                                    </Tooltip>
                                </IconButton>
                            </div>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip title='Id'>
                                    <FingerprintIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.data.id}
                                    id={`${this.props.data.id}-id`}
                                >
                                </InputBase>
                                {/* <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-id`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton> */}
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Name'
                                    title={i18n.t("ChangeInfo.Name")}
                                >
                                    <AddLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.confirmed?this.props.data.Name:this.props.data.properties.name}
                                    id={`${this.props.data.id}-name`}
                                >
                                </InputBase>
                                {/* <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-name`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton> */}
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Description'
                                    title={i18n.t("ChangeInfo.Description")}
                                >
                                    <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.confirmed?this.props.data.Description:this.props.data.properties.description}
                                    id={`${this.props.data.id}-description`}
                                >
                                </InputBase>
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Since'
                                    title={i18n.t("ChangeInfo.Since")}
                                >
                                    <EventRepeatIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.confirmed?this.props.data.Since:this.props.since}
                                    id={`${this.props.data.id}-name`}
                                >
                                </InputBase>
                                {/* <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-name`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton> */}
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip title='Geojson'>
                                    <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.props.confirmed?JSON.stringify(this.props.data.Geojson):JSON.stringify(this.props.data)}
                                    id={`${this.props.data.id}-geojson`}
                                >
                                </InputBase>
                                <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-geojson`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                            <div
                                id='login-page-username-container'
                            >
                                <Tooltip 
                                    // title='Share Link'
                                    title={i18n.t("ChangeInfo.ShareLink")}
                                >
                                    <ShareIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                </Tooltip>
                                <InputBase
                                    sx={{
                                        borderRadius: '3px',
                                        background: 'white',
                                        fontFamily: 'Poppins',
                                        width: '93%',
                                    }}
                                    value={this.state.shareLink}
                                    id={`${this.props.data.id}-link`}
                                >
                                </InputBase>
                                <IconButton 
                                        onClick={() => this.copyToClipboard(`${this.props.data.id}-link`)}
                                    >
                                    <ContentCopyIcon/>
                                </IconButton>
                            </div>
                            <br></br>
                        </Box>
                    </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        flightInfo: state.maps.flightInfo,
        flightName: state.maps.flightName,
        processedDirs: state.maps.processedDirs,
        language: state.language.language
    }
}

export default connect(mapStateToProps, {getFlightInfo, getMapGeojson})(ChangeInfo)