/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { createAnnotation, resetAnnotationForm } from '../redux/actions/annotation';

/* custom components */
import { BlackCheckbox, GrayCheckbox }from './CustomCheckbox'

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'

/* mui icons-material components */
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit';

// const SERVER = process.env.REACT_APP_DJANGO_SERVER

class MapFeatureList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount() {
    }
    
    /**
     * 
     * @returns the component 
     */
    render() {
        return (
            <div id="timelapse-fclayers-main-container">
                <div id="timelapse-fclayers-header-container">
                    <div id='timelapse-fclayer-manager-container'>
                        {
                        !this.props.isDisabled
                            ?
                        <BlackCheckbox size='medium' checked={this.props.checkedAll} indeterminate={this.props.isIndeterminate} onChange={() => this.props.handleCheckAll(!this.props.checkedAll, this.props.featureCollection)} disabled={this.props.isDisabled}/>
                            :
                        <GrayCheckbox size='medium' checked={this.props.checkedAll} indeterminate={!this.props.isIndeterminate} disabled={true}/>
                        }
                        <Typography sx={{ml: '0px', fontFamily: 'Poppins', color: this.props.isDisabled ? '#b1b2b0' :'black'}}>
                            {this.props.nameOfList}
                        </Typography>
                        <IconButton onClick={this.props.handleCollapse} >
                            {
                                this.props.isCollapsed
                                ?
                                <Tooltip 
                                    // title='collapse the list'
                                    title={i18n.t("MapFeatureList.CollapseTheList")}
                                >
                                    <ExpandMoreIcon sx={{color: 'black'}}/>
                                </Tooltip>
                                :
                                <Tooltip 
                                    // title='expand the list'
                                    title={i18n.t("MapFeatureList.ExpandTheList")}
                                >
                                    <KeyboardArrowRightRoundedIcon sx={{color: 'black'}}/>
                                </Tooltip>
                            }
                        </IconButton>
                        {
                            this.props.upload
                            ?
                            <div style={{position:'absolute', right: '2px'}}>
                                <Tooltip title={i18n.t("MapFeatureList.Import")}>
                                    <IconButton onClick={this.props.handleUpload}>
                                        <DownloadIcon sx={{color: 'black'}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <Collapse in={this.props.isCollapsed} sx={{mt: '-5px'}}>
                    <List>
                        {
                            this.props.features.length !== 0
                            ?
                            this.props.features.map((feature, i) =>
                            <ListItem key={i} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', height: '45px', background: this.props.id===feature.id?'whitesmoke':''}}>
                                {
                                    this.props.isDisabled
                                    ?
                                    <GrayCheckbox size='medium' checked={feature.Displayed} disabled={true}/>
                                    :
                                    <BlackCheckbox size='medium' checked={feature.Displayed} onChange={() => this.props.handleCheckOne(feature.id, this.props.featureCollection)}/>
                                }
                                <ListItemButton id={feature.id} onClick={() => this.props.handleClickOnFeature(feature.id, this.props.fType)} disabled={this.props.isDisabled}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%'}}>
                                        <Typography sx={{fontSize: '12px', width: '130px', fontFamily:'Poppins', mt: '2px', ml: '-18px'}}>
                                            <span style={{paddingLeft: '5px', overflowWrap: "break-word", width: '100%'}}>
                                            {`${feature.Name}`}
                                            </span>
                                        </Typography>
                                    </div>
                                </ListItemButton>
                                {
                                    this.props.delete&&feature.User==this.props.user.id ?
                                    <IconButton onClick={() => this.props.handleDeleteOne(feature.id)} disabled={this.props.isDisabled}>
                                        <DeleteIcon sx={{color: 'black', fontSize: '20px'}} />
                                    </IconButton> :
                                    null
                                }
                                {
                                    this.props.delete&&feature.User!=this.props.user.id 
                                    ?
                                    <Tooltip 
                                        // title='Save' 
                                        title={i18n.t("MapFeatureList.Save")}
                                        placement='left'
                                    >
                                        <IconButton onClick={() => this.props.handleSaveAnnotation(feature.id)} disabled={this.props.isDisabled}>
                                            <SaveIcon sx={{color: 'black', fontSize: '20px'}} />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    null
                                }
                                {
                                    this.props.edit&&feature.User==this.props.user.id ?
                                    <IconButton onClick={() => this.props.handleEditOne(feature.id)} disabled={this.props.isDisabled}>
                                        <EditIcon sx={{color: 'black', fontSize: '20px'}} />
                                    </IconButton> :
                                    null
                                }
                            </ListItem>
                            )
                            :
                            null
                        }
                    </List>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userGroup: state.auth.user.userGroup,
        language: state.language.language
    }
}
export default  connect(mapStateToProps, {createAnnotation, resetAnnotationForm})(MapFeatureList)