import {
    WHATSNEWS_LOADING,
    WHATSNEWS_FETCHED,
    CREATED_WHATSNEW,
    WHATSNEW_DELETED,
    RESET_WHATSNEW_FORM,
    FAILED_TO_DELETE_WHATSNEW
  } from '../actions/types';

const initialState = {
    whatsnew: {},
    whatsnewList: [],
    isWhatsnewsFetched: false,
    isWhatsnewListLoading: false,
    isWhatsnewCreated: false,
    isFailedToCreateWhatsnew: false,
    isWhatsnewDeleted: false,
    isFailedToDeleteWhatsnew: false
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case WHATSNEWS_LOADING:
        return {
            ...state,
            isWhatsnewListLoading: true,
            isWhatsnewsFetched: false
        }
      case WHATSNEWS_FETCHED:
        return {
            ...state,
            whatsnewList: action.payload,
            isWhatsnewListLoading: false,
            isWhatsnewsFetched: true
        }
      case CREATED_WHATSNEW:
        return {
            ...state,
            isWhatsnewCreated: true,
            isFailedToCreateWhatsnew: false,
            message: action.payload
        }
      case RESET_WHATSNEW_FORM:
        return {
          ...state,
          isWhatsnewCreated: false,
          isFailedToCreateWhatsnew: false,
          isWhatsnewDeleted: false,
          isFailedToDeleteWhatsnew: false, 
          message: '',
          isWhatsnewPut: false,
          isFailedToPutWhatsnew: false,
          isEditMode: false,
          isWhatsnewsFetched: false
        }
      case WHATSNEW_DELETED:
        return {
          ...state,
          isWhatsnewDeleted: true,
          isFailedToDeleteWhatsnew: false,
          message: ''
        }
      case FAILED_TO_DELETE_WHATSNEW:
        return {
          ...state,
          isFailedToDeleteWhatsnew: true,
          isWhatsnewDeleted: false,
          message: ''
        }
      default:
        return state;
    }
}
  




