/* eslint-disable eqeqeq */
import React from 'react'
import axios from 'axios'
import { connect } from "react-redux";
import i18n from 'i18next'

/* custom components */

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

/* mui icons-material components */
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import FlipIcon from '@mui/icons-material/Flip';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

/* assets */ 
// const SERVER = process.env.REACT_APP_DJANGO_SERVER
const BASE_URL = process.env.REACT_APP_BASE_URL

/**
 * 
 */
 const config = {
    headers: {
      Authorization: `token ${window.localStorage.getItem('token')}`
    }
}

class ComparePanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapseLayerList: true,
            rasterLayers: [],
            mapArea: null,
            firstLayerDate: null,
            secondLayerDate: null,
            regionName: '',
            areaId: 0
        }

        /* Function bindings here */
        this.handleCollapseRasters = this.handleCollapseRasters.bind(this)
        this.goTo = this.goTo.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleMapSelectFirst = this.handleMapSelectFirst.bind(this)
        this.handleMapSelectSecond = this.handleMapSelectSecond.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        let regionName = params.regionName
        let areaId = params.areaId
        this.setState({
            regionName: regionName,
            areaId: areaId
        })
        axios.get(`/flightsapi/storage?Area=${areaId}`, config).then((res) => {
            let filteredLayers = res.data
            this.setState({rasterLayers: filteredLayers})
        })
        try {
            this.setState({firstLayerDate: JSON.parse(window.localStorage.getItem('firstLayer')).FlightDate})
            this.setState({secondLayerDate: JSON.parse(window.localStorage.getItem('secondLayer')).FlightDate})
        } catch (error) {
            
        }
    }

    /**
     * 
     */
    handleChange(){
        window.localStorage.setItem('firstLayer', JSON.stringify(this.state.rasterLayers[3]))
        window.location.reload()
    }

    /**
     * 
     */
    handleCollapseRasters(e) {
        try {
            this.setState({
                collapseLayerList: !this.state.collapseLayerList
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * 
     */
     goTo(page){
        const urlSearchParams=new URLSearchParams(window.location.search)
        window.location.replace(`${BASE_URL}/${page}?${urlSearchParams}`)
    }


    /**
     * 
     */
    handleMapSelectFirst(e){
        this.setState({firstLayerDate: e.target.value})
        let layer = this.state.rasterLayers.find((layer) => layer.FlightDate === e.target.value)
        window.localStorage.setItem('firstLayer', JSON.stringify(layer))
        window.location.reload()
    }

    /**
     * 
     */
    handleMapSelectSecond(e){
        this.setState({secondLayerDate: e.target.value})
        let layer = this.state.rasterLayers.find((layer) => layer.FlightDate === e.target.value)
        window.localStorage.setItem('secondLayer', JSON.stringify(layer))
        window.location.reload()
    }
    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <div id="compare-panel-main-container">
                    {
                        this.state.rasterLayers.length > 0
                        ?
                        <div id="compare-panel-map-area-name-container" >
                            <Tooltip title='timelapse'>
                                <IconButton onClick={() => this.goTo('timelapse')} >
                                    <ArrowBackIosNewIcon sx={{color: 'black', fontSize: '18px'}}/>
                                </IconButton>
                            </Tooltip>
                            <div id="compare-panel-map-area-text">
                                <Tooltip 
                                    // title='Fly to map area'
                                    title={i18n.t("ComparePanel.FlyToMapArea")}
                                >
                                    <IconButton onClick={this.props.handleMapIcon } sx={{'&:hover': { backgroundColor: 'transparent'}}}>
                                        <Typography sx={{fontFamily: 'Poppins',color: 'black'}}>
                                        {this.state.regionName}
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Tooltip 
                                // title='Difftool'
                                title={i18n.t("ComparePanel.Difftool")}
                            >
                                <IconButton onClick={() => this.goTo('difftool')} >
                                    <LayersOutlinedIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        :
                        <div id="compare-panel-map-area-name-container">
                            {null}
                        </div>
                    }
                <div id="compare-panel-firstlayer-container">
                    <FlipIcon/>
                    <div className="compare-panel-formcontrol-container">
                        <FormControl fullWidth={true}>
                            <Select sx={{height: '30px', color: 'black', fontFamily: 'Poppins'}} value={this.state.firstLayerDate} onChange={this.handleMapSelectFirst}>
                                {
                                    this.state.rasterLayers.map((layer, i) => 
                                    <MenuItem value={layer.FlightDate}>
                                        {layer.FlightDate}
                                    </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div id="compare-panel-secondlayer-container">
                    <FlipIcon sx={{transform: 'rotate(180deg)'}} />
                    <div className="compare-panel-formcontrol-container">
                        <FormControl fullWidth={true}>
                            <Select value={this.state.secondLayerDate} labelId='map-id' sx={{height: '30px', color: 'black', fontFamily: 'Poppins'}} onChange={this.handleMapSelectSecond}>
                                {
                                    this.state.rasterLayers.map((layer, i) => 
                                    <MenuItem value={layer.FlightDate}>
                                        {layer.FlightDate}
                                    </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      language: state.language.language
    };
  };

export default connect(mapStateToProps, {

  })(ComparePanel);