/* eslint-disable eqeqeq */
import React from 'react'

/* Redux */

/* custom components */

/* mui material components */
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components*/

/* helpers */
import { } from '../helpers/helpers'

/* assets */

class CustomCircularProgress extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){

    }

    /**
     * 
     * @returns the component 
     */
    render () {

        return (
            <div style={{
                display:"flex", 
                flexDirection: "column", 
                width: '100%', 
                height: '100%', 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                <br></br>
                    <CircularProgress sx={{ color:'#121f33'}} size={23}/>
                <br></br>
            </div>
        )
    }
}

export default (CustomCircularProgress)