/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import i18n from 'i18next'

/*redux*/
import {
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
  } from "../redux/actions/bucket";

/* custom components */

/* mui material components */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";

/* mui icons-material components */
import GroupIcon from '@mui/icons-material/Group';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

class CreateBucket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bucketName: '',
            region: 'eu-central-1',
            accessKeyId: window.sessionStorage.getItem("accessKeyId")? window.sessionStorage.getItem("accessKeyId") : process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: window.sessionStorage.getItem("secretAccessKey")? window.sessionStorage.getItem("secretAccessKey"):process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            regions: ['eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-west-3', 'eu-north-1']
        };

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * When the component is rendered
     */
    componentDidMount() {
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps) {
        if (prevProps.isBucketListLoading !== this.props.isBucketListLoading && !this.props.isBucketListLoading) {
            this.props.resetBucketForm()
        }
    }
    /* */
    handleSubmit() {
        window.sessionStorage.setItem("accessKeyId",this.state.accessKeyId)
        window.sessionStorage.setItem("secretAccessKey",this.state.secretAccessKey)
        let bucket = {
            name: this.state.bucketName,
            region: this.state.region,
            accessKeyId: this.state.accessKeyId,
            secretAccessKey: this.state.secretAccessKey
        }
        this.props.createBucket(bucket)
        this.setState({
            bucketName: '',
            region: 'eu-central-1',
            accessKeyId: '',
            secretAccessKey: ''
        })
    }
    /* */
    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };
    /* */
    handleRegion(e) {
        this.setState({
            region: e.target.value
        })
    }
    /**
     *
     * @returns the component
     */
    render() {
        return (
            <div id="add-user-page-container">
                <div id="admin-page-alert-container">
                    {this.props.isBucketCreated ? (
                    <Alert
                        onClose={this.props.resetBucketForm}
                        severity="success"
                    >{`${this.props.alert}`}</Alert>
                    ) : null}
                    {this.props.isBucketFailedToCreate ? (
                    <Alert
                        onClose={this.props.resetBucketForm}
                        severity="error"
                    >{`${this.props.alert}`}</Alert>
                    ) : null}
                </div>
                <Box
                    sx={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "500px",
                    borderRadius: "10px",
                    border: "solid 1px lightgray",
                    background: "white",
                    }}
                    component="form"
                    type="form"
                    onSubmit={this.handleSubmit}
                >
                    <Typography
                    sx={{
                        height: "70px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "30px",
                        fontFamily: "Poppins",
                        color: "#121f33",
                    }}
                    >
                    {/* Create S3 Bucket */}
                    {i18n.t("CreateBucket.CreateS3Bucket")}
                    </Typography>
                    <div id="login-page-username-container">
                    <Tooltip title={i18n.t("CreateBucket.BucketName")}>
                        <GroupIcon sx={{ color: "lightgray", ml: "5px", mr: "4px" }} />
                    </Tooltip>
                    <InputBase
                        sx={{
                        borderRadius: "3px",
                        background: "white",
                        fontFamily: "Poppins",
                        width: "93%",
                        }}
                        // placeholder="Bucket Name"
                        placeholder={i18n.t("CreateBucket.BucketName")}
                        autoFocus
                        required
                        value={this.state.bucketName}
                        onChange={this.handleChange("bucketName")}
                    />
                    </div>
                    <br />
                    <div id='admin-panel-formcontrol-container'>
                    <FormControl 
                        placeholder='E-mail'
                        fullWidth sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Select
                            value={this.state.userGroup} 
                            defaultValue={-1} 
                            sx={{height: '42px', color: this.state.userGroup==-1?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                            onChange={this.handleUserGroup}
                        >
                            <MenuItem value={-1} disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                {/* Select Region */}
                                {i18n.t("CreateBucket.SelectRegion")}
                            </MenuItem>
                            {
                                this.state.regions.map((region)=>
                                    <MenuItem value={region} sx={{fontFamily: 'Poppins'}}>{region}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    </div>
                    <br />
                    <div id="login-page-username-container">
                    <Tooltip title="AWS Access Key ID">
                        <GroupIcon sx={{ color: "lightgray", ml: "5px", mr: "4px" }} />
                    </Tooltip>
                    <InputBase
                        sx={{
                        borderRadius: "3px",
                        background: "white",
                        fontFamily: "Poppins",
                        width: "93%",
                        }}
                        placeholder="AWS Access Key ID"
                        autoFocus
                        required
                        value={this.state.accessKeyId}
                        onChange={this.handleChange("accessKeyId")}
                    />
                    </div>
                    <br />
                    <div id="login-page-username-container">
                    <Tooltip title="AWS Secret Access Key">
                        <VpnKeyIcon sx={{ color: "lightgray", ml: "5px", mr: "4px" }} />
                    </Tooltip>
                    <InputBase
                        sx={{
                        borderRadius: "3px",
                        background: "white",
                        fontFamily: "Poppins",
                        width: "93%",
                        }}
                        placeholder="AWS Secret Access Key"
                        autoFocus
                        required
                        value={this.state.secretAccessKey}
                        onChange={this.handleChange("secretAccessKey")}
                    />
                    </div>
                    <br />
                    <Button
                    sx={{
                        width: "91%",
                        height: "37px",
                        mb: "20px",
                        background: "#1aaa55",
                        color: "white",
                        fontSize: "13px",
                        fontFamily: "Poppins",
                        "&:hover": {
                        background: "#168f48",
                        },
                        borderRadius: "4px",
                    }}
                    onClick={this.handleSubmit}
                    type="submit"
                    >
                    {/* Submit */}
                    {i18n.t("CreateBucket.Submit")}
                    </Button>
                </Box>
            </div>
            );
        }
    }

const mapStateToProps = (state) => {
    return {
        alert: state.bucket.alert,
        isBucketListLoading: state.bucket.isBucketListLoading,
        bucketList: state.bucket.bucketList,
        isBucketListFetched: state.bucket.isBucketListFetched,
        isBucketListFailedToFetch: state.bucket.isBucketListFailedToFetch,
        isBucketCreating: state.bucket.isBucketCreating,
        isBucketCreated: state.bucket.isBucketCreated,
        isBucketFailedToCreate: state.bucket.isBucketFailedToCreate,
        bucketPolicy: state.bucket.bucketPolicy,
        newBucket: state.bucket.newBucket,
        isBucketLoading: state.bucket.isBucketLoading,
        isBucketFetched: state.bucket.isBucketFetched,
        isBucketFailedToFetch: state.bucket.isBucketFailedToFetch,
        isBucketPolicyFetched: state.bucket.isBucketPolicyFetched,
        isBucketPolicyFailedToFetch: state.bucket.isBucketPolicyFailedToFetch,
        isBucketCorsFetched: state.bucket.isBucketCorsFetched,
        isBucketCorsFailedToFetch: state.bucket.isBucketCorsFailedToFetch,
        isBucketDeleting: state.bucket.isBucketDeleting,
        isBucketDeleted: state.bucket.isBucketDeleted,
        isBucketFailedToDelete: state.bucket.isBucketFailedToDelete,
        isBucketEditing: state.bucket.isBucketEditing,
        isBucketPut: state.bucket.isBucketPut,
        isBucketFailedToPut: state.bucket.isBucketFailedToPut,
        language: state.language.language
    };
};
      
export default connect(mapStateToProps, {
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
})(CreateBucket);
