/* eslint-disable eqeqeq */
import React from "react";
import { connect } from "react-redux";
import i18n from 'i18next'

import {
  getBucketList,
  createBucket,
  resetBucketForm,
  deleteBucket,
  getBucketPolicy,
  getBucketCors,
  putBucketPolicy,
  putCORSConfigurations
} from "../redux/actions/bucket";

/* custom components */
import AwsCredentials from "./AwsCredentials";
import BasicAlert from "./BasicAlert";
import BucketPolicy from "./BucketPolicy";
import BucketCors from "./BucketCors";

/* mui material components */
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { DataGrid, enUS,  trTR} from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip'
import Button from "@mui/material/Button";

/* mui icons-material components */
import DeleteIcon from "@mui/icons-material/Delete";
import PolicyIcon from '@mui/icons-material/Policy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InfoIcon from '@mui/icons-material/Info';

class BucketTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      accessKeyId: '',
      secretAccessKey: '',
      showCredentials: false,
      showPolicy: false,
      showCors: false,
      selectedBucketName: ''
    };

    /* Function bindings here */
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePolicy = this.handlePolicy.bind(this);
    this.handleCors = this.handleCors.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCredentials = this.handleCredentials.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.unsetAlert = this.unsetAlert.bind(this);
  }

  /**
   * When the component is rendered
   */
  componentDidMount() {}

  /**
   * When the component is updated
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isBucketCreated !== this.props.isBucketCreated && this.props.isBucketCreated) {
      this.props.getBucketList({
        accessKeyId: window.sessionStorage.getItem("accessKeyId"),
        secretAccessKey: window.sessionStorage.getItem("secretAccessKey"),
      })
    }
    if (prevProps.isBucketFailedToCreate !== this.props.isBucketFailedToCreate && this.props.isBucketFailedToCreate) {
      setTimeout(() => {
        this.props.resetBucketForm()
      },2500)
    }
    if (prevProps.isBucketDeleting !== this.props.isBucketDeleting && this.props.isBucketDeleting) {
      this.unsetAlert()
    }
    if (prevProps.isBucketDeleted !== this.props.isBucketDeleted && this.props.isBucketDeleted) {
      this.props.getBucketList({
        accessKeyId: window.sessionStorage.getItem("accessKeyId"),
        secretAccessKey: window.sessionStorage.getItem("secretAccessKey"),
      })
    }
    if (prevProps.isBucketFailedToDelete !== this.props.isBucketFailedToDelete && this.props.isBucketFailedToDelete) {
      setTimeout(() => {
        this.props.resetBucketForm()
      },2500)
    }
    if (prevProps.isBucketEditing !== this.props.isBucketEditing && this.props.isBucketEditing) {
      this.unsetAlert()
    }
    if (prevProps.isBucketPut !== this.props.isBucketPut && this.props.isBucketPut) {
      this.props.getBucketList({
        accessKeyId: window.sessionStorage.getItem("accessKeyId"),
        secretAccessKey: window.sessionStorage.getItem("secretAccessKey"),
      })
    }
    if (prevProps.isBucketFailedToPut !== this.props.isBucketFailedToPut  && this.props.isBucketFailedToPut ) {
      setTimeout(() => {
        this.props.resetBucketForm()
      },2500)
    }
    if (prevProps.isBucketListLoading !== this.props.isBucketListLoading && !this.props.isBucketListLoading) {
      this.props.resetBucketForm()
    }
  }
  /* */
  handleChange = (name) => (event) => {
    this.setState({
        [name]: event.target.value,
    });
  };
  /* */
  handlePolicy(bucketName) {
    this.props.resetBucketForm()
    let credentials = {
      accessKeyId: window.sessionStorage.getItem("accessKeyId"),
      secretAccessKey: window.sessionStorage.getItem("secretAccessKey")
    }
    this.props.getBucketPolicy(bucketName, credentials)
    this.setState({
      showPolicy: true,
      selectedBucketName: bucketName
    })
  }
  /* */
  handleCors(bucketName){
    this.props.resetBucketForm()
    let credentials = {
      accessKeyId: window.sessionStorage.getItem("accessKeyId"),
      secretAccessKey: window.sessionStorage.getItem("secretAccessKey")
    }
    this.props.getBucketCors(bucketName, credentials)
    this.setState({
      showCors: true,
      selectedBucketName: bucketName
    })
  }
  /* */
  handleDelete(bucketName) {
    this.setAlert(
      // `Do you want to delete ${bucketName}?`, 
      `${i18n.t("BucketTable.DoYouWantToDelete")} ${bucketName}?`, 
      ()=>this.props.deleteBucket(
        bucketName, 
        {
          accessKeyId: window.sessionStorage.getItem("accessKeyId"),
          secretAccessKey: window.sessionStorage.getItem("secretAccessKey")
        }), 
      () => this.props.unsetAlert(), 
      // 'Delete', 
      i18n.t("BucketTable.Delete"),
      // 'Cancel',
      i18n.t("BucketTable.Cancel"), 
      true, 
      'warning'
    )
  }
  /* */
  handleClose() {
    this.props.resetBucketForm()
    this.setState({
      showCredentials: false,
      showPolicy: false,
      showCors: false
    });
  }
  /* */
  handleCredentials() {
    this.setState({
      showCredentials: true
    })
  }
  /* */
  setAlert(message, actionFunction, cancelAction, confirmText, cancelText, isWithAction, type, withIcon, hasCancel) {
    this.setState({
        alert: (
            <BasicAlert
                message={message}
                onConfirm={actionFunction}
                onCancel={cancelAction}
                onClose={()=>this.unsetAlert()}
                confirmText={confirmText}
                cancelText={cancelText}
                isWithAction={isWithAction}
                type={type}
                withIcon={withIcon}
            />
        )
    });
  }
  /* */
  unsetAlert() {
      this.setState({
          alert: null
      });
  }
  /**
   *
   * @returns the component
   */
  render() {
    const Delete = (props) => (
      <IconButton onClick={() => this.handleDelete(props.id)}>
        <DeleteIcon />
      </IconButton>
    );
    const Policy = (props) => (
      <IconButton onClick={() => this.handlePolicy(props.id)}>
        <PolicyIcon />
      </IconButton>
    );
    const Cors = (props) => (
      <IconButton onClick={() => this.handleCors(props.id)}>
        <AccountTreeIcon />
      </IconButton>
    );
    const Date = (props) => (
      <Tooltip placement='left' title={this.props.bucketList.find(bucket=>bucket.Name==props.id)?this.props.bucketList.find(bucket=>bucket.Name==props.id).CreationDate:''}>
          <IconButton onClick={()=>console.log()}>
              <InfoIcon/>
          </IconButton> 
      </Tooltip>
    );
    const columns = [
      { 
        field: "name", 
        // headerName: "Name", 
        headerName: i18n.t("BucketTable.Name"),
        width: 204 
      },
      {
        field: "date",
        // headerName: "Date",
        headerName: i18n.t("BucketTable.Date"),
        width: 60,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Date(props),
      },
      {
        field: "cors",
        headerName: "Cors",
        width: 60,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Cors(props),
      },
      {
        field: "policy",
        headerName: "Policy",
        width: 84,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Policy(props),
      },
      {
        field: "delete",
        // headerName: "Delete",
        headerName: i18n.t("BucketTable.Delete"),
        width: 84,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (props) => Delete(props),
      },
    ];
    const rows = this.props.bucketList.map((bucket, i) => ({
      id: bucket.Name,
      name: bucket.Name,
      policy: "policy",
      cors: "cors",
      delete: "delete",
    }));
    return (
      <div id="group-table-page-container">
        <div id="group-page-alert-container">
          {this.props.isBucketFailedToDelete ? (
            <Alert severity="error">{`${this.props.alert}`}</Alert>
          ) : null}
          {this.props.isBucketFailedToPut ? (
            <Alert severity="error">{`${this.props.alert}`}</Alert>
          ) : null}
        </div>
        {this.state.alert}
        <div id="buckets-table-container">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowHeight={58}
            rowsPerPageOptions={[5]}
            sx={{
              fontFamily: "Poppins",
              overflowY: "hidden",
            }}
            disableSelectionOnClick
            localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
          >
          </DataGrid>
          <div style={{position: 'absolute', bottom: "10px", left: "10px", width: "170px"}}>
            <Button
              sx={{
                  width: "91%",
                  height: "37px",
                  background: "#1aaa55",
                  color: "white",
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  "&:hover": {
                  background: "#168f48",
                  },
                  borderRadius: "4px",
              }}
              onClick={this.handleCredentials}
              type="submit"
            >
                {/* Enter Credentials */}
                {i18n.t("BucketTable.EnterCredentials")}
            </Button>
          </div>
        </div>
        <AwsCredentials 
          isDialogOpen={this.state.showCredentials}
          handleClose={this.handleClose}
        />
        <BucketPolicy
          isDialogOpen={this.state.showPolicy}
          handleClose={this.handleClose}
          bucketName={this.state.selectedBucketName}
        />
        <BucketCors
          isDialogOpen={this.state.showCors}
          handleClose={this.handleClose}
          bucketName={this.state.selectedBucketName}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.bucket.alert,
    isBucketListLoading: state.bucket.isBucketListLoading,
    bucketList: state.bucket.bucketList,
    isBucketListFetched: state.bucket.isBucketListFetched,
    isBucketListFailedToFetch: state.bucket.isBucketListFailedToFetch,
    isBucketCreating: state.bucket.isBucketCreating,
    isBucketCreated: state.bucket.isBucketCreated,
    isBucketFailedToCreate: state.bucket.isBucketFailedToCreate,
    bucketPolicy: state.bucket.bucketPolicy,
    newBucket: state.bucket.newBucket,
    isBucketLoading: state.bucket.isBucketLoading,
    isBucketFetched: state.bucket.isBucketFetched,
    isBucketFailedToFetch: state.bucket.isBucketFailedToFetch,
    isBucketPolicyFetched: state.bucket.isBucketPolicyFetched,
    isBucketPolicyFailedToFetch: state.bucket.isBucketPolicyFailedToFetch,
    isBucketCorsFetched: state.bucket.isBucketCorsFetched,
    isBucketCorsFailedToFetch: state.bucket.isBucketCorsFailedToFetch,
    isBucketDeleting: state.bucket.isBucketDeleting,
    isBucketDeleted: state.bucket.isBucketDeleted,
    isBucketFailedToDelete: state.bucket.isBucketFailedToDelete,
    isBucketEditing: state.bucket.isBucketEditing,
    isBucketPut: state.bucket.isBucketPut,
    isBucketFailedToPut: state.bucket.isBucketFailedToPut,
    language: state.language.language
  };
};

export default connect(mapStateToProps, {
  getBucketList,
  createBucket,
  resetBucketForm,
  deleteBucket,
  getBucketPolicy,
  getBucketCors,
  putBucketPolicy,
  putCORSConfigurations
})(BucketTable);
