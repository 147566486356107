/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next';

/* mui material components */
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';


class BasicAlert extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isOpen: true
        }

        /* Function bindings here */
    }
    /**
     * When the component is rendered
     */
    componentDidMount(){
    }
    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }
    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <Dialog
                open={this.state.isOpen}
            >
                <br></br>
                <Box sx={{
                    height: 'auto', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    alignItems: 'center', 
                    width: '500px',
                    borderRadius: '10px',
                    }}
                    >
                        <Alert 
                            severity={this.props.type}
                            sx={{width:"84%", fontFamily: "Poppins"}}
                            onClose={this.props.onClose}
                            closeText={i18n.t("BasicAlert.Close")}
                            icon={this.props.withIcon}
                        >
                            {this.props.message}
                        </Alert> 
                </Box>
                {
                    this.props.isWithAction?
                    <DialogActions >
                        <div id='edit-form-button-container'>
                            <Button
                                sx={{
                                    width: this.props.hasCancel ? '46%' : '93%',
                                    height: '37px',
                                    background: '#1aaa55',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#168f48',
                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.onConfirm}
                            >
                                {`${this.props.confirmText}`}
                            </Button>
                            <Button
                                sx={{
                                    width: '46%',
                                    height: '37px',
                                    background: '#DC143C',
                                    color: 'white',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#b22222',
                                    },
                                    borderRadius: '4px',
                                    display: this.props.hasCancel ? '' : 'none'
                                }}
                                onClick={this.props.onCancel}
                            >
                                {`${this.props.cancelText}`}
                            </Button>                   
                        </div>
                    </DialogActions>
                    :
                    <br/>
                }
            </Dialog>
        )
    }
}

export default (BasicAlert)