/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'
/* configs */
import { IS_ATLAS_ML } from '../configs/hosts' 

/* Redux */
import { connect } from 'react-redux'
import { getWhatsnewList, createWhatsnew, resetWhatsnewForm } from '../redux/actions/whatsnew';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton';

/* mui icons-material components */
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class WhatsnewForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: '',
            alwaysDisplay: false,
            zoom: 12,
        }

        /* Function bindings here */
        this.handleChange = this.handleChange.bind(this)
        this.handleAlwaysDisplay = this.handleAlwaysDisplay.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        const urlSearchParams=new URLSearchParams(window.location.search)
        const params=Object.fromEntries(urlSearchParams.entries())
        this.setState({
            name: this.props.name,
            description: this.props.description,
            zoom: params.zoom
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaPut !== this.props.isAreaPut && this.props.isAreaPut){
            this.props.getAreaList()
        }

        if(prevProps.isEditMode !== this.props.isEditMode && this.props.isEditMode){
            let editedArea=this.props.areaList.find((area)=>area.id==this.props.editedAreaId)
            this.setState({
                area: {...editedArea}
            })
        }
    }
    /* */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /* */
    handleAlwaysDisplay(e) {
        this.setState({
            alwaysDisplay: !this.state.alwaysDisplay
        })
    }
    /* */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    };
    /* */
    render () {
            return (
                <div style={{
                    position: 'absolute', 
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    marginBottom: 'auto', 
                    marginTop: 'auto', 
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 200, 
                    width: 'fit-content', 
                    height: 'fit-content',
                    paddingBottom: 10,
                    gap: '10px', 
                    fontFamily: 'Poppins', 
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '7px'
                }}>
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.props.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {this.props.isEditMode ? i18n.t("WhatsnewForm.EditWhatsnew") : i18n.t("WhatsnewForm.SaveWhatsnew")}
                        </Typography>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("WhatsnewForm.Name")}>
                                {
                                    this.props.isEditMode
                                    ?
                                    <EditLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    :
                                    <AddLocationIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                }
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("WhatsnewForm.Name")}
                                required
                                value={this.props.name}
                                onChange={this.props.handleChange('changeName')}
                                autoFocus={true}
                            />
                        </div>
                        <br/>
                        <div id='login-page-username-container'>
                            <Tooltip title={i18n.t("WhatsnewForm.Description")}>
                                <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={i18n.t("WhatsnewForm.Description")}
                                value={this.props.description}
                                onChange={this.props.handleChange('changeDescription')}
                            />
                        </div>
                        <br></br>
                        <div
                            id='login-page-username-container'
                        >
                            <Tooltip title='Geojson'>
                                <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                value={JSON.stringify(this.props.data)}
                                id='form-geojson'
                            >
                            </InputBase>
                            <IconButton 
                                onClick={() => this.copyToClipboard('form-geojson')}
                            >
                                <ContentCopyIcon/>
                            </IconButton>
                        </div>
                    </Box>
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '44%',
                                height: '37px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("WhatsnewForm.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '44%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("WhatsnewForm.Close")}
                        </Button>                 
                    </div>
                    {
                        IS_ATLAS_ML
                        ?
                        <div>
                            <Button sx={{
                                    width: '100%',
                                    height: '37px',
                                    background: '1aaa55',
                                    color: 'black',
                                    fontSize: '13px',
                                    fontFamily: 'Poppins',
                                    '&:hover':{
                                        background: '#1aaa55',
                                        color: 'white',

                                    },
                                    borderRadius: '4px',
                                }}
                                onClick={this.props.handleChangeDetection}
                                disabled={this.props.isChangeDetectionStarted&&!this.props.isChangeDetectionsGenerated}
                                >
                                {i18n.t("WhatsnewForm.RunChangeDetection")}
                            </Button>
                        </div>
                        :
                        null
                    }
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        // isAreaCreated: state.whatsnew.isChangeCreated,
        // isFailedToCreateChange: state.whatsnew.isFailedToCreateChange,
        isWhatsnewCreated: state.whatsnew.isWhatsnewCreated,
        isFailedToCreateWhatsnew: state.whatsnew.isFailedToCreateWhatsnew,
        whatsnewList: state.whatsnew.whatsnewList,
        message: state.whatsnew.message,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML,
        isChangeDetectionStarted: state.change.isChangeDetectionStarted,
        isChangeDetectionsGenerated: state.change.isChangeDetectionsGenerated,
        changeDetectionResults: state.change.changeDetectionResults,
        isFailedToGenerateChangeDetections: state.change.isFailedToGenerateChangeDetections
    }
}

export default connect(mapStateToProps, { getWhatsnewList, createWhatsnew, resetWhatsnewForm })(WhatsnewForm)