/* eslint-disable eqeqeq */
import React from 'react'
import { Link } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux'
import { getAnnotationList, createAnnotation } from '../redux/actions/annotation';

/* custom components */

/* mui material components */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

/* mui icons-material components */

/* assets */ 

class DatasetCard extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            latestDate: null,
            center: null,
            imageUrl: null
        }
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * 
     * @returns the component 
     */
    render () {

        return (
            <Link 
                style={{textDecoration: 'none'}} 
                to={`/timelapse?regionName=${this.props.regionName}&areaId=${this.props.areaId}&areaLng=${this.props.center[0]}&areaLat=${this.props.center[1]}&lng=${this.props.center[0]}&lat=${this.props.center[1]}&zoom=12`}>
                <Card>
                    <CardMedia
                        component='img'
                        alt={`${this.props.regionName}`}
                        height='150'
                        image={this.props.imageUrl}
                    />
                    <CardContent sx={{height: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontFamily: 'Poppins'}}>
                            {`${this.props.regionName}`}
                        </Typography>
                    </CardContent>
                </Card>
            </Link>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAnnotationCreated: state.annotation.isAnnotationCreated,
        isFailedToCreateAnnotation: state.annotation.isFailedToCreateAnnotation,
        annotationList: state.annotation.annotationList,
        message: state.annotation.message
    }
}

export default connect(mapStateToProps, { getAnnotationList, createAnnotation })(DatasetCard)