/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* redux */
import { connect } from "react-redux";

/* mui material components */
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import FilledInput from '@mui/material/FilledInput';

/* mui icons-material components */
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneAllIcon from '@mui/icons-material/DoneAll';


class CopySuccessNotif extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            copied: false
        }

        /* Function bindings here */
        this.handleCopy = this.handleCopy.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){

    }

    /**
     * 
     */
    handleCopy(id) {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
            this.setState({
                copied: true
            })
        } catch (error) {
            console.log('ERROR: ', error)
            this.setState({
                copied: false
            })
        }
        setTimeout(() => {
            this.setState({
                copied: false
            })
        }, 1500)
    }

    /**
     * 
     * @returns the component 
     */
    render () {
        return (
            <ClickAwayListener onClickAway={this.props.handleCloseSuccessAlarm}>
                <div style={{
                    position: 'absolute', 
                    marginLeft: 'auto', 
                    marginRight: 'auto', 
                    marginBottom: 'auto', 
                    marginTop: 'auto', 
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 200, 
                    width: '400px', 
                    height: '195px',
                    gap: '10px', 
                    fontFamily: 'Poppins', 
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '7px'
                }}>
                    {/* <h2 style={{fontWeight: 'normal'}}>Share your map via the link</h2> */}
                    <h2 style={{fontWeight: 'normal'}}>{i18n.t("CopySuccessNotif.ShareYourMapViaTheLink")}</h2> 
                    <Alert
                        action={
                        <Tooltip title={this.state.copied ? `${i18n.t("CopySuccessNotif.Copied")}!` : this.props.alarmAction}>
                            {
                                !this.state.copied
                                ?
                                <IconButton sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '40px', width: '40px'}} onClick={() => this.handleCopy(this.props.hashLink)}>
                                    <ContentCopyIcon style={{fontSize: '19px'}}/>
                                </IconButton>
                                :
                                <span style={{height: '40px', width: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                    <DoneAllIcon style={{fontSize: '19px', color: 'green'}}/>
                                </span>
                            }
                        </Tooltip>
                        }
                        onClose={this.props.handleCloseSuccessAlarm}
                        sx={{width: '85%', fontFamily: 'Poppins', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}
                    >
                        {this.props.message}
                    </Alert>
                    <div
                        id='map-hash-link-container'
                    >
                        <FilledInput 
                            sx={{
                            fontFamily: 'Poppins',
                            fontSize: '14px'
                            }} 
                            fullWidth
                            size='small'
                            id={this.props.hashLink}
                            value={this.props.hashLink}
                        />
                    </div>
                </div>
            </ClickAwayListener>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      language: state.language.language
    };
  };

export default connect(mapStateToProps, {

  })(CopySuccessNotif);