/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* Redux */
import { connect } from 'react-redux'
import { generateWhereelses } from '../redux/actions/whereelse';
import { createObject } from '../redux/actions/object';
/* helper functions */
import { removeWhereelseResults } from '../helpers/helpers'

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton';

/* mui icons-material components */
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

class WhereelseForm extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            radius: 10,
            name: '',
            description: '',
            label: '',
            areaId: null
        }
        /* Function bindings here */
        this.handleChange=this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSubmitAsConfirmed = this.handleSubmitAsConfirmed.bind(this);
    }
    /* */
    componentDidMount(){
        removeWhereelseResults(this.props.map)
        try {
            const urlSearchParams=new URLSearchParams(window.location.search)
            const params=Object.fromEntries(urlSearchParams.entries())
            let areaId=params.areaId
            this.setState({
                name: `${this.props.data.Name}-${this.props.whereelseObject.properties.id}`,
                description: this.props.data.Description,
                label: this.props.data.Name,
                geojson: this.props.whereelseObject,
                areaId: areaId
            })   
        } catch (error) {
            
        }
    }
    /* */
    componentDidUpdate(){
    }
    /* */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /* */
    copyToClipboard = (id) => {
        try {
            document.getElementById(id).select()
            document.execCommand('copy');
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }
    /* */
    handleSubmit() {
        let l = this.props.displayedLayer.MFStitch.split('/').length
        let whereelseBody = {
            center: this.props.center,
            radius: Number(this.props.radius),
            polygon: this.props.data.Geojson.geometry.coordinates[0],
            tile_source: this.props.displayedLayer.MFStitch.split('/').slice(0,l-3).join('/')
        }
        this.props.generateWhereelses(whereelseBody, this.props.map, this.props.atlasML)
    }
    /* */
    handleReset() {
        this.setState({
            radius: 10
        })
    }
    /* */
    handleSubmitAsConfirmed() {
        this.props.createObject({
            Name: this.state.name,
            Description: this.state.description,
            User: this.props.user,
            Storage: this.props.displayedLayer.id,
            Area: this.state.areaId,
            Geojson: this.state.geojson,
            Displayed: true,
            Zoom: 21,
            Label: this.state.label
        })
        this.props.handleClose()
    }
    /* */
    render () {
        return (
            <ClickAwayListener onClickAway={this.props.handleClose}>
                {
                    this.props.input ?
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        marginTop: 'auto', 
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                            <Box sx={{
                                height: 'auto', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'flex-start',
                                alignItems: 'center', 
                                width: '500px',
                                borderRadius: '10px',
                                background: 'white'
                                }}
                                component="form" 
                                type="submit"
                                onSubmit={() => this.handleSubmit()}>
                                <Typography
                                    sx={{
                                        height: '70px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '30px',
                                        fontFamily: 'Poppins',
                                        color: '#121f33'
                                    }}
                                >
                                    {`${i18n.t("WhereelseForm.Confirm")} ${this.state.label}`}
                                </Typography>
                                <div id='login-page-username-container'>
                                    <Tooltip title={i18n.t("WhereelseForm.Name")}>
                                        <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        placeholder={i18n.t("WhereelseForm.Name")}
                                        required
                                        value={this.state.name}
                                        onChange={this.handleChange('name')}
                                        autoFocus={true}
                                    />
                                    <Tooltip title={i18n.t("WhereelseForm.Reset")} placement='right'>
                                        <IconButton 
                                            onClick={() => this.handleInitialName()}
                                        >
                                            <RestartAltIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <br/>
                                <div id='login-page-username-container'>
                                    <Tooltip title={i18n.t("WhereelseForm.Description")}>
                                        <InfoIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                    </Tooltip>
                                    <InputBase
                                        sx={{
                                            borderRadius: '3px',
                                            background: 'white',
                                            fontFamily: 'Poppins',
                                            width: '93%',
                                        }}
                                        placeholder={i18n.t("WhereelseForm.Description")}
                                        value={this.state.description}
                                        onChange={this.handleChange('description')}
                                    />
                                </div>
                                <br/>
                                    <div
                                        id='login-page-username-container'
                                    >
                                        <Tooltip title='Geojson'>
                                            <LocationSearchingIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                                        </Tooltip>
                                        <InputBase
                                            sx={{
                                                borderRadius: '3px',
                                                background: 'white',
                                                fontFamily: 'Poppins',
                                                width: '93%',
                                            }}
                                            value={JSON.stringify(this.state.geojson)}
                                            id={`${this.state.name}-geojson`}
                                        >
                                        </InputBase>
                                        <Tooltip title={i18n.t("WhereelseForm.Copy")} placement='right'>
                                            <IconButton 
                                                    onClick={() => this.copyToClipboard(`${this.state.name}-geojson`)}
                                                >
                                                <ContentCopyIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                            </Box>
                            <div id='edit-form-button-container'>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#1aaa55',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#168f48',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={() => this.handleSubmitAsConfirmed()}
                                >
                                    {i18n.t("WhereelseForm.Submit")}
                                </Button>
                                <Button
                                    sx={{
                                        width: '44%',
                                        height: '37px',
                                        background: '#DC143C',
                                        color: 'white',
                                        fontSize: '13px',
                                        fontFamily: 'Poppins',
                                        '&:hover':{
                                            background: '#b22222',
                                        },
                                        borderRadius: '4px',
                                    }}
                                    onClick={this.props.handleClose}
                                >
                                {i18n.t("WhereelseForm.Close")}
                            </Button>                 
                        </div>
                    </div>
                    :
                    <div style={{
                        position: 'absolute', 
                        marginLeft: 'auto', 
                        marginRight: 'auto', 
                        marginBottom: 'auto', 
                        marginTop: 'auto', 
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 200, 
                        width: 'fit-content', 
                        height: 'fit-content',
                        paddingBottom: 10,
                        gap: '10px', 
                        fontFamily: 'Poppins', 
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '7px'
                    }}>
                        <br/>
                            <Box sx={{
                                height: 'auto', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'center',
                                alignItems: 'center', 
                                width: '500px',
                                borderRadius: '10px',
                                }}
                            >
                                <Alert 
                                    severity={'success'}
                                    color={'info'}
                                    sx={{width:"84%", fontFamily: "Poppins"}}
                                    onClose={this.props.handleClose}
                                    closeText={`i18n.t("BasicAlert.Close")`}
                                >
                                    {`${i18n.t("WhereelseForm.DoYouWantToStartSearchingFor")} ${this.props.data.Name}?`}
                                </Alert> 
                            </Box>
                            <div id='edit-form-button-container'>
                                    <Button
                                        sx={{
                                            width: '44%',
                                            height: '37px',
                                            background: '#1aaa55',
                                            color: 'white',
                                            fontSize: '13px',
                                            fontFamily: 'Poppins',
                                            '&:hover':{
                                                background: '#168f48',
                                            },
                                            borderRadius: '4px',
                                        }}
                                        onClick={() => this.handleSubmit()}
                                    >
                                        {/* Start */}
                                        {i18n.t("WhereelseForm.Start")}
                                    </Button>
                                    <Button
                                        sx={{
                                            width: '44%',
                                            height: '37px',
                                            background: '#DC143C',
                                            color: 'white',
                                            fontSize: '13px',
                                            fontFamily: 'Poppins',
                                            '&:hover':{
                                                background: '#b22222',
                                            },
                                            borderRadius: '4px',
                                        }}
                                        onClick={this.props.handleRedraw}
                                    >
                                        {/* Redraw */}
                                        {i18n.t("WhereelseForm.Redraw")}
                                </Button>                 
                            </div>
                    </div>
                }
            </ClickAwayListener>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        areaId: state.map.areaId,
        user: state.auth.user,
        language: state.language.language,
        /* generateWhereelses */
        isWhereelsesRequested: state.whereelse.isWhereelsesRequested,
        isWhereelsesGenerated: state.whereelse.isWhereelsesGenerated,
        whereelseResults: state.whereelse.whereelseResults,
        isFailedToGenerateWhereelses: state.whereelse.isFailedToGenerateWhereelses,
        /* getWhereelseList */
        iswhereelseListLoading: state.whereelse.iswhereelseListLoading,
        isWhereelseListFetched: state.whereelse.isWhereelseListFetched,
        whereelseList: state.whereelse.whereelseList,
        isFailedToFetchWhereelseList: state.whereelse.isFailedToFetchWhereelseList,
        /* AtlasML related props */
        atlasML: state.hosts.atlasML
    }
}

export default connect(mapStateToProps, { generateWhereelses , createObject })(WhereelseForm)