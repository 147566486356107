import {
    LOAD_MAP, RESET_MAP_STATE
} from '../actions/types';

const initialState = {
    areaId: null,
    lng: null,
    lat: null,
    mapCenter: [],
    clickedLocation: [],
    regionName: null,
    zoom: null,
    mapStateLoaded: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_MAP:
      return {
        ...state,
        rasterLayers: action.payload.layers,
        fcLayers: {...action.payload.fcLayers},
        selectedRasterLayers: action.payload.layers,
        timelapseLayers: action.payload.layers,
        baseLayer: action.payload.layers[0],
        displayedLayer: action.payload.savedState.displayedLayer,
        displayedLayerId: action.payload.savedState.displayedLayerId,
        lng: action.payload.savedState.lng,
        lat: action.payload.savedState.lat,
        mapCenter: action.payload.center,
        clickedLocation: [action.payload.savedState.lng, action.payload.savedState.lat],
        zoom: action.payload.savedState.zoom,
        mapRef: action.payload.mapRef,
        featureCollectionLayers: action.payload.savedState.featureCollectionLayers,
        mapStateLoaded: true,
        regionName: action.payload.regionName
      }
    case RESET_MAP_STATE:
      return {
        areaId: null,
        lng: null,
        lat: null,
        mapCenter: [],
        clickedLocation: [],
        regionName: null,
        zoom: null,
        mapStateLoaded: false
      }
    default:
      return state;
  }
}
