/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { createUser, resetUserForm, getUserList } from '../redux/actions/users';

/* custom components */

/* mui material components */
import Button from '@mui/material/Button'

/* mui material icons */

class AlertAction extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.props.getUserList()
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <div id='alert-action-container'>
                    <Button
                        sx={{
                            width: '33%',
                            background: this.props.disableConfirm ? 'lightgray' : '#1aaa55',
                            color: 'white',
                            fontSize: '10px',
                            fontFamily: 'Poppins',
                            '&:hover':{
                                background: '#168f48',
                            },
                            borderRadius: '4px',
                        }}
                        onClick={this.props.onConfirm}
                        disabled={this.props.disableConfirm}
                    >
                        {`${this.props.confirmText}`}
                    </Button>
                    {
                        !this.props.disableDelete?
                        <Button
                            sx={{
                                width: '33%',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '10px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.onDelete}
                        >
                            {`${this.props.deleteText}`}
                        </Button>:
                        null
                    }
                        <Button
                            sx={{
                                width: '33%',
                                background: '#2196f3',
                                color: 'white',
                                fontSize: '10px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#0277bd',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.onCancel}
                            disabled={this.props.disableCancel}
                        >
                            {`${this.props.cancelText}`}
                        </Button>
                </div>
            )}
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users,

    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList })(AlertAction)