/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { createUser, resetUserForm, getUserList } from '../redux/actions/users';

/* custom components */
import AddArea from './AddArea'


/* mui material components */

/* mui icons-material components */


class Areas extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.props.getUserList()
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(){
    }

    /**
     * 
     */
    handleSubmit(){
    }


    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {
        this.setState({
            userRole: e.target.value
        })
    }

    /**
     * @returns the component
     * Note: The Area table is put in the AddArea component 
     * that contains the map instance for easy interaction 
     * with the map instance with the table 
     */
    render () {
            return (
                <AddArea />
            )}
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users
    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList })(Areas)