/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'

import {
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
  } from "../redux/actions/bucket";

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress'

/* mui icons-material components */

class BucketPolicy extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            bucketPolicy: '',
            defaultPolicy: ''
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleDefault = this.handleDefault.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        this.setState({
            bucketPolicy: ''
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if (prevProps.isBucketPolicyFetched !== this.props.isBucketPolicyFetched && this.props.isBucketPolicyFetched) {
            this.setState({
              bucketPolicy: this.props.bucketPolicy
            })
        }
        if (prevProps.isBucketPolicyFailedToFetch !== this.props.isBucketPolicyFailedToFetch && this.props.isBucketPolicyFailedToFetch) {
            this.setState({
              bucketPolicy: this.props.bucketPolicy
            })
        }
    }

    /* */
    handleSubmit(){
        let credentials = {
            accessKeyId: window.sessionStorage.getItem("accessKeyId"),
            secretAccessKey: window.sessionStorage.getItem("secretAccessKey")
          }
        this.props.putBucketPolicy(this.props.bucketName, this.state.bucketPolicy, credentials)
    }
    /* */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }
    /* */
    handleDefault() {
        this.setState({
            bucketPolicy: `{"Version":"2012-10-17","Statement":[{"Sid":"AddPerm","Effect":"Allow","Principal":"*","Action":"s3:GetObject","Resource":"arn:aws:s3:::${this.props.bucketName}/*"}]}`
        })
    }
    /**
     * 
     * @returns the component 
     */
    render () {
            return (    
                <Dialog
                    open={this.props.isDialogOpen}
                >  
                    {
                    this.props.isBucketLoading
                    ?
                    <div id='area-table-page-container'>
                        <div style={{
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}
                        >
                            {/* <Typography sx={{fontFamily: 'Poppins'}}>Loading Bucket Policy</Typography> */}
                            <Typography sx={{fontFamily: 'Poppins'}}>{i18n.t("BucketPolicy.LoadingBucketPolicy")}</Typography>
                            <br></br>
                            <CircularProgress sx={{color: "#121f33"}}/>
                        </div>
                    </div>
                    :
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* {`Bucket Policy`} */}
                            {i18n.t("BucketPolicy.BucketPolicy")}
                        </Typography>
                        <div style={{
                            width: '91%',
                            height: '40px',
                            marginBottom: '25px'
                        }}>
                        {
                            this.props.isBucketFailedToPut ?
                            // <Alert severity='error'>{`Wrong format`}</Alert> 
                            <Alert severity='error'>{i18n.t("BucketPolicy.WrongFormat")}</Alert> 
                            :
                            // <Alert severity='warning'>{`Please input in the correct format`}</Alert> 
                            <Alert severity='warning'>{i18n.t("BucketPolicy.PleaseInputInTheCorrectFormat")}</Alert>
                        }
                        </div>
                        <div id='add-area-geojson-container'>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                placeholder={'JSON'}
                                required
                                value={this.state.bucketPolicy}
                                rows={8}
                                multiline
                                onChange={this.handleChange('bucketPolicy')}
                            />
                        </div>
                    </Box>
                    }
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '30%',
                                height: '37px',
                                // mb:'20px',
                                background: '#2196f3',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#1769aa',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleDefault}
                        >
                            {/* Default */}
                            {i18n.t("BucketPolicy.Default")}
                        </Button>
                        <Button
                            sx={{
                                width: '30%',
                                height: '37px',
                                // mb:'20px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("BucketPolicy.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '30%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("BucketPolicy.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        alert: state.bucket.alert,
        isBucketListLoading: state.bucket.isBucketListLoading,
        bucketList: state.bucket.bucketList,
        isBucketListFetched: state.bucket.isBucketListFetched,
        isBucketListFailedToFetch: state.bucket.isBucketListFailedToFetch,
        isBucketCreating: state.bucket.isBucketCreating,
        isBucketCreated: state.bucket.isBucketCreated,
        isBucketFailedToCreate: state.bucket.isBucketFailedToCreate,
        bucketPolicy: state.bucket.bucketPolicy,
        newBucket: state.bucket.newBucket,
        isBucketLoading: state.bucket.isBucketLoading,
        isBucketFetched: state.bucket.isBucketFetched,
        isBucketFailedToFetch: state.bucket.isBucketFailedToFetch,
        isBucketPolicyFetched: state.bucket.isBucketPolicyFetched,
        isBucketPolicyFailedToFetch: state.bucket.isBucketPolicyFailedToFetch,
        isBucketCorsFetched: state.bucket.isBucketCorsFetched,
        isBucketCorsFailedToFetch: state.bucket.isBucketCorsFailedToFetch,
        isBucketDeleting: state.bucket.isBucketDeleting,
        isBucketDeleted: state.bucket.isBucketDeleted,
        isBucketFailedToDelete: state.bucket.isBucketFailedToDelete,
        isBucketEditing: state.bucket.isBucketEditing,
        isBucketPut: state.bucket.isBucketPut,
        isBucketFailedToPut: state.bucket.isBucketFailedToPut,
    };
};
  
export default connect(mapStateToProps, {
    getBucketList,
    createBucket,
    resetBucketForm,
    deleteBucket,
    getBucketPolicy,
    putBucketPolicy,
    putCORSConfigurations
})(BucketPolicy);