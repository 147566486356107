/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import {
    getUserGroupNames
} from '../helpers/helpers'
import i18n from 'i18next'

/* custom components */

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton'
import { DataGrid, trTR, enUS } from '@mui/x-data-grid';

/* mui icons-material components */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/MapRounded'
import GroupIcon from '@mui/icons-material/Group';

/* Redux */
import { getAreaList, deleteArea, resetAreaForm, editModeOn, editModeOff } from '../redux/actions/areas';
import { addAreaPolygonsToMap } from '../helpers/helpers'
import AdminSearchBar from './AdminSearchBar'

class AreaTable extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isDialogOpen: false,
            selectedAreaId: null,
            query: ''
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isAreaDeleted !== this.props.isAreaDeleted && this.props.isAreaDeleted){
            this.props.getAreaList()
            this.props.map.removeLayer(`fill-areaPolygon-${this.state.selectedAreaId}`)
            this.props.map.removeLayer(`outline-areaPolygon-${this.state.selectedAreaId}`)
        }

        if(prevProps.areaList !== this.props.areaList){
            addAreaPolygonsToMap(this.props.map, this.props.areaList)
        }

        
    }

    /**
     * 
     */
    handleSubmit(){

    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {

    }

    /**
     * 
     * @param {*} id 
     */
    handleDelete(id) {
        this.props.deleteArea(id)
        this.setState({
            selectedAreaId: id
        })
        setTimeout(() => {
            this.props.resetAreaForm()
        }, 500)
    }
    /**
     * 
     */
    handleClose() {
        this.setState({
            isDialogOpen: false
        })
    }
    /**
     * 
     * @returns the component 
     */
    render () {

        const Fly = (props) =>                 
        <IconButton onClick={() => this.props.handleFly(this.props.areaList.find((area) => area.id == props.id).center, props.id)} disabled={this.props.isEditMode}>
            <MapIcon/>
        </IconButton> 

        const Edit = (props) =>                 
        <IconButton onClick={() => this.props.handleEdit(props.id)} disabled={this.props.isEditMode}>
            <EditIcon/>
        </IconButton> 

        const Delete = (props) =>                 
        <IconButton onClick={() => this.handleDelete(props.id)} disabled={this.props.isEditMode}>
            <DeleteIcon/>
        </IconButton> 

        const Info = (props) =>
        <Tooltip placement='left' title={this.props.groups?getUserGroupNames(this.props.areaList.find(area=> area.id==props.id),this.props.groups):''}>
            <IconButton onClick={()=>console.log()} disabled={this.props.isEditMode}>
                <GroupIcon/>
            </IconButton> 
        </Tooltip>    

        const columns = [
            {field: 'id', headerName: 'ID' , width: 70},
            // {field: 'regionName', headerName: 'Area' , width: 270},
            {field: 'regionName', headerName: i18n.t("AreaTable.Area") , width: 270},
            // {field: 'flightsCount', headerName: '# of Flights' , width: 130},
            {field: 'flightsCount', headerName: i18n.t("AreaTable.NumberOfFlights") , width: 130},
            // {field: 'userGroup', headerName: 'Group' , width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
            // {field: 'location', headerName: 'Fly' , width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Fly(props)},
            // {field: 'flightCount', headerName: 'Edit' , width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Edit(props)},
            // {field: 'delete', headerName: 'Delete' , width: 98, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
            {field: 'userGroup', headerName: i18n.t("AreaTable.Group") , width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
            {field: 'location', headerName: i18n.t("AreaTable.Fly"), width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Fly(props)},
            {field: 'flightCount', headerName: i18n.t("AreaTable.Edit") , width: 90, sortable: false, disableColumnMenu: true, renderCell: (props) => Edit(props)},
            {field: 'delete', headerName: i18n.t("AreaTable.Delete") , width: 98, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
        ]

        const result = this.props.areaList.filter((area)=>area.RegionName.toLowerCase().includes(this.state.query.toLowerCase()))
        const rows = result.map((area)=>(
            {
                id: area.id, 
                regionName: area.RegionName, 
                flightsCount: area.FlightsCount,
                userGroup: '',
                edit: '',
                delete: '',
                location: '',
            }
        ))

        return (
            <div 
                id='area-table-page-container'
            >
                <div id='add-area-page-alert-container'>
                {
                    this.props.isAreaDeleted?
                    <Alert severity='success'>
                        {/* {`Successfully deleted!`} */}
                        { i18n.t("AreaTable.SuccessfullyDeleted")}
                    </Alert> 
                    :
                    null
                }
                {
                    this.props.isFailedToDeleteArea ?
                    <Alert severity='error'>
                        {/* {`Failed to delete!`} */}
                        { i18n.t("AreaTable.FailedToDelete")}
                    </Alert> 
                    :
                    null
                }
                </div>
                <AdminSearchBar
                    handleChange={this.handleChange}
                    // text='Search area'
                    // iconTitle='Region Name'
                    text={i18n.t("AreaTable.SearchArea")}
                    iconTitle={i18n.t("AreaTable.RegionName")}
                    value={this.state.query}
                    width='840px'
                />
                <div id='area-table-container'>
                    <DataGrid 
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowHeight={43}
                        rowsPerPageOptions={[10]}
                        sx={{
                            fontFamily: 'Poppins',
                            overflowY: 'hidden'
                        }}
                        disableSelectionOnClick
                        localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </div>
        )}
}

const mapStateToProps = (state) => {
    return {
        isAreaCreated: state.areas.isAreaCreated,
        isFailedToCreateArea: state.areas.isFailedToCreateArea,
        areaList: state.areas.areaList,
        isAreaDeleted: state.areas.isAreaDeleted,
        isFailedToDeleteArea: state.areas.isFailedToDeleteArea,
        message: state.areas.message,
        isEditMode: state.areas.isEditMode,
        groups: state.groups.groups,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { getAreaList, deleteArea, resetAreaForm, editModeOn, editModeOff})(AreaTable)