/* eslint-disable eqeqeq */
import React from 'react'

/* custom components */

/* mui material components */
import Checkbox from '@mui/material/Checkbox'
import { withStyles } from '@mui/styles'

/* mui icons-material components */

export const BlackCheckbox = withStyles({
    root: {
        color: '#000000',
        "&$checked": {
            color: '#000000',
        },
        "&$indeterminate": {
            color: '#000000',
        }
    },
    checked: {},
    indeterminate: {}
})((props) => <Checkbox {...props} />)

export const GrayCheckbox = withStyles({
    root: {
        color: '#b1b2b0',
        "&$checked": {
            color: '#b1b2b0'
        }
    },
    checked: {}
})((props) => <Checkbox {...props} />)

//export default BlackCheckbox