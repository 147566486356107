/* eslint-disable eqeqeq */
import React from 'react'
import i18n from 'i18next'

/* redux */
import { connect } from 'react-redux'
import { createUser, resetUserForm, getUserList, getEditedUser, putUser } from '../redux/actions/users';

/* custom components */

/* mui material components */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

/* mui icons-material components */
import EmailIcon from '@mui/icons-material/Email';


class AddUser extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            email: '',
            userRole: 'none',
            loggedIn: false,
            loginFailed: false
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
        window.addEventListener('keypress', (e) =>{
            if(e.key === 'Enter'){
                this.handleSubmit()
            }
        })
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isUserPut !== this.props.isUserPut && this.props.isUserPut){
            this.props.getUserList()
        }
    }

    /**
     * 
     */
    handleSubmit(){
        this.props.putUser({
            user:{
                username: this.props.editedUser.username,
                id: this.props.editedUser.id,
                email: this.state.email == '' ? this.props.editedUser.email : this.state.email,
                is_superuser: this.state.userRole === 'admin',
                is_staff: this.state.userRole === 'admin',
            }, 
            token: `token ${window.localStorage.getItem('token')}`
        })
        this.props.handleClose()
        this.setState({
            username: '',
            password: '',
            email: '',
            userRole: 'none'
        })
        setTimeout(() => {
            this.props.resetUserForm()
        }, 2000)
    }


    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {
        this.setState({
            userRole: e.target.value
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {
            return (
                <Dialog
                    open={this.props.isDialogOpen}
                >
                    <Box sx={{
                        height: 'auto', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start',
                        alignItems: 'center', 
                        width: '500px',
                        borderRadius: '10px',
                        background: 'white'
                        }}
                        component="form" 
                        type="submit"
                        onSubmit={this.handleSubmit}>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '30px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {/* {`Edit User`} */}
                            {i18n.t("EditUser.EditUser")}
                        </Typography>
                        <Typography
                            sx={{
                                height: '70px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '15px',
                                fontFamily: 'Poppins',
                                color: '#121f33'
                            }}
                        >
                            {`${this.props.editedUser.username}, ${this.props.editedUser.is_superuser?i18n.t("EditUser.Admin"):i18n.t("EditUser.Viewer")} ${this.props.editedUser.group?`, ${this.props.editedUser.group}`:''}`}
                        </Typography>
                        <div id='login-page-username-container'>
                            <Tooltip title='E-mail'>
                                <EmailIcon sx={{color: 'lightgray', ml: '5px', mr: '4px'}}/>
                            </Tooltip>
                            <InputBase
                                sx={{
                                    borderRadius: '3px',
                                    background: 'white',
                                    fontFamily: 'Poppins',
                                    width: '93%',
                                }}
                                label='asd'
                                placeholder={`${this.props.editedUser.email}`}
                                required
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                            />
                        </div>
                        <br/>
                        <div id="admin-panel-formcontrol-container">
                            <FormControl 
                                placeholder='E-mail'
                                fullWidth sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                                }}>
                                <Select
                                    value={this.state.userRole} 
                                    defaultValue='none' 
                                    sx={{height: '42px', color: this.state.userRole=='none'?'lightgray':'black', fontFamily: 'Poppins', width: '91%'}} 
                                    onChange={this.handleUserRole}
                                >
                                    <MenuItem value='none' disabled sx={{color: 'gray', fontFamily: 'Poppins'}}>
                                        {/* Select user role */}
                                        {i18n.t("EditUser.SelectUserRole")}
                                    </MenuItem>
                                    <MenuItem value='viewer' sx={{fontFamily: 'Poppins'}}>
                                        {/* Viewer */}
                                        {i18n.t("EditUser.Viewer")}
                                    </MenuItem>
                                    <MenuItem value='admin' sx={{fontFamily: 'Poppins'}}>
                                        {/* Admin */}
                                        {i18n.t("EditUser.Admin")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <DialogActions >
                    <div id='edit-form-button-container'>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                // mb:'20px',
                                background: '#1aaa55',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#168f48',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.handleSubmit}
                        >
                            {/* Submit */}
                            {i18n.t("EditUser.Submit")}
                        </Button>
                        <Button
                            sx={{
                                width: '46%',
                                height: '37px',
                                background: '#DC143C',
                                color: 'white',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                '&:hover':{
                                    background: '#b22222',
                                },
                                borderRadius: '4px',
                            }}
                            onClick={this.props.handleClose}
                        >
                            {/* Close */}
                            {i18n.t("EditUser.Close")}
                        </Button>                      
                    </div>
                    </DialogActions>
                </Dialog>
            )}
}

const mapStateToProps = (state) => {
    return {
        isSuccess: state.users.isSuccess,
        isFailed: state.users.isFailed,
        newUser: state.users.newUser,
        error: state.users.error,
        users: state.users.users,
        editedUser: state.users.editedUser,
        isUserPut: state.users.isUserPut,
        isFailedToPutUser: state.users.isFailedToPutUser,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList, getEditedUser, putUser })(AddUser)