/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'

/* redux */
import { createUser, resetUserForm, getUserList, deleteUser, getEditedUser, putUser } from '../redux/actions/users';

/* custom components */

/* mui material components */
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton'
import { DataGrid, enUS, trTR } from '@mui/x-data-grid';


/* mui icons-material components */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditUser from './EditUser'
import GroupIcon from '@mui/icons-material/Group';


class UserTable extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isDialogOpen: false
        }

        /* Function bindings here */
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleUserRole = this.handleUserRole.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    /**
     * When the component is rendered
     */
    componentDidMount(){
    }

    /**
     * When the component is updated
     */
    componentDidUpdate(prevProps){
        if(prevProps.isDeleted !== this.props.isDeleted && this.props.isDeleted){
            this.props.getUserList()
        }
    }

    /**
     * 
     */
    handleSubmit(){

    }

    /**
     * 
     */
    handleChange = name => (event) => {
        this.setState({
            [name] : event.target.value
        })
    }

    /**
     * 
     */
    handleUserRole(e) {

    }

    /**
     * 
     * @param {*} id 
     */
    handleDelete(id) {
        this.props.deleteUser(id)
        setTimeout(() => {
            this.props.resetUserForm()
        }, 3000)
    }

    /**
     * 
     * @param {*} id 
     */
    handleEdit(id) {
        this.setState({
            isDialogOpen: true
        })
        this.props.getEditedUser(id)
    }

    /**
     * 
     */
    handleClose() {
        this.setState({
            isDialogOpen: false
        })
    }

    /**
     * 
     * @returns the component 
     */
    render () {

        const Delete = (props) =>                 
        <IconButton onClick={() => this.handleDelete(props.id)}>
            <DeleteIcon/>
        </IconButton> 

        const Edit = (props) =>                 
        <IconButton onClick={() => this.handleEdit(props.id)}>
            <EditIcon/>
        </IconButton> 

        const Info = (props) =>
            <Tooltip placement='left' title={this.props.users.find(user=>user.id==props.id)?this.props.users.find(user=>user.id==props.id).group:''}>
                <IconButton onClick={()=>console.log()} disabled={this.props.isEditMode}>
                    <GroupIcon/>
                </IconButton> 
            </Tooltip>  

        const columns = [
            {field: 'id', headerName: 'ID' , width: 70},
            {field: 'group', headerName: i18n.t("UserTable.Group") , width: 80, sortable: false, disableColumnMenu: true, renderCell: (props) => Info(props)},
            {field: 'username', headerName: i18n.t("UserTable.User")  , width: 110},
            {field: 'role', headerName: i18n.t("UserTable.Type")  , width: 90},
            {field: 'edit', headerName: i18n.t("UserTable.Edit")  , width: 73, sortable: false, disableColumnMenu: true, renderCell: (props) => Edit(props)},
            {field: 'delete', headerName: i18n.t("UserTable.Delete")  , width: 75, sortable: false, disableColumnMenu: true, renderCell: (props) => Delete(props)},
        ]

        const rows = this.props.users.map((user)=>(
            {
                id: user.id, 
                username: user.username, 
                role: user.is_superuser ? i18n.t("UserTable.Admin") : i18n.t("UserTable.Viewer"),
                edit: 'edit',
                delete: 'asd'
            }
        ))

        return (
            <div 
                id='user-table-page-container'
            >
                <div id='admin-page-alert-container'>
                {
                    this.props.isDeleted ?
                    <Alert severity='success'>{i18n.t("UserTable.SuccessfullyDeleted")}</Alert> 
                    :
                    null
                }
                {
                    this.props.isFailedToDelete ?
                    <Alert severity='error'>{i18n.t("UserTable.FailedToDelete")}</Alert> 
                    :
                    null
                }
                {
                    this.props.isUserPut ?
                    <div id='admin-page-alert-container'>
                        <Alert onClose={this.props.resetUserForm} severity='success'>{`${i18n.t("UserTable.EditedTheUser")}: ${this.props.editedUser.username}`}</Alert> 
                    </div>
                    :
                    null
                }
                {
                    this.props.isFailedToPutUser ?
                    <div id='admin-page-alert-container'>
                        <Alert onClose={this.props.resetUserForm} severity='error'>{`${i18n.t("UserTable.FailedToEditTheUser")}: ${this.props.editedUser.username}`}</Alert> 
                    </div>
                        :
                    null
                } 
                </div>
                <div id='user-table-container'>
                    <DataGrid 
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowHeight={73}
                        rowsPerPageOptions={[5]}
                        sx={{
                            fontFamily: 'Poppins',
                            overflowY: 'hidden'
                        }}
                        disableSelectionOnClick
                        localeText={this.props.language=="GB"?enUS.components.MuiDataGrid.defaultProps.localeText:trTR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
                <EditUser
                    isDialogOpen={this.state.isDialogOpen}
                    handleClose={this.handleClose}
                />
            </div>
        )}
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users,
        isDeleted: state.users.isDeleted,
        isFailedToDelete: state.users.isFailedToDelete,
        editedUser: state.users.editedUser,
        isUserPut: state.users.isUserPut,
        isFailedToPutUser: state.users.isFailedToPutPuser,
        groups: state.groups.group,
        language: state.language.language
    }
}

export default connect(mapStateToProps, { createUser, resetUserForm, getUserList, deleteUser, getEditedUser, putUser })(UserTable)