import {
    CHANGES_LOADING,
    CHANGES_FETCHED,
    CHANGE_CREATING,
    CREATED_CHANGE,
    FAILED_NEW_CHANGE,
    CHANGE_LOADING,
    CHANGE_FETCHED,
    FAILED_CHANGE,
    CHANGE_EDITING,
    CHANGE_PUT,
    FAILED_TO_PUT_CHANGE,
    CHANGE_DELETING,
    CHANGE_DELETED,
    RESET_CHANGE_FORM,
    FAILED_TO_DELETE_CHANGE,
    CHANGE_DETECTION_STARTED,
    CHANGES_GENERATED,
    FAILED_TO_GENERATE_CHANGES
  } from '../actions/types';

const initialState = {
    change: {}, //clicked individual change
    changeList: [],
    changes: {
      'type': 'FeatureCollection',
      'features': []
    }, //changesFeatureCollection
    isChangesFetched: false,
    isChangeListLoading: false,
    isChangeLoading: false,
    isChangeFetched: false,
    isChangeFailedToFetch: false,
    isChangeCreating: false,
    isChangeCreated: false,
    isFailedToCreateChange: false,
    isChangeEditing: false,
    isChangePut: false,
    isChangeFailedToPut: false,
    isChangeDeleting: false,
    isChangeDeleted: false,
    isFailedToDeleteChange: false,
    //AtlasML
    isChangeDetectionStarted: false,
    isChangeDetectionsGenerated: false,
    changeDetectionResults: [],
    isFailedToGenerateChangeDetections: false
};

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
      case CHANGES_LOADING:
        return {
            ...state,
            changes: {
              'type': 'FeatureCollection',
              'features': []
            },
            isChangeListLoading: true,
            isChangesFetched: false
        }
      case CHANGES_FETCHED:
        return {
            ...state,
            changes: action.payload,
            isChangeListLoading: false,
            isChangesFetched: true
        }
      case CHANGE_CREATING:
        return {
            ...state,
            isChangeCreating: true,
            isChangeCreated: false,
            isFailedToCreateChange: false,
        }
      case CREATED_CHANGE:
        return {
            ...state,
            isChangeCreating: false,
            isChangeCreated: true,
            isFailedToCreateChange: false,
            message: action.payload
        }
      case FAILED_NEW_CHANGE:
        return {
            ...state,
            isChangeCreating: false,
            isChangeCreated: false,
            isFailedToCreateChange: true,
            message: action.payload
        }
      case CHANGE_LOADING:
        return {
          ...state,
          change:{},
          isChangeLoading: true,
          isChangeFetched: false,
          isChangeFailedToFetch: false
        }
      case CHANGE_FETCHED:
        return {
          ...state,
          change: action.payload,
          isChangeFetched: true,
          isChangeFailedToFetch: false,
          isChangeLoading: false,
        }
      case FAILED_CHANGE:
        return {
          ...state,
          change: {},
          isChangeFetched: false,
          isChangeFailedToFetch: true,
          isChangeLoading: false,
        }
      case CHANGE_EDITING:
        return {
          ...state,
          isChangeEditing: true,
          isChangePut: false,
          isChangeFailedToPut: false
        }
      case CHANGE_PUT:
        return {
          ...state,
          isChangeEditing: false,
          isChangePut: true,
          isChangeFailedToPut: false
        }
      case FAILED_TO_PUT_CHANGE:
        return {
          ...state,
          isChangeEditing: false,
          isChangePut: false,
          isChangeFailedToPut: true
        }
      case CHANGE_DELETING:
        return {
          ...state,
          isChangeDeleting: true,
          isChangeDeleted: false,
          isFailedToDeleteChange: false,
          message: 'Deleting change...'
        }
      case CHANGE_DELETED:
        return {
          ...state,
          isChangeDeleting: false,
          isChangeDeleted: true,
          isFailedToDeleteChange: false,
          message: action.payload
        }
      case FAILED_TO_DELETE_CHANGE:
        return {
          ...state,
          isChangeDeleting: false,
          isFailedToDeleteChange: true,
          isChangeDeleted: false,
          message: action.payload
        }
      case RESET_CHANGE_FORM:
        return {
          change: {}, //clicked individual change
          changeList: [],
          changes: {
            'type': 'FeatureCollection',
            'features': []
          }, //changesFeatureCollection
          isChangesFetched: false,
          isChangeListLoading: false,
          isChangeLoading: false,
          isChangeFetched: false,
          isChangeFailedToFetch: false,
          isChangeCreating: false,
          isChangeCreated: false,
          isFailedToCreateChange: false,
          isChangeEditing: false,
          isChangePut: false,
          isChangeFailedToPut: false,
          isChangeDeleting: false,
          isChangeDeleted: false,
          isFailedToDeleteChange: false
        }
      case CHANGE_DETECTION_STARTED:
        return {
          ...state,
          isChangeDetectionStarted: true,
          isChangeDetectionsGenerated: false,
          changeDetectionResults: [],
          isFailedToGenerateChangeDetections: false
        }
      case CHANGES_GENERATED:
        return {
          ...state,
          isChangeDetectionStarted: false,
          isChangeDetectionsGenerated: true,
          changeDetectionResults: action.payload,
          isFailedToGenerateChangeDetections: false
        }
      case FAILED_TO_GENERATE_CHANGES:
        return {
          ...state,
          isChangeDetectionStarted: false,
          isChangeDetectionsGenerated: false,
          changeDetectionResults: [],
          isFailedToGenerateChangeDetections: false
        }
      default:
        return state;
    }
}
  




